<template>
  <div
    id="container"
    class="map_container"
    v-loading="loading"
    element-loading-text="拼命加载车辆轨迹中..."
  ></div>
</template>

<script>
import { _FindParkByCarOperation } from "@/api/equipmentMonitoring";
import { _GetSweepTrack } from "@/api/sweep/index.js";
import { calculateCenter, computeSignedArea } from "@/until/helper";
import util from "@/until/WSCoordinate.js";
export default {
  data() {
    return {
      loading: true,
      map: null,
      polygon: null,
      sParkId: sessionStorage.getItem("parkId"),
      arrCenter: null, //中心
      nZoom: null, //缩放
      carRoute: "", //车辆路径
      route: "", //车辆路径
      routeList: null //路径列表
    };
  },
  props: ["jobDetail"],
  async created() {},
  async mounted() {
    //  获取园区信息
    await this.getParkInfo();
    // 添加地图
    this.initMap();
    // 添加园区
    this.initPolygon();
    // 添加线路
    this.addCarRoute();
    // 获取轨迹
    await this.getSweepTrack();
  },
  methods: {
    // 获取绘制园区
    async getParkInfo() {
      let { data } = await _FindParkByCarOperation({ parkId: this.sParkId },this);
      // console.log(data, 9999);
      this.routeList = data.carRouteList; //获取全部路径
      //计算园区范围的中心点
      let routeSite = JSON.parse(data.parkScope);
      if (routeSite.length > 0) {
        routeSite.push(routeSite[0]); //绘制线路需要将第一个元素复制一个放到数组最后，完成高德线段的闭合绘制
      }
      this.arrCenter = [
        calculateCenter(routeSite).lng,
        calculateCenter(routeSite).lat
      ];
      //根据面积，配置地图缩放级别
      let nLngLatArea = Math.round(computeSignedArea(routeSite));
      if (nLngLatArea <= 1) {
        this.nZoom = 16;
      } else if (nLngLatArea <= 2) {
        this.nZoom = 15;
      } else if (nLngLatArea <= 3) {
        this.nZoom = 14;
      } else if (nLngLatArea <= 4) {
        this.nZoom = 13;
      } else {
        this.nZoom = 12;
      }
      //   圈园区
      // 多边形轮廓线的节点坐标数组
      let path = [];
      routeSite.forEach(ele => {
        path.push(new AMap.LngLat(ele[0], ele[1]));
      });
      this.polygon = new AMap.Polygon({
        path: path,
        fillColor: "#52A2EB", // 多边形填充颜色
        borderWeight: 2, // 线条宽度，默认为 1
        strokeColor: "#52A2EB" // 线条颜色
      });
    },
    // init地图
    initMap() {
      //   创建园区对象
      this.map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: this.nZoom,
        center: this.arrCenter
      });
    },
    // initPolygo园区大小
    initPolygon() {
      this.map.add([this.polygon]);
    },
    // 获取车辆轨迹信息
    async getSweepTrack() {
      // 定义请求params
      let params = {
        page: 1,
        size: 200,
        carNumber: this.jobDetail.carNumber,
        startTime: this.jobDetail.startTime,
        endTime: this.jobDetail.endTime
      };
      let res = await _GetSweepTrack(params);
      // 该时段有报文
      if (res) {
        let countAll = Math.ceil(res.data.total / 200);
        let count = 0; //计数判断请求是否全部返回
        let twoDimension = []; // 二维路径数组
        for (let i = 1; i <= countAll; i++) {
          let params2 = {
            page: i,
            size: 200,
            carNumber: this.jobDetail.carNumber,
            startTime: this.jobDetail.startTime,
            endTime: this.jobDetail.endTime
          };
          // 同时发出全部请求
          _GetSweepTrack(params2).then(res => {
            ++count; // 请求回来计数加一
            twoDimension[i - 1] = res.data.carRoute; // 二维数组顺序添加
            // 请求全部回来之后
            if (count == countAll) {
              let path = twoDimension.flat();
              this.carRoute = new AMap.Polyline({
                map: this.map,
                path: path,
                strokeWeight: 8, // 线条宽度，默认为 1
                strokeColor: "#20B00B", // 线条颜色
                zIndex: 100,
                showDir: true
              });
              this.loading = false;
            }
            // 最后一页请求回来之后添加车辆
            if (i == countAll) {
              let data = res.data;
              let carInfo = data.list[data.list.length - 1];
              // 转换坐标
              let amapCarGps = util.transformFromWGSToGCJ(
                parseFloat(carInfo.latitude),
                parseFloat(carInfo.longitude)
              );
              // 添加车辆
              this.mapCarMarker = new AMap.Marker({
                map: this.map,
                position: [amapCarGps.longitude, amapCarGps.latitude],
                angle: parseFloat(carInfo.head),
                offset: new AMap.Pixel(-20, -17),
                icon: new AMap.Icon({
                  image: require("@/assets/images/icon_qsc_default.png"),
                  imageSize: new AMap.Size(36, 36),
                  size: new AMap.Size(36, 36)
                })
              });
            }
          });
        }
      } else {
        this.loading = false;
      }
    },
    //添加规定路径
    addCarRoute() {
      // console.log(this.routeList);
      let path = null;
      this.routeList.forEach(ele => {
        if (ele.routeName == this.jobDetail.carRoute) {
          path = JSON.parse(ele.routeSite);
        }
      });
      if (path) {
        this.route = new AMap.Polyline({
          map: this.map,
          path: path,
          strokeWeight: 4, // 线条宽度，默认为 1
          strokeColor: "red" // 线条颜色
        });
      }
    }
  },
  beforeDestroy() {
    //清除地图实例
    this.map.destroy();
  }
};
</script>

<style lang="less" scoped>
#container {
  width: 100%;
  height: 100%;
}
</style>
