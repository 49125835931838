import service from '../../until/api'
//车辆运营
export function _PtBusList(data) {
    return service({
        url: 'patrol-bus/web/vehicleOperation/findCarOperationMessage',
        method: 'GET',
        params: data
    })
}


export function _PtBusStatus(data) {
    return service({
        url: 'patrol-bus/web/vehicleOperation/switchOperationStatus',
        method: 'GET',
        params: data
    })
}
//
export function _PtBuscleaningTask(data) {
    return service({
        url: 'patrol-bus/web/taskManager/findPage',
        method: 'GET',
        params: data
    })
}
export function _PtBusAdd(data) {
    return service({
        url: 'patrol-bus/web/taskManager/insert',
        method: 'POST',
        data
    })
}
export function _PtBusUp(data) {
    return service({
        url: 'patrol-bus/web/taskManager/update',
        method: 'POST',
        data
    })
}
//园区巡逻告警
// export function _PtBusPwList(data) {
//     return service({
//         url: 'patrol-bus/web/patrolWarning/findPage',
//         method: 'POST',
//         data
//     })
// }
export function _PtBusPwList(data) {
    return service({
        url: 'patrol-bus/web/patrolWarning/findPage',
        method: 'GET',
        params: data
    })
}
export function _PtBusPwStatus(data) {
    return service({
        url: 'patrol-bus/web/patrolWarning/strangerRecognitionReminder',
        method: 'GET',
        params: data
    })
}
//sos记录查询

export function _PtBussosList(data) {
    return service({
        url: 'patrol-bus/web/sosRecord/findPage',
        method: 'GET',
        params: data
    })
}
export function _PtBussosDel(data) {
    return service({
        url: 'patrol-bus/web/sosRecord/delete',
        method: 'GET',
        params: data
    })
}
//人脸信息
export function _PtBusinfList(data) {
    return service({
        url: 'patrol-bus/web/informationCollection/findPage',
        method: 'GET',
        params: data
    })
}





//视频查看


// export funct