<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="searchObj.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" @click="searchpacList()">搜索</button>
        <button class="centerBtn resetbtn" @click="clearAll()">重置</button></el-form-item
      >
    </el-form>
    <el-table  :data="tableData"   style="width: 100%">
      <el-table-column  type="index"  label="序号" width="130"> </el-table-column>
      <el-table-column prop="vehicleNumber"  label="车辆编号"  width="250"></el-table-column>
      <el-table-column  prop="carType"  label="车辆类型"  width="250"></el-table-column>
      <el-table-column  prop="carStatus" label="车辆状态"></el-table-column>
      <el-table-column  prop="count" label="摄像头数量"></el-table-column>
      <el-table-column   label="操作">
        <template slot-scope="scope">
            <el-link type="primary" @click="getVideo(scope.row)" v-if="scope.row.carStatus == '在线' " >查看监控录像</el-link>
            <span v-else>- -</span>
        </template>
      </el-table-column>
  </el-table>
  <el-row>
    <el-col :span="8" :offset="14" calss="currPage">
       <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="searchObj.page"
        :page-sizes="[10, 20, 30, 40,50,100]"
        :page-size="searchObj.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>  
    </el-col> 
  </el-row> 
   <el-drawer
      title="查看视频监控点"
      :visible.sync="detail"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="50%"
      :destroy-on-close = true
    >
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="实时视频" name="first">
            <p class="textcolor">车辆编号</p>
            <p class="flex justify-between align-end">
              <span class="textbig">{{videoVehicleNumber}}</span>
              <span class="textcolor">{{nowDate}}</span>
            </p>
           <!-- 如果有视频源就显示视频没有就显示未上线 -->
           <!-- <button class="centerCzbtnB">播放</button> -->
            <p v-if="videoCarStatus == false" class="videoMessage">车辆未在线无法查看</p>
            <el-card shadow="never" class="videoCard" v-else>
                <PacvideoShow
                ref="pacvideoShow"
                v-if="detail && videoCarStatus"
                :url.sync="videoUrl"
                @openVideoUrl="openVideoUrl"
                @closeVideoUrl="closeVideoUrl"
                ></PacvideoShow>
            </el-card>
          </el-tab-pane>
          <!-- <el-tab-pane label="历史视频" name="second">
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="选择日期"
              class="date"
            >
            </el-date-picker>
            <el-time-picker
              is-range
              v-model="value2"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              class="time"
            >
            </el-time-picker>
            <button class="centerCzbtn ml20">搜索</button>
            <button class="centerBtn resetbtn">重置</button>
            <p class="textcolor textB">车辆编号</p>
            <p class="flex justify-between align-end">
              <span class="textbig">{{videoVehicleNumber}}</span>
              <span class="textcolor">{{nowDate}}</span>
            </p>
            <el-card shadow="never" class="videoCard" >
                   视频界面
            </el-card>
          </el-tab-pane>  -->
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {_getpactrol , _getVideoUrl, _openVideoUrl, _closeVideoUrl}from '@/api/patrolBus/pacvideoViewing';
import PacvideoShow from "../../components/pacvideoShow.vue"

export default {
  components: {
    PacvideoShow,
  },
  
  data() {
    return {
      detail: false,
      activeName: "first",
      formLabelWidth: "100px",
      value1: "",
      value2: "",
      //请求对象
      searchObj: {
        carNumber: "",
        page: 1,
        size: 10,
        parkId: sessionStorage.getItem("parkId")
      },
      tableData: [],
      // pageSize: 10,//每页数目
      // pageNum: 1,//当前页
      total: 0,//总条数
      //视频页面
      videoVehicleNumber: 0,
      nowDate:'',
      videoCarStatus: true,
      videoUrl: '',
      deviceId: '',
      channelId: '',
    };
  },
  mounted(){
    this.getAllCar();
    
    // this.createVideo();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },

    // searchpacList(){
    //   this.searchObj.page = 1;
    //   this.getAllCar()
    // },
    //显查询表格 获得所有车辆
    getAllCar(){  
        // console.log("查询")
      _getpactrol(this.searchObj).then((res)=>{  
        // console.log(res)
          if(res.success){
             //分页
              this.searchObj.page = res.data.pageNum;
              this.searchObj.size  = res.data.pageSize;
              this.total = res.data.total;
              let newArr = [];
              res.data.list.forEach(item => {
                  if(item.carStatus == 0){
                    item.carStatus = '离线'
                  }else{
                    item.carStatus = '在线'
                  }
                  newArr.push({
                    vehicleNumber : item.vehicleNumber,
                    carType : item.carType,
                    carStatus : item.carStatus,
                    count : item.count
                  })
              });
               this.tableData = newArr
          }else{
            this.$message.error(res.message)
          }
      })
    },
    //搜索按钮
    searchpacList(){
      this.searchObj.page = 1;
       this.getAllCar(this.searchObj)
    },
    //重置
    clearAll(){
        this.searchObj.carNumber="";
        this.getAllCar(this.searchObj)
    },
    //点击时候传入车辆点击的车辆信息 传回视频信息
   async getVideo(row){
      this.currentTime();//时间
      this.detail = !this.detail
      this.videoVehicleNumber = row.vehicleNumber
      if(row.carStatus === "离线" ){
         this.videoCarStatus = false
      }else{
        this.videoCarStatus = true
        let {data} = await _getVideoUrl({ vehicleNumber: row.vehicleNumber })
        this.videoUrl = data.yl2
        // this.videoUrl = "http://1011.hlsplay.aodianyun.com/demo/game.flv" 测试用
        // deviceId=34020000001110000001&channelId=34020000001310000005
        this.deviceId = data.yl3
        this.channelId = data.yl4
        // console.log(row.vehicleNumber,data);
      }
    },
    //页面条数改变
    sizeChange(newsize){
      this.searchObj.size = newsize
      this.getAllCar(this.searchObj)
    },
    //当前页
    currentChange(newnum){
      this.searchObj.page = newnum
      this.getAllCar(this.searchObj)
    },
    //视频
    async openVideoUrl(){
      let {data} = await _openVideoUrl({deviceId:this.deviceId,channelId:this.channelId})
      console.log({deviceId:this.deviceId,channelId:this.channelId});
      this.$message({
          message:data,
          type:'success',
          customClass:'message-override'
        });
   },
    async closeVideoUrl(){
      await _closeVideoUrl({deviceId:this.deviceId,channelId:this.channelId})
   },
    //获取当前时间
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}-${month}-${day}  ${hour}:${minute}:${second}`;
    }
  },
};
</script>

<style scoped lang='less'>
/deep/.el-tabs__active-bar {
  width: 280px !important;
}
/deep/.el-tabs__item {
  width: 280px;
  text-align: center;
}
/deep/.el-tabs__nav-wrap::after {
  width: 560px;
}
.date /deep/.el-input__inner {
  width: 136px;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
/deep/ .el-range-editor.el-input__inner {
  width: 190px;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
  margin-left: -30px;
}
.textbig {
  font-size: 27px;
  font-weight: 400;
  color: #666666;
}
/deep/.el-range-input {
  background: #f5f8fa;
}
.textB {
  margin-top: 20px;
}
// 
/deep/.el-tabs__header{
  display: none;
}
.videoCard {
  margin-top: 30px;
  width:100%;
  height:50%;
  /deep/.el-card__body{
    padding: 0;
  }
}
.videoMessage{
  margin-top:20px;
  font-size:20px;
  font-weight:bold;
}
.currPage{
  margin:10px 0 10px 0;
}
</style>