<template>
  <div id="pageContainer">
    <el-form :inline="true">
      <el-form-item>
        <!-- <el-input
          v-model="searchObj.vehicleNumber"
          placeholder="车辆编号"
          class="centerInput bottom"
        ></el-input> -->
        <el-autocomplete
          :trigger-on-focus="false"
          class="inline-input centerInput bottom"
          v-model="searchObjCopy.vehicleNumber"
          :fetch-suggestions="querySearch"
          value-key="carNumber"
          placeholder="车辆编号"
        >
          <template slot-scope="{ item }">
            {{ item.carNumber }}
          </template>
        </el-autocomplete>
      </el-form-item>

      <el-form-item>
        <el-date-picker
          v-model="searchObjCopy.datetime"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin-right: 20px"
          class="bottom"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <button
          class="centerCzbtn ml20"
          type="button"
          @click="DoSearch"
        >
          <!-- :disabled="carMoveData.length > 0" -->
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="ResetSearch">
          重置
        </button>
      </el-form-item>
    </el-form>

    <div class="largMap flex">
      <div id="container"></div>

      <div
        v-show="ifData"
        class="pc-container"
        v-if="trackData.length > 0 && carMoveData.length > 0"
      >
        <div class="pc-tips">
          <span class="tips-title">轨迹播放速度</span
          ><span class="tips-num">x {{ playControl.speedMultiple }}</span>
        </div>

        <div class="pc-speed">
          <el-tooltip placement="top" content="增加速度">
            <span @click="AddSpeed">
              <i class="el-icon-arrow-up"></i>
            </span>
          </el-tooltip>

          <el-tooltip placement="bottom" content="减少速度">
            <span @click="ReduceSpeed">
              <i class="el-icon-arrow-down"></i>
            </span>
          </el-tooltip>
        </div>

        <div class="pc-play">
          <el-tooltip placement="top" content="播放" v-if="!playControl.play">
            <span
              class="pc-btn pc-btn-color"
              style="margin-right: 10px"
              @click="ChangePlayStatus"
            >
              <i class="el-icon-video-play"></i>
            </span>
          </el-tooltip>
          <el-tooltip placement="top" content="暂停" v-else>
            <span
              class="pc-btn pc-btn-color"
              style="margin-right: 10px"
              @click="ChangePlayStatus"
            >
              <i class="el-icon-video-pause"></i>
            </span>
          </el-tooltip>

          <el-tooltip placement="top" content="重播">
            <span class="pc-btn pc-btn-color" @click="RefreshHistoryTrack">
              <i class="el-icon-refresh"></i>
            </span>
          </el-tooltip>
        </div>
      </div>

      <div class="right" v-if="carBaseData.show" v-show="ifData">
        <span
          >{{ carBaseData.carNumber }}【{{ carBaseData.carTypeName }}】</span
        >
        <div class="flex align-center" style="margin-bottom: 10px">
          <!-- <span class="bigtext"></span><span class="statues">离线</span> -->
        </div>
        <div class="title flex align-center justify-between">
          <p class="flex align-center">
            <img src="../../assets/images/msg.png" alt="" />
            <span>未编队</span>
          </p>
          <p class="flex align-center">
            <img src="../../assets/images/car.png" alt="" />
            <span>快车</span>
          </p>
          <p class="flex align-center">
            <img src="../../assets/images/line.png" alt="" />
            <span>{{ carBaseData.lineName }}</span>
          </p>
        </div>
        <div class="flex align-center">
          <p class="textcolor2">
            <span class="tit_textcolor">下一站 </span>
            {{ carBaseData.nstaName }}
          </p>
          <p class="flex align-center">
            <img
              src="../../assets/images/new_place.png"
              alt=""
              style="margin-left: 30px"
            />距站点<span class="tit_color">{{ carBaseData.nstaDistance }}</span
            >m
          </p>
        </div>
        <div class="flex align-center">
          <span class="tit_textcolor">驾驶模式</span>
          <img src="../../assets/images/drive.png" alt="" />
          <span class="color">{{ carBaseData.driveModel }}</span>
        </div>
        <div class="flex align-center">
          <span class="tit_textcolor">更新时间 </span
          ><span class="textcolor2">{{ carBaseData.updateDate }}</span>
        </div>
        <p class="line"></p>
        <div class="flex" style="margin-top: -25px">
          <div id="main" :style="{ width: '215px', height: '215px' }"></div>

          <div id="main2" :style="{ width: '215px', height: '215px' }"></div>
        </div>
        <div class="padding flex justify-between" style="margin-top: -25px">
          <span class="tit_textcolor">累计里程</span>
          <!-- <span class="tit_textcolor">小计里程</span> -->
          <span class="tit_textcolor">续航里程</span>
        </div>
        <div class="flex justify-between">
          <span class="tit_textcolor" style="margin-left: 30px"
            ><span class="textcolor2"></span>
            {{ carBaseData.accumulate }}Km</span
          >
          <!-- <span class="tit_textcolor" style="margin-left: 0px"
            ><span class="textcolor2"></span>0Km</span
          > -->
          <span class="tit_textcolor" style="margin-right: 30px"
            ><span class="textcolor2"></span> {{ carBaseData.recharge }}Km</span
          >
        </div>
        <div class="value">
          <div class="value_top flex align-center justify-center">
            <img src="../../assets/images/back_img.png" alt="" />
            <span class="value_top_text">警告通知</span>
          </div>
          <span class="value_text">三级告警</span>
          <span class="value_textB">
            {{ carBaseData.warning }}
          </span>
        </div>
        <span>{{ carBaseData.carNumber }}</span>
        <!-- <div class="flex align-center" style="margin-bottom: 10px">
          <span class="bigtext"></span><span class="statues">离线</span>
        </div> -->
        <div class="value_msg flex">
          <span class="value_bg">上行通信速率</span>
          <span class="value_border" v-if="carBaseData.uspeed == -1">{{ carBaseData.uspeed }}(未采集)</span>
          <span class="value_border" v-else>{{ carBaseData.uspeed }}M/s</span>
        </div>
        <div class="value_msg flex">
          <span class="value_bg">下行通信速率</span>
          <span class="value_border" v-if="carBaseData.dspeed == -1">{{ carBaseData.dspeed }}(未采集)</span>
          <span class="value_border" v-else>{{ carBaseData.dspeed }}M/s</span>
        </div>
        <!--<div class="value_msg flex">-->
        <!--  <span class="value_bg">OBU通信效率</span>-->
        <!--  <span class="value_border"></span>-->
        <!--</div>-->
        <div class="value_msg flex">
          <span class="value_bg">网络延迟</span>
          <span class="value_border" v-if="carBaseData.delay == -1">{{ carBaseData.delay }}(未采集)</span>
          <span class="value_border" v-else>{{ carBaseData.delay }}ms</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Amap, Marker } from "@amap/amap-vue";
import * as echarts from "echarts";
import {
  _VHgetCarHistoryTrackList,
  _VHgetHistoricalData,
  _GetCarId,
} from "@/api/statisticalQuery";
import { _FindParkByCarOperation } from "@/api/equipmentMonitoring";
import {
	calculateCenter,
	computeSignedArea,
	TimestampToDatetime,
} from "@/until/helper";

export default {
  components: {
    Amap,
    AmapMarker: Marker,
  },
  data() {
    return {
      parkId:null,
      pageLoading: null,
      searchObjCopy: { // 修复不能再次搜索的问题
        parkId:null,
        vehicleNumber: "",
        datetime: [],
      },
      searchObj: {
        vehicleNumber: "", //SV10PLUS99999
        datetime: [], //"2021-10-12 19:50:00", "2021-10-12 20:10:00"
      },
      parkData: null, //园区数据
      trackData: [], //轨迹数据
      carLinePolyline: null,
			
      carBaseData: {
        show: false,
        carNumber: "",
        carTypeName: "",
        nstaName: "",
        nstaDistance: 0,
        driveModel: "",
        updateDate: "",
        speed: 0,
        power: 0,
        accumulate: 0,
        recharge: 0,
        warning: "",
        uspeed: 0,
        dspeed: 0,
        delay: 0,
        lineName: "",
      },
      ifData: false,//显示详细信息
      carMarker: null, //车辆marker对象
      carMoveData: [], //车辆移动数据
      carMoveTrackPath: [], //车辆移动轨迹路径
			carMoveTrackPolyline: null,
      carPaging: {
        page: 1,
        pageSize: 50,
        total: 0,
      },
      mapTimes: null,
      carDataTimes: null,
      playControl: {
        play: true,
        speed: 1000,
        defaultSpeed: 1000,
        speedMultiple: 1,
        speedAvg: parseInt(1000 / 16),
        playIdx: 0,
      },
      nowCount: 0 //车辆重置计数
    };
  },
  mounted() {
    this.InitData();
    this.getParkId()
  },

  beforeDestroy: function () {
    clearInterval(this.mapTimes);
    clearInterval(this.carDataTimes);
  },

  methods: {
    async InitData() {
      await this.findParkByCarOperation();

      if (this.parkData === null) {
        this.$message.error("数据初始化失败");

        return false;
      }

      await this.initMap();
    },

    //获取园区车辆实时数据
    async findParkByCarOperation() {
      let res = await _FindParkByCarOperation({
        parkId: sessionStorage.getItem("parkId"),
      },this);

      if (res) {
        if (res.success) {
          this.parkData = res.data;
        }
      }
    },

    //初始化地图
    async initMap() {
      let routeSite = null;
      let CenterPoint = null;
      let arrCenter = [108.112583, 34.233588];
      let nLngLatArea = 0;
      let nZoom = 5;

      if (this.parkData) {
        routeSite = JSON.parse(this.parkData.parkScope);

        if (routeSite.length > 0) {
          routeSite.push(routeSite[0]); //绘制线路需要将第一个元素复制一个放到数组最后，完成高德线段的闭合绘制
        }

        //计算园区范围的中心点
        CenterPoint = calculateCenter(routeSite);

        //计算园区范围的面积值，用于地图缩放比例
        nLngLatArea = Math.round(computeSignedArea(routeSite));
        arrCenter = [CenterPoint.lng, CenterPoint.lat];

        //根据面积，配置地图缩放级别
        if (nLngLatArea <= 1) {
          nZoom = 16;
        } else if (nLngLatArea <= 2) {
          nZoom = 15;
        } else if (nLngLatArea <= 3) {
          nZoom = 14;
        } else if (nLngLatArea <= 4) {
          nZoom = 13;
        } else {
          nZoom = 12;
        }
      }

      if (!this.map) {
        this.map = new AMap.Map("container", {
          resizeEnable: true,
          center: arrCenter,
          // center: [114.07695956912,30.44312798772], //测试代码
          zoom: nZoom,
        });

        if (routeSite) {
          this.CreateFence(routeSite);
        }
      }
    },

    //创建园区围栏
    CreateFence(fence_list) {
      let nCount = fence_list.length;
      let polylinePath = [];

      for (let i = 0; i < nCount; i++) {
        polylinePath.push(new AMap.LngLat(fence_list[i][0], fence_list[i][1]));
      }

      let polyline = new AMap.Polyline({
        path: polylinePath, // 设置线覆盖物路径
        showDir: false,
        strokeColor: "#FF6600", // 线颜色
        strokeWeight: 5, // 线宽
        strokeOpacity: 0.7,
				strokeStyle: 'dashed',
			});

      this.map.add([polyline]);
    },

    //查询获取车辆历史轨迹数据
    async DoSearch() {
      this.searchObjCopy.parkId = this.parkId
      this.searchObj = JSON.parse(JSON.stringify(this.searchObjCopy))
      if (
        this.searchObj.vehicleNumber === "" &&
        this.searchObj.datetime === ""
      ) {
        this.$message.error("请输入查询条件");
        return false;
      }

      if (this.searchObj.vehicleNumber === "") {
        this.$message.error("请输入车辆编号");
        return false;
      }

      if (this.searchObj.datetime.length <= 0) {
        this.$message.error("请选择日期");
        return false;
      }
      this.ResetSearch("search")// 再次搜索重置一下
      this.pageLoading = this.$loading({
        lock: true,
        text: "数据加载中",
        spinner: "el-icon-loading",
        background: "rgba(0,0,0,0.6)",
        target: document.getElementById("pageContainer"),
      });

      let res;
      let blFoundError = false;
      console.log(this.searchobj)
      res = await _VHgetCarHistoryTrackList({
        carNumber: this.searchObj.vehicleNumber,
        parkId:this.searchObj.parkId,
        startTime: this.searchObj.datetime[0],
        endTime: this.searchObj.datetime[1],
        page: 1,
        size: this.carPaging.pageSize,
      });

      if (!res) {
        this.pageLoading.close();
        blFoundError = true;
      } else {
        if (!res.success) {
          this.pageLoading.close();
          blFoundError = true;
        }
      }

      if (!res.data) {
        this.$message.error("未查询到车辆历史数据");
        this.pageLoading.close();
        return false;
      }

      if (blFoundError) {
        this.$message.error("车辆数据初始化失败");
        this.pageLoading.close();
        return false;
      }

      if (res.data.carRoute <= 0) {
        this.$message.error("历史路径数据初始化失败");
        this.pageLoading.close();
        return false;
      }

      if (res.data.list <= 0) {
        this.$message.error("车辆数据初始化失败");
        this.pageLoading.close();
        return false;
      }

      this.trackData = res.data.carRoute;
      this.carMoveData = res.data.list;
      this.carBaseData.carTypeName = res.data.carTypeName;
      this.carPaging.page += 1;
      this.carPaging.total = res.data.total;
      // 一次性发出全部请求
      let countAll = Math.ceil(this.carPaging.total / 500) || 1;
      let count = 0; //计数判断请求是否全部返回
      let twoDimension = []; // 二维路径数组
      let twoCarMoveData = [] //车辆轨迹信息
      for (let i = 1; i <= countAll; i++) {
        this.LoadMoreData2( i, 500).then(async(res)=>{
          ++count; // 请求回来计数加一
          twoDimension[i - 1] = res.trackData; // 二维数组顺序添加
          twoCarMoveData[i - 1] = res.carMoveData
          // 请求全部回来之后
          if (count == countAll) {
            this.trackData = twoDimension.flat()
            this.carMoveData = twoCarMoveData.flat()
            await this.initLine()
            this.initCar();
            this.pageLoading.close();

            await this.GetHistoricalData(this.carMoveData[0].id);
            this.initCharts();

            // let nCurrTotal = this.carPaging.page * this.carPaging.pageSize;

            // if (nCurrTotal < this.carPaging.total) {
            //   await this.LoadMoreData();
            // }
            // 显示面板
            this.ifData = true
          }
        })
      }
      this.RefreshHistoryTrack()//重播一下
      // await this.initLine();
      // this.initCar();
      // this.pageLoading.close();

      // await this.GetHistoricalData(this.carMoveData[0].id);
      // this.initCharts();

      // let nCurrTotal = this.carPaging.page * this.carPaging.pageSize;

      // if (nCurrTotal < this.carPaging.total) {
      //   await this.LoadMoreData();
      // }
    },
    // 获取全部历史蓝色路径
    LoadMoreData2(page, pageSize){
      let that = this
      return new Promise(function(resolve, reject){
        let blFoundError = false;
        if (
          that.searchObj.vehicleNumber === "" ||
          that.searchObj.datetime[0] === "" ||
          that.searchObj.datetime[1] === ""
        ) {
          reject('失败')
          return false;
        }

        _VHgetCarHistoryTrackList({
          carNumber: that.searchObj.vehicleNumber,
          parkId: that.searchObj.parkId,
          startTime: that.searchObj.datetime[0],
          endTime: that.searchObj.datetime[1],
          page: page,
          size: pageSize,
        }).then((res)=>{
          if (!res) {
            blFoundError = true;
          } else {
            if (!res.success) {
              blFoundError = true;
            }
          }

          if (blFoundError) {
            that.$message.error("车辆数据初始化失败");
            reject('失败')
            return false;
          }

          if (res.data.carRoute <= 0) {
            that.$message.error("历史路径数据初始化失败");
            reject('失败')
            return false;
          }

          if (res.data.list <= 0) {
            that.$message.error("车辆数据初始化失败");
            reject('失败')
            return false;
          }
          
          let trackData = []
          for (const route of res.data.carRoute) {
            trackData.push(route);
          }
          let carMoveData =[]
          for (const car of res.data.list) {
            carMoveData.push(car);
          }
          resolve({trackData,carMoveData})
        })
      });
    },
    //加载更多的车辆历史轨迹数据
    async LoadMoreData() {
      let blFoundError = false;

      if (
        this.searchObj.vehicleNumber === "" ||
        this.searchObj.datetime[0] === "" ||
        this.searchObj.datetime[1] === ""
      ) {
        return false;
      }

      let res = await _VHgetCarHistoryTrackList({
        carNumber: this.searchObj.vehicleNumber,
         parkId: this.searchObj.parkId,
        startTime: this.searchObj.datetime[0],
        endTime: this.searchObj.datetime[1],
        page: this.carPaging.page,
        size: this.carPaging.pageSize,
      });

      if (!res) {
        blFoundError = true;
      } else {
        if (!res.success) {
          blFoundError = true;
        }
      }

      if (blFoundError) {
        this.$message.error("车辆数据初始化失败");
        return false;
      }

      if (res.data.carRoute <= 0) {
        this.$message.error("历史路径数据初始化失败");
        return false;
      }

      if (res.data.list <= 0) {
        this.$message.error("车辆数据初始化失败");
        return false;
      }

      // for (const route of res.data.carRoute) {
      //   this.trackData.push(route);
      // }

      for (const car of res.data.list) {
        this.carMoveData.push(car);
      }

      this.carPaging.page += 1;
      this.carPaging.total = res.data.total;

      let nCurrTotal = this.carPaging.page * this.carPaging.pageSize;
			nCurrTotal = this.carMoveData.length;
			
      if (nCurrTotal < this.carPaging.total) {
        await this.LoadMoreData();
      }
    },

    //获取车辆历史报文数据
    async GetHistoricalData(data_id) {
      var blFoundError = false;

      var res = await _VHgetHistoricalData({
        id: data_id,
      });

      if (!res) {
        blFoundError = true;
      } else {
        if (!res.success) {
          blFoundError = true;
        }
      }

      var sCarNumber = "";
      var sNextSiteName = "";
      var nNstaDistance = 0;
      var sDmodeStr = 0;
      var sUpdateDate = "";
      var nSpeed = 0;
      var nPower = 0;
      var nAccumulate = 0;
      var nRecharge = 0;
      var sWarning = "";
      var nUpSpeed = 0;
      var nDownSpeed = 0;
      var nDelay = 0;
      var sLineName = "";

      if (res.data) {
        if (res.data.timestamp) {
          sUpdateDate = TimestampToDatetime(res.data.timestamp);
        }

        if (res.data.vehicle) {
          if (res.data.vehicle.base) {
            sCarNumber = res.data.vehicle.base.vid;
            sDmodeStr = res.data.vehicle.base.dmodeStr;
            nSpeed = parseInt(res.data.vehicle.base.speed);
            nPower = parseInt(res.data.vehicle.base.power);
            nAccumulate = parseInt(res.data.vehicle.base.accumulate);
            nRecharge = parseInt(res.data.vehicle.base.recharge);
          }

          if (res.data.vehicle.ict) {
            nUpSpeed = res.data.vehicle.ict.uspeed;
            nDownSpeed = res.data.vehicle.ict.dspeed;
            nDelay = res.data.vehicle.ict.delay;
          }
        }

        if (res.data.business) {
          if (res.data.business.nstaid) {
            sNextSiteName = res.data.business.nstaName;
          }

          if (res.data.business.nstadis) {
            nNstaDistance = res.data.business.nstadis;
          }

          if (res.data.business.lineName) {
            sLineName = res.data.business.lineName;
          }
        }

        if (res.data.warning) {
          switch (res.data.warning.desc) {
            case "Low_Liguid_Level_Warning":
              if (res.data.warning.data == 1) {
                sWarning = "低速预警";
              }
              break;

            case "Low_SOC_Warning":
              if (res.data.warning.data == 1) {
                sWarning = "SOC 低预警";
              }
              break;

            case "High_SOC_Warning":
              if (res.data.warning.data == 1) {
                sWarning = "SOC 过高预警";
              }
              break;

            case "Battery high temperature warning":
              if (res.data.warning.data == 1) {
                sWarning = "电池高温预警";
              }
              break;

            case "Braking system abnormol":
              if (res.data.warning.data == 1) {
                sWarning = "制动系统异常预警";
              }
              break;

            default:
              sWarning = "无预警";
              break;
          }
        }
      }

      this.carBaseData.carNumber = sCarNumber;
      this.carBaseData.nstaName = sNextSiteName ? sNextSiteName : "";
      this.carBaseData.nstaDistance = nNstaDistance;
      this.carBaseData.driveModel = sDmodeStr;
      this.carBaseData.updateDate = sUpdateDate;
      this.carBaseData.speed = nSpeed;
      this.carBaseData.power = nPower;
      this.carBaseData.accumulate = nAccumulate;
      this.carBaseData.recharge = nRecharge;
      this.carBaseData.warning = sWarning;
      this.carBaseData.uspeed = nUpSpeed && nUpSpeed !== "NULL" ? nUpSpeed : 0;
      this.carBaseData.dspeed =
        nDownSpeed && nDownSpeed !== "NULL" ? nDownSpeed : 0;
      this.carBaseData.delay = nDelay ? nDelay : 0;

      this.carBaseData.show = true;
    },

    //重置查询
    async ResetSearch(search) {
      if (this.carMarker !== null) {
        this.carMarker.remove();
      }

      if (this.carLinePolyline !== null) {
        this.carLinePolyline.remove();
      }
			
			if (this.carMoveTrackPolyline !== null) {
				this.carMoveTrackPolyline.remove();
			}

      this.playControl = {
        play: true,
        speed: 1000,
        defaultSpeed: 1000,
        speedMultiple: 1,
        speedAvg: parseInt(1000 / 16),
        playIdx: 0,
      };


      clearInterval(this.mapTimes);
      clearInterval(this.carDataTimes);
      this.carMarker = null;
      this.carLinePolyline = null;
			this.carMoveTrackPolyline = null;
      this.carMoveData = [];
      this.carMoveTrackPath = []; // 重置车辆历史轨迹
      this.carPaging.page = 1;// 重置车辆轨迹页面数
      this.trackData = [];
      this.carBaseData.show = false;
      this.ifData = false;// 关闭面板
      if(search=="search"){ // 判断是否是搜索的重置
        this.ifData = true;// 打开面板
      }else{
        this.searchObj.datetime = [];
        this.searchObj.vehicleNumber = "";
        this.searchObjCopy = JSON.parse(JSON.stringify(this.searchObj))
      }
    },

    //初始化地图上的线路标记
    async initLine() {
      let nCount = this.trackData.length;

      if (nCount <= 0) {
        return false;
      } else {
				this.carLinePolyline = new AMap.Polyline({
          path: this.trackData,
          zIndex: 5,
          // showDir: true,
          outlineColor: "#ffeeff",
          borderWeight: 3,
          strokeWeight: 10, // 线宽
          strokeColor: "#3366FF",
          strokeOpacity: 0.3,
          strokeStyle: "solid",
        });
	
        this.carLinePolyline.setMap(this.map);
      }
    },

    //初始化车辆标记
    initCar() {
			let GPS_Position = [
				this.carMoveData[0].longitude,
				this.carMoveData[0].latitude,
			];
			let initHead = this.carMoveData[0].head;
			let that = this;
			
			AMap.convertFrom(GPS_Position, "gps", function (status, result) {
				if (result.info === "ok") {
					let AmapPosition = [
						result["locations"][0].lng,
						result["locations"][0].lat,
					];
					
					that.carMarker = new AMap.Marker({
						map: that.map,
						clickable: true,
						
						//标记在地图上的位置
						position: AmapPosition,
						icon: new AMap.Icon({
							image: require("@/assets/images/minbus.png"),
							imageSize: new AMap.Size(36, 36),
							size: new AMap.Size(36, 36),
						}),
						offset: new AMap.Pixel(-15, -20),
						//航向角
						angle: initHead,
					});
					that.SetPlayHistoryTask();
					
					//region 创建车辆移动轨迹
					that.carMoveTrackPath.push(AmapPosition);
					that.carMoveTrackPolyline = new AMap.Polyline({
						path: that.carMoveTrackPath,
						showDir: true,
						outlineColor: "#ffeeff",
						borderWeight: 1,
						strokeWeight: 5, // 线宽
						strokeColor: "#000",
						strokeOpacity: 0.5,
						strokeStyle: "solid",
					});
					
					that.carMoveTrackPolyline.setMap(that.map);
					//endregion 创建车辆移动轨迹
				}
			})
    },

    //车辆移动
    CarMove(count) {
      if (this.playControl.playIdx >= this.trackData.length) {
        this.playControl.playIdx = this.trackData.length - 1;

        clearInterval(this.mapTimes);
        clearInterval(this.carDataTimes);
        return false;
      }

      if (this.carMarker) {
				let idx = this.playControl.playIdx;
				let GPS_Position = [this.carMoveData[idx].longitude, this.carMoveData[idx].latitude];
				let that = this;
	
				this.carMarker.setAngle(this.carMoveData[idx].head);
				
				AMap.convertFrom(GPS_Position, "gps", function (status, result) {
					if (result.info === "ok") {
            // 坐标异步，判断是否重置
           if(that.nowCount == count){
						let AmapPosition = [
							result["locations"][0].lng,
							result["locations"][0].lat,
						];
						
						//region 创建车辆移动轨迹
						that.carMoveTrackPath.push(AmapPosition);
						that.carMoveTrackPolyline.setPath(that.carMoveTrackPath);
						/*let polylineCarMoveTrack = new AMap.Polyline({
							path: [AmapPosition],
							showDir: true,
							outlineColor: "#ffeeff",
							borderWeight: 3,
							strokeWeight: 10, // 线宽
							strokeColor: "#000",
							strokeOpacity: 1,
							strokeStyle: "solid",
						});
						
						polylineCarMoveTrack.setMap(that.map);*/
						//endregion 创建车辆移动轨迹
						that.carMarker.setPosition(AmapPosition);
            }
          }
				});
      }
    },

    //region 播放控制
    //设置车辆历史轨迹播放
    SetPlayHistoryTask() {
      let that = this;
			
      //定时刷新车辆数据
      this.mapTimes = setInterval(async () => {
        if (that.playControl.play) {
          that.CarMove(this.nowCount);
          that.playControl.playIdx++;
        }
      }, that.playControl.speed);


      this.carDataTimes = setInterval(async () => {
        if (that.playControl.playIdx < that.carMoveData.length) {
          await that.GetHistoricalData(
            that.carMoveData[that.playControl.playIdx].id
          );
          that.initCharts();
        }
      }, 1000);
    },

    //重新加载播放历史轨迹
    ReloadPlayHistoryTask(type) {
      if(type == 'dianji'){
        this.nowCount++
      }
      clearInterval(this.mapTimes);
      clearInterval(this.carDataTimes);
      this.mapTimes = null;
      this.carDataTimes = null;
      this.SetPlayHistoryTask();
    },

    //改变播放状态。暂停/播放
    ChangePlayStatus() {
      // if (this.playControl.play){
      //   //播放状态，就暂停
      //   clearInterval(this.mapTimes);
      // }else{
      //   //暂停状态，就播放
      //   this.ReloadPlayHistoryTask();
      // }

      this.playControl.play = !this.playControl.play;
    },

    //增加播放速度
    AddSpeed() {
      if (this.playControl.speedMultiple >= 16) {
        this.playControl.speedMultiple = 16;
        return false;
      }

      this.playControl.speedMultiple += 1;
      let nAddSpeed =
        this.playControl.speedMultiple * this.playControl.speedAvg;
      this.playControl.speed = this.playControl.defaultSpeed - nAddSpeed;

      if (this.playControl.speed <= 1) {
        this.playControl.speed = 1;
      }

      this.ReloadPlayHistoryTask();
    },

    //减少播放速度
    ReduceSpeed() {
      if (this.playControl.speedMultiple <= 1) {
        this.playControl.speedMultiple = 1;
        this.playControl.speed = 1000;
        return false;
      }

      this.playControl.speedMultiple -= 1;
      this.playControl.speed += this.playControl.speedAvg;

      if (this.playControl.speed >= 1000) {
        this.playControl.speed = 1000;
      }
			
      // console.log('--- ReduceSpeed ----');
      // console.log(this.playControl.speed);
      // console.log('--- ReduceSpeed ----');

      this.ReloadPlayHistoryTask();
    },

    //重置
    RefreshHistoryTrack() {
      this.playControl.play = true;
      this.playControl.playIdx = 0;
      this.playControl.speedMultiple = 1;
      this.playControl.speed = 1000;
      this.carMoveTrackPath = []; // 重置车辆历史轨迹

      this.ReloadPlayHistoryTask("dianji");
    },
    //endregion 播放控制

    initCharts() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var chartDom2 = document.getElementById("main2");
      var myChart2 = echarts.init(chartDom2);
      var option = {
        series: [
          {
            type: "gauge",
            itemStyle: {
              color: "#1490FE",
            },
            progress: {
              show: true,
              width: 6 /*外圈线*/,
            },
            axisLine: {
              lineStyle: {
                width: 1 /*外圈线灰色宽度*/,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 10 /*内圈线长度*/,
              lineStyle: {
                width: 1,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 6,
              color: "#999",
              fontSize: 10,
            },
            pointer: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              offsetCenter: [0, "-10%"],
              formatter: "{value}km/h ",
              color: "auto",
              fontSize: 14,
            },
            data: [
              {
                value: this.carBaseData.speed,
                name: "车速",
              },
            ],
          },
        ],
      };
      var option2 = {
        series: [
          {
            type: "gauge",
            itemStyle: {
              color: "#78C976",
            },
            progress: {
              show: true,
              width: 6 /*外圈线*/,
            },
            axisLine: {
              lineStyle: {
                width: 1 /*外圈线灰色宽度*/,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 10 /*内圈线长度*/,
              lineStyle: {
                width: 1,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 6,
              color: "#999",
              fontSize: 10,
            },
            pointer: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              offsetCenter: [0, "-10%"],
              formatter: "{value}%",
              color: "auto",
              fontSize: 14,
            },
            data: [
              {
                value: this.carBaseData.power,
                name: "电量",
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      option2 && myChart2.setOption(option2);
    },
    getParkId(){
      this.parkId = sessionStorage.getItem("parkId")
    },
    // querySearch 搜索建议
   async querySearch(queryString, cb){
      let data = {
        carId:queryString,
        parkId:this.parkId
      }
      console.log(data)
      let res = await _GetCarId(data)
      let arr = []
      if(res){
        res.data.forEach((ele)=>{
          arr.push({carNumber:ele})
        })
      }
      cb(arr)
    }
  },
};
</script>

<style lang="less" scoped>
#container {
  width: 100%;
  height: 100%;
}

//region 播放控制
.pc-container {
  width: 279px;
  height: 30px;
  position: absolute;
  right: 30%;
  margin-right: -40px;
  margin-top: 20px;

  .pc-tips {
    width: 130px;
    height: 24px;
    font-weight: 400;
    font-size: 12px;
    color: #333;
    float: left;

    span {
      background-color: #fff;
      display: block;
      height: 30px;
      line-height: 30px;
      text-align: center;
      float: left;
    }
    .tips-title {
      width: 90px;
    }
    .tips-num {
      width: 40px;
    }
  }

  .pc-speed {
    width: 34px;
    height: 24px;
    float: left;

    span {
      display: block;
      width: 34px;
      height: 15px;
      line-height: 15px;
      background-color: #1490fe;
      color: #fff;
      text-align: center;
      font-size: 5px;
    }
  }

  .pc-play {
    width: 100px;
    height: 30px;
    float: left;
    margin-left: 10px;

    .pc-btn {
      display: block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #1490fe;
      float: left;
      font-size: 20px;
      color: #fff;
    }
  }
}
//endregion 播放控制

.lineChartdv1 {
  background-color: #fff;
  height: 330px;
  width: 50%;
  margin-bottom: 20px;
  position: relative;
}
.largMap {
  width: 100%;
  height: 770px;
}
.right {
  width: 30%;
  height: 100%;
  background: #ffffff;
  padding: 15px;
}
.bigtext {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.statues {
  width: 46px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #3dc27f;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  border: 1px solid #42c382;
  display: block;
  margin-left: 15px;
}
.title {
  width: 302px;
  height: 41px;
  background: #f5f7fa;
  border-radius: 4px;
  padding: 0 20px;
}
.tit_text {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
.tit_textcolor {
  font-size: 14px;
  color: #666666;
  margin-right: 10px;
}
.tit_color {
  font-size: 16px;
  font-weight: bold;
  color: #4287ff;
}
.color {
  color: #4287ff;
}
.line {
  width: 100%;
  height: 1px;
  opacity: 0.15;
  background-color: #000000;
}
.padding {
  padding: 0 16px;
}

.value {
  width: 100%;
  height: 77px;
  background: #fff3f3;
  border-radius: 4px;
  border-top: 4px solid #ff7760;
  padding: 0 12px;
  margin-top: 21px;
  margin-bottom: 6px;
}
.value_top {
  width: 40%;
  height: 18px;
  background: #ff7760;
  border-radius: 0px 0px 11px 11px;
  margin-left: 30%;
}
.value_top_text {
  font-size: 12px;
  color: #ffffff;
}
.value_text {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  display: block;
}
.value_textB {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.value_msg {
  width: 100%;
  height: 41px;
  border: 1px solid #ededed;
}
.value_bg {
  width: 40%;
  height: 41px;
  background: #f5f7fa;
  display: block;
  text-align: center;
  line-height: 41px;
}
.value_border {
  width: 60%;
  height: 41px;
  display: block;
  text-align: center;
  line-height: 41px;
}
/deep/.el-input__inner {
  border: none !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
/deep/.el-textarea__inner {
  width: 100%;
  /* background: #f5f8fa;
  border: 0px solid white !important; */
  font-size: 14px;
  background: #ffffff;
  border: 0px solid white !important;
}
.el-form-item {
  margin-bottom: 0px;
}
</style>
