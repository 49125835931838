<template>
  <div class="flex">
    <div class="left">
      <el-tree
        :data="parktreeList"
        node-key="value"
        default-expand-all
        ref="tree"
        :expand-on-click-node="false"
        highlight-current
        :render-content="renderContent"
        @node-click="gettree"
      >
      </el-tree>
    </div>
    <div class="right" v-if="top != '1'">
      <p class="bottom">
        <span class="textcolor">园区名称：</span
        ><span class="textcolor2">{{ list.companyName }}</span>
      </p>
      <p class="bottom">
        <span class="textcolor">所属企业</span
        ><span class="textcolor2">{{ parkList.companyName }}</span>
      </p>
      <p class="bottom">
        <span class="textcolor">园区负责人：</span
        ><span class="textcolor2">{{ list.authAccount }}</span>
      </p>
      <p class="bottom">
        <span class="textcolor">联系方式：</span
        ><span class="textcolor2">{{ list.phone }}</span>
      </p>
      <p class="bottom">
        <span class="textcolor">启用状态：</span
        ><span class="textcolor2" v-if="list.authStatus == 1">启用</span>
        <span class="textcolor2" v-else>禁用</span>
      </p>
      <el-button type="primary" @click="updateYq" class="button"
        >编辑园区</el-button
      >
    </div>
    <div class="right" v-else>
      <p class="bottom">
        <span class="textcolor">企业名称：</span
        ><span class="textcolor2">{{ parkList.companyName }}</span>
      </p>
      <p class="bottom">
        <span class="textcolor">企业地址：</span
        ><span class="textcolor2">{{ parkList.companyAddress }}</span>
      </p>
      <p class="bottom">
        <span class="textcolor">法人代表：</span
        ><span class="textcolor2">{{ parkList.legalPerson }}</span>
      </p>
      <p class="bottom">
        <span class="textcolor">联系方式：</span
        ><span class="textcolor2">{{ parkList.phone }}</span>
      </p>
      <p class="bottom">
        <span class="textcolor">注册时间：</span
        ><span class="textcolor2">{{ parkList.createTime }}</span>
      </p>
      <p class="bottom">
        <span class="textcolor">经营范围：</span
        ><span class="textcolor2">{{ parkList.businessNature }}</span>
      </p>
    </div>
    <el-drawer
      :title="isAdd ? '新增园区' : '编辑园区'"
      :visible.sync="addshow"
      direction="rtl"
      custom-class="demo-drawer"
      :before-close="handleClose"
      class="elDra"
      destroy-on-close
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="园区名称"
          prop="companyName"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="form.companyName"
            autocomplete="off"
            placeholder="请输入"
            class="centerCzInput"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="textcolor textA">
            <span class="red">*</span><span>所属企业</span>
            <span style="margin-left:20px;">{{ parkList.companyName }}</span>
          </div>
        </el-form-item>
        <el-form-item
          label="园区超级管理员"
          prop="authAccount"
          label-width="150px"
          class="elForm"
        >
          <el-input
            v-model="form.authAccount"
            autocomplete="off"
            placeholder="请输入"
            class="centerCzInput"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="管理员联系电话"
          prop="phone"
          label-width="150px"
          class="elFormB"
        >
          <el-input
            v-model="form.phone"
            autocomplete="off"
            placeholder="请输入"
            class="centerCzInput"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span class="textcolor textB"
            ><span class="red">*</span>启用状态</span
          >
          <el-switch
            v-model="statushow"
            active-color="#1683D9"
            inactive-color="#DCDFE6"
            @change="getstatus"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div class="leftDv">
        <button class="centerCzbtn textleftA" @click="getaddpark">
          下一步
        </button>
      </div>
    </el-drawer>
    <el-drawer
      title="设置园区范围"
      :visible.sync="parkshow"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      :before-close="handleCloseB"
      size="50%"
      destroy-on-close
    >
      <div class="textA">
        <span class="textcolor">园区名称</span>
        <span class="textcolor2" v-if="isAdd == true">{{
          form.companyName
        }}</span>
        <span class="textcolor2" v-else>{{ rowData.companyName }}</span>
        <el-form class="zoom-input" ref="zoomForm" :rules="zoomRules" :model="zoomForm" label-width="150px">
          <el-form-item label="固定默认缩放值" prop="defaultZoom">
            <el-input v-model="zoomForm.defaultZoom" placeholder="缩放值范围2-20"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex">
        <span class="textcolor">设置园区范围</span>
        <div class="largMap">
          <!-- <amap ref="myMap" :center.sync="center"> </amap> -->
          <div id="container"></div>
          <input id="tipinput" />
          <button type="button" class="centerCzbtn refresh" @click="refresh">
            刷新
          </button>
        </div>
      </div>
      <div class="footerButton">
        <button class="centerCzbtn textleft" @click="qxShow">下一步</button>
      </div>
    </el-drawer>
    <el-drawer
      title="设置功能权限"
      :visible.sync="mngshow"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <el-tree
           v-if="mngshow"
          :data="treeData"
          :props="defaultProps"
          show-checkbox
          node-key="id"
          default-expand-all
          @check="checkChange"
          :check-strictly="checkSt"
          ref="resourcesMenuRef"
          :default-checked-keys="keyids"
        >
        </el-tree>
      </div>
      <div class="textR">
        <el-button class="centerCzbtn" @click="getparkAdd" :loading="isLoading">
          确认
        </el-button>
        <button class="centerBtn resetbtn" @click="mngshow = false">
          取消
        </button>
      </div>
    </el-drawer>
    <el-dialog title="删除" :visible.sync="delDiaA" width="30%">
      <span>确认删除吗</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getdelpark">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _ParkList,
  _DelparkList,
  _Addpark,
  _UpdateUserPark,
  _ParkcheckName,
  _AddAccountPhone,
  _UpAccountPhone,
} from "@/api/systemMng";
import { _GetRoute } from "@/api/parksysMng";
export default {
  components: {
    Table,
  },
  data() {
    //电话号码校验
    var validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;

      if (value == "" || value == undefined || value == null) {
        callback();
      } else {
        if (!reg.test(value) && value != "") {
          callback(new Error("请输入正确的电话号码"));
        } else {
          callback();
        }
      }
    };
    //园区名校验
    var validateCpName = (rule, value, callback) => {
      if (value == "" || value == undefined || value == null) {
        callback(new Error("请输入正确的电话号码"));
      } else {
        if (value != "") {
          if (this.isAdd == true) {
            this.getparkSelect();
          }

          callback();
        }
      }
    };
    // 缩放校验
    var checkZoom = (rule, value, callback) => {
      if (value == "" || value == undefined || value == null) {
        callback();
      } else {
        let regPos =/^\d+(\.\d+)?$/; //判断是否是数字。
        if (!regPos.test(value)) {
            callback(new Error('请输入数字或不填'));
        } else {
           if (value > 20 || value < 2) {
              callback(new Error('请输入2-20内的缩放值'));
            } else {
              callback();
            }
        }
      }
    }
    return {
      zoomForm: {
        defaultZoom: null
      },
      zoomRules: {
        defaultZoom: [
          { validator: checkZoom, trigger: 'blur' },
          { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' }
        ]
      },
      keyids:[],
      loading: false,
      isLoading: false,
      delDiaA: false,
      addAccount: false,
      addshow: false,
      mngshow: false,
      statushow: true,
      isAdd: true,
      parkshow: false,
      center: false,
      nextShow: true,
      checkSt: false,
      polygon2: null,
      polyEditor: null,
      map: null,
      top: "1",
      parktreeList: [
        {
          top: "1",
          parkName: "",
          children: [],
        },
      ],
      parkList: [],
      list: [],
      path: [],
      rowData: [],
      formLabelWidth: "100px",
      companyId: sessionStorage.getItem("companyId"),
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      form: {
        companyName: "",
        companyId: "",
        authAccount: "",
        phone: "",
        authStatus: "",
        menuIds: [],
        parentId: sessionStorage.getItem("companyId"),
        parkScopeList: [],
        district: "",
      },
      rules: {
        companyName: [
          {
            required: true,
            message: "请输入园区名称",
            trigger: "blur",
            validator: validateCpName,
          },
        ],
        authAccount: [
          {
            required: true,
            message: "请输入园区负责人",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            required: true,
            validator: validatePhone, //在data里定义 ( 重 点 )
            trigger: "blur",
            message: "请输入正确的手机号格式",
          },
        ],
      },
      treeData: [],
      parentIdArr:[],
    };
  },
  mounted() {
    this.getList();
    this.getjur();
  },
  methods: {
    //点击企业/园区列表
    gettree(eve, b, c) {
      this.rowData = eve;
      this.parkId = eve.id;
      this.top = eve.top;
      this.getList();
    },
    //企业/园区操作
    renderContent(h, { node, data, store }) {
      if (data.top) {
        return (
          <div>
            <span>{data.parkName}</span>
            <span class="color" on-click={() => this.getAdd(node, data, store)}>
              新增
            </span>
          </div>
        );
      } else {
        return (
          <div>
            <span>{data.companyName}</span>
            <span on-click={() => this.getdel(node, data, store)} class="color">
              删除
            </span>
          </div>
        );
      }
    },
    //下一步
    qxShow() {
      this.$refs["zoomForm"].validate((valid) => {
        if (valid) {
          this.parkshow = false;
          this.mngshow = true;
         } else {
          return false;
         }
      });
    },
    //编辑园区
    updateYq() {
      // this.$nextTick(() => {
      //   this.$refs.resourcesMenuRef.setCheckedKeys([]);
      // });
      this.addshow = true;
      this.isAdd = false;
      this.form.companyName = this.list.companyName;
      this.form.companyId = sessionStorage.getItem("companyId");
      this.form.id = this.list.id;
      this.form.authAccount = this.list.authAccount;
      this.form.phone = this.list.phone;
      this.statushow = this.list.authStatus == 1 ? true : false;
      this.form.authStatus = this.list.authStatus;
      this.zoomForm.defaultZoom = this.list.defaultZoom;// 编辑园区赋值zoom
      this.checkSt = true;
      this.$nextTick(() => {
        this.keyids = this.list.menuIds.filter((ele) => !this.parentIdArr.includes(ele));
        this.checkSt = false;
      });
      this.form.menuIds = this.list.menuIds;
    },
    //关闭抽屉
    handleClose() {
      this.addshow = false;
      // this.zoomForm.defaultZoom = null //重置zoom
    },
    handleCloseB() {
      this.parkshow = false;
      // this.zoomForm.defaultZoom = null //重置zoom
    },
    //添加园区
    getAdd() {
      this.addshow = true;
      this.isAdd = true;
      this.keyids = [];
      this.statushow = true;
      this.form = {
        companyName: "",
        companyId: "",
        username: "",
        phone: "",
        authStatus: this.statushow == true ? "1" : "0",
        parentId: sessionStorage.getItem("companyId"),
        parkScopeList: [],
        menuIds: [],
      };
      this.zoomForm.defaultZoom = null //重置zoom
      this.$nextTick(() => {
        this.$refs.resourcesMenuRef.setCheckedKeys([]);
      });
    },
    //删除园区
    getdel(node, data, store) {
      this.parkId = data.id;
      console.log(data.id);
      this.delDiaA = true;
    },
    //切换园区状态
    getstatus(val) {
      if (val == true) {
        this.statushow = true;
        this.form.authStatus = 1;
      } else {
        this.statushow = false;
        this.form.authStatus = 0;
      }
    },
    //园区名校验
    async getparkSelect() {
      this.nextShow = false;
      let { success, data, message } = await _ParkcheckName({
        userId: this.companyId,
        companyName: this.form.companyName,
      });
      if (success) {
        this.nextShow = true;
        if (this.isAdd == false) {
          this.getUp();
        }
      }
    },
    //修改
    async getUp() {
      this.isLoading = true;
      console.log(this.form);
      _UpdateUserPark({...this.form , ...this.zoomForm}).then((res) => {
        if (res.success == true) {
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.message);
        }
        this.isLoading = false;
        this.getList();
        this.mngshow = false;
        this.addshow = false;
      });
    },
    //新增园区
    async getaddpark() {
      if (
        this.form.companyName != "" &&
        this.form.authAccount != "" &&
        this.form.phone != ""
      ) {
        if (this.nextShow == true) {
          this.$refs.form.validate((valid) => {
            console.log(valid);
            if (valid) {
              this.parkshow = true;
              this.$nextTick(() => {
                this.initMap();
              });
            }
          });
        }
      } else {
        this.$message.error("新增园区内容不能为空");
      }
    },
    getparkAdd() {
      this.isLoading = true;
      if (this.form.menuIds) {
        if (this.isAdd) {
          _Addpark({...this.form , ...this.zoomForm}).then((res) => {
            if (!res) {
              this.isLoading = false;
            }
            if (res.success == true) {
              this.$message.success("园区添加成功");
              this.getList();
            } else {
              this.$message.error(res.message);
            }
            this.isLoading = false;
            this.mngshow = false;
            this.addshow = false;
          });
        } else {
          if (this.form.companyName != this.list.companyName) {
            this.getparkSelect();
          } else {
            this.getUp();
          }
        }
      } else {
        this.$message.error("功能权限配置不能为空");
      }
    },
    //获取企业/园区数据列表
    async getList() {
      let { success, data, message } = await _ParkList({
        parkId: this.parkId,
        companyId: sessionStorage.getItem("companyId"),
      });
      if (success) {
        this.parkList = data[0];
        this.parktreeList[0].parkName = this.parkList.companyName;
        this.parktreeList[0].children = this.parkList.companyList;
        let dat = data[0].companyList.filter((id) => {
          return id.id == this.parkId;
        });
        this.list = dat[0] ? dat[0] : {};
        this.total = data.total;
        this.form.companyId = this.parkList.id;
      }
    },
    //权限列表
    async getjur() {
      let { success, data, message } = await _GetRoute({
        companyId: this.companyId,
      });
      if (success) {
        this.treeData = data.list;
        this.parentIdArr = this.treeData.map((ele)=>{
          return ele.id
        })
      }
    },
    //点击权限列表
    checkChange(item, nodes) {
      this.form.menuIds = nodes.checkedKeys;
    },
    //删除园区
    async getdelpark() {
      let { success, data, message } = await _DelparkList({
        companyId: this.parkId,
      });
      if (success) {
        this.$message.success("园区删除成功");
        this.parkId = "";
        this.top = 1;
        this.getList();
      }
      this.delDiaA = false;
    },
    //刷新
    refresh() {
      this.$nextTick(() => {
        document.getElementById("tipinput").value = "";
        this.initMap();
      });
    },
    // 初始化地图
    initMap() {
      console.log(this.form);
      this.map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 3.5,
        layers: [
          // 卫星
          new AMap.TileLayer.Satellite(),
          // 路网
          new AMap.TileLayer.RoadNet(),
        ],
      });

      //输入提示
      var autoOptions = {
        input: "tipinput",
      };

      var auto = new AMap.AutoComplete(autoOptions);
      var placeSearch = new AMap.PlaceSearch({
        map: this.map,
      }); //构造地点查询类
      auto.on("select", (data) => {
        console.log(data);
        // this.form.district=data.poi.district
        if (this.polygon2) {
          this.map.remove(this.polygon2);
        }
        if (this.polyEditor) {
          this.map.remove(this.polyEditor);
        }

        placeSearch.setCity(data.poi.adcode);
        placeSearch.search(data.poi.name); //关
        this.form.district = [];
        this.initSearch();
      }); //注册监听，当选中某条记录时会触发

      let path = this.rowData.parkScope
        ? JSON.parse(this.rowData.parkScope)
        : [];
      if (this.rowData.parkScope) {
        this.polygon2 = new AMap.Polygon({
          path,
        });

        this.map.add([this.polygon2]);
        this.map.setFitView();
        this.polyEditor = new AMap.PolygonEditor(this.map);
        this.polyEditor.addAdsorbPolygons([this.polygon2]);

        console.log(this.form);
        let that = this;
        this.polyEditor.on("adjust", function (data1) {
          console.log(data1.target._opts.path);
          that.form.parkScopeList = data1.target._opts.path;
          that.getCity();
        });

        this.polyEditor.on("addnode", function (data1) {
          console.log(data1.target._opts.path);

          that.form.parkScopeList = data1.target._opts.path;
          that.getCity();
        });

        // this.polygon2.on('dblclick', () => {
        //   this.polyEditor.setTarget(polygon2);
        //   this.polyEditor.open();
        // })
        this.polyEditor.setTarget(this.polygon2);
        this.polyEditor.open();
      } else {
        this.initSearch();
      }
    },
    initSearch() {
      this.polyEditor = new AMap.PolygonEditor(this.map);
      this.polyEditor.setTarget();
      this.polyEditor.open();
      this.polyEditor.on("add", (data) => {
        let polygon = data.target;
        this.form.parkScopeList = polygon._opts.path;
        this.polyEditor.addAdsorbPolygons(polygon);
        this.getCity();
      });

      this.polyEditor.on("addnode", (data) => {
        console.log(data);
        this.form.parkScopeList = data.target._opts.path;
        this.getCity();
      });
      this.polyEditor.on("adjust", (data) => {
        this.form.parkScopeList = data.target._opts.path;
      });
    },
    getCity() {
      let geocoder = new AMap.Geocoder({
        city: "", //城市，默认：“全国”
      });
      let that = this;
      var lnglatXY = this.form.parkScopeList[0];
      geocoder.getAddress(lnglatXY, function (status, result) {
        console.log(status);
        console.log(result);
        if (status === "complete" && result.info === "OK") {
          var city = result.regeocode.addressComponent.city
            ? result.regeocode.addressComponent.city
            : result.regeocode.addressComponent.province;
          that.form.district = city;
        }
      });
    },
  },
};
</script>
<style>
.amap-sug-result {
  z-index: 3093 !important;
}
</style>
<style lang="less" scoped>
.largMap {
  /* width: 80%; */
  height: 600px;
  position: relative;
  flex: 1;
}
p {
  margin: 0;
}
.left {
  width: 20%;
  height: 701px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  margin-right: 20px;
}
.right {
  width: 80%;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 80px;
}
.bottom {
  margin-bottom: 36px;
}
.textA {
  margin-right: 0 !important;
  margin-left: 22px;
  display: flex;
  justify-content: flex-start;
}
.textB {
  margin-right: 16px;
  margin-left: 27px;
}
.button {
  width: 100px;
  background: #1683d9;
  box-shadow: 0px 4px 16px 0px rgba(22, 131, 217, 0.6);
  margin-left: 70px;
}
.leftDv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 200px;
}
.textleftA {
  /* margin-left: 310px;
  margin-top: 200px; */
}
.textleft {
  margin-left: 80%;
  margin-top: 8%;
}
.footerButton{
  text-align: right;
}
.footerButton .textleft{
  margin-left: 0px;
  margin-right: 50px;
  margin-top: 50px;
}
.textA {
  /* margin-bottom: 20px; */
}
.textR {
  margin-left: 50%;
  margin-top: 100px;
}
/* .textl {
  margin-left: 10px;
} */
/deep/.color {
  color: #1683d9;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 550;
}
/* .elDra{
  margin-left:30px;
} */
.elForm {
  margin-left: -30px;
}
.elFormB {
  margin-left: -25px;
}
#container {
  width: 100%;
  height: 600px;
  position: absolute;
  top: 0px;
  left: 0px;
}
#tipinput {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #fff;
  width: 240px;
  height: 34px;
  color: #999;
  font-size: 14px;
  z-index: 100;
  border: 0px;
  outline: none;
}
.refresh {
  position: absolute;
  top: 20px;
  left: 280px;
  z-index: 100;
}
.zoom-input{
  margin-left: auto;
  display: flex;
  align-items: center;
}
/deep/.zoom-input .el-input>.el-input__inner {
  border: 1px solid #DCDFE6 !important;
}
/deep/.el-form-item.is-error .el-input__inner{
  border-color: #F56C6C !important;
}
</style>
