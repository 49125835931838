<template>
  <div>
    <button class="centerBtn" @click="getaddcount" style="margin-bottom: 20px">
      添加账号
    </button>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.phone"
          placeholder="请输入手机号"
          class="centerInput"
        ></el-input
      ></el-form-item>

      <el-form-item>
        <el-input
          v-model="list.username"
          placeholder="请输入账号"
          class="centerInput"
        ></el-input
      ></el-form-item>

      <el-form-item>
        <div class="buttonLeft">
          <button class="centerCzbtn ml20" type="button" @click="getList2">搜索</button>
          <button class="centerBtn resetbtn" type="button" @click="cancel">重置</button>
        </div></el-form-item
      >
    </el-form>
    <Table
      :data1="accountMngList"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />

    <!-- 添加车辆弹框 -->
    <el-drawer
      destroy-on-close
      :title="isAdd ? '添加账号' : '编辑信息'"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      :before-close="handleClose"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="refForm">
          <el-form-item label="账号名" :label-width="formLabelWidth" prop="username">
            <el-input
              v-model="form.username"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
            <el-input
              v-model="form.phone"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入 (接受初始密码短信)"
              :disabled="!isAdd"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="中文名" :label-width="formLabelWidth" prop="name">
            <el-input
              v-model="form.name"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button class="centerCzbtn" @click="getSelect" :loading="isLoading"
          >确定</el-button
        >
        <button class="centerBtn resetbtn" @click="addAccount = false">取消</button>
      </div>
    </el-drawer>
    <!-- 删除弹框 -->
    <el-dialog title="删除" :visible.sync="delDia" width="30%">
      <span>确认删除</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn" @click="getDel">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">取 消</button>
      </span>
    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="resetPwd" width="30%">
      <span>执行操作后，系统会给用户手机号发送新密码</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn" @click="getupPwd">确 定</button>
        <button class="centerBtn resetbtn" @click="resetPwd = false">取 消</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _AccountMngList,
  _AddAccountCpname,
  _AddAccountPhone,
  _AddAccount,
  _UpAccountCpname,
  _UpAccountPhone,
  _UpAccount,
  _UpStatu,
  _DelList,
  _UpAccountpwd,
} from "@/api/systemMng";
export default {
  components: {
    Table,
  },
  data() {
    //电话校验
    var validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

      if (value === "" || value === undefined || value === null) {
        callback();
      } else {
        if (!reg.test(value) && value !== "") {
          callback(new Error("请输入正确的电话号码"));
        } else {
          /*if (this.isAdd == false) {
            this.getUpphone();
          } else {
            this.getAddphone();
          }*/
          callback();
        }
      }
    };
    //用户名校验
    var validateCpName = (rule, value, callback) => {
      if (value === "" || value === undefined || value === null) {
        callback(new Error("账号名不能为空"));
      } else {
        if (value.length >= 15) {
          callback(new Error("账号名长度不能超过15个字母"));
          return false;
        }

        const reg = /^[a-zA-Z]*$/g;

        if (!reg.test(value)) {
          callback(new Error("账号名只能输入字母"));
          return false;
        } else {
          if (value !== "") {
            callback();
          }
        }
      }
    };

    return {
      loading: false,
      isLoading: false,
      addAccount: false,
      accountEdit: false,
      delDia: false,
      resetPwd: false,
      isAdd: false,
      userCompanyId: null,
      total: 0,
      formLabelWidth: "100px",
      accountMngList: [],
      columns: [
        {
          label: "账号名",
          prop: "username",
        },
        {
          label: "中文名",
          prop: "name",
        },
        {
          label: "手机号",
          prop: "phone",
        },
        {
          label: "激活",
          render: (h, params) => {
            return h("div", {}, [
              h("span", {style: `color: ${params.row.activate? '#2FCA3B': '#A2B2A4'}`}, '●'),
              h("span", {}, params.row.activate ? '已激活': '未激活')
            ])
          }
        },
        {
          label: "账号状态",
          prop: "status",
          render: (h, params) => {
            return h("div", [
              h(
                "el-switch",
                {
                  on: {
                    change: (val) => {
                      if (val == true) {
                        params.row.authStatus = 1;
                      } else {
                        params.row.authStatus = 0;
                      }
                      this.getUpstatu(params.row, params.row.authStatus);
                    },
                  },
                  props: {
                    value: params.row.authStatus == 0 ? false : true,
                    disabled: (params.row.activate ? false: true),
                  },
                },
                "编辑"
              ),
            ]);
          },
        },
        {
          label: "最近登录时间",
          prop: "createTime",
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.isAdd = false;
                      this.addAccount = true;
                      this.userCompanyId = params.row.id;
                      this.form = {
                        username: params.row.username,
                        phone: params.row.phone,
                        name: params.row.name,
                        id: params.row.id,
                      };
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      if(params.row.activate) {
                        this.resetPwd = true;
                        this.companyId = params.row.id;
                      }
                    },
                  },
                  style: `cursor: ${params.row.activate ? 'pointer': 'not-allowed'};
                          color: ${params.row.activate ? '#1683d9': '#A2B2A4'}`
                },
                "重置密码"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDia = true;
                      this.userCompanyId = params.row.id;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        total: 0,
        phone: "",
        username: "",
        companyId: sessionStorage.getItem("companyId"),
      },
      searchObj: {
        phone: "",
      },
      form: {
        username: "",
        phone: "",
        name: "",
        id: "",
        companyId: sessionStorage.getItem("companyId"),
      },
      rules: {
        username: [
          {
            required: true,
            validator: validateCpName,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入中文名",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            required: true,
            validator: validatePhone, //在data里定义 ( 重 点 )
            trigger: "blur",
            message: "请输入正确的手机号格式",
          },
        ],
        role: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //添加账号
    getaddcount() {
      this.addAccount = true;
      this.isAdd = true;
      this.form.username = "";
      this.form.phone = "";
      this.form.name = "";
    },
    //关闭抽屉
    handleClose() {
      this.addAccount = false;
      this.form = {
        username: "",
        phone: "",
        name: "",
      };
      this.$refs.refForm.clearValidate();
    },
    //点击确定触发
    getSelect() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          if (this.isAdd == true) {
            this.getAdd();
          } else {
            this.getUp();
          }
        }
      });
    },
    //取消
    cancel() {
      this.list.page = 1;
      this.list.phone = "";
      this.list.username = ""
      this.getList();
    },
    //切换分页
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    //获取数据列表
    async getList() {
      let { success, data, message } = await _AccountMngList(this.list);
      if (success) {
        this.accountMngList = data.list;
        this.list.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2() {
      this.list.page = 1;
      this.getList();
    },
    //切换状态
    async getUpstatu(row, statu) {
      let { success, data, message } = await _UpStatu({
        userId: row.id,
        authStatus: statu,
      });
      if (success) {
        this.getList();
      }
    },
    //添加用户名校验
    async getAddCpname() {
      let { success, data, message } = await _AddAccountCpname({
        username: this.form.username,
      });
      if (success) {
      }
    },
    //添加手机号校验
    async getAddphone() {
      let { success, data, message } = await _AddAccountPhone({
        phone: this.form.phone,
      });
      if (success) {
      }
    },
    //添加
    async getAdd() {
      this.isLoading = true;
      _AddAccount(this.form).then((res) => {
        if (!res) {
          this.isLoading = false;
        } else if (res.success) {
          this.getList();
          this.$message.success("账号添加成功");
          this.isLoading = false;
        }
      });
      this.addAccount = false;
    },
    //修改用户名校验
    async getUpCpname() {
      let { success, data, message } = await _UpAccountCpname({
        userId: this.userCompanyId,
        username: this.form.username,
      });
      if (success) {
      }
    },
    //修改手机号校验
    async getUpphone() {
      let { success, data, message } = await _UpAccountPhone({
        userId: this.userCompanyId,
        phone: this.form.phone,
      });
      if (success) {
      }
    },
    //修改
    async getUp() {
      _UpAccount(this.form).then((res) => {
        if (res.success == true) {
          this.getList();
          this.addAccount = false;
          this.addAccount = false;

          this.$message.success("账号编辑成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //删除
    async getDel() {
      let { success, data, message } = await _DelList({
        userId: this.userCompanyId,
        companyId: this.list.companyId,
      });
      if (success) {
        this.getList();
        this.$message.success("账号删除成功");
      }
      this.delDia = false;
    },
    //重置密码
    async getupPwd() {
      let { success, data, message } = await _UpAccountpwd({
        userId: this.companyId,
        platformType: 2
      });
      if (success) {
        this.getList();
        this.$message.success("重置密码成功");
      }
      this.resetPwd = false;
    },
  },
};
</script>

<style scoped>
.buttonLeft {
  margin-left: -15px;
}
</style>