import service from '../../../until/api'

//获取巡逻车所有数据

export function _getpactrol(data){
    return service({
        url: 'patrol-bus/web/communicationRecordPlayback/findPatrolPage',
        method: 'GET',
        params: data
    })
} 

// 获取视频地址
export function _getVideoUrl(data){
    return service({
        url: '/patrol-bus/web/communicationRecordPlayback/findVideoByCarNumber',
        method: 'GET',
        params: data
    })
} 
// 通知开启地址
export function _openVideoUrl(data){
    return service({
        url: '/patrol-bus/web/communicationRecordPlayback/startPlayVideo',
        method: 'GET',
        params: data
    })
} 
// 通知关闭地址
export function _closeVideoUrl(data){
    return service({
        url: '/patrol-bus/web/communicationRecordPlayback/stopPlayVideo',
        method: 'GET',
        params: data
    })
} 