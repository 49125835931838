import service from '../../until/api'

/**
 * @name: _GetTaskList
 * @msg: 获取表格信息
 * @param {*} data
 * @return {*}
 */
export function _GetTaskLocationList(data) {
    return service({
        url: 'transport-truck/web/icps/getTaskList',
        method: 'GET',
        params: data
    })
}

/**
 * @name: 
 * @msg: 二次定位任务新增
 * @param {*}
 * @return {*}
 */
 export function _AddTaskLocation(data) {
  return service({
      url: 'transport-truck/web/icps/addTask',
      method: 'POST',
      data
  })
}

// /**
//  * @name: _ExecuteTaskLocation
//  * @msg: 二次定位任务下发
//  * @param {*}
//  * @return {*}
//  */
//  export function _ExecuteTaskLocation(data) {
//   return service({
//       url: 'transport-truck/web/icps/executeTask',
//       method: 'POST',
//       data
//   })
// }

/**
 * @name: _GetTaskExecuteResult
 * @msg: 二次定位任务下发结果获取
 * @param {*}
 * @return {*}
 */
 export function _GetTaskExecuteResult(data) {
  return service({
      url: 'transport-truck/web/icps/getTaskExecuteResult',
      method: 'GET',
      params: data
  })
}