<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <!-- 尽量避免使用行内样式，此处行内样式是为了清除centerInput公共类的样式 -->
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
          style="margin-right: 10px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="list.carVin"
          placeholder="车辆VIN"
          class="centerInput"
          style="margin-right: 10px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="list.carStatus"
          placeholder="车辆状态"
          class="centerSelInput centerInput"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in vehStateList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-cascader
          clearable
          v-model="carTypeId"
          class="centerSelInput centerInput"
          :options="cartypelist"
          :props="defaultProps"
          @change="handleChange"
          :show-all-levels="false"
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2()">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button>
      </el-form-item>
    </el-form>

    <el-dialog
      title="查看位置"
      :visible.sync="viewLocation"
      width="50%"
      id="statusMap"
    >
      <amap ref="myMap"> </amap>
    </el-dialog>

    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      @getAjax="getTableData"
      :loading="loading"
      :page="list.page"
    />

    <!--region 车辆实时信息-->
    <CarRealTimeData
      :visible.sync="carRealTimeConfig.visible"
      :carData.sync="carRealTimeConfig.carData"
      v-if="carRealTimeConfig.visible"
    ></CarRealTimeData>
    <!--endregion 车辆实时信息-->
    <!-- 清扫 -->
    <el-dialog title="清扫" :visible.sync="sweepDiaA" width="30%">
      <p>
        当前状态 <span v-if="sweeplist.switch == 1">【已开启】</span
        ><span v-else>【已关闭】</span>
      </p>
      <span
        >确认执行<span v-if="sweeplist.switch == 1">【关闭清扫】</span
        ><span v-else>【开启清扫】</span> 操作?</span
      >
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getsweep">确 定</button>
        <button
          class="centerBtn resetbtn ml20"
          @click="sweepDiaA = false"
          style="margin-left: 10px"
        >
          取 消
        </button>
      </span>
    </el-dialog>
    <!-- 喷水 -->
    <el-dialog title="喷水" :visible.sync="waterDiaA" width="30%">
      <p>
        当前状态 <span v-if="this.sweeplist.witch_two == 1">【已开启】</span
        ><span v-else>【已关闭】</span>
      </p>
      <span
        >确认执行 <span v-if="this.sweeplist.witch_two == 1">【关闭喷头】</span
        ><span v-else>【开启喷头】</span>操作?</span
      >
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getwater">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="waterDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <!-- 刹车 -->
    <el-dialog title="刹车" :visible.sync="carDiaA" width="30%">
      <span
        >确认执行 <span v-if="vehicle.base.brake == 1">【关闭刹车】</span
        ><span v-else>【开启刹车】</span>操作?</span
      >
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getpaCar()">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="carDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <!-- 车锁车门 -->
    <el-dialog title="车锁车门" :visible.sync="doorsDiaA" width="30%">
      <span
        >确认执行 <span v-if="this.sweeplist.witch_two == 1">【关闭车锁】</span
        ><span v-else>【开启车锁】</span>操作?</span
      >
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="openFullScreen1(false)">
          确 定
        </button>
        <button class="centerBtn resetbtn ml20" @click="doorsDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <!-- 除雾 -->
    <el-dialog title="除雾" :visible.sync="DemistingDiaA" width="30%">
      <span
        >确认执行 <span v-if="vehicle.base.defrost == 1">【关闭除雾】</span
        ><span v-else>【开启除雾】</span>操作?</span
      >
      <span slot="footer" class="dialog-footer">
        <button
          class="centerCzbtn"
          @click="getsw_one(8, vehicle.base.defrost == 1 ? '0' : '1')"
        >
          确 定
        </button>
        <button class="centerBtn resetbtn ml20" @click="DemistingDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <!-- 车辆灯光 -->
    <el-dialog
      title="车辆灯光"
      :visible.sync="dialogTableVisible"
      @close="getnoShow"
    >
      <div class="doorImg">
        <img
          v-if="nowCarType == '运输载货车'"
          src="@/assets/images/TruckBusImg.png"
          alt=""
        />
        <img
          v-else-if="nowCarType == 'SV-BUS'"
          src="@/assets/images/SvBus.png"
          alt=""
        />
        <img v-else src="@/assets/images/microBusImg.png" alt="" />
        <div class="doorLock">
          <div>
            <span>近光灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.lowbeam == 1">开</span>
            <span v-else>关</span>
            <el-switch
              :disabled="nowCarType == '运输载货车'"
              v-model="vehicle.light.lowbeam"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="getsw_one(3, vehicle.light.lowbeam)"
            >
            </el-switch>
          </div>
          <div>
            <span>远光灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.highbeam == 1">开</span>
            <span v-else>关</span>
            <el-switch
              :disabled="nowCarType == '运输载货车'"
              v-model="vehicle.light.highbeam"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="getsw_one(4, vehicle.light.highbeam)"
            >
            </el-switch>
          </div>
          <div v-show="nowCarType != '运输载货车'">
            <span>车内灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.interior == 1">开</span>
            <span v-else>关</span>
            <el-switch
              v-model="vehicle.light.interior"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="getsw_one(5, vehicle.light.interior)"
            >
            </el-switch>
          </div>
          <div v-show="nowCarType != '运输载货车'">
            <span>后雾灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.rearfog == 1">开</span>
            <span v-else>关</span>
            <el-switch
              v-model="vehicle.light.rearfog"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="getsw_one(6, vehicle.light.rearfog)"
            >
            </el-switch>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 空调 -->
    <el-dialog
      title="空调"
      :visible.sync="dialogTableVisible_two"
      @close="getnoShow"
    >
      <div class="airConditioner">
        <div class="airConditionerHead">
          <div class="temperature">
            <!-- <p>车内温度</p>
            <p>
              <span class="centigrade">{{ carinwd }}</span> ℃
            </p> -->
          </div>
          <div class="airConditionerImg">
            <img
              src="@/assets/images/SvBus.png"
              alt=""
              v-if="nowCarType == 'SV-BUS'"
            />
            <img src="@/assets/images/microBusImg.png" alt="" v-else />
          </div>
        </div>
      </div>
      <div class="vehState">
        <div class="AirConditioningStatus">
          <div>
            空调状态
            <img src="@/assets/images/lock.png" alt="" />
            <span v-if="mstatus == 1">开启</span>
            <span v-else>关闭</span>
          </div>
          <div>
            空调模式
            <img src="@/assets/images/airconditioner.png" alt="" />
            <span v-if="modelstatus == '0x0'">关闭</span>
            <span v-else-if="modelstatus == '0x1' && mstatus == 1"
              >自动制冷</span
            >
            <span v-else-if="modelstatus == '0x2' && mstatus == 1"
              >自动制热</span
            >
            <span v-else-if="modelstatus === '0x3' && mstatus == 1"
              >强制制冷模式</span
            >
            <span v-else-if="modelstatus == '0x4' && mstatus == 1"
              >强制制热模式</span
            >
            <span v-else-if="modelstatus == '0x5' && mstatus == 1">通风</span>
          </div>
        </div>
      </div>
      <div class="state" v-loading.fullscreen.lock="fullscreenLoading2">
        <img
          src="@/assets/images/refrigeration.png"
          alt=""
          @click="getcool(0x1)"
          v-if="modelstatus != '0x1'"
        />
        <img
          src="@/assets/images/refrigeration_two.png"
          alt=""
          @click="getcool(0x1)"
          v-if="modelstatus == '0x1' && mstatus == 1"
        />
        <img
          src="@/assets/images/ForcedCooling.png"
          alt=""
          @click="getcool(0x2)"
          v-if="modelstatus != '0x2'"
        />
        <img
          src="@/assets/images/ForcedCooling_two.png"
          alt=""
          @click="getcool(0x2)"
          v-if="modelstatus == '0x2' && mstatus == 1"
        />
        <img
          src="@/assets/images/heating.png"
          alt=""
          @click="getcool(0x3)"
          v-if="modelstatus != '0x3' || mstatus == 0"
        />
        <img
          src="@/assets/images/heating_two.png"
          alt=""
          @click="getcool(0x3)"
          v-if="modelstatus == '0x3' && mstatus == 1"
        />
        <img
          src="@/assets/images/forcedHeat.png"
          alt=""
          @click="getcool(0x4)"
          v-if="modelstatus != '0x4'"
        />
        <img
          src="@/assets/images/forcedHeat_two.png"
          alt=""
          @click="getcool(0x4)"
          v-if="modelstatus == '0x4' && mstatus == 1"
        />
        <img
          src="@/assets/images/improveAircirculation.png"
          alt=""
          @click="getcool(0x5)"
          v-if="modelstatus != '0x5'"
        />
        <img
          src="@/assets/images/improveAircirculation_two.png"
          alt=""
          @click="getcool(0x5)"
          v-if="modelstatus == '0x5' && mstatus == 1"
        />
      </div>
      <!-- <div class="wind flex justify-center" v-show="avoshow">
                <el-input-number
                  v-model="cvolume"
                  @change="handleChangewind"
                  :min="1"
                  :max="7"
                  label="风量"
                ></el-input-number>
              </div> -->
      <div class="wind flex justify-center">
        <el-input-number
          v-show="!(airConditioningData.model == 3)"
          v-model="cvolume"
          @change="handleChangewind"
          :min="1"
          :max="7"
          :disabled="
            airConditioningData.model === 4 ||
            airConditioningData.model === 2 ||
            airConditioningData.model === 0
              ? true
              : false
          "
          label="风量"
        ></el-input-number>
      </div>
      <!-- <div
                class="openClean"
                @click="getcool(0x0)"
                v-if="this.sweeplist.witch == 1"
              >
                关闭
              </div> -->
      <div class="openClean-btn">
        <button
          class="openClean"
          @click="getcool(0x0)"
          :disabled="airConditioningData.model == 0"
        >
          关闭
        </button>
      </div>
    </el-dialog>
    <!-- 查看位置 -->
    <el-dialog
      title="查看车辆位置"
      :visible.sync="priceShow"
      width="60%"
      @close="closePrice"
      destroy-on-close
    >
      <div id="lookContainer"></div>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn" @click="priceShow = false">确 定</button>
        <button class="centerBtn resetbtn" @click="priceShow = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <!-- Taxi 灯光控制 -->
    <el-dialog
      title="车辆灯光"
      :visible.sync="taxiLamplightVisible"
      @close="getnoShow"
    >
      <div class="doorImg">
        <img src="@/assets/images/microBusImg.png" alt="" />
        <div class="taxiLight doorLock">
          <div>
            <span>近光灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.lowbeam == 1">开</span>
            <span v-else-if="vehicle.light.lowbeam == 0">关</span>
            <span v-else>未采集</span>
          </div>
          <div>
            <span>远光灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.highbeam == 1">开</span>
            <span v-else-if="vehicle.light.highbeam == 0">关</span>
            <span v-else>未采集</span>
          </div>
          <div>
            <span>左转向灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.leftturn == 1">开</span>
            <span v-else-if="vehicle.light.leftturn == 0">关</span>
            <span v-else>未采集</span>
          </div>
          <div>
            <span>右转向灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.rightturn == 1">开</span>
            <span v-else-if="vehicle.light.rightturn == 0">关</span>
            <span v-else>未采集</span>
          </div>
          <div>
            <span>倒车灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.backuplamp == 1">开</span>
            <span v-else-if="vehicle.light.backuplamp == 0">关</span>
            <span v-else>未采集</span>
          </div>
          <div>
            <span>刹车灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.brakelight == 1">开</span>
            <span v-else-if="vehicle.light.brakelight == 0">关</span>
            <span v-else>未采集</span>
          </div>
          <div>
            <span>危险报警灯</span>
            <img src="@/assets/images/lamp.png" alt="" />
            <span v-if="vehicle.light.warning == 1">开</span>
            <span v-else-if="vehicle.light.warning == 0">关</span>
            <span v-else>未采集</span>
            <el-switch
              v-show="vehicle.light.warning == 1 || vehicle.light.warning == 0"
              v-model="vehicle.light.warning"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="getsw_one(17, vehicle.light.warning)"
            >
            </el-switch>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Table from "../../components/Table.vue";
import { Amap, Marker } from "@amap/amap-vue";
import {
  _FindstatusList,
  _FindMsgList,
  _FindMsgclear,
  _FindMsgminBus,
  _FindpatrolV,
  _Findpatrol,
} from "@/api/equipmentMonitoring";
import { _CarType } from "@/api/systemMng";
import {
  BeltsStatusValueToLabel,
  ChargeValueToLabel,
  DmodeValueToLabel,
  GpsValueToLabel,
  OffOnValueToLabel,
  SeatsStatusValueToLabel,
  TimestampToDatetime,
} from "@/until/helper";
import CarRealTimeData from "../../components/CarRealTimeDrawer/CarRealTimeData.vue";
import { _ExecuteTask } from "@/api/transportCar/index.js";

export default {
  components: {
    Table,
    Amap,
    AmapMarker: Marker,
    CarRealTimeData,
  },
  data() {
    return {
      airConditioningData: {}, //空调数据
      nowCarType: null, //点开的车辆类型
      loading: false,
      viewLocation: false,
      total: 0,
      isSweeper: false,
      sweepDiaA: false,
      waterDiaA: false,
      ispatrolCar: false,
      isMicroBus: false,
      isativehicleOperation: false,
      zgshow: false,
      fullscreenLoading: false,
      fullscreenLoading2: false,
      fullscreenLoading3: false,
      carDiaA: false,
      doorsDiaA: false,
      DemistingDiaA: false,
      dialogTableVisible: false,
      dialogTableVisible_two: false,
      taxiLamplightVisible: false, // Taxi车型灯光弹窗
      avoshow: false,
      priceShow: false,
      activeName1: "first",
      activeName: "first",
      activeNameth: "first",
      num: 1,
      numb: 0,
      timestamp: "",
      time: "",
      typeName: "",
      id: "",
      type: "",
      timer: "",
      timer2: "",
      size: "0",
      addedmap: null,
      modelstatus: 0,
      cvolume: 0,
      mstatus: 0,
      carStatus: 0,
      statusdoors: 0,
      carinwd: 0,
      warning: "",
      carTypeId: "",
      patorstatus: null,
      valueList: [false, false, false, false],
      valueListB: [false, false, false, false, false, false],
      tableData: [],
      cartypelist: [],
      waterbox: 0,
      dustbin: 0,
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
          render: (h, params) => {
            // console.log(params.row.blOperation)
            if (!params.row.blOperation) {
              return h(
                "div",
                {
                  style: {
                    color: "#999",
                  },
                },
                params.row.carNumber
              );
            } else {
              return h(
                "div",
                {
                  style: {
                    color: "#1683D9",
                    cursor: "pointer",
                  },
                  on: {
                    click: async () => {
                      this.openCarRealtimeDrawer(params.row);
                    },
                  },
                },
                params.row.carNumber
              );
            }
          },
        },
        {
          label: "车辆VIN",
          prop: "carVin",
        },
        {
          label: "车辆类型",
          prop: "typeName",
        },
        {
          label: "车辆子类型",
          prop: "sonTypeName",
        },

        {
          label: "运营类别",
          prop: "operationTypeName",
        },
        {
          label: "车辆状态",
          render: (h, params) => {
            if (params.row.carStatus == 1) {
              return h("span", "在线");
            } else {
              return h("span", "离线");
            }
          },
        },
        {
          label: "车速",
          render: (h, params) => {
            return h("span", params.row.carSpeed + "km/h");
          },
        },
        {
          label: "车辆电量",
          render: (h, params) => {
            let nPower = params.row.power < 0 ? 0 : params.row.power;

            return h("span", nPower + "%");
          },
        },
        {
          label: "更新时间",
          prop: "updateTime",
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            if (!params.row.blOperation) {
              return;
            }
            this.carStatus = params.row.carStatus;
            if (params.row.typeName == "微公交") {
              if (params.row.carStatus == 1) {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.priceShow = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                        // this.getlocation();
                      },
                    },
                  }),
                  // h("img", {
                  //   class: "ml20",
                  //   attrs: {
                  //     src: require("../../assets/images/lock_two.png"),
                  //   },
                  //   on: {
                  //     click: () => {
                  //       this.doorsDiaA = true;
                  //       this.id = params.row.carNumber;
                  //       this.carStatus = params.row.carStatus;
                  //       this.newMsg();
                  //       // this.openFullScreen1();
                  //     },
                  //   },
                  // }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/left.png"),
                    },
                    on: {
                      click: () => {
                        this.dialogTableVisible = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                        this.timer = setInterval(() => {
                          this.newMsg();
                        }, 5000);
                        // this.patorstatus = 1;
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/air_conditioner.png"),
                    },
                    on: {
                      click: () => {
                        this.dialogTableVisible_two = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                      },
                    },
                  }),

                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/fog.png"),
                    },
                    on: {
                      click: () => {
                        this.DemistingDiaA = true;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                        // this.patorstatus = 1;
                      },
                    },
                  }),
                  // h("img", {
                  //   class: "ml20",
                  //   attrs: {
                  //     src: require("../../assets/images/foot.png"),
                  //   },
                  //   on: {
                  //     click: () => {
                  //       this.carDiaA = true;
                  //       this.id = params.row.carNumber;
                  //       this.patorstatus = 1;
                  //     },
                  //   },
                  // }),
                ]);
              } else {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.priceShow = true;
                        this.id = params.row.carNumber;

                        this.newMsg();
                        // this.getlocation();
                      },
                    },
                  }),
                  // h("img", {
                  //   class: "ml20",
                  //   attrs: {
                  //     src: require("../../assets/images/lock_twotwo.png"),
                  //   },
                  // }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lefttwo.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/air_conditioner_two.png"),
                    },
                  }),

                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/fog_two.png"),
                    },
                  }),
                  // h("img", {
                  //   class: "ml20",
                  //   attrs: {
                  //     src: require("../../assets/images/foot_two.png"),
                  //   },
                  // }),
                ]);
              }
            } else if (params.row.typeName == "SV-BUS") {
              if (params.row.carStatus == 1) {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.priceShow = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                        // this.getlocation();
                      },
                    },
                  }),
                  // h("img", {
                  //   class: "ml20",
                  //   attrs: {
                  //     src: require("../../assets/images/lock_two.png"),
                  //   },
                  //   on: {
                  //     click: () => {
                  //       this.doorsDiaA = true;
                  //       this.id = params.row.carNumber;
                  //       this.carStatus = params.row.carStatus;
                  //       this.newMsg();
                  //       // this.openFullScreen1();
                  //     },
                  //   },
                  // }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/left.png"),
                    },
                    on: {
                      click: () => {
                        this.dialogTableVisible = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                        this.timer = setInterval(() => {
                          this.newMsg();
                        }, 5000);
                        // this.patorstatus = 1;
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/air_conditioner.png"),
                    },
                    on: {
                      click: () => {
                        this.dialogTableVisible_two = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                      },
                    },
                  }),
                ]);
              } else {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.priceShow = true;
                        this.id = params.row.carNumber;

                        this.newMsg();
                        // this.getlocation();
                      },
                    },
                  }),
                  // h("img", {
                  //   class: "ml20",
                  //   attrs: {
                  //     src: require("../../assets/images/lock_twotwo.png"),
                  //   },
                  // }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lefttwo.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/air_conditioner_two.png"),
                    },
                  }),
                ]);
              }
            } else if (params.row.typeName == "观光车") {
              return h("div", [
                h("img", {
                  class: "ml20",
                  attrs: {
                    src: require("../../assets/images/lockBlue.png"),
                  },
                  on: {
                    click: () => {
                      this.priceShow = true;
                      this.id = params.row.carNumber;
                      this.carStatus = params.row.carStatus;
                      this.newMsg();
                      // this.getlocation();
                    },
                  },
                }),
              ]);
            } else if (params.row.typeName == "巡逻车") {
              return h("div", [
                h("img", {
                  class: "ml20",
                  attrs: {
                    src: require("../../assets/images/lockBlue.png"),
                  },
                  on: {
                    click: () => {
                      this.priceShow = true;
                      this.id = params.row.carNumber;
                      this.carStatus = params.row.carStatus;
                      this.newMsg();
                      // this.getlocation();
                    },
                  },
                }),
              ]);
            } else if (params.row.typeName == "清扫车") {
              if (params.row.carStatus == 1) {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.priceShow = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                        // this.getlocation();
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/clear.png"),
                    },
                    on: {
                      click: () => {
                        this.sweepDiaA = true;
                        // this.getsweep();
                        // this.getsweep(params.row.carNumber);
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/water.png"),
                    },
                    on: {
                      click: () => {
                        this.waterDiaA = true;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                      },
                    },
                  }),
                ]);
              } else {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.priceShow = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                        // this.getlocation();
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/cleartwo.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/watertwo.png"),
                    },
                  }),
                ]);
              }
            } else if (params.row.typeName == "智能抗冰车") {
              if (params.row.carStatus == 1) {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lock_two.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/left.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/air_conditioner.png"),
                    },
                  }),

                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/foot.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/ice.png"),
                    },
                  }),
                ]);
              } else {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lock_twotwo.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lefttwo.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/air_conditioner_two.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/foot_two.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/icetwo.png"),
                    },
                  }),
                ]);
              }
            } else if (params.row.typeName == "运输载货车") {
              if (params.row.carStatus == 1) {
                return h("p", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.nowCarType = params.row.typeName;
                        this.priceShow = true;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/switchGray.png"),
                    },
                    on: {
                      click: () => {
                        this.isPoweOn(params.row);
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/left.png"),
                    },
                    on: {
                      click: () => {
                        this.nowCarType = params.row.typeName;
                        this.dialogTableVisible = true;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                        this.timer = setInterval(() => {
                          this.newMsg();
                        }, 5000);
                      },
                    },
                  }),
                ]);
              } else {
                return h("p", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/zhanwei.png"),
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/switchBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.isPoweOn(params.row);
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/zhanwei.png"),
                    },
                  }),
                ]);
              }
            } else if (params.row.typeName == "Sharing TAXI") {
              if (params.row.carStatus == 1) {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.priceShow = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        this.newMsg();
                        // this.getlocation();
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/left.png"),
                    },
                    on: {
                      click: () => {
                        this.taxiLamplightVisible = true;
                        this.nowCarType = params.row.typeName;
                        this.id = params.row.carNumber;
                        this.carStatus = params.row.carStatus;
                        console.log(this.vehicle);
                        this.newMsg();
                        this.timer = setInterval(() => {
                          this.newMsg();
                        }, 5000);
                        // this.patorstatus = 1;
                      },
                    },
                  }),
                ]);
              } else {
                return h("div", [
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lockBlue.png"),
                    },
                    on: {
                      click: () => {
                        this.priceShow = true;
                        this.id = params.row.carNumber;
                        this.newMsg();
                        // this.getlocation();
                      },
                    },
                  }),
                  h("img", {
                    class: "ml20",
                    attrs: {
                      src: require("../../assets/images/lefttwo.png"),
                    },
                  }),
                ]);
              }
            }
          },
        },
      ],
      vehStateList: [
        { id: "0", value: "离线" },
        { id: "1", value: "在线" },
      ],
      operationClassList: [
        {
          label: "操作类1",
          value: 0,
        },
        {
          label: "操作类2",
          value: 1,
        },
        {
          label: "操作类3",
          value: 2,
        },
      ],
      typeList: [
        {
          label: "类型1",
          value: 0,
        },
        {
          label: "类型2",
          value: 1,
        },
        {
          label: "类型3",
          value: 2,
        },
      ],
      cleartableData: [
        // {lishengxi1:""},
        { name: "产生时间", englishname: "timestamp", key: "" },
        { name: "车辆id", englishname: "vid", key: "" },
        { name: "车速", englishname: "speed", key: "" },
        { name: "EPB制动状态", englishname: "epb", key: "" },
        { name: "GPS状态", englishname: "status", key: "" },
        { name: "经度", englishname: "longitude", key: "" },
        { name: "纬度", englishname: "latitude", key: "" },
        { name: "航向角", englishname: "head", key: "" },
        { name: "下一站点 ID", englishname: "nstaid", key: "" },
        { name: "下一站点距离", englishname: "nstadis", key: "" },
        { name: "当前站点ID", englishname: "cstaid", key: "" },
        { name: "近光灯状态", englishname: "lowbeam", key: "" },
        { name: "远光灯状态", englishname: "highbeam", key: "" },
        { name: "后雾灯", englishname: "rearfog", key: "" },
        { name: "车内灯", englishname: "interior", key: "" },
        { name: "左转灯", englishname: "leftturn", key: "" },
        { name: "右转灯", englishname: "rightturn", key: "" },
        { name: "电池信息", englishname: "battery", key: "" },
        { name: "续航里程", englishname: "recharge", key: "" },
        { name: "座椅状态", englishname: "seats", key: "" },
        { name: "安全带状态", englishname: "belts", key: "" },
        { name: "自动驾驶状态", englishname: "dmode", key: "" },
        { name: "车辆obu编号", englishname: "oid", key: "" },
        { name: "上行通信速率", englishname: "uspeed", key: "" },
        { name: "下行通信速率", englishname: "dspeed", key: "" },
        { name: "网络延时", englishname: "delay", key: "" },
        { name: "丢包", englishname: "loss", key: "" },
        { name: "V2X事件", englishname: "v2x", key: "" },
        { name: "信号接收功率", englishname: "rsrp", key: "" },
        { name: "信噪比", englishname: "sinr", key: "" },
        { name: "水箱状态", englishname: "waterbox", key: "" },
        { name: "垃圾箱状态", englishname: "dustbin", key: "" },
      ],
      conditioner: {
        modelStr: [],
        cvolume: [],
        status: [],
      },
      business: {},
      vehicle: {
        base: {},
        battery: {},
        conditioner: {},
        doors: [],
        electrics: [],
        ict: {},
        light: {},
        location: {
          latitude: "",
          longitude: "",
        },
      },
      defaultProps: {
        children: "children",
        label: "typeName",
        value: "id",
        checkStrictly: true,
      },
      searchObj: {
        vehicleNumber: "",
        vehVIN: "",
        state: "",
        type: "",
        operationClass: "",
      },
      list: {
        page: 1,
        size: 10,
        total: 0,
        carNumber: "",
        carVin: "",
        carStatus: "",
        carTypeId: "",
        parkId: sessionStorage.getItem("parkId"),
      },
      clearform: {
        cmdType: "",
        cmd: "",
        vehicleId: "",
      },
      sweeplist: {
        witch: 0,
        witch_two: 0,
      },
      minBusform: {
        cmdType: "",
        cmd: "",
        vehicleId: "",
      },
      PaCarform: {
        cmdType: "",
        cmd: "",
        vehicleId: "",
      },
      Cardorm: {
        cmd: 0,
        cmdType: 0,
        vehicleId: "",
        source: "WEB",
      },
      carRealTimeConfig: {
        visible: false,
        carData: null,
      },
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
  },
  mounted() {
    this.getList();
    this.getCarType();
  },
  methods: {
    async openCarRealtimeDrawer(rowData) {
      this.carRealTimeConfig.carData = {
        carNumber: rowData.carNumber,
        carTypeName: rowData.typeName,
        carStatus: rowData.carStatus,
      };

      this.carRealTimeConfig.visible = true;
    },

    //获取实时报文
    async newMsg() {
      _FindMsgList({ vid: this.id }).then((res) => {
        if (!res) {
          this.isSweeper = false;
          this.isMicroBus = false;
          return false;
        }

        if (res.success === true) {
          if (res.data) {
            if (res.data.business) {
              this.business = res.data.business;
            } else {
              if (this.isSweeper || this.isMicroBus) {
                this.$message.error("缺少business数据");
                return false;
              }
            }

            if (res.data.vehicle) {
              this.vehicle = res.data.vehicle;
            } else {
              if (this.isSweeper || this.isMicroBus) {
                this.$message.error("缺少vehicle数据");
                return false;
              }
            }
          } else {
            this.isSweeper = false;
            this.isMicroBus = false;
            this.$message.error(res.message);
            return false;
          }

          this.type = res.data.vehicle.base.type;
          this.vehicle.doors = res.data.vehicle.doors;
          //车门
          if (res.data.vehicle.doors.length > 0) {
            this.statusdoors = res.data.vehicle.doors[0].status;
          }
          this.vehicle.base = res.data.vehicle.base;
          this.vehicle.ict = res.data.vehicle.ict;
          this.vehicle.conditioner = res.data.vehicle.conditioner;
          this.vehicle.light = res.data.vehicle.light;
          //空调数据
          if (res.data.vehicle.conditioner.length > 0) {
            // 空调数据
            this.airConditioningData = res.data.vehicle.conditioner[0];
            this.modelstatus = res.data.vehicle.conditioner[0].model;
            this.cvolume = res.data.vehicle.conditioner[0].cvolume;
            this.mstatus = res.data.vehicle.conditioner[0].status;
            this.carinwd = res.data.vehicle.conditioner[0].ctemp;

            for (const conditioner of res.data.vehicle.conditioner) {
              this.conditioner.modelStr.push(conditioner.modelStr);
              this.conditioner.cvolume.push(conditioner.cvolume);
              this.conditioner.status.push(
                OffOnValueToLabel(conditioner.status)
              );
            }
          }

          //告警
          this.warning = "";
          if (res.data.warning != null) {
            this.warning = res.data.warning.desc;
          }

          this.vehicle.location.longitude = res.data.vehicle.location.longitude;
          this.vehicle.location.latitude = res.data.vehicle.location.latitude;
          this.time = this.format(res.data.timestamp);
          // if (res.data.type == 1) {
          if (this.business.task?.length > 0) {
            let bas = JSON.parse(this.business.task[0].data);
            if (this.business.task[0].type == 1) {
              this.sweeplist.witch = bas.switch;
            } else if (this.business.task[0].type == 4) {
              this.sweeplist.witch_two = bas.switch;
            }
          }

          //region 报文
          let sNstaName =
            this.business.nstaName !== null ? this.business.nstaName : "-";
          let sCstaName =
            this.business.cstaName !== null ? this.business.cstaName : "-";
          let sLineName =
            this.business.lineName !== null ? this.business.lineName : "-";

          let TableCol = [
            {
              cn_name: "产生时间",
              en_name: "timestamp",
              value: TimestampToDatetime(this.time),
            },
            {
              cn_name: "充电状态",
              en_name: "charge",
              value: this.vehicle.base.chargeStr,
            },
            {
              cn_name: "车辆ID",
              en_name: "vid",
              value: this.vehicle.base.vid,
            },
            {
              cn_name: "车速",
              en_name: "speed",
              value: this.vehicle.base.speed + " km/h",
            },
            {
              cn_name: "epb 状态",
              en_name: "epb",
              value: this.vehicle.base.epbStr,
            },
            {
              cn_name: "gps状态",
              en_name: "status",
              value: GpsValueToLabel(res.data.vehicle.location.status),
            },
            {
              cn_name: "经度",
              en_name: "longitude",
              value: res.data.vehicle.location.longitude,
            },
            {
              cn_name: "纬度",
              en_name: "latitude",
              value: res.data.vehicle.location.latitude,
            },
            {
              cn_name: "航向角",
              en_name: "head",
              value: res.data.vehicle.location.head,
            },
            {
              cn_name: "下一站点ID",
              en_name: "nstaid",
              value: this.business.nstaid + "（" + sNstaName + "）",
            },
            {
              cn_name: "下一站点距离",
              en_name: "nstadis",
              value: this.business.nstadis,
            },
            {
              cn_name: "当前站点ID",
              en_name: "cstaid",
              value: this.business.cstaid + "（" + sCstaName + "）",
            },
            {
              cn_name: "近光灯状态",
              en_name: "lowbeam",
              value: OffOnValueToLabel(res.data.vehicle.light.lowbeam),
            },
            {
              cn_name: "远光灯状态",
              en_name: "highbeam",
              value: OffOnValueToLabel(res.data.vehicle.light.highbeam),
            },
            {
              cn_name: "后雾灯状态",
              en_name: "rearfog",
              value: OffOnValueToLabel(res.data.vehicle.light.rearfog),
            },
            {
              cn_name: "后雾灯状态",
              en_name: "rearfog",
              value: OffOnValueToLabel(res.data.vehicle.light.rearfog),
            },
            {
              cn_name: "车内灯状态",
              en_name: "interior",
              value: OffOnValueToLabel(res.data.vehicle.light.interior),
            },
            {
              cn_name: "左转向灯",
              en_name: "leftturn",
              value: OffOnValueToLabel(res.data.vehicle.light.leftturn),
            },
            {
              cn_name: "右转向灯",
              en_name: "rightturn",
              value: OffOnValueToLabel(res.data.vehicle.light.rightturn),
            },
            {
              cn_name: "空调状态",
              en_name: "status",
              value: this.conditioner.status.join("、"),
            },
            {
              cn_name: "空调模式",
              en_name: "model",
              value: this.conditioner.modelStr.join("、"),
            },
            {
              cn_name: "风量档位",
              en_name: "cvolume",
              value: this.conditioner.cvolume.join("、"),
            },
            {
              cn_name: "电池信息",
              en_name: "power",
              value: this.vehicle.base.power + "%",
            },
            {
              cn_name: "续航里程",
              en_name: "recharge",
              value: this.vehicle.base.recharge + " km",
            },
            {
              cn_name: "驾驶模式",
              en_name: "dmode",
              // value: DmodeValueToLabel(this.vehicle.base.dmode),
              value: this.vehicle.base.dmodeStr,
            },
            {
              cn_name: "座椅状态",
              en_name: "seats",
              value: SeatsStatusValueToLabel(this.vehicle.base.seats),
            },
            {
              cn_name: "安全带状态",
              en_name: "belts",
              value: BeltsStatusValueToLabel(this.vehicle.base.belts),
            },
            {
              cn_name: "上行通信速率",
              en_name: "uspeed",
              value: this.vehicle.ict.uspeed,
            },
            {
              cn_name: "下行通信速率",
              en_name: "dspeed",
              value: this.vehicle.ict.dspeed,
            },
            {
              cn_name: "网络延时",
              en_name: "delay",
              value: this.vehicle.ict.delay,
            },
            {
              cn_name: "丢包率",
              en_name: "loss",
              value: this.vehicle.ict.loss,
            },
            {
              cn_name: "线路ID",
              en_name: "line",
              value: this.business.line + "（" + sLineName + "）",
            },
            {
              cn_name: "车辆类型",
              en_name: "type",
              value: this.vehicle.base.typeStr,
            },
            {
              cn_name: "电机转速",
              en_name: "rspeed",
              value:
                this.vehicle.electrics && this.vehicle.electrics.length > 0
                  ? this.vehicle.electrics[0].rspeed + "（r/min）"
                  : "",
            },
            {
              cn_name: "信号接收功率",
              en_name: "rsrp",
              value: this.vehicle.ict.rsrp,
            },
            {
              cn_name: "信噪比",
              en_name: "sinr",
              value: this.vehicle.ict.sinr,
            },
            {
              cn_name: "档位",
              en_name: "gear",
              value: this.vehicle.base.gear,
            },
            {
              cn_name: "水箱状态",
              en_name: "waterbox",
              value: this.business.waterbox,
            },
            {
              cn_name: "垃圾箱状态",
              en_name: "dustbin",
              value: this.business.dustbin,
            },
          ];

          this.cleartableData = TableCol;
          //endregion 报文

          if (this.priceShow) {
            this.getlocation();
          }

          if (this.isSweeper || this.isMicroBus) {
            if (
              this.typeName == "微公交" ||
              this.typeName == "观光车" ||
              this.typeName == "SV-BUS" ||
              this.typeName == "巡逻车" ||
              this.typeName == "运输载货车"
            ) {
              this.$nextTick(() => {
                this.drawLine1();
                this.drawLine2();
              });
            } else if (this.typeName == "清扫车") {
              this.$nextTick(() => {
                this.drawLine3();
                this.drawLine4();
              });
            }
          }
          console.log(this.vehicle, 999);
        } else {
          this.$message.error(res.message);
        }
      });
    },

    //获取地址
    getlocation() {
      this.$nextTick(() => {
        this.addedmap = new AMap.Map("lookContainer", {
          resizeEnable: true,
          center: [
            this.vehicle.location.longitude,
            this.vehicle.location.latitude,
          ],
          zoom: 16,
        });

        let GPS_Position = [
          this.vehicle.location.longitude,
          this.vehicle.location.latitude,
        ];

        let that = this;

        AMap.convertFrom(GPS_Position, "gps", function (status, result) {
          if (result.info === "ok") {
            let AmapPosition = [
              result["locations"][0].lng,
              result["locations"][0].lat,
            ];
            // 判断车辆类型切换图片
            let carImg = null;
            switch (that.nowCarType) {
              case "运输载货车":
                carImg = require("@/assets/images/map_car_type_9.png");
                break;
              case "SV-BUS":
                carImg = require("@/assets/images/map_car_type_2.png");
                break;
              case "清扫车":
                carImg = require("@/assets/images/map_car_type_5.png");
                break;
              case "Sharing TAXI":
                carImg = require("@/assets/images/map_car_type_5.png");
                break;
              default:
                carImg = require("@/assets/images/minbus.png");
            }
            var marker = new AMap.Marker({
              map: that.addedmap,
              clickable: true,
              position: AmapPosition,
              icon: new AMap.Icon({
                image: carImg,
                imageSize: new AMap.Size(36, 36),
                size: new AMap.Size(36, 36),
              }),
              offset: new AMap.Pixel(-13, -26),
              // angle: that.vehicle.location.head
            });
          }
        });
      });
    },
    //关闭抽屉
    getnoShow() {
      clearTimeout(this.timer);
    },
    //开门
    openFullScreen1(boolean) {
      if (boolean != false) {
        this.fullscreenLoading = true;
      }

      if (this.statusdoors == 1) {
        // this.minBusform = {
        //   cmdType: 1,
        //   cmd: 0,
        //   vehicleId: this.id,
        // };
        this.Cardorm = {
          cmd: 0,
          cmdType: 1,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        // this.minBusform = {
        //   cmdType: 1,
        //   cmd: 1,
        //   vehicleId: this.id,
        // };
        this.Cardorm = {
          cmd: 1,
          cmdType: 1,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //空调(微公交)
    getcool(val) {
      this.fullscreenLoading2 = true;
      if (val == "0x5") {
        this.Cardorm = {
          cmd: val,
          cmdType: 7,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        this.Cardorm = {
          cmd: val,
          cmdType: 2,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //风量
    handleChangewind(value) {
      this.fullscreenLoading = true;
      this.Cardorm = {
        cmd: value,
        cmdType: 7,
        vehicleId: this.id,
        source: "WEB",
      };
      this.newfindCar();
    },
    //灯光
    getsw_one(one, val) {
      this.fullscreenLoading = true;
      if (val == 1) {
        // this.minBusform = {
        //   cmdType: one,
        //   cmd: 1,
        //   vehicleId: this.id,
        // };
        this.Cardorm = {
          cmd: 1,
          cmdType: one,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        // this.minBusform = {
        //   cmdType: one,
        //   cmd: 0,
        //   vehicleId: this.id,
        // };
        this.Cardorm = {
          cmd: 0,
          cmdType: one,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //清扫
    getsweep() {
      this.fullscreenLoading = true;
      if (this.sweeplist.switch == 1) {
        // this.clearform = {
        //   cmdType: 1,
        //   cmd: 0,
        //   vehicleId: this.id,
        // };
        this.Cardorm = {
          cmd: 0,
          cmdType: 10,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        // this.clearform = {
        //   cmdType: 1,
        //   cmd: 1,
        //   vehicleId: this.id,
        // };
        this.Cardorm = {
          cmd: 1,
          cmdType: 10,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //喷水
    getwater() {
      this.fullscreenLoading = true;
      if (this.sweeplist.witch_two == 1) {
        this.Cardorm = {
          cmd: 0,
          cmdType: 11,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        this.Cardorm = {
          cmd: 1,
          cmdType: 11,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //刹车
    getpaCar() {
      if (this.vehicle.base.brake == 1) {
        this.Cardorm = {
          cmd: 0,
          cmdType: 9,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        this.Cardorm = {
          cmd: 1,
          cmdType: 9,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //取消
    cancel() {
      this.carTypeId = "";
      this.list = {
        page: 1,
        size: 10,
        total: this.list.total,
        carNumber: "",
        carVin: "",
        carStatus: "",
        carTypeId: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList();
    },
    //分页
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    //选择车辆类型
    handleChange(value) {
      this.carTypeId = value;
      if (value.length == 1) {
        this.list.carTypeId = value[0];
      } else {
        this.list.carTypeId = value[1];
      }
    },
    //获取列表分页数据
    async getList() {
      // this.loading = true;
      let { success, data, message } = await _FindstatusList(this.list);
      // console.log(data)
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.loading = false;
      }
      console.log(data);
    },
    getList2() {
      this.list.page = 1;
      this.getList();
    },
    //获取车辆类型
    async getCarType() {
      _CarType().then((res) => {
        if (res.success == true) {
          this.cartypelist = res.data.list;
        }
      });
    },

    //下发操作指令
    async newfindCar() {
      // if (this.carStatus == 1) {
      _Findpatrol(this.Cardorm).then((res) => {
        debugger;
        let blFoundError = false;
        if (!res) {
          blFoundError = true;
        } else {
          if (!res.success) {
            blFoundError = true;
          }
        }
        if (!blFoundError) {
          this.$message.success("执行成功");
          this.carDiaA = false;
          this.doorsDiaA = false;
          this.DemistingDiaA = false;
          this.sweepDiaA = false;
          this.waterDiaA = false;
          this.timer = setInterval(() => {
            this.fullscreenLoading = false;
            this.fullscreenLoading2 = false;
          }, 1000);

          if (this.Cardorm.cmdType == 7) {
            this.avoshow = true;
          }
        } else {
          // this.$message.error("执行失败");
          this.carDiaA = false;
          this.doorsDiaA = false;
          this.avoshow = false;
          this.DemistingDiaA = false;
          this.sweepDiaA = false;
          this.waterDiaA = false;
          this.timer = setInterval(() => {
            this.fullscreenLoading = false;
            this.fullscreenLoading2 = false;
          }, 1000);
        }
      });
      // } else {
      //   this.fullscreenLoading = false;
      //   this.fullscreenLoading2 = false;
      //   clearTimeout(this.timer);
      //   this.$message.error("车辆不在线");
      // }
    },
    // startAnimation() {
    //   this.marker.moveAlong(this.lineArr, {
    //     // 每一段的时长
    //     duration: 500, //可根据实际采集时间间隔设置
    //     // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
    //     autoRotation: true,
    //     circlable: true,
    //   });
    // },
    //时间戳转换时间
    format(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    //绘制可视化视图
    drawLine1() {
      let myChart = echarts.init(document.getElementById("myChart1"));
      this.drawLine(
        myChart,
        "#1490FE",
        parseInt(this.vehicle.base.speed),
        8,
        0,
        80
      );
    },
    drawLine2() {
      let myChart = echarts.init(document.getElementById("myChart2"));
      this.drawLine(myChart, "#78C976", this.vehicle.base.power, 4, 0, 100);
    },
    drawLine3() {
      let myChart = echarts.init(document.getElementById("myChart3"));
      this.drawLine(
        myChart,
        "#78C976",
        parseInt(this.vehicle.base.speed),
        4,
        0,
        80
      );
    },
    drawLine4() {
      let myChart = echarts.init(document.getElementById("myChart4"));
      this.drawLine(myChart, "#78C976", this.vehicle.base.power, 4, 0, 100);
    },
    drawLine5() {
      let myChart = echarts.init(document.getElementById("myChart5"));
      this.drawLineTwo(myChart, 0.65, "#45E4A3");
    },
    drawLine6() {
      let myChart = echarts.init(document.getElementById("myChart6"));
      this.drawLineTwo(myChart, 0.8, "#1891FE");
    },
    drawLine(myChart, color, value, splitNumber, min, max) {
      myChart.setOption({
        series: [
          {
            type: "gauge",
            progress: {
              show: true,
              width: 3,
            },
            radius: "100%",
            itemStyle: {
              color: color, //设置圆环颜色
            },
            axisLine: {
              lineStyle: {
                width: 4,
                color: [
                  [0.0, "lime"],
                  [0, "#FA8565"],
                  [1, "#EFEFEF"],
                ],
              },
            },
            pointer: {
              show: false, //是否显示指针,
              width: 10,
            },
            splitNumber, //刻度数量
            axisTick: {
              show: false,
              splitNumber: 10,
            },
            splitLine: {
              length: 8,
              lineStyle: {
                width: 2,
                color: "#999",
              },
              distance: 0,
            },
            axisLabel: {
              distance: 10,
              color: "#999",
              fontSize: 10,
            },
            anchor: {
              show: false,
              showAbove: false,
              size: 10,
              itemStyle: {
                borderWidth: 10,
              },
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: 20,
              offsetCenter: [0, "0%"],
            },
            data: [
              {
                value,
              },
            ],
            min,
            max,
          },
        ],
      });
    },
    drawLineTwo(myChart, value, color) {
      myChart.setOption({
        series: [
          {
            radius: "90%",
            type: "liquidFill",
            data: [value],
            color: [color],
            label: {
              fontSize: 15,
              color: "#308055",
              fontFamily: "DINAlternate-Bold, DINAlternate",
            },
            outline: {
              borderDistance: 4,
              itemStyle: {
                borderWidth: 1,
                borderColor: color,
              },
            },
            backgroundColor: "white",
            backgroundStyle: {
              color: "GhostWhite",
            },
          },
        ],
      });
    },
    /**
     * @name: isPoweOn
     * @msg: 上下电弹窗
     * @param {*} 车辆状态 车辆编号
     * @return {*}
     */
    isPoweOn(row) {
      let order = {
        cmdType: 3,
        timeout: 10,
        carNumber: row.carNumber,
        params: {
          powerUpAndDownSwitch: null,
        },
      };
      if (row.carStatus == 0) {
        this.$confirm(
          `是否确认对车辆【${row.carNumber}】执行上电操作`,
          "上电",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            order.params.powerUpAndDownSwitch = 1;
            let loading = this.$loading({
              lock: true,
              text: "指令下发中。。。",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            try {
              let { success, message } = await _ExecuteTask(order);
              if (success) {
                this.$message.success(message);
              }
            } catch (error) {
              loading.close();
            }
          })
          .catch(() => {});
      } else {
        this.$confirm(
          `是否确认对车辆【${row.carNumber}】执行下电操作`,
          "下电",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            order.params.powerUpAndDownSwitch = 0;
            let loading = this.$loading({
              lock: true,
              text: "指令下发中。。。",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            try {
              let { success, message } = await _ExecuteTask(order);
              if (success) {
                this.$message.success(message);
              }
            } catch (error) {
              loading.close();
            }
          })
          .catch(() => {});
      }
    },
    // 关闭弹窗重置当前车辆
    closePrice() {
      this.nowCarType = null;
    },
  },
};
</script>

<style>
#statusMap .el-dialog__body {
  height: 500px;
}
</style>
<style scoped lang="less">
/deep/.el-input-number {
  margin: 0px auto;
  margin-top: 50px;
}
/deep/.el-dialog__body {
  padding: 0px 20px 10px 20px;
}
.largMap {
  width: 100%;
  height: 100%;
  position: relative;
}

.leftArr {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  text-align: center;
  height: 100px;
  line-height: 100px;
  width: 40px;
  background: rgba(22, 131, 217, 0.039);
  cursor: pointer;
  outline: none;
}

.time {
  position: absolute;
  top: 27px;
  left: 190px;
  font-size: 16px;
  color: #999;
}

.drawContent {
  display: flex;
}

.drawContentLeft {
  width: 35%;
  border-right: 4px solid rgba(151, 151, 151, 0.15);
  box-sizing: border-box;
  padding: 0px 30px 0px 0px;
}

.drawContentRight {
  width: 65%;
  box-sizing: border-box;
  padding: 0px 30px;
}

.drawContentHead {
  margin-top: 10px;
  margin-bottom: 10px;
}

.licenseWords {
  font-size: 12px;
  color: #333;
  margin-bottom: 12px;
}

.licenseNumber {
  color: #000000;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.onLine {
  height: 22px;
  border-radius: 4px;
  border: 1px solid #42c382;
  color: #3dc27f;
  font-size: 14px;
  padding: 0px 9px;
  line-height: 22px;
  margin-left: 15px;
}
.onLinetwo {
  height: 22px;
  border-radius: 4px;
  border: 1px solid #999999;
  color: #999999;
  font-size: 14px;
  padding: 0px 9px;
  line-height: 22px;
  margin-left: 15px;
}

.vehIcon {
  display: flex;
  background: #f5f7fa;
  border-radius: 4px;
  justify-content: space-around;
  height: 41px;
  align-items: center;
  color: #000;
  font-size: 14px;
  margin: 19px 0px 13px;
}

.vehIcon img {
  vertical-align: text-top;
}

.vehIcon span {
  margin-left: 5px;
}

.nextS {
  display: flex;
  align-items: center;
}

.nextStation {
  color: #999999;
  font-size: 14px;
}

.nextStation > span {
  color: #333;
  font-size: 14px;
}

.location {
  margin-left: 32px;
  color: #333;
  font-size: 14px;
}

.location img {
  vertical-align: text-bottom;
}

.locationWords {
  font-size: 16px;
  color: #4287ff;
  margin: 0px 5px;
}

.drivingMode {
  margin: 15px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 20px;
}

.drivingMode > span:first-child {
  font-size: 14px;
  color: #999;
}

.drivingMode img {
  vertical-align: text-bottom;
  margin-left: 15px;
}

.drivingMode > span:last-child {
  font-size: 14px;
  color: #4287ff;
  margin-left: 4px;
}

.ec {
  display: flex;
  justify-content: space-between;
}

.ec1 {
  position: relative;
  width: 146px;
}

.km {
  position: absolute;
  left: 50%;
  bottom: 40px;
  font-size: 12px;
  color: #999;
  transform: translateX(-50%);
}

.speed {
  position: absolute;
  bottom: 13px;
  font-size: 12px;
  color: #333;
  left: 50%;
  transform: translateX(-50%);
}

.mileage {
  display: flex;
  justify-content: space-around;
}

.mileage > div {
  text-align: center;
  font-size: 12px;
  color: #333333;
}

.mileage > div > div {
  margin-top: 7px;
}

.mileageBold {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.warning {
  height: 77px;
  background: #fff3f3;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-top: 30px;
  font-size: 12px;
  color: #333;
}

.warningHead {
  height: 4px;
  background: #ff7760;
}

.warningNotice {
  width: 110px;
  height: 18px;
  background: #ff7760;
  border-radius: 0px 0px 11px 11px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.Tertiary {
  margin-top: 15px;
  margin-left: 10px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.fault {
  font-size: 12px;
  color: #333333;
  margin-top: 5px;
  margin-left: 10px;
}

.doorImg {
  text-align: center;
}

.doorLock {
  text-align: center;
  font-size: 14px;
  color: #999;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.taxiLight {
  text-align: left;
  padding-left: 100px;
  font-size: 14px;
  color: #999;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.doorLock .el-switch,
.demisting .el-switch {
  margin-left: 14px;
}

.doorLock > div {
  width: 50%;
  margin-top: 21px;
}

.doorLock > div > span:first-child {
  width: 100px;
  text-align: right;
  display: inline-block;
}

.doorLock > div img {
  vertical-align: text-top;
  margin-left: 20px;
  margin-right: 5px;
}

.lockTheDoor {
  margin-top: 57px;
  width: 100%;
  text-align: center;
}

.lockTheDoor img {
  cursor: pointer;
}

.airConditioner {
}

.airConditionerHead {
  display: flex;
}

.temperature {
  width: 20%;
  box-sizing: border-box;
  padding-top: 40px;
}

.temperature > p:first-child {
  color: #999999;
  font-size: 14px;
}

.centigrade {
  color: #000000;
  font-size: 64px;
}

.airConditionerImg {
  width: 80%;
}

.AirConditioningStatus {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  color: #999;
}

.AirConditioningStatus img {
  margin-left: 20px;
  vertical-align: text-bottom;
  margin-right: 4px;
}

.state {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.state img {
  cursor: pointer;
}

/* .wind > :first-child {
  width: 51px;
  text-align: center;
  cursor: pointer;
}

.wind > :nth-child(2) {
  width: 62px;
  text-align: center;
  font-size: 18px;
  color: #000000;
  background-color: #fff;
}

.wind > :nth-child(3) {
  width: 51px;
  text-align: center;
  cursor: pointer;
} */

.demisting img {
  vertical-align: middle;
  margin: 0px 8px 0px 21px;
}

.video {
  text-align: center;
}

.lookVideo {
  margin-top: 90px;
}

.epbState {
  display: flex;
  justify-content: space-around;
  margin: 30px 0px;
  font-size: 14px;
  color: #999;
}

.epbState img {
  vertical-align: middle;
  margin: 0px 14px 0px 5px;
}

.clean {
  display: flex;
  justify-content: center;
}

.clean img {
  margin-left: 30px;
  margin-right: 5px;
}

.openClean-btn {
  text-align: center;
}
.openClean {
  width: 166px;
  height: 60px;
  font-size: 20px;
  color: #fff;
  line-height: 60px;
  text-align: center;
  background-color: #0a94e1;
  border-radius: 30px;
  margin: 30px auto;
  cursor: pointer;
  outline: none;
  border: none;
}

.openClean:disabled {
  cursor: not-allowed;
  background-color: #abb7bb;
}
.sprayWater {
  background-color: #0a94e1;
}

.lineMap {
  width: 135px;
  height: 97px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.lineMap > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.technology {
  width: 30px;
  height: 5px;
  background: #47a5e9;
  border-radius: 3px;
}

.businessAffairs {
  width: 30px;
  height: 5px;
  background: #c72928;
  border-radius: 3px;
}

.production {
  width: 30px;
  height: 5px;
  background: #3a0f7a;
  border-radius: 3px;
}

.liquidfill {
  width: 100%;
  height: 126px;
  display: flex;
  justify-content: center;
  margin-top: 27px;
}

#container {
  width: 100%;
  height: 100%;
}
.popContainer {
  width: 100%;
  height: 100%;
  position: fixed;
}
#lookContainer {
  height: 550px;
}
/deep/.el-tooltip > p {
  display: flex;
  align-items: center;
}
</style>
