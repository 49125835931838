import service from '../../until/api'
//车辆运营
export function _SvBusList(data) {
    return service({
        url: 'sv-bus/web/carOperation/findPageForSaas',
        method: 'GET',
        params: data
    })
}

export function _SvBusStatus(data) {
    return service({
        url: 'sv-bus/web/carOperation/switchOperationStatus',
        method: 'GET',
        params: data
    })
}
//排班计划
export function _SvBuschedulingPlan(data) {
    return service({
        url: 'sv-bus/web/schedulingPlan/findPage',
        method: 'GET',
        params: data
    })
}
export function _SvBuschedulingDel(data) {
    return service({
        url: 'sv-bus/web/schedulingPlan/delete',
        method: 'GET',
        params: data
    })
}


export function _SvBuschedulingAdd(data) {
    return service({
        url: 'sv-bus/web/schedulingPlan/insert',
        method: 'POST',
        data
    })
}
export function _SvBuschedulingUp(data) {
    return service({
        url: 'sv-bus/web/schedulingPlan/update',
        method: 'POST',
        data
    })
}
//服务评价
export function _SvBusserviceEvaluation(data) {
    return service({
        url: 'sv-bus/web/serviceEvaluation/findPage',
        method: 'GET',
        params: data
    })
}
//车辆运营记录
export function _SvBusListRecord(data) {
    return service({
        url: 'sv-bus/web/carOperationRecord/findPage',
        method: 'GET',
        params: data
    })
}
//订单管理
export function _SvBusorderManage(data) {
    return service({
        url: 'sv-bus/web/orderManage/findPage',
        method: 'GET',
        params: data
    })
}


export function _SvBusOrderList(data) {
    return service({
        url: 'sv-bus/web/orderManage/findDetail',
        method: 'GET',
        params: data
    })
}
//排班历史记录
export function _SvBusschedulingHistory(data) {
    return service({
        url: 'sv-bus/web/schedulingHistory/findPage',
        method: 'GET',
        params: data
    })
}
export function _SvBusVideoList(data) {
    return service({
        url: 'sv-bus/web/videoViewing/findPage',
        method: 'GET',
        params: data
    })
}

export function _SvBusvideoViewingAll(data) {
    return service({
        url: 'sv-bus/web/videoViewing/getHistoricalVideo',
        method: 'GET',
        params: data
    })
}

export function _SvBusvideoViewingHis(data) {
    return service({
        url: 'sv-bus/web/videoViewing/findVideoByCarNumber',
        method: 'GET',
        params: data
    })
}

/**
 * @msg: 获取车辆线路
 * @param {*} data
 */
 export function _GetAllRoute(data) {
    return service({
        url: 'sv-bus/web/schedulingPlan/getSVBusCarRouteInfo',
        method: 'GET',
        params: data
    })
}