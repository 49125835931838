<template>
  <div>
    <div>
      <button
        class="centerBtn"
        @click="addAccountShow"
        style="margin-bottom: 20px"
      >
        新增
      </button>
    </div>
    <el-form :inline="true">
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="list.page"
    />
    <el-dialog title="发布" :visible.sync="delDiaA" width="30%">
      <span>确认发布内容?</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="geMsg">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-drawer
      :title="title"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
			@close="handleCloseAccountDrawer"
			:destroy-on-close="true"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item
            label="消息标题"
            :label-width="formLabelWidth"
            prop="messageTitle"
          >
            <el-input
              v-model="form.messageTitle"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="消息类别" :label-width="formLabelWidth" prop="messageCategory" required>
            <el-radio-group v-model="form.messageCategory">
              <!-- <el-radio label="1">运营公告</el-radio>
              <el-radio label="2">其他</el-radio> -->
              <el-radio :label="item.value" v-for="item in dataMessageCategory" :key="item.value">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="公告截止日期"
            :label-width="formLabelWidth"
            prop="expirationDate"
            v-if="form.messageCategory == 1"
          >
            <el-date-picker
              class="centerCzInput"
              v-model="form.expirationDate"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              :clearable="false"
              default-time="23:59:59"
              :picker-options="pickerOptions"
              placeholder="选择截止日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="消息内容"
            :label-width="formLabelWidth"
            prop="messageContent"
          >
            <el-input
              type="textarea"
              class="centerCzInput"
              :rows="10"
              placeholder="请输入"
              v-model="form.messageContent"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn" @click="getAdd" v-if="title=='发布消息'">保存</button>
        <button class="centerCzbtn" @click="editMsg" v-else>保存</button>
        <button class="centerBtn resetbtn" @click="handleCloseAccountDrawer">
          取消
        </button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _AppMsgFeedback,
  _AppAddMsgFeedback,
  _AppreleaseMessage,
  _AppMsgEdit,
  _AppMsgDelete
} from "@/api/appMng";
export default {
  components: {
    Table,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let curDate = (new Date()).getTime();
          let one = 30 * 24 * 3600 * 1000;
          let oneMonths = curDate + one;
          return time.getTime() < Date.now() - 8.64e7 || time.getTime() > oneMonths;
        }
      }, 
      loading: false,
      addAccount: false,
      delDiaA: false,
      value1: "",
      value: "",
      formLabelWidth: "130px",
      total: 0,
      rangeList: [],
      searchObj: {
        vehicleNumber: "",
        operationMode: "",
        operationStatus: "",
      },
      title: "发布消息",
      dataMessageCategory:[
        {value:1 , label:"运营公告"},
        {value:2 , label:"其他"}
      ],
      form: {
        messageTitle: "",
        messageContent: "",
        messageCategory: 2,
        expirationDate: null,
        parkId: sessionStorage.getItem("parkId"),
      },
      rules: {
        messageTitle: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur",
          },
        ],
        messageContent: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        expirationDate: [
          {
            required: true,
            message: "请选择截止日期",
            trigger: "change",
          },
        ]
        // sendingRange: [
        //   {
        //     required: true,
        //     message: "请选择",
        //     trigger: "blur",
        //   },
        // ],
      },
      tableData: [],
      columns: [
        {
          label: "标题",
          prop: "messageTitle",
        },
        {
          label: "内容预览",
          prop: "messageContent",
        },
        {
          label: "发布对象",
          render: (h, params) => {
            if (params.row.messageType == 1) {
              return h("span", "普通");
            } else {
              return h("span", "VIP");
            }
          },
        },
        {
          label: "创建时间",
          prop: "createTime",
        },

        {
          label: "发布时间",
          prop: "releaseTime",
        },
        {
          label: "发布人",
          prop: "userName",
        },
        {
          label: "类型",
          prop: "messageCategory",
          render: (h, params) => {
            let length = this.dataMessageCategory.length
            let label = "--"
            for (let i = 0; i < length; i++) {
              if(params.row.messageCategory == this.dataMessageCategory[i].value){
                label = this.dataMessageCategory[i].label
                break
              }
            }
            return h("span", label);
          }
        },
        {
          label: "发布状态",
          prop: "releaseStatus",
          render: (h, params) => {
            if (params.row.releaseStatus == 0) {
              return h("span", "未发送");
            } else {
              return h("span", "已发送");
            }
          },
        },
        {
          label: "操作",
          // width: 100,
          render: (h, params) => {
            if (params.row.releaseStatus == 0) {
              return h("div", [
                h(
                  "span",
                  {
                    class: "operationA",
                    on: {
                      click: () => {
                        this.delDiaA = true;
                        this.messageId = params.row.id;
                      },
                    },
                  },
                  "发布"
                ),
                h(
                  "span",
                  {
                    class: "operationA",
                    on: {
                      click: () => {
                        this.title = "编辑消息"
                        let {messageContent,messageTitle,messageCategory,id,expirationDate} = params.row
                        // 赋值
                        this.form.messageContent = messageContent
                        this.form.messageTitle = messageTitle
                        this.form.messageCategory = messageCategory ? messageCategory : 2
                        this.form.expirationDate = expirationDate
                        this.messageId = id
                        this.addAccount = true
                      },
                    },
                  },
                  "编辑"
                ),
                h(
                  "span",
                  {
                    class: "operationA",
                    on: {
                      click: () => {
                        this.deleteMsg(params.row.id)
                      },
                    },
                  },
                  "删除"
                ),
              ]);
            } else {
              return h("span", "-");
            }
          },
        },
      ],
      valueTime: "",
      list: {
        page: 1,
        size: 10,
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.valueTime[0];
        this.list.endTime = this.valueTime[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    cancel() {
      this.valueTime = "";
      this.list = {
        page: 1,
        size: 10,
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList()
    },
    async getList() {
      let { success, data, message } = await _AppMsgFeedback(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2(){
      this.list.page = 1;
      this.getList();
    },
    async getAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          _AppAddMsgFeedback(this.form).then((res) => {
            if (res.success) {
              this.$message.success("新增成功");
            }
            this.addAccount = false;
            this.getList();
          });
        }
      });
    },
    async geMsg() {
      let { success, data, message } = await _AppreleaseMessage({
        messageId: this.messageId,
      });
      if (success) {
        this.$message.success("发布成功");
      }
      this.delDiaA = false;
      this.getList();
    },
	
		handleCloseAccountDrawer(){
			this.form.messageContent = '';
			this.form.messageTitle = '';
      this.form.expirationDate = null
			this.form.messageCategory= 2,
      this.title = "发布消息"
			this.addAccount = false;
		},
    addAccountShow(){
      // this.form.messageContent = '';
			// this.form.messageTitle = '';
      // this.form.messageCategory= "2",
      this.addAccount = true
    },
    /**
     * @name: editMsg
     * @msg: 消息编辑 
     * @param {*}
     * @return {*}
     */    
    editMsg(){
      this.$refs.form.validate(async(valid) => {
        if (valid) {
        let res = await _AppMsgEdit({...this.form , id: this.messageId})
          if (res.success) {
            this.$message.success("编辑成功");
          }
          this.addAccount = false;
          this.getList();
        }
      });
    },
    /**
     * @name: deleteMsg
     * @msg: 消息删除
     * @param {*}
     * @return {*}
     */    
    deleteMsg(id){
      this.$confirm('此操作将删除该消息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let {success} = await _AppMsgDelete({id})
        if(success){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }
        this.getList()
      }).catch(() => {});
    }
  },
};
</script>

<style scoped lang="less">
.textcolor {
  font-size: 14px;
  color: #666666;
  margin-right: 20px;
}
.textcolor2 {
  font-size: 14px;
  color: #333333;
}
.el-input__inner {
  border: none !important;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.el-date-editor--datetime{
  width: 100%;
}
</style>
