<template>
<div>
    <el-row class="margin_b">
        <el-col :span="5">
            <el-select v-model="list.coordinateType" placeholder="坐标类别" class="centerSelInput ipwidth">
                <el-option v-for="item in coordinateTypeList"  :key="item.value"  :label="item.label"  :value="item.value"></el-option>
            </el-select>
        </el-col>
        <el-col :span="5">
            <el-input v-model="list.coordinateNumber" placeholder="坐标编号"  class="centerInput"></el-input>
        </el-col>
        <el-col :span="14">
            <button type="primary" size="medium" class="centerCzbtn ml20" @click="searchData_B">搜索</button>
            <button type="primary" size="medium" class="centerBtn resetbtn" @click="clearData">重置</button>
            <button type="primary" size="medium" class="centerCzbtn ml20" @click="adddrawer = true">添加坐标点</button>
            <button type="primary" size="medium" class="centerCzbtn ml20" @click="uplog_btn">导入坐标点</button>
        </el-col>
    </el-row>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :page="list.page"
      :loading="list.loading"
      @getAjax="getTableData"
    />

  <!-- 删除弹窗 -->
    <el-dialog  title="删除" :visible.sync="deleteDrawer" width="30%"  >
      <div>您当前正在执行删除<span>{{this.deleteForm.coordinateType}}【{{this.deleteForm.coordinateNumber }}】</span>的操作，确认继续吗？</div> 
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deletedata" :loading="deleteBtnLoading">确 定</el-button>
        <el-button @click="deleteDrawer = false">取 消</el-button>
      </span>
    </el-dialog>

  <!-- 添加坐标点 -->
  <el-drawer title="添加坐标点" :visible.sync="adddrawer" :direction="direction"  size="40%" @close="clearAddForm" >
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-row >
                <el-col :span="12">
                    <el-form-item label="坐标类型" prop="coordinateType">
                        <el-select v-model="ruleForm.coordinateType" placeholder="请选择"    class='elInput'>
                            <el-option v-for="item in coordinateTypeList"  :key="item.value"  :label="item.label"  :value="item.value"></el-option>
                        </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                    <el-form-item label="坐标编号" prop="coordinateNumber" >
                       <el-input v-model="ruleForm.coordinateNumber" class='elInput' placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>  
              </el-row>
              <el-row>
                <el-col :span="12">
                     <el-form-item label="经度" prop="longitude">
                        <el-input v-model="ruleForm.longitude"  class='elInput' placeholder="请输入"></el-input>
                      </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                     <el-form-item label="纬度" prop="latitude">
                        <el-input v-model="ruleForm.latitude"  class='elInput' placeholder="请输入"></el-input>
                      </el-form-item>
                </el-col>
              </el-row>
              <el-form-item>
                <el-button type="primary" @click="addcoordinateForm('ruleForm')" :loading="addBtnLoadin">确认</el-button>
                <el-button @click="adddrawer = false">取消</el-button>
              </el-form-item>
            </el-form>
    </el-drawer>
  <el-drawer title="导入坐标" :visible.sync="importlogConfig.visible" :direction="direction" :destroy-on-close="true"
			@close="handleCloseImportlogDrawer" >
      <el-upload	drag	class="upload-demo"	action="#"	:auto-upload="false"	:file-list="importlogConfig.logFileList"
				:on-change="handleSelectUploadFile"
        multiple
        :limit="1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <el-row>
            <el-col :span="12">
                <span class="red">注：上传的文件必须是 <span style="font-weight:bold">.csv</span> 格式。</span>
            </el-col>
            <el-col :span="5" :offset="5">
                <span class="textCol" style="cursor: pointer;" @click="muban_down">下载模板</span>
            </el-col>
          </el-row>
        </div>
      </el-upload>
      <div style="margin-top: 20%" class="flex justify-center">
        <button class="centerCzbtn" @click="handleImportlog">上传</button>
      </div>
    </el-drawer>
  <!-- 查看位置 -->
  <el-dialog title="查看位置" :visible.sync="priceShow" width="60%" :close-on-click-modal="false">
      <div id="lookMapContainer"></div>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn" @click="priceShow = false">确 定</button>
      </span>
    </el-dialog>
 </div>   
</template>

<script>
import  Table  from '../../components/Table.vue' ;
import { Amap, Marker } from "@amap/amap-vue";
import { _GetList,
        _DeleteData,
        _addLocation,
        _ExcelDownload,
        _ExcelUpload
 } from "../../api/transportCar/transportMapMng.js";
export default {
  components: {
   Table,
   Amap,
   AmapMarker: Marker,
  },
  data() {

    const validateLongitude = (rule, value, callback) => {
      //经度,整数部分为0-180小数部分为0到15位
      var longreg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/
      if (!longreg.test(value)) {
        callback(new Error('经度范围-180至180'))
      }
      callback()
    }
    const validateLatitude = (rule, value, callback) => {
      //纬度,整数部分为0-90小数部分为0到15位
      var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
      if (!latreg.test(value)) {
        callback(new Error('纬度范围-90至90'))
      }
      callback()
    }
    return {
        deleteBtnLoading: false,// 删除按钮的loading
        addBtnLoadin: false,// 添加按钮loading
        //查看位置的坐标
        longitude:null,//经度
        latitude:null,//纬度
        priceShow: false,//查看位置窗口
        parkId:null, 
        userId:null,
        adddrawer: false,//添加左拉框
        loaddrawer: false,//导入左拉框
        direction: 'rtl',
        deleteDrawer:false,
        dialog_parkname:null,
        handleClose:false,
        //上传配置
        importlogConfig: {
          visible: false,
          isImport: false,
          logFileList: [],
        },
         //表格数据
         tableData:[
         ],
       //添加表单
        ruleForm:{
           coordinateType:null,//坐标类型
           coordinateNumber:null,//坐标编号
           longitude:null,//经度
           latitude:null,//纬度
        },
         rules: {
         coordinateType: [
            { required: true, message: '请选择坐标类型', trigger: 'blur' },
          ],
          coordinateNumber: [
            { required: true, message: '请输入坐标编号', trigger: 'blur' },
          ],
          //经度 纬度的校验
          longitude: [
            { required: true, message: '请输入纬度', trigger: 'blur' },
            { validator: validateLongitude, trigger: 'blur' },
            { validator: validateLongitude, trigger: 'change' }

          ],
          latitude: [
            { required: true, message: '请输入经度', trigger: 'blur' },
            { validator: validateLatitude, trigger: 'blur' },
            { validator: validateLatitude, trigger: 'change' }
          ],
        
        },

       //删除表单  
         deleteForm:{ 
           coordinateNumber:null,//集装箱编号
           coordinateType:null,
         },
        delete_coordinateId:null,//删除ID
        //坐标类型
        coordinateTypeList:[
            {
              label:"停车缓冲区",
              value:"4"
            },
            {
              label:"停车区",
              value:"5"
            },
            {
              label:"堆场",
              value:"6"
            },
        ],
        
        list: {
            loading:false,
            page: 1,
            size: 10,
            total: 0,
            coordinateType:null,//坐标类别
            coordinateNumber:null,//坐标编号
          },
        columns:[
        {
          label: "坐标类别",
          prop: "coordinateType",
          render: (h, params) => {
            let length = this.coordinateTypeList.length
            let label = "-"
            for (let i = 0; i < length; i++) {
              if(params.row.coordinateType==this.coordinateTypeList[i].value){
                label = this.coordinateTypeList[i].label
                break
              }
            }
            return h("span", label);
          },
        },
        {
          label: "坐标编号",
          prop: "coordinateNumber",
        },
        {
          label: "添加时间",
          prop: "createTime",
        },
        {
          label: "添加人",
          prop: "createUserName",
        },
        {
          label: "操作项",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA ",
                  on: {
                    click: () => {
                    this.deleteDrawer = true
                    //坐标类型
                    this.deleteForm.coordinateType  = params.row.coordinateType
                    //坐标编号
                    this.deleteForm.coordinateNumber = params.row.coordinateNumber
                    this.delete_coordinateId = params.row.id 
                    },
                  },
                },
                "删除"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.priceShow = true;
                       this.deleteForm.coordinateType  = params.row.coordinateType
                      //坐标编号
                      this.deleteForm.coordinateNumber = params.row.coordinateNumber
                      //获取当前坐标 再调用函数
                      this.longitude = params.row.longitude;
                      this.latitude = params.row.latitude
                        this.getlocation()
                    },
                  },
                },
                "查看位置"
              ),
             
            ]);
          },
        },
        ],
        
    };
  },
  created(){
        
  },
  mounted() {
    this.getparkId()
    this.getuserId()
    this.searchData()
    this.getDrawer()
  },

  methods: {
    getDrawer(){
      this.adddrawer = true
      this.adddrawer = false
    },
    //获取园区id
    getparkId(){
        this.parkId = sessionStorage.getItem("parkId")
    },
    getuserId(){
      let userId = sessionStorage.getItem("userId")
      this.userId =  userId.substring(1,userId.length-1)
    },
    //添加坐标
    addcoordinateForm(ruleForm){
      //验证 验证过就可以发送请求
      this.$refs[ruleForm].validate((valid) => {
          if (valid) {
              this.addcoordinateForm_btn()
          } else {
            return false;
          }
        })
   },
      //关闭添加坐标 清空
    clearAddForm(){
        for (let key of Object.keys(this.ruleForm)) {
                this.ruleForm[key] = null
            }
      },
   //发送添加请求
    async addcoordinateForm_btn(){
      this.addBtnLoadin = true
     //发送请求带园区id
      this.ruleForm.parkId = this.parkId
      await _addLocation(this.ruleForm).then((res)=>{
        if(res.success == true){
          this.$message({
                  message: '添加成功',
                  type: 'success'
                });
          this.adddrawer =false;
        }else{
            this.$message({
                  message: `${res.message}`,
                  type: 'error'
                });
        }
          this.searchData()
          this.addBtnLoadin = false
      }).catch(()=>{
          this.addBtnLoadin = false
      })
   },



//导入坐标
    handleOpenImportlogDrawer(){
			this.importlogConfig.visible = true;
		},
    handleCloseImportlogDrawer(){
			this.importlogConfig.visible = false;
			this.importlogConfig.logFileList = [];
			this.importlogConfig.isImport = false;
		},
    handleSelectUploadFile(file, fileList){
			this.importlogConfig.logFileList = fileList;
		},
     //导入

     //可能需要获取用户ID 那么就直接存一下用户Id

    async handleImportlog(){
      if(this.importlogConfig.logFileList[0]){
        const loading = this.$loading({
          lock: true,
          text: '导入中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let file = new FormData();
        file.append("file", this.importlogConfig.logFileList[0].raw);
        file.append("parkId", this.parkId);
        file.append("userId", this.userId);
        this.importlogConfig.isImport = true;
        console.log(file)
        await _ExcelUpload(file).then((res) => {
          if(res != undefined){
            if (res.success == true) {
              this.handleCloseImportlogDrawer();
              this.searchData()
              this.$message.success(res.message);
               loading.close();
            }   
          }else{
             loading.close();
          } 
         });
       
      }else{
        this.$message({
          showClose: true,
          message: '没有上传文件',
          type: 'error'
        });
      }
		},
    uplog_btn(){
      	this.importlogConfig.visible = true;
    },

  //下载模板
  async muban_down(){
    await  _ExcelDownload().then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");//生成虚拟标签a 
        link.style.display = "none";
        link.href = url;
        this.exportLoad = false;

        link.download = "坐标导入模板.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.timer = setTimeout(() => {
          this.$message.success("下载成功");
        }, 3000);
      })
      .catch(err =>{
          this.$message.error(err)
      });
    },


    //获取数据
   async  searchData(){
     this.list.loading = true
      this.list.parkId = this.parkId;
      // console.log(this.list)
         await _GetList(this.list).then((res)=>{
           console.log(res)
           if(res.success){
            //   for(var i = 0 ;i<res.data.list.length;i++){
            //     if(res.data.list[i].coordinateType ==1 ){
            //        res.data.list[i].coordinateType = '集装箱'
            //   }else if(res.data.list[i].coordinateType == 2) {
            //       res.data.list[i].coordinateType ='吊桥'
            //   }else if(res.data.list[i].coordinateType == 3){
            //     res.data.list[i].coordinateType ='轮胎吊'
            //   }
            //  }
            this.tableData = res.data.list;
            this.list.page = res.data.pageNum;
            this.list.size = res.data.pageSize;
            this.list.total = res.data.total;
            this.list.loading = false;
           }else{
             this.$message.error(this.message)
           }
             
         })
    },
    //搜索按钮
    searchData_B(){
      this.list.page = 1;
      this.searchData()
    },
    //重置
    clearData(){
      //搜索项默认
      this.list={
            page: 1,
            size: 10,
            total: 0,
            coordinateType:null,//坐标类别
            coordinateNumber:null,//坐标编号
      }
      this.searchData()
    },
    //删除本条数据
    async  deletedata( ){
      this.deleteBtnLoading = true
      await _DeleteData({id:this.delete_coordinateId}).then((res)=>{
          if(res.success == true){
             this.$message({
                message: '删除成功',
                type: 'success'
              });
          }else{
             this.$message({
                message: `${this.message}`,
                type: 'error'
              });
          }
      })
      this.deleteBtnLoading = false
      this.deleteDrawer =false;
      this.searchData()
    },
//获取地址
   getlocation() {
      this.$nextTick(() => {
        this.addedmap = new AMap.Map("lookMapContainer", {
          resizeEnable: true,
          center: [
            //坐标号
            this.longitude,
            this.latitude,
          ],
          zoom: 16,
        });
        //坐标号
        let GPS_Position = [

          this.longitude,
          this.latitude,
        ];
        let that = this;
        AMap.convertFrom(GPS_Position, "gps", function (status, result) {
          if (result.info === "ok") {
            let AmapPosition = [
              result["locations"][0].lng,
              result["locations"][0].lat,
            ];
            var marker = new AMap.Marker({
              map: that.addedmap,
              clickable: true,
              position: GPS_Position,
              icon: new AMap.Icon({
                image: require("@/assets/images/postion_pon.png"),
                imageSize: new AMap.Size(36, 36),
                size: new AMap.Size(36, 36),
              }),
              offset: new AMap.Pixel(-13, -26),
            });
          }
        });
      });
    },

    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      await this.searchData();
    },
  }
};
</script>
<style scoped lang="less">
.textcolor {
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  margin-right: 0px;
}
.statutext {
  color: rgba(22, 131, 217, 1);
  font-size: 16px;
  margin-right: 61px;
  cursor: pointer;
}
.second_row{
    margin:1% 0 1% 0;
}
.pickerTime{border:0px solid black; }
.margin_b{
  margin-bottom: 2%;
}
.centerSelInput{
  height:30px;
}
.textCol {
  color: #1683d9;
}
/deep/ .colorB {
  /* color: red; */
  /* -webkit-touch-callout: none; */
  margin-right: 20px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 20px;
    height:15px;
  }
#lookMapContainer {
  height: 550px;
}
/deep/ .elInput .el-input__inner {
    border: 0.005208rem solid #DCDFE6 !important
  }
.el-drawer__body{
  .el-select{
    width: 100%;
  }
}
</style>








