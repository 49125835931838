import service from '../../until/api'
//车辆运营
export function _AiBusList(data) {
    return service({
        url: 'ai-bus/web/carOperation/findPageForSaas',
        method: 'GET',
        params: data
    })
}

// export function _AiBusStatus(data) {
//     return service({
//         url: 'ai-bus/web/carOperation/switchOperationStatus',
//         method: 'GET',
//         params: data
//     })
// }
export function _AiBusModel(data) {
    return service({
        url: 'ai-bus/web/carOperation/switchOperationType',
        method: 'GET',
        params: data
    })
}
export function _AiBusDel(data) {
    return service({
        url: 'ai-bus/web/carOperation/deleteCarOperation',
        method: 'GET',
        params: data
    })
}