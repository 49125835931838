import { render, staticRenderFns } from "./viewConfig.vue?vue&type=template&id=5ca7c242&scoped=true&"
import script from "./viewConfig.vue?vue&type=script&lang=js&"
export * from "./viewConfig.vue?vue&type=script&lang=js&"
import style0 from "./viewConfig.vue?vue&type=style&index=0&id=5ca7c242&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca7c242",
  null
  
)

export default component.exports