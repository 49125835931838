<template>
  <!-- 这是框架自带的分页控件 -->
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
      :small="true"
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"/>
      <!-- hide-on-single-page -->

  </div>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50, 80, 100]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      let maxPage =  Math.ceil(this.total/val ? this.total/val : 1)
      let page = this.currentPage
      if(this.currentPage > maxPage ){
        this.$emit('update:page', maxPage)
        page = maxPage
      }
      this.$emit('pagination', { page, limit: val })
      // if (this.autoScroll) {
      // }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      // if (this.autoScroll) {
      // }
    }
  }
}
</script>

<style scoped>
.pagination-container {
  padding: 15px 16px;
  /* position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  background-color: white; */
}
.pagination-container.hidden {
  display: none;
}
</style>
