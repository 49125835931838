<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.phone"
          placeholder="请输入手机号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="parkaccountMngList"
      :columns1="columns"
      :total="total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <!-- 添加车辆弹框 -->
    <el-drawer
      title="回复"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      :before-close="handleClose"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules">
          <el-form-item
            label="回复"
            :label-width="formLabelWidth"
            prop="replyContent"
          >
            <el-input
              type="textarea"
              class="centerCzInput"
              :rows="10"
              placeholder="请输入"
              v-model="form.replyContent"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn" @click="getmsg">保存</button>
        <button class="centerBtn resetbtn" @click="cancelB">取消</button>
      </div>
    </el-drawer>
    <!-- 图片详细信息 -->
    <el-dialog title="图片详情" :visible.sync="pictureShow" width="30%">
      <el-image
        fit="cover"
        class="image-show"
        :src="ulr"
        :preview-src-list="pictureLists"
        v-for="(ulr, index) in pictureLists"
        :key="index"
      >
      </el-image>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pictureShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-drawer
      :title="'查看反馈信息详情' + '【' + checkData.phone + '】'"
      :visible.sync="checkDrawer"
    >
      <el-form label-width="80px">
        <el-form-item label="发表时间">
          <el-input disabled v-model="checkData.createTime"></el-input>
        </el-form-item>
        <el-form-item label="反馈详情">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 20 }"
            disabled
            v-model="checkData.feedbackContent"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片详情">
          <template v-if="pictureLists.length">
            <el-image
              fit="cover"
              class="image-show"
              :src="ulr"
              :preview-src-list="pictureLists"
              v-for="(ulr, index) in pictureLists"
              :key="index"
            >
            </el-image>
          </template>
          <span v-else> 暂无</span>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import { _AppFeedback, _AppreplyFeedback } from "@/api/appMng";
// import { _RoleList } from "@/api/parksysMng";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      addAccount: false,
      accountEdit: false,
      delDia: false,
      resetPwd: false,
      formLabelWidth: "100px",
      isAdd: false,
      pictureShow: false, // 图片详情弹窗
      pictureLists: [], // 图片列表
      checkDrawer: false, // 查看详情弹窗
      checkData: {}, // 详情数据
      userParkId: "",
      total: 0,
      searchObj: {
        phone: "",
      },
      form: {
        replyContent: "",
        appUserId: "",
        userFeedbackId: "",
      },
      rules: {
        replyContent: [
          {
            required: true,
            message: "请输入回复内容",
            trigger: "blur",
          },
        ],
      },
      columns: [
        {
          label: "用户手机号",
          prop: "phone",
        },
        {
          label: "反馈内容",
          prop: "feedbackContent",
        },
        {
          label: "回复内容",
          prop: "replyContent",
        },
        {
          label: "查看图片",
          prop: "imageList",
          width: 150,
          render: (h, params) => {
            if (params.row.imageList?.length) {
              let label =" X"+ params.row.imageList.length
              return h(
                "div",
                {
                  style: {
                    display: "flex",
                    "align-items": "center",
                  },
                },
                [
                  h("svg-icon", {
                    style: {
                      width: "18px",
                      height: "18px",
                      cursor: "pointer",
                    },
                    props: {
                      iconClass: "picture",
                    },
                    nativeOn: {
                      click: () => {
                        this.pictureShow = true;
                        let list = params.row.imageList.map((ele) => {
                          return ele.url;
                        });
                        this.pictureLists = list;
                      },
                    },
                  }),
                  h("span", label),
                ]
              );
            } else {
              return h("span", "-");
            }
          },
        },
        {
          label: "时间",
          prop: "createTime",
          width: 200,
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.addAccount = true;
                      this.form.appUserId = params.row.appUserId;
                      this.form.userFeedbackId = params.row.id;
                    },
                  },
                },
                "回复"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.checkDrawer = true;
                      this.checkData = params.row;
                      let list = params.row.imageList?.length
                        ? params.row.imageList.map((ele) => {
                            return ele.url;
                          })
                        : [];
                      this.pictureLists = list;
                    },
                  },
                },
                "查看详情"
              ),
            ]);
          },
        },
      ],
      list: {
        page: "1",
        size: "10",
        feedbackType: 1,
        phone: "",
      },
      parkaccountMngList: [],
      rolelist: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancelB() {
      this.addAccount = false;
    },
    handleClose(done) {
      window.location.reload();
      this.addAccount = false;
      this.form = {
        roleParkIds: [],
        name: "",
        phone: "",
        username: "",
      };
    },
    getselect() {
      if (this.isAdd == false) {
        this.getparkUp();
      } else {
        this.getAdd();
      }
    },
    cancel() {
      this.list.page = 1;
      this.list.phone = "";
      this.list.username = "";
      this.getList();
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async getList() {
      let { success, data, message } = await _AppFeedback(this.list);
      if (success) {
        this.parkaccountMngList = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2() {
      this.list.page = 1;
      this.getList();
    },
    async getmsg() {
      let { success, data, message } = await _AppreplyFeedback(this.form);
      if (success) {
        this.$message.success("回复成功");
        this.addAccount = false;
        this.getList();
      }
    },
  },
};
</script>

<style scoped lang='less'>
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 90px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.image-show {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin: 10px;
  &:hover::after {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    content: url(../../assets/images/magnify.png);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}
/deep/.el-textarea.is-disabled .el-textarea__inner,
/deep/.el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>