<template>
  <div class="flex">
    <div class="left">
      <el-tree
        :data="parktreeList"
        node-key="value"
        default-expand-all
        ref="tree"
        :expand-on-click-node="false"
        :default-expanded-keys="[1]"
        highlight-current
        :render-content="renderContent"
        @node-click="gettree"
      >
      </el-tree>
    </div>
    <div class="right">
      <!-- <div class="button" v-if="id">
        <button
          class="centerBtn"
          @click="(addAccount = true), (formB.carNumber = '')"
        >
          新增
        </button>
        <button class="centerBtn" @click="Import = true">导入</button>
      </div> -->
      <el-form :inline="true">
        <el-form-item prop="carnumber">
          <el-input
            v-model="form.carNumber"
            autocomplete="off"
            placeholder="请输入车辆编号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <!-- <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option> -->
          <!-- <el-cascader
            :options="cartypelist"
            :show-all-levels="false"
            :props="defaultProps"
            placeholder="请选择车型"
            v-model="formB.carTypeId"
            @change="handleChange"
          ></el-cascader> -->
          <el-cascader
            v-model="carTypeId"
            :options="cartypelist"
            :props="defaultProps"
            @change="handleChange"
            :show-all-levels="false"
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <button class="centerCzbtn ml20" type="button" @click="getList2">
            搜索
          </button>
          <button class="centerBtn resetbtn" type="button" @click="cancel">
            重置
          </button></el-form-item
        >
      </el-form>
      <Table
        :data1="carlist"
        :columns1="columns"
        :total="list.total"
        :loading="loading"
        @getAjax="getTableData"
        class="tableTop"
        :page="list.page"
      />
    </div>
    <el-dialog title="删除" :visible.sync="delDiaA" width="30%">
      <span>确认删除这条记录?</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn ml20" @click="getDel">确 定</button>
        <button class="centerBtn resetbtn" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-dialog title="新增车辆" :visible.sync="addAccount" width="30%">
      <el-form :model="formB" :rules="rules">
        <el-form-item label="车辆编号" prop="carNumber">
          <el-input
            v-model="formB.carNumber"
            autocomplete="off"
            placeholder="请输入车辆编号"
            class="input centerInput"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn ml20" @click="getAdd">确 定</button>
        <button class="centerBtn resetbtn" @click="addAccount = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-drawer
      title="导入园区"
      :visible.sync="Import"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <el-upload
        class="upload-demo"
        drag
        action="#"
        :auto-upload="false"
        :file-list="fileList"
        :on-change="handleUpXls"
        :before-upload="getupload"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span> 只能上传jpg/png文件，且不超过500kb</span>
          <span @click="downTemplate">下载模板</span>
        </div>
      </el-upload>
      <div style="margin-top: 20%" class="flex justify-center">
        <button class="centerCzbtn" @click="upload">上传</button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _Addcar,
  _ParkList,
  _CarList,
  _CarNoList,
  _Del,
  _CarType,
  _CarUpload,
  _CarExport,
} from "@/api/systemMng";

export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      delDiaA: false,
      addAccount: false,
      Import: false,
      file: null,
      total: 0,
      id: "",
      treeid: "",
      fileList: [],
      tableData: [],
      carlist: [],
      cartypelist: [],
      eve: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carTypeName",
        },
        {
          label: "车辆子类型",
          prop: "carSonTypeName",
        },
        {
          label: "运营类别",
          prop: "operationTypeName",
        },
        {
          label: "所属组织",
          render: (h, params) => {
            if (params.row.parkName == null || params.row.parkName == "") {
              return h("span", params.row.companyName);
            } else {
              return h("span", params.row.parkName);
            }
          },
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: 200,
        },
        {
          label: "操作",
          width: 100,
          render: (h, params) => {
            let srclist = 3;
            return h("div", [
              h(
                "span",
                {
                  class: "operationB",
                  on: {
                    click: () => {
                      this.delDiaA = true;
                      this.carOperationId = params.row.carOperationId;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      parktreeList: [
        {
          ids: "",
          companyName: "",
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "typeName",
        value: "id",
        checkStrictly: true,
      },
      form: {
        carNumber: "",
      },
      formB: {
        carNumber: "",
        companyId: sessionStorage.getItem("parkId"),
        parkId: "",
        carTypeId: "",
      },
      carTypeId: "",
      rules: {
        carNumber: [
          {
            required: true,
            message: "请输入车辆编号",
            trigger: "blur",
          },
        ],
      },
      list: {
        page: 1,
        size: 10,
        total: 0,
      },
      searchObj: {
        page: 1,
        size: 1,
        companyId: sessionStorage.getItem("companyId"),
      },
    };
  },
  mounted() {
    this.getParkList();
    this.getList();
    this.getCarType();
  },
  methods: {
    //选择车辆类型
    handleChange(value) {
      console.log(value);
      this.carTypeId = value;
      if (value.length == 1) {
        this.formB.carTypeId = value[0];
      } else {
        this.formB.carTypeId = value[1];
      }
    },
    // 导入
    handleUpXls(file, fileList) {
      console.log(fileList);
      this.fileList = fileList;
    },
    // 下载模板
    downTemplate() {
      this.exportCar();
    },
    //导出车辆
    exportCar() {
      _CarExport(this.searchObj).then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        this.exportLoad = false;

        link.download = "车辆授权导出数据.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.timer = setTimeout(() => {
          this.$message.success("导出成功");
        }, 3000);
      });
    },
    //上传文件
    getupload(file) {
      this.file = file;
    },
    async upload() {
      console.log(this.file);
      let file = new FormData();
      file.append("file", this.fileList[0].raw);
      file.append("enterpriseId", this.parktreeList[0].ids);
      file.append("parkId ", this.formB.parkId);
      let { success, data, message } = await _CarUpload(file);
      if (success) {
        this.$message.success(message);
        this.getTableData();
        this.Import = false;
        this._CarList();
      }
      this.Import = false;
    },
    //获取页面列表数据
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      // if (this.id) {
      this.getList();
      // } else {
      //   this.getNoList();
      // }
    },
    //点击园区
    gettree(eve) {
      // this.id = eve.id;

      this.formB.parkId = this.parktreeList[0].ids;
      // if (this.id) {
      //   this.getList();
      // } else {
      this.getList();
      // }
    },
    //园区数据
    renderContent(h, { node, data, store }) {
      return (
        <div>
          <span>{data.companyName}</span>
        </div>
      );
    },
    //取消
    cancel() {
      this.formB.carTypeId = "";
      this.form.carNumber = "";
      this.carTypeId = "";
      this.list={
        page: 1,
        size: 10,
        total: 0,
      },
      this.getList();
    },
    //添加车辆
    async getAdd() {
      _Addcar(this.formB).then((res) => {
        if (res.success == true) {
          this.getList();
          this.addAccount = false;
          this.$message.success("车辆新增成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取园区数据
    async getParkList() {
      _ParkList(this.formB).then((res) => {
        if (res.success == true) {
          this.parkList = res.data[0];
          this.parktreeList[0].companyName = this.parkList.companyName;
          this.parktreeList[0].ids = this.parkList.id;
          this.parktreeList[0].children = this.parkList.companyList;

          // this.form.companyId = this.parkList.id;
        }
      });
    },
    //获取授权车辆数据
    async getList() {
      _CarList({
        page: this.list.page,
        size: this.list.size,
        parkId: sessionStorage.getItem("parkId"),
        carNumber: this.form.carNumber,
        carTypeId: this.formB.carTypeId,
      }).then((res) => {
        if (res.success == true) {
          this.carlist = res.data.list;
          this.list.total = res.data.total;
          this.loading = false;
        }
      });
      this.addAccount = false;
    },
    // 搜索
    async getList2() {
      this.list.page = 1
      this.getList()
    },
    // async getNoList() {
    //   _CarNoList({
    //     page: this.list.page,
    //     size: this.list.size,
    //     parkId: this.formB.parkId,
    //     carNumber: this.form.carNumber,
    //     carTypeId: this.formB.carTypeId[1],
    //     companyId: this.formB.companyId,
    //   }).then((res) => {
    //     if (res.success == true) {
    //       this.carlist = res.data.list;
    //       this.total = res.data.total;
    //       this.loading = false;
    //     }
    //   });
    //   this.addAccount = false;
    // },
    //删除
    async getDel() {
      _Del({ carOperationId: this.carOperationId }).then((res) => {
        if (res.success == true) {
          // this.carlist = res.data.list;
          this.getList();
          this.$message.success("车辆删除成功");
        }
      });
      this.delDiaA = false;
    },
    //获取车辆类型
    async getCarType() {
      _CarType().then((res) => {
        if (res.success == true) {
          this.cartypelist = res.data.list;
        }
      });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.left {
  width: 20%;
  height: 701px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  margin-right: 20px;
}
.right {
  width: 80%;
}
.button {
  margin-bottom: 20px;
}
.el-upload__tip {
  display: flex;
  justify-content: space-between;
  padding-right: 43px;
}
.el-upload__tip > span:last-child {
  cursor: pointer;
  color: #1683d9;
}
/deep/ .el-input__inner {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  border: 0px solid white !important;
  font-size: 14px;
  border-radius: 0px;
}
.input /deep/.el-input__inner {
  width: 240px;
}
.el-form-item {
  margin-bottom: 0px;
}
.tableTop {
  margin-top: 22px;
}
</style>
