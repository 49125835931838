import service from '../../until/api'
//车辆运营
export function _Import(data) {
    return service({
        url: 'file-nacos/excelUpload/uploadSchedulingPlan',
        method: 'POST',
        data
    })
}

export function _Download(data) {
    return service({
        url: 'file-nacos/excelDownload/exportHistoricalSchedulingRecord',
        method: 'GET',
        params: data,
        responseType: 'blob'
    })
}

export function _OperateDownload(data) {
    return service({
        url: 'file-nacos/excelDownload/exportVehicleOperationRecord',
        method: 'GET',
        params: data,
        responseType: 'blob'
    })
}
export function _ExportModel(data) {
    return service({
        url: 'file-nacos/excelDownload/carExportTemplate',
        method: 'get',
        params: data,
        responseType: 'blob'

    })
}

export function _Emdownload(data) {
    return service({
        url: 'file-nacos/excelDownload/exportCarBrakeVideo',
        method: 'GET',
        params: data,
        responseType: 'blob'
    })
}
