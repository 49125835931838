<template>
  <div>
    <el-row class="margin_b">
      <el-col :span="4">
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="list.alarmLevel"
          placeholder="报警等级"
          class="centerSelInput ipwidth"
        >
          <el-option
            v-for="item in levelOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="16">
        <button class="centerCzbtn ml20" @click="searchData">搜索</button>
        <button class="centerBtn resetbtn" @click="clearData">重置</button>
      </el-col>
    </el-row>

    <el-table
      ref="multipleTable"
      max-height="550px"
      element-loading-text="数据加载中..."
      v-loading="loading"
      border
      :data="tableData"
      class="table"
    >
      <el-table-column type="index" width="50" label="序号" align="center">
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        align="left"
        header-align="left"
        :key="idx"
        :prop="item.prop"
        :label="item.label"
        v-for="(item, idx) of columns"
        :width="item.width"
        :type="item.type"
        :fixed="item.fixed"
      >
        <template slot-scope="scope">
          <ex-slot
            v-if="item.render"
            :render="item.render"
            :row="scope.row"
            :index="scope.$index"
            :column="item"
          >
          </ex-slot>
          <span v-else>
            {{ scope.row[item.prop] | firstFilter(scope.row[item.prop]) }}
          </span>
        </template>
      </el-table-column>

      <template slot="empty" class="emptyTem">
        <img :src="emptyImg" alt="" srcset="" />
        <div>暂无数据</div>
      </template>
    </el-table>
    <el-dialog title="报警发生地" :visible.sync="priceShow" width="60%" destroy-on-close>
      <div id="lookContainer" style="height: 400px"></div>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn" @click="priceShow = false">确 定</button>
        <button class="centerBtn resetbtn" @click="priceShow = false">取 消</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapCarMarker } from "@/until/helper";
import Table from "../../components/Table.vue";
import { _GetWarningList } from "@/api/equipmentMonitoring";
var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,

    column: {
      type: Object,
      default: null,
    },
  },
  render: (h, data) => {
    const params = {
      row: data.props.row,
      index: data.props.index,
    };
    if (data.props.column) params.column = data.props.column;
    return data.props.render(h, params);
  },
};
export default {
  components: {
    Table,
    "ex-slot": exSlot,
  },
  data() {
    return {
      emptyImg: require("@/assets/images/empty.png"),
      desOptions: [], // 目的地列表
      desIoading: false, //目的地加载
      cancelAjax: null,
      timer: null, // 定时器
      id: null, //某一项id
      parkId: null,
      loading: false, //列表加载动效
      //表格数据
      tableData: [],
      //新增任务表单
      list: {
        alarmLevel: null,
        carNumber: null, //车辆编号'
        parkId: sessionStorage.getItem("parkId"),
      },
      levelOption: [
        { label: "一级报警", value: 1 },
        { label: "二级报警", value: 2 },
        { label: "三级报警", value: 3 },
      ],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
          width: 100,
        },
        {
          label: "车辆类型 ",
          prop: "carType",
        },
        {
          label: "车辆状态",
          prop: "isOnline",
          render: (h, params) => {
            let label = params.row.isOnline ? "在线" : "离线";
            return h("span", label);
          },
        },
        {
          label: "报警事件",
          prop: "alarmEventDesc",
        },

        {
          label: "报警等级",
          prop: "alarmLevel",
          render: (h, params) => {
            let label = (
              this.levelOption.find((item) => item.value == params.row.alarmLevel) || {
                label: "",
              }
            ).label;
            return h("span", label);
          },
        },
        {
          label: "报警发生时间",
          prop: "alarmTriggerTime",
        },
        {
          label: "报警状态",
          prop: "alarmTriggerTime",
          render: (h, params) => {
            return h("span", {}, [
              h("span", {
                style: {
                  display: "inline-block",
                  height: "8px",
                  width: "8px",
                  borderRadius: "4px",
                  backgroundColor: params.row.isOnline ? "red" : "gray",
                  marginRight: "2px",
                },
              }),
              "报警中",
            ]);
          },
        },

        {
          label: "操作项",
          width: 160,

          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.priceShow = true;
                      this.getlocation(params.row);
                    },
                  },
                },
                "查看报警发生地"
              ),
            ]);
          },
        },
      ],
      priceShow: false,
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getparkId();
  },
  filters: {
    firstFilter(val) {
      if (val === "" || val === null) {
        return "-";
      } else {
        return val;
      }
    },
  },
  methods: {
    //获取地址
    getlocation(row) {
      const { alarmPositionLongitude, alarmPositionLatitude } = row;
      let sCarTypeImage = mapCarMarker(row.carTypeId);
      this.$nextTick(() => {
        console.log(alarmPositionLongitude, alarmPositionLatitude);
        this.addedmap = new AMap.Map("lookContainer", {
          resizeEnable: true,
          center: [Number(alarmPositionLongitude), Number(alarmPositionLatitude)],
          zoom: 16,
        });

        let GPS_Position = [alarmPositionLongitude, alarmPositionLatitude];

        let that = this;

        AMap.convertFrom(GPS_Position, "gps", function (status, result) {
          if (result.info === "ok") {
            let AmapPosition = [result["locations"][0].lng, result["locations"][0].lat];
            // // 判断车辆类型切换图片
            // let carImg = null;
            // switch (that.nowCarType) {
            //   case "运输载货车":
            //     carImg = require("@/assets/images/map_car_type_9.png");
            //     break;
            //   case "SV-BUS":
            //     carImg = require("@/assets/images/map_car_type_2.png");
            //     break;
            //   case "清扫车":
            //     carImg = require("@/assets/images/map_car_type_5.png");
            //     break;
            //   default:
            //     carImg = require("@/assets/images/minbus.png");
            // }
            var marker = new AMap.Marker({
              map: that.addedmap,
              clickable: true,
              position: AmapPosition,
              icon: new AMap.Icon({
                image: sCarTypeImage,
                imageSize: new AMap.Size(36, 36),
                size: new AMap.Size(36, 36),
              }),
              offset: new AMap.Pixel(-13, -26),
              // angle: that.vehicle.location.head
            });
          }
        });
      });
    },
    // 添加定时器
    setTimer(params) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getList(true, params);
      }, 5000);
    },

    getparkId() {
      this.parkId = sessionStorage.getItem("parkId");
    },
    //获取页面数据
    async getList(e, obj) {
      // 重复请求就取消
      if (typeof this.cancelAjax === "function") {
        this.cancelAjax();
      }
      // 定时器刷新不loading
      e || (this.loading = true);
      // 非定时器清除timer
      e || clearInterval(this.timer);
      // 定时器请求搜索值没变之前的数据
      let params = obj ? obj : this.list;
      try {
        let { data, success } = await _GetWarningList(params, this);

        if (success) {
          this.tableData = data;
        }
        e || (this.loading = false);
        e || this.setTimer(JSON.parse(JSON.stringify(this.list)));
      } catch (error) {
        e || (this.loading = false);
        this.tableData = [];
      }
    },

    //新增任务

    //获取数据
    searchData() {
      this.getList();
    },
    clearData() {
      // 搜索项重置
      Object.assign(this.list, this.$options.data().list);
      //时间默认

      this.getList();
    },

    async getTableData(pagination) {
      this.loading = true;
      await this.getList();
    },

    // 获取执行结果-4-13弃用
    getResult(id, loading) {
      return new Promise((resolve, reject) => {
        let timer = setInterval(async () => {
          try {
            let res = await _GetTaskExecuteResult({ id, timeout: 15 });
            let nowTime = new Date().getTime();
            let { success, data } = res;
            if (success) {
              let startTime = new Date(data.startTime).getTime() + 15000;
              if (nowTime < startTime && data.operationResult == 0) {
                // 操作失败
                clearInterval(timer);
                loading.close();
                this.$message.warning("任务执行失败");
                resolve();
                return;
              } else if (nowTime < startTime && data.operationResult == 2) {
                clearInterval(timer);
                loading.close();
                this.$message.success("任务执行成功");
                resolve();
                return;
              } else if (nowTime >= startTime) {
                clearInterval(timer);
                loading.close();
                this.$message.warning("车辆无响应，执行超时");
                resolve();
                return;
              }
            }
          } catch (error) {
            reject();
            clearInterval(timer);
          }
        }, 1000);
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped lang="less">
.textcolor {
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  margin-right: 0px;
}
.statutext {
  color: rgba(22, 131, 217, 1);
  font-size: 16px;
  margin-right: 61px;
  cursor: pointer;
}
.second_row {
  margin: 1% 0 1% 0;
}
.pickerTime {
  border: 0px solid black;
}
.margin_b {
  margin-bottom: 2%;
}
.centerSelInput {
  height: 30px;
}
.textCol {
  color: #1683d9;
}
/deep/ .colorB {
  /* color: red; */
  /* -webkit-touch-callout: none; */
  margin-right: 20px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar:horizontal {
  width: 20px;
  height: 15px;
}
/deep/.is-scrolling-left ~ .el-table__fixed,
/deep/.is-scrolling-middle ~ .el-table__fixed,
/deep/.is-scrolling-right ~ .el-table__fixed {
  bottom: 15px !important;
}
/deep/.is-scrolling-left ~ .el-table__fixed-right,
/deep/.is-scrolling-middle ~ .el-table__fixed-right,
/deep/.is-scrolling-right ~ .el-table__fixed-right {
  bottom: 15px !important;
}
/deep/ .centerSelInput > div > input {
  width: 1.25rem;
  height: 0.1875rem;
  background: #ffffff;
  box-shadow: 0 0.020833rem 0.083333rem 0 rgb(0 0 0 / 8%);
  border: 1 solid black !important;
  font-size: 0.072917rem;
}
.el-form-item .el-select,
.el-form-item .el-date-editor {
  width: 100%;
}
/deep/.add-drawer .el-input__inner {
  border: 1px solid #dcdfe6 !important;
}
/deep/.disabledBtn {
  cursor: not-allowed;
  color: #ccc;
  margin-right: 20px;
}
</style>
