<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          style="margin-right: 20px"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.roadLine"
          placeholder="路线"
          class="centerSelInput centerInput"
        >
          <el-option
            v-for="item in routeList"
            :key="item.id"
            :label="item.routeName"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item
        ><button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button>
        <button class="centerCzbtn ml20" type="button" @click="getDowload">
          导出
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <el-dialog title="园区账号属性更改" :visible.sync="vipshow" width="30%">
      <p>
        <span class="textcolor">当前用户 167****3254：</span>
        <span class="textcolor2">普通用户</span>
      </p>
      <p>
        <span class="textcolor">确认切换用户账号属性为：</span>
        <span class="textcolor2">VIP用户</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="vipshow = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import { _MinBusListRecord, _MinBusOrderLine } from "@/api/minBus";
import { _OperateDownload } from "@/api/file";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      vipshow: false,
      valueTime: "",
      routeList: [],
      searchObj: {
        vehicleNumber: "",
        value1: "",
        consumptionRoute: "",
      },
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },
        {
          label: "车辆子类型",
          prop: "sonCarType",
        },
        {
          label: "运营模式",
          render: (h, params) => {
            if (params.row.practices == 1) {
              return h("span", "普通模式");
            } else {
              return h("span", "VIP模式");
            }
          },
        },
        {
          label: "行驶路线",
          prop: "carRoute",
        },
        {
          label: "安全员",
          prop: "securityName",
        },
        {
          label: "取车时间",
          prop: "pickUpTime",
        },
        {
          label: "还车时间",
          prop: "updateTime",
        },
      ],
      list: {
        page: 1,
        size: 10,
        total: 0,
        carNumber: "",
        startTime: "",
        endTime: "",
        roadLine: "",
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
    this.getLine();
  },
  methods: {
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.valueTime[0];
        this.list.endTime = this.valueTime[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    cancel() {
      this.list = {
        page: 1,
        size: 10,
        total: this.list.total,
        carNumber: "",
        startTime: "",
        endTime: "",
        roadLine: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.valueTime = "";
      this.getList();
    },
    async getList() {
      let { success, data, message } = await _MinBusListRecord(this.list);
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2(){
      this.list.page=1
      this.getList()
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async getLine() {
      let { success, data, message } = await _MinBusOrderLine({
        parkId: sessionStorage.getItem("parkId"),
      });
      if (success) {
        this.routeList = data;
        this.loading = false;
      }
    },
    async getDowload() {
      // 复制搜索条件
      let list = JSON.parse(JSON.stringify(this.list))
      // 一次性获取1页全部条数
      list.size = this.total
      list.page = 1
      _OperateDownload({
        ...list,
        carType: 1,
      }).then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        this.exportLoad = false;
        link.download = "车辆运营记录导出数据.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.timer = setTimeout(() => {
          this.$message.success("导出成功");
        }, 3000);
      });
    },
  },
};
</script>

<style scoped>
.textcolor {
  font-size: 14px;
  color: #666666;
  margin-right: 20px;
}
.textcolor2 {
  font-size: 14px;
  color: #333333;
}
.el-input__inner {
  border: none !important;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
</style>