<template>
  <div>
    <div class="title flex">
      <div class="timeList flex align-center">
        <span>
          <img
            class="time_img"
            src="@/assets/images/iconLeft.png"
            @click="getleft"
        /></span>
        <div
          :class="number == index ? 'time_color' : 'time_img'"
          class="flex direction-column justify-center align-center"
          v-for="(item, index) in timeList[num]"
          :key="index"
          @click="getclick(index, item)"
        >
          <span>{{ item.replace(/\//g, ".").slice(5, 11) }}</span>
          <p class="timecolor" v-if="number == index && total > 0"></p>
        </div>
        <span>
          <img
            class="time_img"
            src="@/assets/images/iconRight.png"
            @click="getright"
        /></span>
      </div>

      <button class="centerBtn" type="button" @click="getadd">创建</button>
      <button
        class="centerBtn"
        type="button"
        @click="(Import = true), (fileList = [])"
      >
        导入
      </button>
      <button class="centerBtn" type="button" @click="getpush">
        排班历史记录
      </button>
    </div>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      :loading="loading"
      @getAjax="getTableData"
    />
    <el-drawer
      :title="isAdd ? '创建排班记录' : '编辑排班记录'"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      destroy-on-close
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item
            label="车辆编号"
            :label-width="formLabelWidth"
            prop="carnumber"
          >
            <el-select
              v-model="form.vehicleId"
              filterable
              placeholder="请选择"
              class="select"
            >
              <!-- @change="getchange" -->
              <el-option
                v-for="item in carRegisterlist"
                :key="item.id"
                :label="item.carNumber"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <span class="router">{{ lineName }}</span> -->
          </el-form-item>

          <el-form-item
            label="设置出发时间"
            :label-width="formLabelWidth"
            prop="departureTime"
            class="select time"
          >
            <el-date-picker
              v-model="form.departureTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="设置起始时间"
            :label-width="formLabelWidth"
            prop="valueTime"
            class="time"
          >
            <el-time-picker
              is-range
              v-model="form.valueTime"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              class="centerCzSelInput"
              value-format="HH:mm:ss"
              @change="gettime"
            >
            </el-time-picker>
          </el-form-item>

          <el-form-item
            label="分配安全员"
            :label-width="formLabelWidth"
            prop="safetyPersonnelId"
            class="select"
          >
            <el-select
              v-model="form.safetyPersonnelId"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in peplist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
					<el-form-item
						label="行驶路线"
						:label-width="formLabelWidth"
						class="select"
						prop="carRouteId"
					>
						<el-select placeholder="请选择行驶路线" v-model="form.carRouteId">
							<el-option :label="item.carRouteName" :value="item.carRouteId" v-for="item in carRoute" :key="item.carRouteId"></el-option>
						</el-select>
					</el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn" @click="getselect">保存</button>
        <button class="centerBtn resetbtn" @click="addAccount = false">
          取消
        </button>
      </div>
    </el-drawer>
    <el-drawer
      title="批量导入排班计划"
      :visible.sync="Import"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <el-upload
        class="upload-demo"
        drag
        action="#"
        accept=".xlsx"
        :auto-upload="false"
        :file-list="fileList"
        :on-change="handleUpXls"
        :before-upload="getupload"
        :limit="1"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span> 只能上传.xlsx文件，且不超过500kb</span>
          <span @click="downTemplate">下载模板</span>
        </div>
      </el-upload>
      <div style="margin-top: 20%" class="flex justify-center">
        <button class="centerCzbtn" @click="upload">上传</button>
      </div>
    </el-drawer>
    <el-dialog title="删除" :visible.sync="delDiaA" width="30%">
      <span>确认删除此计划?</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getDel">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _MinBusGetpep,
  _MinBuscarRegister,
  _MinBusgetLineName,
} from "@/api/minBus";
import {
  _TuBuschedulingPlan,
  _TuBuschedulingDel,
  _TuBuschedulingAdd,
  _TuBuschedulingUp,
  _GetAllRoute
} from "@/api/tuBus";
import { _Import, _ExportModel } from "@/api/file";
export default {
  components: {
    Table,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let curDate = new Date().getTime();

          //结束日期的逻辑，获取当前周第一天的时间，然后加上28天，则可以确定，今天之后的3周结束时间。
          let objDate = new Date();
          let nextDate = 28 * 24 * 3600 * 1000;
          let weekday = objDate.getDay() || 7;
          objDate.setDate(objDate.getDate() - weekday);
          let DateRoot = objDate.getTime() + nextDate;

          return time.getTime() < curDate - 8.64e7 || time.getTime() > DateRoot;
        },
      },
      loading: false,
      addAccount: false,
      Import: false,
      formLabelWidth: "100px",
      operationStatus: false,
      number: null,
      num: 0,
      isAdd: true,
      delDiaA: false,
      fileList: [],
      safetyOfficerList: [],
      valueTime: [],
      id: "",
      timeList: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
			year: new Date().getFullYear(),
			month: new Date().getMonth(), //获取当前月份(0-11,0代表1月)
			day: new Date().getDate(),
      searchObj: {
        vehicleNumber: "",
        operationStatus: "",
      },
      form: {
        vehicleId: "",
        departureTime: "",
        planStartTime: "",
        planEndTime: "",
        valueTime: [],
        safetyPersonnelId: "",
        parkId: sessionStorage.getItem("parkId"),
        carRouteId: null,
      },
      rules: {
				vehicleId: [
					{
						required: true,
						message: "请输入车辆编号",
						trigger: "blur",
					},
				],
        departureTime: [
          {
            required: true,
            message: "请设置出发时间",
            trigger: "blur",
          },
        ],
        valueTime: [
          {
            required: true,
            message: "请设置起始时间",
            trigger: "blur",
          },
        ],
        safetyPersonnelId: [
          {
            required: true,
            message: "请分配安全员",
            trigger: "change",
          },
        ],
				carRouteId:[
					{
						required: true,
						message: "请选择行驶路线",
						trigger: "change",
					},
				]
      },
      tableData: [],
      carRoute:[],
      columns: [
        {
          label: "日期",
          prop: "jobStartTime",
        },
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },
        {
          label: "车辆子类型",
          prop: "sonCarType",
        },
        {
          label: "行驶路线",
          prop: "routeName",
        },
        {
          label: "安全员",
          prop: "securityName",
        },
        {
          label: "时间段",
          width: 300,
          render: (h, params) => {
            return h("span", params.row.startTime + "-" + params.row.endTime);
          },
        },
        {
          label: "操作",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.isAdd = false;
                      this.addAccount = true;
                      this.form.valueTime = [];
                      this.form = {
                        vehicleId: params.row.carRegisterId,
                        departureTime: params.row.jobStartTime,
                        planStartTime: params.row.startTime,
                        planEndTime: params.row.endTime,
                        safetyPersonnelId: params.row.securityId,
                        shiftPlanId: params.row.id,
                        userId: JSON.parse(sessionStorage.getItem("userId")),
                        parkId: sessionStorage.getItem("parkId"),
                        carRouteId: params.row.carRouteId
                      };
                      this.form.valueTime[0] = params.row.startTime;
                      this.form.valueTime[1] = params.row.endTime;
                    },
                  },
                },
                "编辑排班"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDiaA = true;
                      this.id = params.row.id;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      carRegisterlist: [],
      peplist: [],
      list: {
        page: 1,
        size: 10,
        time:
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate(),
        parkId: sessionStorage.getItem("parkId"),
      },
      parkId: sessionStorage.getItem("parkId"),
      lineName: "",
    };
  },
  mounted() {
    this.gettimes();
	
		this.month = this.month + 1;
		this.month = (this.month <= 9) ? '0' + this.month : this.month;
		this.day = (this.day <= 9) ? '0' + this.day : this.day;
	
		for (var a = 0; a < this.timeList[0].length; a++) {
			if (this.month + "-" + this.day === this.timeList[0][a].replace(/\//g, ".").slice(5, 11)) {
				this.number = a;
			}
		}
	
		this.list.time = this.year + '-' + this.month + '-' + this.day;
	
		this.getList();
    this.getPepList();
    this.getcarRegister();
  },
  methods: {
    getadd() {
      this.addAccount = true;
      this.isAdd = true;
      this.form.valueTime = null;
      this.lineName = "";
      this.form = {
        vehicleId: "",
        departureTime: "",
        planStartTime: "",
        planEndTime: "",
        safetyPersonnelId: "",
        shiftPlanId: "",
        userId: JSON.parse(sessionStorage.getItem("userId")),
        parkId: sessionStorage.getItem("parkId"),
        carRouteId: null
      };
    },
		gettimes() {
			var currentDate = new Date();
		
			var timesStamp = currentDate.getTime();
		
			var currenDay = currentDate.getDay();
		
			var workone = timesStamp + 24 * 60 * 60 * 1000 * (0 - ((currenDay + 6) % 7));
		
			for (var a = 0; a < 4; a++) {
				for (var i = 0; i < 7; i++) {
					let objDate = new Date(workone + 24 * 60 * 60 * 1000 * (7 * a + i));
				
					let sYear = objDate.getFullYear();
					let sMonth = ("0" + (objDate.getMonth() + 1)).slice(-2);
					let sDay = ("0" + objDate.getDate()).slice(-2);
				
					let sDate = sYear + '-' + sMonth + '-' + sDay;
				
					this.timeList[a].push(sDate);
				}
			}
		},
    handleUpXls(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList);
    },
    getupload(file) {
      this.file = file;
    },
    downTemplate() {
      this.exportCar();
    },
    //下载模板
    exportCar() {
      _ExportModel().then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        this.exportLoad = false;

        link.download = "排班计划导入模板.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.timer = setTimeout(() => {
          this.$message.success("下载成功");
        }, 3000);
      });
    },
    getchange(id) {
      this.getLineName(id);
    },
    async getLineName(id) {
      let { success, data, message } = await _MinBusgetLineName({ id: id });
      if (success) {
        if (data != null) {
          this.lineName = data.routeName;
        } else {
          this.lineName = "暂无";
        }
      }
    },
    async upload() {
      let file = new FormData();
      file.append("file", this.fileList[0].raw);
      file.append("carType", 3);
      file.append("parkId", this.parkId);
      _Import(file).then((res) => {
        if (!res) {
          this.fileList = [];
        }
        if (res.success) {
          this.$message.success(res.message);
          this.fileList = [];
          this.getList();
          this.Import = false;
        }
        this.Import = false;
      });
    },
    getleft() {
      if (this.num > 0 && this.num <= 3) {
        this.num = this.num - 1;
        this.number = null;
      } else {
        for (var a = 0; a < this.timeList[0].length; a++) {
          if (this.month + 1 + "." + this.day == this.timeList[0][a]) {
            this.number = a;
          }
        }
      }
    },
    getright() {
      if (this.num >= 0 && this.num < 3) {
        this.num = this.num + 1;
        this.number = null;
      }
    },
    getclick(index, item) {
      this.number = index;
      this.list.time = item.replace(/\//g, "-");
      this.getList();
    },
    getpush() {
      this.$router.push("/rosterHistory");
    },
    gettime(val) {
      if (val != null) {
        this.form.planStartTime = this.form.valueTime[0];
        this.form.planEndTime = this.form.valueTime[1];
      } else {
        this.form.planStartTime = "";
        this.form.planEndTime = "";
      }
    },
    getselect() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd == true) {
            this.getAdd();
          } else {
            this.getUp();
          }
        }
      });
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async getList() {
      let { success, data, message } = await _TuBuschedulingPlan(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    async getDel() {
      let { success, data, message } = await _TuBuschedulingDel({
        shiftPlanId: this.id,
      });
      if (success) {
        this.getList();
        this.loading = false;
      }
      this.delDiaA = false;
    },
    async getPepList() {
      let { success, data, message } = await _MinBusGetpep({
        parkId: this.parkId,
      });
      if (success) {
        this.peplist = data;
        this.loading = false;
      }
    },
    async getcarRegister() {
      let { success, data, message } = await _MinBuscarRegister({
        parkId: this.parkId,
        carType: 3,
      });
      if (success) {
        this.carRegisterlist = data;
        this.loading = false;
      }
    },
    async getAdd() {
      let { success, data, message } = await _TuBuschedulingAdd(this.form);
      if (success) {
        this.getList();
        this.loading = false;
				this.$message.success('创建成功');
			}
      this.addAccount = false;
    },
    async getUp() {
      let { success, data, message } = await _TuBuschedulingUp(this.form);
      if (success) {
        this.getList();
        this.loading = false;
				this.$message.success('保存成功');
			}
      this.addAccount = false;
    },
		async getAllRoute(){
			let {data} = await _GetAllRoute({
				carTypeId: 3,// 观光车
				parkId: this.parkId
			})
			this.carRoute = data
		},
  },
  created(){
		this.getAllRoute()
	}
};
</script>

<style scoped lang="less">
.el-input__inner {
  border: none !important;
  height: 36px;
  background: #f5f8fa;
  width: 100%;
}
/deep/.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/.el-range-input {
  background: #f5f8fa;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
.timeList {
  width: 450px;
  height: 36px;
  /* line-height: 36px; */
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  margin-right: 20px;
}
.time_img {
  width: 50px;
  height: 36px;
  /* text-align: center; */
  cursor: pointer;
}
.time_color {
  width: 50px;
  height: 36px;
  /* text-align: center; */
  color: #1683d9;
  cursor: pointer;
}
.timecolor {
  width: 5px;
  height: 5px;
  display: block;
  border-radius: 100%;
  /* bgccolor: #90d12f; */
  background-color: #90d12f;
}
.router {
  color: #606266;
  margin-left: 5px;
}
.timeList p {
  margin: 0px;
}
.title {
  margin-bottom: 22px;
}
.router {
  color: #606266;
  margin-left: 5px;
}
.el-upload__tip {
  display: flex;
  justify-content: space-between;
  padding-right: 43px;
}
.el-upload__tip > span:last-child {
  cursor: pointer;
  color: #1683d9;
}
	.demo-drawer__content{
		/deep/.el-select{
			width: 100%;
		}
	}
</style>
