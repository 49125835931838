import service from '../../until/api'
//车辆控制记录
export function _SQcarControlList(data) {
    return service({
        url: 'protocol-nacos/carControlRecord/findAllPage',
        method: 'GET',
        params: data
    })
}

export function _SQcarexcelDownloa(data) {
    return service({
        url: 'file-nacos/excelDownload/exportCarControlRecord',
        method: 'GET',
        params: data,
        responseType: 'blob'
    })
}
//车辆维修记录服务

export function _SQcarMain(data) {
    return service({
        url: 'protocol-nacos/carMaintenanceRecord/findAllPage',
        method: 'GET',
        params: data
    })
}


export function _SQcarMainType(data) {
    return service({
        url: 'protocol-nacos/carMaintenanceRecord/getParkAllCar',
        method: 'GET',
        params: data
    })
}

export function _SQcarMainAdd(data) {
    return service({
        url: 'protocol-nacos/carMaintenanceRecord/addMaintenanceRecord',
        method: 'POST',
        data
    })
}
//车辆报警管理

export function _SQcarAlarm(data) {
    return service({
        url: 'protocol-nacos/carAlarm/findAllPage',
        method: 'GET',
        params: data
    })
}
export function _SQgetCarBrakeList(data) {
    return service({
        url: 'protocol-nacos/carAlarm/getCarBrakeList',
        method: 'GET',
        params: data
    })
}

export function _VHgetCarHistoryTrackList(data) {
    return service({
        url: '/protocol-nacos/vehicleController/historicalTrack',
        method: 'GET',
        params: data
    })
}

export function _VHgetHistoricalData(data) {
    return service({
        url: '/protocol-nacos/vehicleController/getHistoricalData',
        method: 'GET',
        params: data
    })
}

// 获取模糊搜索的车辆id
export function _GetCarId(data) {
    return service({
        url: `/protocol-nacos/vehicleController/getCarIdList`,
        method: 'GET',
        params: data
    })
}