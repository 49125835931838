import service from '../../until/api';
import axios from 'axios'
/**
 * @name: _TransportTaskList
 * @msg: 获取商用车任务管理列表
 * @param {*}
 * @return {*}
 */
export function _TransportTaskList(data,_this) {
  let CancelToken= axios.CancelToken
  return service({
    url: 'transport-truck/web/taskManage/findPage',
    method: 'GET',
    params: data,
    cancelToken: new CancelToken(function executor(c) {
      _this.cancelAjax = c
    })
  });
}

/**
 * @name: _TaskInsert
 * @msg: 新增任务
 * @param {*}
 * @return {*}
 */
export function _TaskInsert(data) {
  return service({
    url: 'transport-truck/web/taskManage/insert',
    method: 'POST',
    data
  });
}

/**
 * @name:_TaskUpdate
 * @msg: 任务更新
 * @param {object} data
 * @return {object}
 */
export function _TaskUpdate(data) {
  return service({
    url: 'transport-truck/web/taskManage/update',
    method: 'POST',
    data
  });
}

/**
 * @name:_TaskDelete
 * @msg: 删除任务
 * @param {object} data
 * @return {*}
 */
export function _TaskDelete(data) {
  return service({
    url: 'transport-truck/web/taskManage/delete',
    method: 'get',
    params: data
  });
}

/**
 * @name: _DownloadImportTransportTemplate
 * @msg: 下载模板
 * @param {*}
 * @return {*}
 */
export function _DownloadImportTransportTemplate() {
  return service({
    url: 'file-nacos/excelDownload/exportTaskManagerTemplate',
    method: 'GET',
    responseType: 'blob'
  });
}

/**
 * @name: _ImportTransport
 * @msg: 文件上传
 * @param {*} data
 * @return {*}
 */
export function _ImportTransport(data) {
  return service({
    url: 'file-nacos/excelUpload/uploadTaskManage',
    method: 'POST',
    data
  });
}

// /**
//  * @name: _ExecuteTask
//  * @msg: 任务管理路径下发
//  * @param {*} id, taskProperties 任务来源 0-任务管理 1-二次定位
//  * @return {*}
//  */
//  export function _ExecuteTask(data) {
//   return service({
//     url: 'transport-truck/web/taskManage/executeTask',
//     method: 'POST',
//     data
//   });
// }

/**
 * @name: _GetTaskExecuteResult
 * @msg: 获取指令下发结果 ----弃用
 * @param {*}
 * @return {*}
 */
 export function _GetTaskExecuteResult(data) {
  return service({
    url: 'transport-truck/web/taskManage/getTaskExecuteResult',
    method: 'GET',
    params: data
  });
}