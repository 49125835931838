<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-input
          v-model="list.carVin"
          placeholder="VIN"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          style="margin-right: 20px"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.alarmType"
          placeholder="警告类型"
          class="centerSelInput"
        >
          <el-option
            v-for="item in alarmTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item
        ><el-select
          v-model="list.alarmLevel"
          placeholder="警告等级"
          class="centerSelInput"
        >
          <el-option
            v-for="item in alarmLevelList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      @getAjax="getTableData"
      class="textB"
      :page="list.page"
    />
  </div>
</template>

<script>
import Table from "../../components/Table.vue";

import { _SQcarAlarm } from "@/api/statisticalQuery";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      addAccount: false,
      formLabelWidth: "100px",
      isAdd: false,
      warningLevelList: [],
      warningTypeList: [],
      searchObj: {
        vehicleNumber: "",
        faultMaintenance: "",
        VIN: "",
        warningType: "",
        warningLevel: "",
      },
      valueTime: "",
      list: {
        page: 1,
        size: 10,
        total: 0,
        loading: false,
        carNumber: "",
        carVin: "",
        startTime: "",
        endTime: "",
        alarmLevel: "",
        alarmType: "",
        parkId: sessionStorage.getItem("parkId"),
      },
      alarmTypeList: [
        {
          id: 1,
          value: "制动故障报警",
        },
        {
          id: 2,
          value: "制动电池故障报警",
        },
        {
          id: 3,
          value: "动力电池过热",
        },
        {
          id: 4,
          value: "电机系统故障",
        },
        {
          id: 5,
          value: "电机及控制器过热报警",
        },
        {
          id: 6,
          value: "绝缘报警",
        },
        {
          id: 7,
          value: "AES/ESC故障报警",
        },
        {
          id: 8,
          value: "EPS报警",
        },
        {
          id: 9,
          value: "基站未连接报警",
        },
        {
          id: 10,
          value: "自动驾驶系统故障报警",
        },
        {
          id: 11,
          value: "SOC信息及报警",
        },
        {
          id: 12,
          value: "驻车制动提示",
        },
        {
          id: 13,
          value: "DC系统故障报警",
        },
        {
          id: 14,
          value: "车门未关报警",
        },
      ],
      alarmLevelList: [
        { id: 1, value: "一级警告" },
        { id: 2, value: "二级警告" },
        { id: 3, value: "三级警告" },
      ],
      tableData: [],
      columns: [
        {
          label: "报警ID",
          prop: "alarmId",
        },
        {
          label: "报警时间",
          prop: "alarmTime",
        },
        {
          label: "地点",
          prop: "address",
        },
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆VIN",
          prop: "carVin",
        },
        {
          label: "车辆类型",
          prop: "carTypeName",
        },
        {
          label: "车辆子类型",
          prop: "sonCarTypeName",
        },
        {
          label: "运营类别",
          prop: "operationName",
        },
        {
          label: "警告等级",
          render: (h, params) => {
            return h("span", params.row.alarmLevelStr);
          },
        },
        {
          label: "警告类型",
          // prop: "alarmType",
          render: (h, params) => {
            return h("span", params.row.alarmTypeStr);
          },
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.valueTime[0];
        this.list.endTime = this.valueTime[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    cancel() {
      this.valueTime = "";
      this.list = {
        page: 1,
        size: 10,
        total: this.list.total,
        loading: false,
        carNumber: "",
        carVin: "",
        startTime: "",
        endTime: "",
        alarmLevel: "",
        alarmType: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList();
    },
    async getList() {
      this.loading = true;
      let { success, data, message } = await _SQcarAlarm(this.list);
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
      }
      this.loading = false;
    },
    // 搜索
    getList2(){
      this.list.page = 1;
      this.getList();
    },
    async getTableData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }

      await this.getList();
    },
  },
};
</script>

<style scoped>
.el-input__inner {
  border: none !important;
  height: 36px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
.el-form-item {
  margin-bottom: 10px;
}
.textB {
  margin-top: 12px;
}
</style>
