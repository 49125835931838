<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.operationStatus"
          placeholder="运营状态"
          class="centerSelInput"
        >
          <el-option
            v-for="item in StatusList"
            :key="item.id"
            :label="item.status"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getsearchList">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <el-dialog title="切换运营状态" :visible.sync="resetcarstatus" width="30%">
      <p>
        <span class="textcolor">当前状态</span>
        <span class="textcolor2" v-if="carstdate.operationStatus == 1"
          >空闲</span
        >
        <span class="textcolor2" v-else-if="carstdate.operationStatus == 2"
          >运营中</span
        >
        <span class="textcolor2" v-else-if="carstdate.operationStatus == 3"
          >维修中</span
        >
        <span class="textcolor2" v-else-if="carstdate.operationStatus == 4"
          >运营下线</span
        >
      </p>
      <div>
        <span class="red">*</span><span class="textcolor">切换运营状态至</span>
        <el-radio
          v-model="form.carStatus"
          label="1"
          v-if="
            carstdate.operationStatus == 3 || carstdate.operationStatus == 4
          "
          >空闲</el-radio
        >
        <el-radio
          v-model="form.carStatus"
          label="3"
          v-if="
            carstdate.operationStatus == 1 || carstdate.operationStatus == 4
          "
          >维修中</el-radio
        >
        <el-radio
          v-model="form.carStatus"
          label="4"
          v-if="
            carstdate.operationStatus == 1 || carstdate.operationStatus == 3
          "
          >运营下线</el-radio
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn ml20" @click="getOpStatu">确 定</button>
        <button class="centerBtn resetbtn" @click="resetcarstatus = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/swpvehicleTable.vue";
import Register from "../register/register.vue";
import { _SwBusList, _SwBusStatus } from "@/api/sweep";
export default {
  components: {
    Table,
    Register,
  },
  data() {
    return {
      loading: false,
      radio: "1",
      resetcarstatus: false,
      operationMode: false,
      formLabelWidth: "100px",
      isAdd: false,
      carstdate: [],
      StatusList: [
        { id: "2", status: "作业中" },
        { id: "1", status: "空闲" },
        { id: "3", status: "维修中" },
        { id: "4", status: "运营下线" },
      ],
      searchObj: {
        vehicleNumber: "",
        operationStatus: "",
      },
      form: {
        carId: "",
        carStatus: null,
      },
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },
        {
          label: "车辆子类型",
          prop: "sonCarType",
        },
        {
          label: "车辆状态",
          render: (h, params) => {
            if (params.row.carStatus == 1) {
              return h("span", "在线");
            } else {
              return h("span", "离线");
            }
          },
        },

        {
          label: "运营状态",
          render: (h, params) => {   
            if (params.row.operationStatus == 2) {
                if(params.row.type == 1){
                  return h("span", "作业中(清扫)");
                }else if(params.row.type == 4){
                    return h("span", "作业中(洒水)");
                }else if(params.row.type == 5){
                    return h("span", "作业中(清扫、洒水)");
                }
            } else if (params.row.operationStatus == 1) {
              return h("span", "空闲");
            } else if (params.row.operationStatus == 3) {
              return h("span", "维修中");
            } else if (params.row.operationStatus == 4) {
              return h("span", "运营下线");
            }
          },
        },
        {
          label: "行驶路线",
          prop: "carRoute",
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            if(params.row.operationStatus == "2"){
                  return h("div", [
                  h(
                    "span",
                    {
                      class: "operationA",
                    },
                    "--"
                  ),
                ]);
            }else{
                  return h("div", [
                  h(
                    "span",
                    {
                      class: "operationA",
                      on: {
                        click: () => {
                          if (params.row.operationStatus == "2") {
                            this.resetcarstatus = false;
                          } else {
                            this.resetcarstatus = true;
                          }
                          this.carstdate = params.row;
                          this.form.carId = params.row.id;
                          if (params.row.operationStatus == "1") {
                            this.form.carStatus = "3";
                          } else if (
                            params.row.operationStatus == "3" ||
                            params.row.operationStatus == "4"
                          ) {
                            this.form.carStatus = "1";
                          }
                        },
                      },
                    },
                    "切换运营状态"
                  ),
                ]);
            }
            
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        total: 0,
        carNumber: "",
        operationStatus: "",
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let { success, data, message } = await _SwBusList(this.list);
      // console.log(data)
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.loading = false;
      }
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      await this.getList();
    },
    async getOpStatu() {
      let { success, data, message } = await _SwBusStatus(this.form);
      if (success) {
        this.$message.success("切换运营状态成功");
        this.loading = false;
        this.getList();
      }
      this.resetcarstatus = false;
    },
    cancel() {
      this.list = {
        page: 1,
        size: 10,
        total: this.list.total,
        carNumber: "",
        operationStatus: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList();
    },
     getsearchList(){
       this.list.page=1;
       this.getList()
    },
    
  },
};
</script>
<style scoped>
</style>