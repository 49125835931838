<template>
  <el-drawer
    title="视角配置"
    :visible.sync="viewConfigShow"
    custom-class="footerDrawer"
    size="45%"
    @closed="closeDrawer"
    @open="openDrawer"
  >
    <div class="body" v-loading="drawerLoading">
      <div style="height: 100%; overflow-y: auto">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane
            :label="item.carTypeName"
            :name="item.carTypeName"
            v-for="item in cameraArr"
            :key="item.carType"
          ></el-tab-pane>
        </el-tabs>
        <div
          v-for="(item, index) in cameraArr"
          :key="item.carType"
          v-show="item.carTypeName == activeName"
        >
          <el-form
            inline
            v-for="(ele, i) in item.carCameraViewTypeDataList"
            :key="i"
            :model="ele"
            ref="form"
          >
            <div class="row">
              <el-form-item
                label="OBU_Camera_IP:"
                prop="camCode"
                :rules="[
                  {
                    validator: (rule, value, callback) => {
                      validValue(
                        rule,
                        value,
                        callback,
                        ele,
                        item.carCameraViewTypeDataList
                      );
                    },
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  placeholder="例：OBU_Camera0"
                  v-model="ele.camCode"
                  :disabled="ele.id ? true : false"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="视角位:"
                prop="view"
                :rules="[
                  {
                    validator: (rule, value, callback) => {
                      validLable(
                        rule,
                        value,
                        callback,
                        ele,
                        item.carCameraViewTypeDataList
                      );
                    },
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  placeholder="例：车正前平视"
                  v-model="ele.view"
                  :disabled="ele.id ? true : false"
                ></el-input>
              </el-form-item>
              <div class="icon">
                <i
                  class="el-icon-remove-outline"
                  v-show="item.carCameraViewTypeDataList.length != 1"
                  @click="removeCamera(item.carCameraViewTypeDataList, i, ele)"
                ></i>
                <i
                  v-show="
                    i == item.carCameraViewTypeDataList.length - 1 && i < 9
                  "
                  class="el-icon-circle-plus-outline"
                  @click="addCamera(item.carCameraViewTypeDataList)"
                ></i>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="foot">
      <button class="centerCzbtn ml20" type="button" @click="submitForm">
        保存
      </button>
      <button
        class="centerBtn resetbtn"
        type="button"
        @click="viewConfigShow = false"
      >
        取消
      </button>
    </div>
  </el-drawer>
</template>
           
<script>
import {
  _GetCameraViewType,
  _JudgeViewAndCamRelate,
  _AddOrupdate,
} from "@/api/cameraMng";
export default {
  name: "",
  props: {},
  data() {
    return {
      viewConfigShow: false,
      drawerLoading: true,
      activeName: "微公交",
      cameraArr: [],
      deletedIds: [],
    };
  },
  methods: {
    addCamera(list) {
      list.push({ view: "", camCode: "" });
    },
    async removeCamera(list, index, item) {
      if (!item.id) {
        list.splice(index, 1);
      } else {
        try {
          let { success } = await _JudgeViewAndCamRelate({ id: item.id });
          if (success) {
            list.splice(index, 1);
            this.deletedIds.push(item.id);
          }
        } catch (error) {}
      }
    },
    // 获取摄像头
    async getCameraViewType() {
      try {
        let { data, success } = await _GetCameraViewType({
          parkId: sessionStorage.getItem("parkId"),
        });
        if (success) {
          data.forEach((ele) => {
            if (ele.carCameraViewTypeDataList.length == 0) {
              ele.carCameraViewTypeDataList.push({ view: "", camCode: "" });
            }
          });
          this.activeName = data[0].carTypeName;
          this.cameraArr = data;
          this.drawerLoading = false;
        }
      } catch (error) {}
    },
    // OBU_Camera_IP 校验
    validValue(rule, value, callback, ele, list) {
      if (ele.view && value == "") {
        return callback(new Error("视角位已填OBU_Camera_IP必填"));
      }
      let count = 0;
      list.forEach((item) => {
        if (item.camCode == value) {
          count++;
        }
      });
      if (count >= 2 && value) {
        return callback(new Error("同一车型OBU_Camera_IP重复"));
      }
      callback();
    },
    // 视角位 校验
    validLable(rule, value, callback, ele, list) {
      if (ele.camCode && value == "") {
        return callback(new Error("OBU_Camera_IP已填视角位必填"));
      }
      let count = 0;
      list.forEach((item) => {
        if (item.view == value) {
          count++;
        }
      });
      if (count >= 2 && value) {
        return callback(new Error("同一车型视角位重复"));
      }
      callback();
    },
    // 提交
    async submitForm() {
      let refs = this.$refs.form;
      let flag = true;
      refs.forEach((ele) => {
        ele.validate((valid) => {
          if (!valid) {
            flag = false;
          }
        });
      });
      if (!flag) {
        this.$message({
          message: "有校验未通过项",
          type: "error",
          customClass: "message-override",
        });
      } else {
        let cameraArrCopy = JSON.parse(JSON.stringify(this.cameraArr));
        cameraArrCopy.forEach((ele) => {
          ele.carCameraViewTypeDataList = ele.carCameraViewTypeDataList.filter(
            (item) => item.view !== "" && item.camCode !== ""
          );
        });
        try {
          let { success } = await _AddOrupdate({
            carCameraViewTypeVOList: cameraArrCopy,
            deletedIds: this.deletedIds.toString(),
          });
          if (success) {
            this.$message({
              message: "保存修改成功",
              type: "success",
              customClass: "message-override",
            });
            this.viewConfigShow = false;
          }
        } catch (error) {}
      }
    },
    // 关闭弹窗
    closeDrawer() {
      let refs = this.$refs.form;
      refs.forEach((ele) => {
        ele.resetFields();
      });
      this.cameraArr = [];
      this.deletedIds = [];
      this.drawerLoading = true;
      this.activeName = "";
    },
    // 打开弹窗
    openDrawer() {
      this.getCameraViewType();
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.footerDrawer {
  .el-drawer__body {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    .body {
      flex: 1;
      overflow: hidden;
      .row {
        display: flex;
        > .icon {
          font-size: 28px;
          i {
            margin: 0 5px;
            cursor: pointer;
            color: #1683d9;
          }
          .el-icon-remove-outline {
            color: red;
          }
        }
      }
    }
    .foot {
      padding-top: 20px;
    }
  }
}
</style>