import service from '../../until/api';

//下载摄像头导入模板
export function _DownloadImportCameraTemplate() {
  return service({
    url: 'file-nacos/excelDownload/exportCarCameraTemplate',
    method: 'GET',
    responseType: 'blob'
  });
}

export function _ImportCamera(data) {
  return service({
    url: 'file-nacos/excelUpload/uploadCarCamera',
    method: 'POST',
    data
  });
}

export function _ExportCamera(data) {
  return service({
    url: 'vehicle-auth/carCamera/downloadCarCameraList',
    method: 'GET',
    params: data,
    responseType: 'blob'
  });
}

/**
 * @msg: 获取园区下车辆摄像头字典
 */
export function _GetCameraViewType(data) {
  return service({
    url: '/vehicle-auth/carCameraViewType/findListByParkId',
    method: 'GET',
    params: data
  });
}

/**
 * @msg: 校验视角是否关联摄像头接口
 */
export function _JudgeViewAndCamRelate(data) {
  return service({
    url: '/vehicle-auth/carCameraViewType/judgeViewAndCamRelate',
    method: 'GET',
    params: data
  });
}

/**
 * @msg: 新增修改接口
 */
export function _AddOrupdate(data) {
  return service({
    url: '/vehicle-auth/carCameraViewType/addOrupdate',
    method: 'POST',
    data
  });
}

/**
 * @msg: 新增修改接口
 */
export function _GetCamViewDic() {
  return service({
    url: '/vehicle-auth/carCameraViewType/getCamViewDic',
    method: 'GET'
  });
}
