<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput bottom"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.carTypeId"
          placeholder="车辆类型"
          class="width"
        >
          <el-option
            v-for="item in cartypelist"
            :key="item.id"
            :label="item.typeName"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.alarmType"
          placeholder="触发方式"
          class="width"
        >
          <el-option
            v-for="item in brakeTypelist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          class="bottom"
          :clearable="false"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>
      <!-- <el-form-item>
        <el-select
          v-model="searchObj.triggerMode"
          placeholder="触发方式"
          class="centerSelInput"
        >
          <el-option
            v-for="item in triggerModelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option> </el-select
      ></el-form-item> -->
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="list.loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <el-dialog :title="edtitle" :visible.sync="detail" width="60%">
      <div id="lookContainer"></div>
      <!-- <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="operationMode = false">
          取 消
        </button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import imgA from "../../assets/images/place.png";
import imgB from "../../assets/images/remarks.png";
import imgC from "../../assets/images/vido.png";
import imgDownload from "../../assets/images/download.png";
import imgDisableDownload from "../../assets/images/disable_download.png";
import { Amap, Marker } from "@amap/amap-vue";

import { _SQgetCarBrakeList } from "@/api/statisticalQuery";
import { _CameraType } from "@/api/pkMng";
import { _Emdownload } from "@/api/file";

import {
  _DownloadCarBrakeVideo,
  _DownloadEmergencyBrakeRecord,
} from "@/api/sweep";
import {DatetimeToTimestamp} from "@/until/helper";
export default {
  components: {
    Table,
    Amap,
    AmapMarker: Marker,
  },
  data() {
    return {
      loading: false,
      addAccount: false,
      detail: false,
      center: [114.425731, 30.509989],
      formLabelWidth: "100px",
      valueTime:[],
      edtitle: "",
      warningTypeList: [],
      triggerModelList: [],
      cartypelist: [],
      searchObj: {
        vehicleNumber: "",
        faultMaintenance: "",
        VIN: "",
        warningType: "",
        warningLevel: "",
      },
      list: {
        page: 1,
        size: 10,
        total: 0,
        loading: false,
        carNumber: "",
        startTime: "",
        endTime: "",
        carTypeId: "",
        alarmType: "",
        parkId: sessionStorage.getItem("parkId"),
      },
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carTypeName",
        },
        {
          label: "车辆子类型",
          prop: "sonCarTypeName",
        },
        {
          label: "紧急制动时间",
          prop: "alarmTime",
        },
        {
          label: "行驶线路",
          prop: "routeName",
        },
        {
          label: "触发方式",
          render: (h, params) => {
            if (params.row.brakeType == 1) {
              return h("div", "人工触发");
            } else if (params.row.brakeType == 2) {
              return h("div", "自动驾驶");
            } else if (params.row.brakeType == 3) {
              return h("div", "远程驾驶");
            }
          },
        },
        {
          label: "操作",
          width: 300,
          render: (h, params) => {
            let srclist = 3;
						
						let btnDownloadConfig = {
							style: {
								width: "16px",
								height: "16px",
							},
							attrs: {
								src: imgDownload, // 如果是1那种写法,不能实时获取,获取的还是undefined
							},
							on: {
								click: () => {
									this.DownloadCarBrakeVideo(params.row);
								},
							},
						};
						
						let nAlarmTime = DatetimeToTimestamp(params.row.alarmTime);
						let nCurrTime = new Date().getTime();
						
						if ((nCurrTime - nAlarmTime) < (30 * 60 * 1000)){
							btnDownloadConfig.attrs.src = imgDisableDownload;
							btnDownloadConfig.on = {};
						}
						
            return h("div", [
              h("img", {
                style: {
                  width: "16px",
                  height: "16px",
                  marginRight: "10px",
                },
                attrs: {
                  src: imgA, // 如果是1那种写法,不能实时获取,获取的还是undefined
                },
                on: {
                  click: () => {
                    this.detail = true;
                    this.edtitle =
                      "查看行为发生地点【" + params.row.carNumber + "】";
                    this.$nextTick(() => {
                      this.addedmap = new AMap.Map("lookContainer", {
                        resizeEnable: true,
                        center: [params.row.longitude, params.row.latitude],
                        zoom: 16,
                      });
                      var marker = new AMap.Marker({
                        map: this.addedmap,
                        clickable: true,
                        extData: params.row.id,
                        // position: JSON.parse(data.carRouteList[index].routeSite)[0],
                        position: [params.row.longitude, params.row.latitude],
                        icon: new AMap.Icon({
                          image:params.row.carTypeId == 9 ? require("@/assets/images/map_car_type_9.png") : require("@/assets/images/minbus.png"),
                          imageSize: new AMap.Size(36, 36),
                          size: new AMap.Size(36, 36),
                        }),
                        // size: new AMap.Size(56, 56),
                        // imageSize: "64px 64px",
                        offset: new AMap.Pixel(-13, -26),
                      });
                    });
                  },
                },
              }),
              h("img", {
                style: {
                  width: "16px",
                  height: "16px",
                  marginRight: "10px",
                },
                attrs: {
                  src: imgB, // 如果是1那种写法,不能实时获取,获取的还是undefined
                },
                on: {
                  click: () => {
                    this.DownloadEmergencyBrakeRecord(params.row);
                  },
                },
              }),
              // h("img", {
              //   style: {
              //     width: "16px",
              //     height: "16px",
              //     marginRight: "10px",
              //   },
              //   attrs: {
              //     src: imgC, // 如果是1那种写法,不能实时获取,获取的还是undefined
              //   },
              // }),
              h("img", btnDownloadConfig),
            ]);
          },
        },
      ],
      brakeTypelist: [
        { id: "1", name: "人工触发" },
        { id: "2", name: "自动驾驶" },
        { id: "3", name: "远程驾驶" },
      ],
    };
  },
  mounted() {
    this.getNowFormatDate() 
    this.getList();
    this.getCarType();
  },
  methods: {
    getNowFormatDate() {
           //获取当前日期
            var myDate = new Date();
            var nowY = myDate.getFullYear();
            var nowM = myDate.getMonth()+1;
            var nowD = myDate.getDate();
            //获取七天前日期
            var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 7);//最后一个数字30可改，30天的意思
            var lastY = lw.getFullYear();
            var lastM = lw.getMonth()+1;
            var lastD = lw.getDate();
            //默认时间  一加载进来就默认时间 发送请求
             this.list.startTime = lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD)
             this.list.endTime = nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD)
           this.valueTime.push(lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD))
           this.valueTime.push(nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD))
    },
    cancel() {
      this.list = {
        page: 1,
        size: 10,
        total: this.list.total,
        loading: false,
        carNumber: "",
        startTime: "",
        endTime: "",
        carTypeId: "",
        alarmType: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.valueTime=[];
      this.getNowFormatDate()
      this.getList()
      // console.log(this.valueTime)
      // console.log(this.list)
    },
    DownloadEmergencyBrakeRecord(data) {
      _DownloadEmergencyBrakeRecord({ id: data.id }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.download = data.carNumber + "_车辆紧急制动报文.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    gettime(){
   
      this.list.startTime = this.valueTime[0]
      this.list.endTime = this.valueTime[1]
      console.log(this.valueTime)
      console.log(this.list.startTime)
      console.log(this.list.endTime)
    },


    async getList() {
       this.list.loading = true; //显示加载中
       console.log(this.list)
       let { success, data, message } = await _SQgetCarBrakeList(this.list);
       console.log(data)
       if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.list.loading = false;
      }
    },
    // 搜索
    getList2(){
      this.list.page = 1;
      this.getList();
      // console.log(this.list)
      // console.log(this.valueTime)
    },
    async getTableData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }

      await this.getList();
    },
    async getCarType() {
      _CameraType().then((res) => {
        if (res.success == true) {
          this.cartypelist = res.data.list;
        }
      });
    },
    async DownloadCarBrakeVideo(rowData) {
      // const sUrl =
      //   window.urlConfig.url +
      //   "file-nacos/excelDownload/exportCarBrakeVideo?carVin=" +
      //   rowData.carVin +
      //   "&alarmTime=" +
      //   rowData.alarmTime +
      //   "&X-Token=Basic " + window.localStorage.getItem('token');
      // console.log('window.urlConfig.url', window.urlConfig.url)
			// window.open(sUrl);
      window.open(`${window.urlConfig.url}file-nacos/excelDownload/exportCarBrakeVideo?carVin=${rowData.carVin}&alarmTime=${rowData.alarmTime}&X-Token=${'Basic ' + window.sessionStorage.getItem('token')}`)
			/*
      const link = document.createElement("a");
      link.style.display = "none";
      link.download = sUrl;
      link.exportLoad = false;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);*/
    },
  },
};
</script>

<style scoped>
.largMap {
  width: 100%;
  height: 400px;
}
.el-input__inner {
  border: none !important;
  height: 36px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.width /deep/ .el-input {
  width: 140px !important;
  margin-right: 20px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  font-size: 14px;
}
.width /deep/.el-input__inner {
  border: 0px solid white !important;
  margin-right: 20px;
}
#lookContainer {
  height: 550px;
}
</style>
