<template>
  <div>
    <div>
      <p class="txetTop">
        <span
          :class="showVip == false ? 'statutext' : 'textcolor'"
          @click="getorder"
          >班线订单</span
        >

        <span
          :class="showVip == false ? 'textcolor' : 'statutext'"
          @click="getorderVip"
          >VIP订单</span
        >
      </p>
      <!-- <p class="flex">
        <span :class="showVip == false ? 'statustext_bg' : ''"></span>
        <span :class="showVip == false ? '' : 'statustext_bgB'"></span>
      </p> -->
    </div>
    <el-form :inline="true" class="txetTopB">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-input
          v-model="list.siteName"
          placeholder="起始站点"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.orderStatus"
          placeholder="订单状态"
          class="centerSelInput centerInput"
        >
          <el-option
            v-for="item in (showVip == false?StatusList:StatusListVip)"
            :key="item.id"
            :label="item.staus"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          :clearable="false"
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          class="timePicker"
          @change="getTime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getsearchList">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancelB">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="list.loading"
      @getAjax="getTableData"
      v-if="showVip == false"
      ref="table"
    />
    <Table
      :data1="tableDataB"
      :columns1="columnsB"
      :total="list.total"
      :loading="list.loading"
      @getAjax="getTableData"
      v-else
      ref="table"
    />
    <el-dialog title="切换运营状态" :visible.sync="operationStatus" width="30%">
      <p>
        <span class="textcolor">当前状态</span>
        <span class="textcolor2">空闲</span>
      </p>
      <div>
        <span class="red">*</span><span class="textcolor">切换运营状态至</span>
        <el-radio v-model="radio" label="1">维修中</el-radio>
        <el-radio v-model="radio" label="2">运营下线</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="operationStatus = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-dialog title="切换运营模式" :visible.sync="operationMode" width="30%">
      <p>
        <span class="textcolor">当前车辆运营模式</span>
        <span class="textcolor2">普通模式</span>
      </p>
      <p>
        <span class="textcolor">是否切换运营模式至</span>
        <span class="textcolor2">vip模式?</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="operationMode = false">
          取 消
        </button>
      </span>
    </el-dialog>


    <!-- 弹窗 -->
    <!-- <el-drawer
      :title="
        detailList.orderStatus == 1
          ? '订单详情【已预约】'
          : '' || detailList.orderStatus == 2
          ? '订单详情【已取消】'
          : '' || detailList.orderStatus == 3
          ? '订单详情【已接单】'
          : '' || detailList.orderStatus == 4
          ? '订单详情【已上车】'
          : '' || detailList.orderStatus == 5
          ? '订单详情【已完成】'
          : ''
      "
      :visible.sync="detail"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <div>
          <p>
            车辆编号<span v-if="detailList.carTypeId == 1">【微公交】</span>
            <span v-else-if="detailList.carTypeId == 2">【SV-BUS】</span>
            <span v-else-if="detailList.carTypeId == 3">【观光车】</span>
            <span v-else-if="detailList.carTypeId == 4">【巡逻车】</span>
            <span v-else-if="detailList.carTypeId == 5">【清扫车】</span>
            <span v-else-if="detailList.carTypeId == 6">【智能抗冰车】</span>
          </p>
          <p class="carnum">{{ detailList.carNumber }}</p><br/>
          <p class="flex align-center">
            <img
              src="../../assets/images/bus_Route.png"
              alt=""
              width="21px"
              height="21px"
            />
            <span class="route_text">{{ detailList.routeName }}</span>
          </p>
            <el-row style="margin:2% 0">
              <el-col :span="10">
                    <div>
                      <img src="../../assets/images/bus_red.png" alt="" />
                      <span class="text">起点</span>
                    </div>
              </el-col>
              <el-col :span="10">
                    <div>
                      <img src="../../assets/images/bus_green.png" alt="" />
                      <span class="text">终点</span>
                    </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                 <span class="textB pointNameText">{{ detailList.startPointName }}</span>
              </el-col>
              <el-col :span="10">
                <span class="textB pointNameText">{{ detailList.endPointName }}</span>
              </el-col>
            </el-row>
          <p class="textB">
            <span class="textc">订单号:</span>
            <span class="textd">{{ detailList.orderNo }}</span>
          </p>
          <p>
            <span class="textc">车辆子类型：</span>
            <span class="textd">{{ detailList.sonCarType }}</span>
          </p>
          <p>
            <span class="textc">乘客手机号:</span>
            <span class="textd">{{ detailList.phone }}</span>
          </p>
          <p>
            <span class="textc">乘车人数:</span>
            <span class="textd">{{ detailList.peopleNum }}</span>
          </p>
          <p class="textB">
            <span class="textc">下单时间:</span>
            <span class="textd">{{ detailList.createTime }}</span>
          </p>
          <p>
            <span class="textc">上车时间:</span>
            <span class="textd">{{ detailList.boardingTime }}</span>
          </p>
          <p>
            <span class="textc" v-if="detailList.orderStatus == 5"
              >完成时间:</span
            >
            <span class="textc" v-else-if="detailList.orderStatus == 2"
              >取消时间:</span
            >
            <span class="textd">{{ detailList.completionTime }}</span>
          </p>
        </div>
      </div>

      <div class="demo-drawer__footer">
        <button class="centerBtn resetbtn" @click="detail = false">取消</button>
      </div>
    </el-drawer> -->
    <el-drawer :visible.sync="detail"  direction="rtl"  custom-class="demo-drawer"  ref="drawer">
      <div class="demo-drawer__content left_margin_60">
        <div>
          <div>
            <div class="left_line_blue"></div>
            <div class="left_line_title">
                <span v-if="detailList.orderStatus == 1">订单详情【已预约】</span>
                <span v-else-if="detailList.orderStatus == 2">订单详情【已取消】</span>
                <span v-else-if="detailList.orderStatus == 3">订单详情【已接单】</span>
                <span v-else-if="detailList.orderStatus == 4">订单详情【已上车】</span>
                <span v-else-if="detailList.orderStatus == 5">订单详情【已完成】</span>
              </div>    
                
          </div>
          <div class="carnum_title">
            车辆编号<span v-if="detailList.carTypeId == 1">【微公交】</span>
            <span v-else-if="detailList.carTypeId == 2">【SV-BUS】</span>
            <span v-else-if="detailList.carTypeId == 3">【观光车】</span>
            <span v-else-if="detailList.carTypeId == 4">【巡逻车】</span>
            <span v-else-if="detailList.carTypeId == 5">【清扫车】</span>
            <span v-else-if="detailList.carTypeId == 6">【智能抗冰车】</span>
            <p class="carnum">{{ detailList.carNumber }}</p><br/>
          </div>
          <div class="routeLine">
            <img  src="../../assets/images/orderDetail4.png" alt=""  width="21px"  height="21px" />
            <span class="route_text">{{ detailList.routeName }}</span>
          </div>
          <div>
              <div class="routeShow"><!-- 大盒子 做底部 -->
                <!-- 图标 -->
                <ul class="routerShow_item">
                  <li>
                     <img src="../../assets/images/orderDetail6.png" width="30px" height="30px"/>
                     <div>{{ detailList.startPointName}}</div>
                  </li>
                  <li>
                      <img src="../../assets/images/orderDetail2.png" width="30px" height="30px"/>
                  </li>
                  <li>
                    <img src="../../assets/images/orderDetail8.png" width="30px" height="30px"/>
                   <div>{{ detailList.endPointName }} </div>
                  </li>
                </ul>
              </div>
          </div> 
          <div class="infoShow">
              <el-row class="margin_top_2">
                <el-col :span="6" class="text_gray">订单号</el-col>
                <el-col :span="16" class="text_blck">{{ detailList.orderNo }}</el-col>
              </el-row>
              <el-row class="margin_top_2">
                <el-col :span="6" class="text_gray">车辆子类型</el-col>
                <el-col :span="16" class="text_blck">{{ detailList.sonCarType }}</el-col>
              </el-row>
              <el-row class="margin_top_2">
                <el-col :span="6" class="text_gray">乘客手机号</el-col>
                <el-col :span="16" class="text_blck">{{ detailList.phone }}</el-col>
              </el-row>
              <el-row class="margin_top_2">
                <el-col :span="6" class="text_gray">乘客人数</el-col>
                <el-col :span="16" class="text_blck">{{ detailList.peopleNum }}</el-col>
              </el-row>
               <el-row class="margin_top_2">
                <el-col :span="6" class="text_gray">下单时间:</el-col>
                <el-col :span="16" class="text_blck">{{ detailList.createTime }}</el-col>
              </el-row>
               <el-row class="margin_top_2">
                <el-col :span="6" class="text_gray">上车时间</el-col>
                <el-col :span="16" class="text_blck">{{ detailList.boardingTime }}</el-col>
              </el-row>
              <el-row class="margin_top_2">
                <el-col :span="6" class="text_gray">
                  <span   v-if="detailList.orderStatus == 5">完成时间</span>
                  <span   v-else-if="detailList.orderStatus == 2">取消时间</span>
                </el-col>
                <el-col :span="16" class="text_blck">{{ detailList.completionTime }}</el-col>
              </el-row>
          </div>
        </div>
      </div>

      <div class="demo-drawer__footer">
      </div>
    </el-drawer>
    <el-dialog title="取消" :visible.sync="cancel" width="30%">
      <span>确定取消订单？</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getodercace">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="cancel = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _MinBusOrderList,
  _MinBusOrderDetail,
  _MinBusOrdercancel,
} from "@/api/minBus";
export default {
  components: {
    Table,
  },
  data() {
    return {
      valueTime: [],// 时间数组
      loading: false,
      radio: "1",
      resetcarstatus: false,
      operationMode: false,
      showVip: false,
      detail: false,
      formLabelWidth: "100px",
      isAdd: false,
      operationStatus: false,
      cancel: false,
      StatusList: [
        { id: "1", staus: "已预约" },
        { id: "2", staus: "已取消" },
        { id: "4", staus: "已上车" },
        { id: "5", staus: "已完成" },
      ],
      StatusListVip: [
        { id: "1", staus: "已预约" },
        { id: "2", staus: "已取消" },
        { id: "3", staus: "已接单" },
        { id: "4", staus: "已上车" },
        { id: "5", staus: "已完成" },
      ],
      searchObj: {
        vehicleNumber: "",
        siteName: "",
        operationStatus: "",
      },
      detailList: [],
      tableData: [],

      columns: [
        {
          label: "订单号",
          prop: "orderNo",
        },
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },

        {
          label: "乘客手机号",
          prop: "phone",
        },
        {
          label: "起点站",
          prop: "startPointName",
        }, 
        {
          label: "终点站",
          prop: "endPointName",
        },
        {
          label: "下单时间",
          prop: "createTime",
        },
        {
          label: "行驶线路",
          prop: "routeName",
        },
        {
          label: "完成时间",
          prop: "completionTime",
        },
        // {
        //   label: "取消时间",
        //   prop: "cancelTime",
        // },
        {
          label: "状态",
          render: (h, params) => {
            if (params.row.orderStatus == 1) {
              return h("span", "已预约");
            } else if (params.row.orderStatus == 2) {
              return h("span", "已取消");
            } else if (params.row.orderStatus == 4) {
              return h("span", "已上车");
            } else if (params.row.orderStatus == 5) {
              return h("span", "已完成");
            } else if (params.row.orderStatus == 6) {
              return h("span", "已出发");
            }
          },
        },
        {
          label: "操作",
          width: 140,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationB",

                  on: {
                    click: () => {
                      this.detail = true;
                      this.getDetail(params.row.id);
                    },
                  },
                },
                "详情"
              ),
            ]);
          },
        },
      ],
      tableDataB: [],
      columnsB: [
        {
          label: "订单号",
          prop: "orderNo",
        },
        {
          label: "乘客手机号",
          prop: "phone",
          width: '110px'
        },
        {
          label: "下单时间",
          prop: "createTime",
        },
        {
          label: "完成时间",
          prop: "completionTime",
        },
        {
          label: "起点站",
          prop: "startPointName",
        },
        {
          label: "终点站",
          prop: "endPointName",
        },
        // {
        //   label: "分配车辆",
        //   prop: "assignVehicle",
        // },
        {
          label: "订单类型",
          render: (h, params) => {
            if (params.row.orderType == 1) {
              return h("span", "班车");
            } else if (params.row.orderType == 2) {
              return h("span", "VIP实时");
            } else if (params.row.orderType == 3) {
              return h("span", "VIP预约");
            }
          },
          width: '100px'
        },
        {
          label: "接单车辆编号",
          prop: "carNumber",
        },
        {
          label: "行驶线路",
          prop: "routeName",
        },
        {
          label: "状态",
          render: (h, params) => {
            if (params.row.orderStatus == 1) {
              return h("span", "已预约");
            } else if (params.row.orderStatus == 2) {
              return h("span", "已取消");
            } else if (params.row.orderStatus == 3) {
              return h("span", "已接单");
            } else if (params.row.orderStatus == 4) {
              return h("span", "已上车");
            } else if (params.row.orderStatus == 5) {
              return h("span", "已完成");
            } else if (params.row.orderStatus == 6) {
              return h("span", "已出发");
            }
          },
          width: '70px'
        },
        {
          label: "操作",
          width: 140,
          render: (h, params) => {
            if (params.row.orderStatus == 1 || params.row.orderStatus == 3) {
              return h("div", [
                h(
                  "span",
                  {
                    class: "operationB",
                    style: {
                      marginRight: "10px",
                    },
                    on: {
                      click: () => {
                        this.cancel = true;
                        this.orderId = params.row.id;
                      },
                    },
                  },
                  "取消订单"
                ),
                h(
                  "span",
                  {
                    class: "operationB",
                    on: {
                      click: () => {
                        this.detail = true;
                        this.getDetail(params.row.id);
                      },
                    },
                  },
                  "详情"
                ),
              ]);
            } else {
              return h("div", [
                h("span", "-"),
                h(
                  "span",
                  {
                    class: "operationB",
                    style: {
                      marginLeft: "20px",
                    },
                    on: {
                      click: () => {
                        this.detail = true;
                        this.getDetail(params.row.id);
                      },
                    },
                  },
                  "详情"
                ),
              ]);
            }
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        total: 0,
        carNumber: "",
        orderType: "1",
        siteName: "",
        orderStatus: "",
        loading: false,
        parkId: sessionStorage.getItem("parkId"),
        startTime: null,
        endTime: null
      },
      orderStatus: null,
      orderId: null,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getorder() {
      this.showVip = false;
      // this.list.orderType = "1";
      this.list = {
        page: 1,
        size: 10,
        total: 0,
        carNumber: "",
        orderType: "1",
        siteName: "",
        orderStatus: "",
        parkId: sessionStorage.getItem("parkId"),
        startTime: null,
        endTime: null
      };
      this.valueTime = []
      this.getList();
    },
    getsearchList(){
     this.$refs.table.listInterfaceQuery.page = 1;//搜索重置返回第一页
      this.list.page = 1;
      this.getList();
    },
    getorderVip() {
      this.showVip = true;
      // this.list.orderType = "2, 3";
      this.list = {
        page: 1,
        size: 10,
        total: 0,
        carNumber: "",
        orderType: "2,3",
        siteName: "",
        orderStatus: "",
        parkId: sessionStorage.getItem("parkId"),
        startTime: null,
        endTime: null
      };
      this.valueTime = []
      this.getList();
    },
    cancelB() {
      if (this.showVip == false) {
        this.list = {
          page: this.list.page,
          size: this.list.size,
          total: this.list.total,
          carNumber: "",
          orderType: "1",
          siteName: "",
          orderStatus: "",
          loading: false,
          parkId: sessionStorage.getItem("parkId"),
          startTime: null,
          endTime: null
        };
        this.valueTime = []
        this.getsearchList()
      } else {
        this.list = {
          page: this.list.page,
          size: this.list.size,
          total: this.list.total,
          carNumber: "",
          orderType: "2,3",
          siteName: "",
          orderStatus: "",
          loading: false,
          parkId: sessionStorage.getItem("parkId"),
          startTime: null,
          endTime: null
        };
        this.valueTime = []
        this.getsearchList()
      }

      // this.getList();
    },
    async getodercace() {
      let { success, data, message } = await _MinBusOrdercancel({
        orderId: this.orderId,
      });
      if (success) {
        this.getList()
      }
      this.cancel = false;
    },
    async getList() {
      this.list.loading = true;
      let { success, data, message } = await _MinBusOrderList(this.list);
      // console.log(data)

      if (success) {
        //脱敏
        for(var i =0; i <data.list.length;i++){
          if(data.list[i].phone != null){
              data.list[i].phone = data.list[i].phone.slice(0,3) + '****' + data.list[i].phone.slice(data.list[i].phone.length - 4)
          }
        }
        //乘客数
        this.tableData = data.list;
        this.tableDataB = data.list;
        this.list.total = data.total;
        this.list.loading = false;
      }
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      await this.getList();
    },
    async getDetail(id) {
      let { success, data, message } = await _MinBusOrderDetail({
        orderId: id,
      });
      // console.log(data)
      if (success) {
        if(data.phone != null){
            data.phone = data.phone.slice(0,3) + '****' + data.phone.slice(data.phone.length - 4)
        }
        this.detailList = data;
        if(this.detailList.orderType == 1){
          this.detailList.peopleNum = 1
        }
        this.loading = false;
      }
    },
    getTime(){
      if (this.valueTime != null) {
				this.list.startTime = this.valueTime[0]
				this.list.endTime  = this.valueTime[1]
			}
    }
  },
};
</script>

<style scoped>
.textcolor {
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  margin-right: 61px;
}
.statutext {
  color: rgba(22, 131, 217, 1);
  font-size: 16px;
  margin-right: 61px;
  cursor: pointer;
}
.statustext_bg {
  display: block;
  width: 60px;
  height: 14px;
  background: #1683d9;
  opacity: 0.39;
  filter: blur(5px);
  margin-top: -18px;
  margin-right: 61px;
}
.start {
  display: flex;
  margin-bottom: 30px;
  transform: translateY(10px);
}
.statustext_bgB {
  display: block;
  width: 60px;
  height: 14px;
  background: #1683d9;
  opacity: 0.39;
  filter: blur(5px);
  margin-top: -18px;
  margin-left: 124px;
}
.carnum {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.route_text {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
  text-align: center;
}
.text {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-left: 5px;
  margin-right: 136px;
}
.textB {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  margin-right: 139px;
  margin-top: 50px;
}
.textB:last-child {
  margin-right: 0px;
}
.textc {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.textd {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
.textA {
  margin-left: 24px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.mt20 {
  margin-bottom: 20px;
}
/* .txetTop {
  margin-top: 0px;
}*/
.txetTopB {
  margin-top: 10px;
}
.pointNameText{
  font-size:18px;

}



.left_margin_60{
  margin-left:2%;
}
.left_line_blue{
 height:1.8em;border:2px #108EFF solid;float:left;margin-right:3% ;border-radius: 4px;
}
.left_line_title{
  color:black;
  font-size:20px;
  font-weight: bold;
}


.carnum_title{
  margin-top:7%;
  margin-left:4%;
  font-size:16px;
  color:#4F525A;
}
.carnum {
  font-size: 20px;
  font-weight: bold;
  color: #303030;
}

.routeLine{
  margin-left:4%;
  box-sizing: border-box;
}
.routeLine>img{
  float: left;
}
.route_text{
  float: left;
  display: block;
  font-size: 18px;
  font-weight: bold;
}


.routeShow{
  margin:15% 0 0 4%;
  width:90%;
  height:39px;
  border:1px solid white;
  border-bottom:2px  dashed #BFC1C6;
}
.routerShow_item li{
  list-style-type: none;
  text-align: center;
}
.routerShow_item{
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-around;
} 
.infoShow{
  margin:15% 0 0 4%;
}
.margin_top_2{
  margin-top:2%;
}
.text_gray{
  
  color:#9A9EA7;
}
.text_black{
  color:#4F525A;
}


</style>
