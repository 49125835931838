<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="10"
      :loading="loading"
      :page="list.page"
    />
    <el-dialog title="查看呼叫地点" :visible.sync="delDia" width="60%">
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="delDiaA" width="30%">
      <span>确认删除SOS呼叫记录</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getdel">确 定</button>
        <button class="centerBtn resetbtn" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import { _PtBussosList, _PtBussosDel } from "@/api/patrolBus";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      radio: "1",
      delDia: false,
      delDiaA: false,
      showvideo: false,
      formLabelWidth: "100px",
      isAdd: false,
      value1: "",
      id: null,
      searchObj: {
        vehicleNumber: "",
        siteName: "",
        operationStatus: "",
      },
      valueTime: "",
      tableData: [
        {
          vehicleNumber: "SMR-Partol001",
          time: "2021-06-30 10:24:44",
        },
      ],

      columns: [
        {
          label: "车辆编号",
          prop: "vehicleNumber",
        },
        {
          label: "呼叫发起时间",
          prop: "callTime",
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.resetcarstatus = true;
                    },
                  },
                },
                "处理事件"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDia = true;
                    },
                  },
                },
                "查看位置"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDiaA = true;
                      this.id = params.row.id;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        carNumber: "",
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.valueTime = "";
      this.list = {
        page: 1,
        size: 10,
        carNumber: "",
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList()
    },
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.valueTime[0];
        this.list.endTime = this.valueTime[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    async getList() {
      let { success, data, message } = await _PtBussosList(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2(){
      this.list.page=1
      this.getList()
    },
    async getdel() {
      let { success, data, message } = await _PtBussosDel({ id: this.id });
      if (success) {
        this.loading = false;
        this.getList();
      }
      this.delDiaA = false;
    },
  },
};
</script>

<style scoped>
.el-input__inner {
  height: 36px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  border: 0px solid white !important;
  font-size: 14px;
}
/deep/ .el-input__inner {
  border-radius: 0px;
}
</style>