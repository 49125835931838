<template>
<div>
    <el-row class="margin_b">
        <el-col :span="4">
            <el-input v-model="list.taskNumber" placeholder="任务编号"  class="centerInput"></el-input>
        </el-col>
        <el-col :span="4">
            <el-input v-model="list.carNumber" placeholder="车辆编号"  class="centerInput"></el-input>
        </el-col>
        <el-col :span="4">
            <el-select v-model="list.taskType" placeholder="任务类型" class="centerSelInput ipwidth">
                <el-option v-for="item in ruleForm_taskType"  :key="item.value"  :label="item.label"  :value="item.value"></el-option>
            </el-select>
        </el-col>
        <el-col :span="4">
            <el-select v-model="list.workStatus" placeholder="作业状态" class="centerSelInput ipwidth">
                <el-option v-for="item in ruleForm_workStatus"  :key="item.value"  :label="item.label"  :value="item.value"></el-option>
            </el-select>
        </el-col>
        <el-col :span="5" >
              <el-date-picker v-model="searchTime" 
                type="daterange" 
                range-separator="-"  
                start-placeholder="开始日期"  
                end-placeholder="结束日期" 
                class="pickerTime"
                value-format="yyyy-MM-dd">
            </el-date-picker>
        </el-col>
        
    </el-row>
    <el-row class="margin_b">
        <el-col :span="24">
            <button class="centerCzbtn ml20" @click="searchData">搜索</button>
            <button class="centerBtn resetbtn" @click="clearData">重置</button>
            <button class="centerCzbtn ml20" @click="adddrawer = true">新增任务</button>
            <button class="centerCzbtn ml20" @click="loadtask">导入任务</button>
        </el-col>
    </el-row>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
  
<!-- 新增任务 / 编辑任务 -->
    <el-drawer @close="drawerClose" :title="adddrawerTitle" :visible.sync="adddrawer" :direction="direction"  size="40%" class="add-drawer">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
              <el-row>
                <el-col :span="12">
                    <el-form-item label="任务编号" prop="taskNumber">
                      <el-input v-model="ruleForm.taskNumber" placeholder="请输入" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="任务关联" prop="taskAssociation">
                      <el-input v-model="ruleForm.taskAssociation" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                    <el-form-item label="车辆编号" prop="carNumber" >
                       <el-input v-model="ruleForm.carNumber" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="任务类型" prop="taskType">
                        <el-select v-model="ruleForm.taskType" placeholder="请选择"  >
                            <el-option v-for="item in ruleForm_taskType"  :key="item.value"  :label="item.label"  :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                     <el-form-item label="目的地" prop="destination">
                        <!-- <el-input v-model="ruleForm.destination" placeholder="请输入"></el-input> -->
                        <el-select
                        v-model="ruleForm.destination"
                        filterable
                        remote
                        placeholder="请输入搜索项"
                        :remote-method="searchDes"
                        :loading="desIoading">
                        <el-option
                          v-for="item in desOptions"
                          :key="item.id"
                          :label="item.coordinateNumber"
                          :value="item.id">
                        </el-option>
                      </el-select>
                      </el-form-item>
                </el-col>
                <el-col :span="12">
                   <el-form-item label="载物类型" prop="loadType">
                     <el-select v-model="ruleForm.loadType" placeholder="请选择"  >
                            <el-option v-for="item in ruleForm_loadType"  :key="item.value"  :label="item.label"  :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                      <el-form-item label="集装箱ID" prop="containerId">
                        <el-input v-model="ruleForm.containerId" placeholder="请输入"></el-input>
                      </el-form-item>
                </el-col>
                <el-col :span="12">     
                    <el-form-item label="港机类型" prop="portMachineryType">
                      <el-input v-model="ruleForm.portMachineryType" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                      <el-form-item label="港机编号" prop="portMachineryNo">
                        <el-input v-model="ruleForm.portMachineryNo" placeholder="请输入"></el-input>
                      </el-form-item>
                </el-col>
                <el-col :span="12">           
                    <!-- <el-form-item label="作业状态" prop="workStatus">
                      <el-select v-model="ruleForm.workStatus" placeholder="请选择"  >
                            <el-option v-for="item in ruleForm_workStatus"  :key="item.value"  :label="item.label"  :value="item.value"></el-option>
                      </el-select>
                    </el-form-item> -->
                    <el-form-item label="作业类型" prop="workType">
                      <el-select v-model="ruleForm.workType" placeholder="请选择"  >
                            <el-option v-for="item in ruleForm_workType"  :key="item.value"  :label="item.label"  :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <!-- <el-col :span="12">       
                    <el-form-item label="作业类型" prop="workType">
                      <el-select v-model="ruleForm.workType" placeholder="请选择"  >
                            <el-option v-for="item in ruleForm_workType"  :key="item.value"  :label="item.label"  :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col> -->
                <el-col :span="12">                 
                    <el-form-item label="接收任务时间" prop="workReceivingTime">
                        <el-date-picker  v-model="ruleForm.workReceivingTime" value-format="yyyy-MM-dd HH:mm:ss"  type="datetime"  placeholder="例:2022-3-29 10:00:00"></el-date-picker>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">                 
                    <!-- <el-form-item label="作业时长" prop="workingHours">
                      <el-input v-model="ruleForm.workingHours" placeholder="例:2分27秒"></el-input>
                    </el-form-item> -->
                </el-col>
              </el-row>              
              <el-form-item>
                <el-button type="primary" v-if="adddrawerTitle == '新增任务'" @click="addtaskForm('ruleForm')">确认</el-button>
                <el-button type="primary" v-else @click="updateTask('ruleForm')">确认</el-button>
                <el-button @click="adddrawer = false">取消</el-button>
              </el-form-item>
            </el-form>
    </el-drawer>
  <!-- 删除弹窗 -->
    <el-dialog title="删除" :visible.sync="delDiaA" width="30%">
      <span>确认删除这条记录?</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn ml20" @click="getDel">确 定</button>
        <button class="centerBtn resetbtn" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>


<!-- 导入任务 -->
    <el-drawer title="导入任务" :visible.sync="loaddrawer" :direction="direction"  @close="handleCloseLoaddrawer">
      <el-upload	drag	class="upload-demo"	action="#"	:auto-upload="false"	:file-list="importtaskConfig.taskFileList"
				:on-change="handleSelectUploadFile"
        multiple
        :limit="1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip flex justify-between" slot="tip">
          <span class="red">注：请参照模版，批量导入任务。</span>
          <span class="textCol" style="cursor: pointer;" @click="handleDownloadImportTransportTemplate">下载模板</span>
        </div>
      </el-upload>
      <div style="margin-top: 20%" class="flex justify-center">
        <button class="centerCzbtn" @click="handleImporttask" v-if="!importtaskConfig.isImport"  :loading="true">上传</button>
        <button class="centerBtn resetbtn" v-else>上传</button>
      </div>
    </el-drawer>

 </div>   
</template>

<script>

import  Table  from '../../components/Table.vue'
import { 
  _TransportTaskList,
  _TaskInsert,
  _TaskUpdate,
  _TaskDelete,
  _DownloadImportTransportTemplate,
  _ImportTransport,
  // _ExecuteTask,
  _GetTaskExecuteResult
} from '@/api/transportCar/transportTaskMng.js'
import {_ExecuteTask} from '@/api/transportCar'
import {_GetList} from '@/api/transportCar/transportMapMng.js'
export default {
  components: {
   Table
  },
  data() {
    //表单验证 是否为数字
    var Num = (rule, value, callback) => {
      const reg = /^[0-9]*$/;
      if (value == "" || value == undefined || value == null) {
        callback();
      } else {
        if (!reg.test(value) && value != "") {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      }
    };

    return {
        desOptions: [],// 目的地列表
        desIoading: false, //目的地加载
        cancelAjax: null,
        timer: null , // 定时器
        id: null,//某一项id
        delDiaA: false,//删除确认弹窗
        adddrawerTitle: "新增任务",
        parkId:null,
        loading: false,//列表加载动效
        loaddrawer: false,//导入任务弹窗
        adddrawer:false,//添加任务弹窗
        direction: 'rtl',//打开方式
        dialog_parkname:null,
        handleClose:false,
        //上传配置
        importtaskConfig: {
          visible: false,
          isImport: false,
          taskFileList: [],
        },
         //表格数据
         tableData:[],
        //新增任务表单
         ruleForm: {
            taskNumber: '',//任务编号
            taskAssociation: '',//任务关联
            carNumber: '',//车辆编号
            taskType:'',//任务类型
            destination:'',//目的地
            loadType:'',//载物类型
            containerId:'',//集装箱ID
            portMachineryType:'',//港机类型
            portMachineryNo:'',//港机编号
            workType:'',//作业类型
            workStatus:'',//作业状态
            workReceivingTime:'',//接受任务时间
            workingHours:'',//作业时长
            parkId: sessionStorage.getItem("parkId"),
        },
        rules: {
          taskNumber: [
            { required: true, message: '请输入任务编号', trigger: 'blur' },
              {
                required: true,
                validator: Num, //在data里定义 ( 重 点 )
                trigger: "blur",
                message: "请输入数字",
              },
          ],
          taskAssociation: [
            { required: true, message: '请输入任务关联', trigger: 'blur' },
              {
                required: true,
                validator: Num, //在data里定义 ( 重 点 )
                trigger: "blur",
                message: "请输入数字",
              },
          ],
          carNumber: [
            { required: true, message: '请输入车辆编号', trigger: 'blur' }
          ],
          taskType: [
            { required: true, message: '请选择任务类型', trigger: 'change' },
          ],
          destination: [
            { required: true, message: '请输入目的地', trigger: 'blur' },
          ],
          loadType: [
            { required: true, message: '请选择载物类型', trigger: 'change' },
          ],
          containerId: [
            { required: true, message: '请输入集装箱编号', trigger: 'blur' },
          ],
          portMachineryType: [
            { required: true, message: '请输入港机类型', trigger: 'blur' },
          ],
          portMachineryNo: [
            { required: true, message: '请输入港机编号', trigger: 'blur' },
              {
                required: true,
                validator: Num, //在data里定义 ( 重 点 )
                trigger: "blur",
                message: "请输入数字",
              },
          ],
          workType: [
            { required: true, message: '请选择作业类型', trigger: 'change' },
          ],
          workStatus: [
            { required: true, message: '请选择作业状态', trigger: 'change' },
          ],
          workReceivingTime: [
            { required: true, message: '请选择接收时间', trigger: 'change' },
          ],
          workingHours: [
            { required: true, message: '请输入作业时间', trigger: 'blur' },
          ],
        },
        //任务类型
        ruleForm_taskType:[
           {
              label:"去堆场",
              value:"1"
            },
            {
              label:"去停车点",
              value:"2"
            },
            {
              label:"去缓冲区",
              value:"3"
            },
            {
              label:"去桥吊（动态）",
              value:"4"
            },
            // {
            //   label:"去充电",
            //   value:"5"
            // },
            {
              label:"上扭锁（动态）",
              value:"6"
            },
            {
              label:"解扭锁（动态）",
              value:"7"
            },
            // {
            //   label:"遥控任务接管",
            //   value:"8"
            // },
        ],
        //载物类型
        ruleForm_loadType:[
           {
              label:"大箱",
              value:"1"
            },
            {
              label:"小箱",
              value:"2"
            },
            {
              label:"小箱、小箱",
              value:"3"
            },
        ],
        //作业状态
        ruleForm_workStatus:[
                {
                  label:'到达途经点1',
                  value:'1'
                },
                {
                  label:'到达途经点2',
                  value:'2'
                },
                {
                  label:'到达目的地',
                  value:'3'
                },
                {
                  label:'开始充电',
                  value:'13'
                },
                {
                  label:'完成装箱',
                  value:'21'
                },
                {
                  label:'完成卸箱',
                  value:'22'
                },
                {
                  label:'完成充电',
                  value:'23'
                },
                {
                  label:'装箱失败（第一个双小箱触碰到车辆中心线）',
                  value:'37'
                },
                {
                  label:'在辅助对位引导下对位完成',
                  value:'38'
                },
                {
                  label:'车辆停稳锁死',
                  value:'31'
                },
                {
                  label:'车辆解除锁死',
                  value:'32'
                },
                {
                  label:'任务开始',
                  value:'41'
                },
                {
                  label:'任务接收成功',
                  value:'42'
                },
                {
                  label:'AD系统待命',
                  value:'43'
                },
                {
                  label:'作业终止，AD系统静默',
                  value:'44'
                },
        ],
        //作业类型
        ruleForm_workType:[
          {
            value:'1',
            label:'装箱'
          },
          {
            value:'2',
            label:'卸箱'
          },
          {
            value:'3',
            label:'充电'
          },
          {
            value:'4',
            label:'扭锁安装'
          },
          {
            value:'5',
            label:'扭锁卸载'
          },
          {
            value:'6',
            label:'停车'
          },
          {
            value:'7',
            label:'掉头'
          },
          {
            value:'8',
            label:'OBU任务接管'
          },


        ],
        searchTime : [],//搜索项数组
        list: {
            page: 1,
            size: 10,
            total: 0,
            // searchTime:[],
            startTime:null,//开始时间
            endTime:null,//结束时间
            taskType:null,//任务类型
            workStatus:null,//作业状态
            carNumber:null,//车辆编号'
            taskNumber:null,//任务编号
            parkId: sessionStorage.getItem("parkId"),
        },
        columns:[
        {
          label: "任务编号",
          prop: "taskNumber",
          fixed:true,
          width:100
        },
        {
          label: "任务关联",
          prop: "taskAssociation",
          width:100
        },
        {
          label: "任务接受时间",
          prop: "workReceivingTime",
          width:120
        },
        {
          label: "车辆编号",
          prop: "carNumber",
           width:100
        },
        {
          label: "任务类型 ",
          prop: "taskType",
          render: (h, params) => {
            let label = '-'
            for (let i = 0; i < this.ruleForm_taskType.length; i++) {
              if(this.ruleForm_taskType[i].value == params.row.taskType){
                label = this.ruleForm_taskType[i].label
                break
              }
            }
            return h("span", label)
          }
        },
        {
          label: "目的地",
          prop: "destination",
        },
        {
          label: "载物类型",
          prop: "loadType",
          render: (h, params) => {
            let label = '-'
            for (let i = 0; i < this.ruleForm_loadType.length; i++) {
              if(this.ruleForm_loadType[i].value == params.row.loadType){
                label = this.ruleForm_loadType[i].label
                break
              }
            }
            return h("span", label)
          }
        },
        {
          label: "集装箱Id",
          prop: "containerId",
        },
        {
          label: "港机类型",
          prop: "portMachineryType",
        },
        {
          label: "港机编号",
          prop: "portMachineryNo",
        },
        {
          label: "作业类型",
          prop: "workType",
          render: (h, params) => {
            let label = '-'
            for (let i = 0; i < this.ruleForm_workType.length; i++) {
              if(this.ruleForm_workType[i].value == params.row.workType){
                label = this.ruleForm_workType[i].label
                break
              }
            }
            return h("span", label)
          }
        },
        {
          label: "作业状态",
          prop: "workStatus",
          render: (h, params) => {
            let label = "-"
            for (let i = 0; i < this.ruleForm_workStatus.length; i++) {
              if(this.ruleForm_workStatus[i].value == params.row.workStatus){
                label = this.ruleForm_workStatus[i].label
                break
              }
            }
            return h("span", label)
          }
        },
        {
          label: "作业时长",
          prop: "workingHours",
          render: (h, params) => {
            let n = Number(params.row.workingHours)
            let label = "-"
            if(n){
              // 毫秒转分钟
              label = (params.row.workingHours / (1000 * 60)).toFixed(1) + " min"
            }
            return h("span", label)
          }
        },
        {
          fixed:"right",
          label: "操作项",
          width:260,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.ruleForm = JSON.parse(JSON.stringify(params.row))
                      this.adddrawerTitle = "编辑任务"
                      this.adddrawer = true
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.id = params.row.id
                      this.delDiaA = true;
                    },
                  },
                },
                "删除"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.$message({
                        message: '该功能暂未开放',
                        type: 'warning'
                      });
                    },
                  },
                },
                "查看线路"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.lineIssued(params.row)
                    },
                  },
                },
                params.row.isExecuted == 0 ? "路径下发" : "再次下发" 
              ),
            ]);
          },
        },
        ],
        
    };
  },
  created(){
        
  },
  mounted() {
    this.getNowFormatDate() 
    this.getList()
    this.getparkId()
    // this.setTimer()
  },

  methods: {
    // 添加定时器
    setTimer(params){
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.getList(true,params)
      }, 5000);
    },
      //m默认时间
      getNowFormatDate() {
           //获取当前日期
            var myDate = new Date();
            var nowY = myDate.getFullYear();
            var nowM = myDate.getMonth()+1;
            var nowD = myDate.getDate();
            //获取三十天前日期
            var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);//最后一个数字30可改，30天的意思
            var lastY = lw.getFullYear();
            var lastM = lw.getMonth()+1;
            var lastD = lw.getDate();
            this.searchTime=[]
            this.searchTime.push(lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD));
            this.searchTime.push(nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD));   
    },
    getparkId(){
        this.parkId=sessionStorage.getItem("parkId")
    },
    //获取页面数据
    async getList(e,obj) {
      this.list.startTime = this.searchTime?.[0]
      this.list.endTime = this.searchTime?.[1]
      // 重复请求就取消
      if (typeof this.cancelAjax === 'function') {
        this.cancelAjax()
      }
      // 定时器刷新不loading
      e || (this.loading = true)
      // 非定时器清除timer
      e || clearInterval(this.timer)
      // 定时器请求搜索值没变之前的数据
      let params = obj ? obj : this.list
      try {
        let { success, data } = await _TransportTaskList(params, this);
        if (success) {
          this.tableData = data.list;
          this.list.total = data.total;
        }
        e || (this.loading = false)
        e || this.setTimer(JSON.parse(JSON.stringify(this.list)))
      } catch (error) {
        e || (this.loading = false)
        this.tableData = []
        this.list.total = 0
      }
    },
    //获取目的地数据
    async searchDes(query){
      if (query !== '') {
        this.desIoading = true;
        try {
          let {success, data} = await _GetList({
            page:1,
            size:1000,
            parkId:sessionStorage.getItem("parkId"),
            coordinateNumber:query
          })
          if(success){
            this.desOptions = data.list.filter(item => {
              return item.coordinateNumber.toLowerCase()
                .indexOf(query.toLowerCase()) != -1;
            });
            this.desIoading = false;
          }
        } catch (error) {
          this.desIoading = false;
          this.desOptions = []
        }
      } else {
        this.desOptions = [];
      }
    }, 
  //新增任务
   addtaskForm(ruleForm){
      //验证 验证过就可以发送请求
      this.$refs[ruleForm].validate(async(valid) => {
          if (valid) {
            let { message, success} = await _TaskInsert(this.ruleForm)
            this.adddrawer = false
            if(success){
              this.list.page = 1
              this.getList()
              this.$message.success(message);
            }
          } else {
            return false;
          }
        })
   },  
  //  编辑任务
  updateTask(ruleForm){
      //验证 验证过就可以发送请求
    this.$refs[ruleForm].validate(async(valid) => {
      if (valid) {
        let { message, success} = await _TaskUpdate(this.ruleForm)
        this.adddrawer = false
        if(success){
          this.getList()
          this.$message.success(message);
        }
      } else {
        return false;
      }
    })
  },
  //导入任务
  loadtask(){
    this.loaddrawer = true;
  },
//获取数据
    searchData(){
      this.list.page = 1;
      this.getList()
    },
    clearData(){
      // 搜索项重置
      Object.assign(this.list, this.$options.data().list)
      //时间默认
      this.getNowFormatDate()
      this.getList()
    },
    // 上传抽屉关闭回调
    handleCloseLoaddrawer(){
			this.importtaskConfig.visible = false;
			this.importtaskConfig.taskFileList = [];
			this.importtaskConfig.isImport = false;
    },
    handleSelectUploadFile(file, fileList){
      this.importtaskConfig.taskFileList = fileList;
    },
    //上传任务
    handleImporttask(){
      if(this.importtaskConfig.taskFileList[0]){
        const loading = this.$loading({
          lock: true,
          text: '导入中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let file = new FormData();
        file.append("file", this.importtaskConfig.taskFileList[0].raw);
        file.append("parkId ", this.list.parkId);
        this.importtaskConfig.isImport = true;
        _ImportTransport(file).then((res) => {
          if(res != undefined){
            if (res.success == true) {
              this.handleCloseLoaddrawer();
              this.$message.success(res.message);
              this.getList();
              loading.close();
             this.importtaskConfig.isImport = false;
            }   
          }else{
             loading.close();
             this.importtaskConfig.isImport = false;
          } 
         }).catch((error)=>{
           loading.close();
           this.handleCloseLoaddrawer()
           if(error.name.includes("TypeError")){
             this.$message({
               showClose: true,
               message: "文件已修改，请重新上传",
               type: 'error'
             });
           }
         })
      }else{
        this.$message({
          showClose: true,
          message: '没有上传文件',
          type: 'error'
        });
      }
		},
    // 下载模板
    handleDownloadImportTransportTemplate(){
			_DownloadImportTransportTemplate().then((response) => {
				const url = window.URL.createObjectURL(
					new Blob([response], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					})
				);
				const link = document.createElement("a");
				link.style.display = "none";
				link.href = url;
				link.download = "商用车任务管理导入模板.xlsx";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      await this.getList();
    },
    //删除
    async getDel() {
      let { success, message} = await _TaskDelete({id:this.id})
      if (success) {
        this.getList();
        this.$message.success(message);
      }
      this.delDiaA = false;
    },
    // 线路下发
    async lineIssued(data){
      await this.$confirm(
        `确认将任务下发至车辆【<b>${data.carNumber}</b>】？`,
        "任务下发",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          dangerouslyUseHTMLString: true
        }
      )
      let loading = this.$loading({
        lock: true,
        text: "指令下发中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let order = {
        cmdType: 1,
        timeout: 15,
        carNumber: data.carNumber,
        params:{
          taskId: data.id,
        }
      }
      try {
        let {success, message} = await _ExecuteTask(order)
        if(success){
          this.$message.success(message);
          // await this.getResult(data.id, loading)
          this.getList();
        }
      } catch (error) {
        loading.close();
        this.getList();
      }
    },
    // 获取执行结果-4-13弃用
    getResult(id, loading) {
      return new Promise((resolve, reject) => {
        let timer = setInterval(async() => {
          try {
            let res = await _GetTaskExecuteResult({ id, timeout: 15 })
            let nowTime = new Date().getTime();
            let { success, data } = res;
            if (success) {
              let startTime = new Date(data.startTime).getTime() + 15000;
              if (nowTime < startTime && data.operationResult == 0) {
                // 操作失败
                clearInterval(timer);
                loading.close();
                this.$message.warning("任务执行失败");
                resolve();
                return
              } else if (nowTime < startTime && data.operationResult == 2) {
                clearInterval(timer);
                loading.close();
                this.$message.success("任务执行成功");
                resolve();
                return
              } else if (nowTime >= startTime) {
                clearInterval(timer);
                loading.close();
                this.$message.warning("车辆无响应，执行超时");
                resolve();
                return
              }
            }
          } catch (error) {
            reject();
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    // 抽屉关闭
    drawerClose(){
      // 清除表单报错
      this.$refs.ruleForm.resetFields();
      // 恢复数据
      // Object.assign(this.ruleForm, this.$options.data().ruleForm)
      this.ruleForm = {
          taskNumber: '',//任务编号
          taskAssociation: '',//任务关联
          carNumber: '',//车辆编号
          taskType:'',//任务类型
          destination:'',//目的地
          loadType:'',//载物类型
          containerId:'',//集装箱ID
          portMachineryType:'',//港机类型
          portMachineryNo:'',//港机编号
          workType:'',//作业类型
          workStatus:'',//作业状态
          workReceivingTime:'',//接受任务时间
          workingHours:'',//作业时长
          parkId: sessionStorage.getItem("parkId"),
      }
      this.adddrawerTitle = "新增任务"
      // 清空目的地选择
      this.desOptions = []
    }
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
};
</script>
<style scoped lang="less">
.textcolor {
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  margin-right: 0px;
}
.statutext {
  color: rgba(22, 131, 217, 1);
  font-size: 16px;
  margin-right: 61px;
  cursor: pointer;
}
.second_row{
    margin:1% 0 1% 0;
}
.pickerTime{border:0px solid black; }
.margin_b{
  margin-bottom: 2%;
}
.centerSelInput{
  height:30px;
}
.textCol {
  color: #1683d9;
}
/deep/ .colorB {
  /* color: red; */
  /* -webkit-touch-callout: none; */
  margin-right: 20px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar:horizontal {
    width: 20px;
    height:15px;
}
/deep/.is-scrolling-left~.el-table__fixed,
/deep/.is-scrolling-middle~.el-table__fixed,
/deep/.is-scrolling-right~.el-table__fixed{
  bottom: 15px !important;
}
/deep/.is-scrolling-left~.el-table__fixed-right,
/deep/.is-scrolling-middle~.el-table__fixed-right,
/deep/.is-scrolling-right~.el-table__fixed-right{
  bottom: 15px !important;
}
/deep/ .centerSelInput > div > input {
    width: 1.25rem;
    height: 0.1875rem;
    background: #ffffff;
    box-shadow: 0 0.020833rem 0.083333rem 0 rgb(0 0 0 / 8%);
    border: 1 solid black !important;
    font-size: 0.072917rem;
}
.el-form-item .el-select,
.el-form-item .el-date-editor {
  width: 100%;
}
/deep/.add-drawer .el-input__inner {
  border: 1px solid #dcdfe6 !important;
}
/deep/.disabledBtn{
  cursor: not-allowed;
  color: #ccc;
  margin-right: 20px;
}
</style>
