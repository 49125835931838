<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          clearable
          v-model.trim="searchObj.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          clearable
          v-model.trim="searchObj.carVin"
          placeholder="车牌号"
          class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          no-match-text="行驶路线"
          clearable
          v-model.trim="searchObj.carRouteId"
          @change="getTableData"
          placeholder="行驶路线"
          class="centerSelInput centerInput"
        >
          <el-option
            v-for="item in vehStateList"
            :key="item.id"
            :label="item.routeName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          clearable
          v-model.trim="searchObj.carTypeId"
          @change="getTableData"
          placeholder="车辆类型"
          class="centerSelInput centerInput"
        >
          <el-option
            v-for="item in cartypelist"
            :key="item.id"
            :label="item.typeName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <button type="button" class="centerCzbtn" @click="getTableData2">
          搜索
        </button>
        <button type="button" class="centerBtn resetbtn" @click="reset">
          重置
        </button>
      </el-form-item>
    </el-form>
    <div>
      <Table
        :data1="tableData"
        :columns1="columns"
        :total="searchObj.total"
        :loading="loading"
        @getAjax="getTableData"
        :page="searchObj.page"
      />
      <el-drawer
        :title="carNumber"
        :visible.sync="isSetRoute"
        direction="rtl"
        custom-class="demo-drawer"
        ref="drawer"
        destroy-on-close
      >
        <div class="demo-drawer__content">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="线路" prop="carRouteId">
              <el-select
                v-model.trim="ruleForm.carRouteId"
                placeholder="行驶路线"
                class="centerSelInput centerInput"
              >
                <el-option
                  v-for="item in vehStateList"
                  :key="item.id"
                  :label="item.routeName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="demo-drawer__footer">
          <!-- Set line -->
          <el-button
            type="primary"
            class="centerCzbtn"
            :loading="blSetLineLoading"
            @click="setLine"
            >确认</el-button
          >
          <button class="centerBtn resetbtn" @click="isSetRoute = false">
            取消
          </button>
        </div>
      </el-drawer>

      <el-drawer
        title="设置站点启停"
        :visible.sync="isStartStop"
        direction="rtl"
        custom-class="demo-drawer"
        ref="drawer"
        size="30%"
        wrapperClosable
      >
        <div class="demo-drawer__content startStopContent">
          <div class="contentHead">
            <div class="startStopContentHead">站点</div>
            <div class="startStopContentHead">站点启停状态</div>
          </div>
          <div
            v-for="(item, index) in siteList"
            class="siteListFlex"
            :key="index"
          >
            <div>{{ item.siteName }}</div>
            <el-switch
              v-model="item.enable"
              :active-text="item.enable ? '停' : '不停'"
              active-color="#1683D9"
              inactive-color="#DCDFE6"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </div>
        </div>

        <div class="demo-drawer__footer">
          <button class="centerCzbtn" @click="getms">保存</button>
          <button class="centerBtn resetbtn" @click="isStartStop = false">
            取消
          </button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _SelectCarRouteForCar,
  _FindByAll,
  _FindRouteWithPark,
  _UpdateCarOperationRoute,
  _FindOne,
  _UpdateRouteSiteList,
} from "@/api/equipmentMonitoring";

import { _CarType } from "@/api/systemMng";
import qs from "qs";

export default {
  components: {
    Table,
  },
  data() {
    return {
      isStartStop: false,
      isSetRoute: false,
      blSetLineLoading: false,
      loading: false,
      total: 0,
      carRouteId: "",
      carNumber: "",
      rowData: [],
      siteList: [],
      tableData: [],
      vehStateList: [],
      cartypelist: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆VIN",
          prop: "carVin",
        },
        {
          label: "车辆类型",
          prop: "typeName",
        },
        {
          label: "车辆子类型",
          prop: "sonTypeName",
        },

        {
          label: "车辆运营模式",
          prop: "isManned",
          render: (h, params) => {
            if (params.row.isManned == 1) {
              return h("div", "载人");
            } else {
              return h("div", "非载人");
            }
          },
        },
        {
          label: "车辆牌证",
          prop: "carLicense",
        },
        {
          label: "行驶线路",
          prop: "routeName",
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            let objSetLine = h(
              "span",
              {
                class: "operationA",
                on: {
                  click: () => {
                    this.isSetRoute = true;
                    this.rowData = params.row;
                    this.carNumber = "设置线路【" + params.row.carNumber + "】";
                    console.log(this.vehStateList);
                    if (this.vehStateList.length > 0) {
                      let newA = this.vehStateList.filter(
                        (e) => e.id == params.row.carRouteId
                      );
                      if (newA.length > 0) {
                        this.ruleForm.carRouteId = params.row.carRouteId;
                      } else {
                        this.ruleForm.carRouteId = "";
                      }
                    }
                    // this.$refs.ruleForm.clearValidate();
                  },
                },
              },
              "设置线路"
            );

            let objSetSite = h(
              "span",
              {
                class: "operationA",
                on: {
                  click: () => {
                    this.isStartStop = true;
                    this.rowData = params.row;
                    window.sessionStorage.setItem(
                      "Number",
                      params.row.carNumber
                    );
                    this.$nextTick(() => {
                      this.lookDetail();
                    });
                  },
                },
              },
              "设置站点启停"
            );

            let RowAction = [];

            if (params.row.carRouteId === null) {
              RowAction = [objSetLine];
            } else {
              RowAction = [objSetLine, objSetSite];
            }

            return h("div", RowAction);
          },
        },
      ],
      consumptionRouteList: [
        {
          value: 1,
          label: "行驶路线",
        },
        {
          value: 2,
          label: "行驶路线2",
        },
        {
          value: 3,
          label: "行驶路线4",
        },
      ],
      typeList: [
        {
          label: "类型1",
          value: 0,
        },
        {
          label: "类型2",
          value: 1,
        },
        {
          label: "类型3",
          value: 2,
        },
      ],
      defaultProps: {
        children: "children",
        label: "typeName",
        value: "id",
        checkStrictly: true,
      },
      searchObj: {
        carNumber: "",
        carVin: "",
        carRouteId: "",
        carTypeId: "",
        total: 0,
        page: 1,
        size: 10,
        parkId: sessionStorage.getItem("parkId"),
      },
      ruleForm: {
        carRouteId: "",
      },
      rules: {
        carRouteId: [
          {
            required: true,
            message: "请选择行驶路线",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.getTableData();
    this.getCarType();
    this.getRoute();
    this.getRouteList();
  },
  methods: {
    // 查看详情
    async lookDetail() {
      let { success, data, message } = await _FindOne({
        carRouteId: this.rowData.carRouteId,
      });
      console.log(data);
      if (success) {
        this.siteList = data.routeSiteList;
      }
    },
    // 设置线路
    async setLine() {
      var that = this;

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.blSetLineLoading = true;

          _UpdateCarOperationRoute({
            carRouteId: this.ruleForm.carRouteId,
            carOperationId: this.rowData.coId,
          }).then((res) => {
            try {
              if (res) {
                if (res.success) {
                  this.$message.success("设置成功");
                  this.isSetRoute = false;
                  this.getTableData();
                } else {
                  this.$message.error(res.message);
                }
              }
            } catch (e) {
              that.blSetLineLoading = false;
            } finally {
              that.blSetLineLoading = false;
            }
          });
        }
      });
    },
    //获取线路列表
    async getRouteList() {
      let { success, data, message } = await _FindRouteWithPark({
        parkId: sessionStorage.getItem("parkId"),
        page: 1,
        size: 10,
      });
      if (success) {
        this.vehStateList = data;
      }
    },
    // 重置
    reset() {
      this.searchObj = {
        carNumber: "",
        carVin: "",
        carRouteId: "",
        carTypeId: "",
        parkId: sessionStorage.getItem("parkId"),
        page: 1,
        size: 10,
        total: this.searchObj.total,
      };
      this.getTableData();
    },
    // getchangF(eF) {
    //   if (eF == true) {
    //     this.valueF = true;
    //   } else {
    //     this.valueF = false;
    //   }
    // },
    // 获取所有路线、
    async getRoute() {
      let { success, data, message } = await _FindByAll({
        parkId: sessionStorage.getItem("parkId"),
      });
      console.log(data);
      if (success) {
      }
    },

    // 获取所有车辆类型
    async getCarType() {
      _CarType().then((res) => {
        if (res.success == true) {
          this.cartypelist = res.data.list;
        }
      });
    },
    //获取列表信息
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page;
          this.searchObj.size = pagination.limit;
        }else{
          this.searchObj.page = 1;
        }
      }
      let { success, data, message } = await _SelectCarRouteForCar(
        this.searchObj
      );
      if (success) {
        this.tableData = data.list;
        this.searchObj.total = data.total;
      }
      this.loading = false;
    },
    // getchangG(e) {
    //   if (e == true) {
    //     this.valueG = true;
    //   } else {
    //     this.valueG = false;
    //   }
    // },
    //搜索
    async getTableData2() {
      this.loading = true;
      this.searchObj.page = 1
      let { success, data, message } = await _SelectCarRouteForCar(
        this.searchObj
      );
      if (success) {
        this.tableData = data.list;
        this.searchObj.total = data.total;
      }
      this.loading = false;
    },
    //设置站点启停
    async getms() {
      console.log(this.siteList);
      // let { success, data, message } = await _UpdateRouteSiteList({
      //   carNumber: this.rowData.carNumber,
      //   params: this.siteList,
      // });

      // let objParams = [];

      // for (const item of this.siteList) {
      //   objParams.push({
      //     id: item.id,
      //     carRouteId: item.carRouteId,
      //     siteNumber: item.siteNumber,
      //     enable: item.enable,
      //   });
      // }

      // objParams = [
      //   {
      //     id: "51ca2eadfa434a33acf5d5f32ee2d94a",
      //     carRouteId: "bf13d8b310bf4a5285716a83b3f3cb30",
      //     siteNumber: 17,
      //     enable: 1,
      //   },
      // ];

      let { success, data, message } = await _UpdateRouteSiteList(
        this.siteList
      );
      if (success) {
        this.$message.success("设置成功");
        this.isStartStop = false;
        this.getTableData();
      } else {
        this.$message.error(message);
      }
      // this.$message({
      //   message: "此功能暂未开放",
      //   type: "warning",
      // });
    },
    getMsg() {
      this.$message.error("测试提示");
    },
  },
};
</script>

<style>
.startStopContent {
  /* display: flex;
    flex-wrap: wrap; */
  font-size: 14px;
  color: #333;
}

.contentHead {
  display: flex;
  justify-content: space-between;
}

.contentHead > div {
  width: 50%;
}

.startStopContent > div {
  /* width: 50%;
    text-align: left; */
}

.startStopContentHead {
  color: #333;
  font-size: 15px;
  font-weight: 550;
}

.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}

.siteListFlex {
  display: flex;
}

.siteListFlex > div {
  width: 50%;
  margin-top: 30px;
}
</style>
