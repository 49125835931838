<template>
  <div>
    <div style="margin-bottom: 20px">
      <button class="centerBtn" @click="getadd">新增</button>
      <button class="centerBtn" @click="handleOpenImportCameraDrawer">
        导入
      </button>
      <button class="centerBtn" @click="handleExportCamera">导出</button>
    </div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-input
          v-model="list.camCode"
          placeholder="摄像头编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button>
      </el-form-item>
      <el-form-item class="form-right">
        <button class="centerCzbtn ml20" type="button" @click="showViewConfig">
          视角配置
        </button>
      </el-form-item>
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <el-drawer
      :title="isAdd ? '新增摄像头' : '编辑摄像头'"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      destroy-on-close
      @closed="drawerClosed"
      @open="drawerOpen"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item
            label="车辆类型"
            :label-width="formLabelWidth"
            prop="carType"
            class="select"
          >
            <el-select
              v-model="form.carType"
              placeholder="请选择"
              class="centerCzSelInput"
              @change="getNumber"
            >
              <el-option
                v-for="item in cartypelist"
                :key="item.id"
                :label="item.typeName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="车辆编号"
            :label-width="formLabelWidth"
            prop="carRegisterId"
          >
            <el-select
              v-model="form.carRegisterId"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in carRegisterlist"
                :key="item.id"
                :label="item.carNumber"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="摄像头编号"
            :label-width="formLabelWidth"
            prop="camCode"
            class="select"
            v-if="!this.isPatrolCar"
          >
            <el-select
              v-model.trim="form.camCode"
              placeholder="请选择"
              class="centerCzSelInput"
              @change="getview"
            >
              <el-option
                v-for="item in camViewList"
                :key="item.id"
                :label="item.camCode"
                :value="item.camCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="摄像头编号"
            :label-width="formLabelWidth"
            prop="camCode"
            class="select"
            v-else
          >
            <el-input
              v-model.trim="form.camCode"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="设备编号"
            :label-width="formLabelWidth"
            prop="deviceId"
            v-show="this.isPatrolCar"
          >
            <el-input
              v-model.trim="form.deviceId"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="视角"
            :label-width="formLabelWidth"
            prop="view"
            class="select"
            v-show="this.isPatrolCar != true"
          >
            <el-select
              v-model="form.view"
              placeholder="请选择"
              class="centerCzSelInput"
              @change="getview2"
              v-if="this.isPatrolCar != true"
            >
              <el-option
                v-for="item in camViewList"
                :key="item.id"
                :label="item.view"
                :value="item.view"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="获取视频平台"
            :label-width="formLabelWidth"
            prop="platform"
            class="time"
            v-show="this.isPatrolCar != true"
          >
            <el-radio-group
              v-model="form.platform"
              v-show="this.isPatrolCar != true"
            >
              <el-radio label="1">推流</el-radio>
              <el-radio label="2">拉流</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="流媒体地址"
            :label-width="formLabelWidth"
            prop="mediaUrl"
            v-if="form.platform == 2"
            v-show="this.isPatrolCar != true"
          >
            <el-input
              v-model="form.mediaUrl"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
              v-show="this.isPatrolCar != true"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" :label-width="formLabelWidth" required>
            <el-switch
              v-model="form.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button class="centerCzbtn" @click="getselect" :loading="isLoading"
          >确定</el-button
        >
        <button class="centerBtn resetbtn" @click="addAccount = false">
          取消
        </button>
      </div>
    </el-drawer>
    <el-drawer
      title="导入摄像头"
      :visible.sync="importCameraConfig.visible"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="25%"
      :destroy-on-close="true"
      @close="handleCloseImportCameraDrawer"
    >
      <el-upload
        drag
        class="upload-demo"
        action="#"
        :auto-upload="false"
        :file-list="importCameraConfig.cameraFileList"
        :on-change="handleSelectUploadFile"
        :limit="1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip flex justify-between" slot="tip">
          <span class="red">注：请参照模版，批量导入车辆。</span>
          <span
            class="textCol"
            style="cursor: pointer"
            @click="handleDownloadImportCameraTemplate"
            >下载模板</span
          >
        </div>
      </el-upload>
      <div style="margin-top: 20%" class="flex justify-center">
        <button
          class="centerCzbtn"
          @click="handleImportCamera"
          v-if="!importCameraConfig.isImport"
        >
          上传
        </button>
        <button class="centerBtn resetbtn" v-else>上传</button>
      </div>
    </el-drawer>
    <el-dialog title="删除" :visible.sync="delDiaA" width="30%">
      <span>确认删除这条记录?</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn" @click="getdel">确 定</button>
        <button class="centerBtn resetbtn" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <!-- 视角配置 -->
    <ViewConfig ref="viewConfig"></ViewConfig>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import ViewConfig from "./components/viewConfig.vue";
import {
  _CameraMng,
  _CameraAdd,
  _CameraType,
  _Cameraedit,
  _Cameradel,
} from "@/api/pkMng";
import { _MinBuscarRegister } from "@/api/minBus";
import {
  _DownloadImportCameraTemplate,
  _ExportCamera,
  _ImportCamera,
  _GetCamViewDic,
} from "@/api/cameraMng";
export default {
  components: {
    Table,
    ViewConfig,
  },
  data() {
    return {
      isPatrolCar: false,
      loading: false,
      isLoading: false,
      isAdd: false,
      addAccount: false,
      Import: false,
      delDiaA: false,
      formLabelWidth: "100px",
      radio: "",
      view: "",
      total: 0,
      id: "",
      detail: false,
      importCameraConfig: {
        visible: false,
        isImport: false,
        cameraFileList: [],
      },
      cartypelist: [],
      cameraNumberList: [],
      visualAngleList: [],
      carRegisterlist: [],
      list: {
        page: 1,
        size: 10,
        total: 0,
        carNumber: "",
        camCode: "",
        parkId: sessionStorage.getItem("parkId"),
      },
      form: {
        carType: "",
        carRegisterId: "",
        camCode: "",
        view: "",
        platform: "1",
        mediaUrl: "",
        deviceId: "", //巡逻车设备编号
        status: 1,
      },
      rules: {
        carType: [
          {
            required: true,
            message: "请选择车辆类型",
            trigger: "change",
          },
        ],
        camCode: [
          {
            required: true,
            message: "摄像头编号不能为空",
            trigger: "change",
          },
        ],
        deviceId: [
          {
            required: this.isPatrolCar,
            message: "设备编号不能为空",
            trigger: "change",
          },
        ],
        carRegisterId: [
          {
            required: true,
            message: "请输入车辆编号",
            trigger: "change",
          },
        ],
        platform: [
          {
            required: true,
            message: "请选择获取视频平台",
            trigger: "blur",
          },
        ],
        // mediaUrl: [
        //   {
        //     required: true,
        //     message: "请输入流媒体地址",
        //     trigger: "blur",
        //   },
        // ],
        view: [
          {
            required: true,
            message: "请选择视角",
            trigger: "change",
          },
        ],
      },
      tableData: [],
      columns: [
        {
          label: "车辆类型",
          prop: "typeName",
        },
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆VIN",
          prop: "carVin",
        },
        {
          label: "摄像头编号",
          prop: "camCode",
        },
        {
          label: "视角",
          prop: "carView",
        },
        {
          label: "流媒体地址",
          prop: "mediaUrl",
        },
        {
          label: "操作",
          width: 180,
          render: (h, params) => {
            let srclist = 3;
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.isAdd = false;
                      this.addAccount = true;
                      this.form = {
                        carType: params.row.carType,
                        carRegisterId: params.row.carRegisterId,
                        camCode: params.row.camCode,
                        view: params.row.carView,
                        platform: params.row.platform,
                        mediaUrl: params.row.mediaUrl,
                        id: params.row.carCameraId,
                        deviceId: params.row.deviceId,
                        status: params.row.status,
                      };
                      // this.view = params.row.view;
                      // if(this.form.carType == 4 ){
                      //   this.isPatrolCar = true
                      // }else{
                      //   this.isPatrolCar = false
                      // }
                      this.getNumber();
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDiaA = true;
                      this.id = params.row.carCameraId;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      camViewDic: [],
      camViewList: [],
      viewConfigShow: false,
    };
  },
  mounted() {
    this.getList();
    this.getCarType();
    this.getCamViewDic();
  },
  methods: {
    showViewConfig() {
      this.$refs.viewConfig.viewConfigShow = true;
    },
    handleOpenImportCameraDrawer() {
      this.importCameraConfig.visible = true;
    },

    handleCloseImportCameraDrawer() {
      this.importCameraConfig.visible = false;
      this.importCameraConfig.cameraFileList = [];
      this.importCameraConfig.isImport = false;
    },

    handleSelectUploadFile(file, fileList) {
      this.importCameraConfig.cameraFileList = fileList;
    },

    async handleImportCamera() {
      if (this.importCameraConfig.cameraFileList[0]) {
        const loading = this.$loading({
          lock: true,
          text: "导入中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let file = new FormData();
        file.append("file", this.importCameraConfig.cameraFileList[0].raw);
        file.append("parkId ", this.list.parkId);
        this.importCameraConfig.isImport = true;
        await _ImportCamera(file).then((res) => {
          if (res != undefined) {
            if (res.success == true) {
              this.handleCloseImportCameraDrawer();
              this.$message.success(res.message);
              this.getList();
              loading.close();
            }
          } else {
            loading.close();
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "没有上传文件",
          type: "error",
        });
      }
    },

    handleDownloadImportCameraTemplate() {
      _DownloadImportCameraTemplate().then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.download = "摄像头导入模板.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },

    async handleExportCamera() {
      _ExportCamera({
        parkId: this.list.parkId,
        carNumber: this.list.carNumber,
        camCode: this.list.camCode,
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.download = "摄像头列表.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },

    cancel() {
      this.list = {
        page: 1,
        size: this.list.size,
        total: this.list.total,
        carNumber: "",
        camCode: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList();
    },
    getselect() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd == true) {
            this.getAdd();
          } else {
            this.getEdit();
          }
        }
      });
      console.log(this.form);
    },
    getadd() {
      this.addAccount = true;
      this.isAdd = true;
      // this.view = "";
      this.form = {
        carType: "",
        carRegisterId: "",
        camCode: "",
        view: "",
        platform: "1",
        mediaUrl: "",
        deviceId: "", //巡逻车设备编号
        status: 1,
      };
    },
    getview(value) {
      this.form.view = this.camViewList.filter(
        (ele) => ele.camCode == value
      )[0]?.view;
    },
    getview2(value2) {
      this.form.camCode = this.camViewList.filter(
        (ele) => ele.view == value2
      )[0]?.camCode;
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async getList() {
      this.loading = true;
      let { success, data, message } = await _CameraMng(this.list);
      console.log(data);
      for (var i = 0; i < data.list.length; i++) {
        if (data.list[i].carType == 4) {
          data.list[i].carView = "-";
        }
      }
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2() {
      this.list.page = 1;
      this.getList();
    },
    async getAdd() {
      this.isLoading = true;
      _CameraAdd(this.form).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success == true) {
          this.$message.success("添加成功");
          this.isLoading = false;
          this.getList();
        }
        this.addAccount = false;
      });
    },

    async getEdit() {
      let { success, data, message } = await _Cameraedit(this.form);
      if (success == true) {
        this.$message.success("编辑成功");
        this, this.getList();
      }
      this.addAccount = false;
    },

    async getdel() {
      let { success, data, message } = await _Cameradel({ id: this.id });
      if (success == true) {
        this.$message.success("删除成功");
        this, this.getList();
      }
      this.delDiaA = false;
    },
    async getCarType() {
      _CameraType().then((res) => {
        if (res.success == true) {
          this.cartypelist = res.data.list;
        }
      });
    },
    async getNumber() {
      // console.log(this.form.carType)
      let { success, data, message } = await _MinBuscarRegister({
        parkId: this.list.parkId,
        carType: this.form.carType,
      });
      if (success) {
        this.carRegisterlist = data;
        this.loading = false;
      }
    },
    drawerClosed() {
      this.form = {
        carType: "",
        carRegisterId: "",
        camCode: "",
        view: "",
        platform: "1",
        mediaUrl: "",
        deviceId: "", //巡逻车设备编号
        status: 1,
      };
    },
    drawerOpen() {
      this.getCamViewDic();
    },
    // 获取摄像头视角
    async getCamViewDic() {
      try {
        let { data, success } = await _GetCamViewDic();
        if (success) {
          this.camViewDic = data;
        }
      } catch (error) {}
    },
  },
  watch: {
    "form.carType": {
      handler(newVal, oldVal) {
        let arr = this.camViewDic.filter((ele) => ele.carType == newVal);
        this.camViewList = arr.length ? arr[0].carCameraViewTypeDataList : [];
        if (oldVal) {
          this.form.camCode = "";
          this.form.view = "";
          this.form.carRegisterId = "";
        }
        if (newVal == 4) {
          this.isPatrolCar = true;
          // 是巡逻车则设备编号必填，视角不必填
          this.rules.deviceId[0].required = true;
          this.rules.view[0].required = false;
        } else {
          this.isPatrolCar = false;
          this.rules.deviceId[0].required = false;
          this.rules.view[0].required = true;
        }
      },
      immediate: true,
      deep: true,
    },
    "form.platform": {
      handler(newVal) {
        if (newVal == 1) {
          this.form.mediaUrl = "";
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped lang='less'>
.el-input__inner {
  border: none !important;
  height: 36px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.width /deep/ .el-input {
  width: 140px !important;
  margin-right: 20px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  font-size: 14px;
}
.width /deep/.el-input__inner {
  border: 0px solid white !important;
  margin-right: 20px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
}
.el-upload-dragger {
  width: 100%;
}
/deep/ .el-upload .el-upload--text {
  width: 360px;
  height: 180px;
}
.upload-demo {
  width: 360px;
}
.textCol {
  color: #1683d9;
}
/deep/.centerCzInput > input,
/deep/.centerCzSelInput > div > input {
  width: 100% !important;
}
.form-right {
  float: right;
}
</style>