<template>
  <div>
    <p>
      <span
        :class="showvideo == false ? 'statutext' : 'textcolor'"
        @click="getvideo"
        >音频通讯记录</span
      >

      <span
        :class="showvideo == false ? 'textcolor' : 'statutext'"
        @click="getvideoB"
        >视频通讯记录</span
      >
    </p>
    <p class="flex">
      <span :class="showvideo == false ? 'statustext_bg' : ''"></span>
      <span :class="showvideo == false ? '' : 'statustext_bgB'"></span>
    </p>
    <el-form inline="true">
      <el-form-item
        ><el-input
          v-model="searchObj.vehicleNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20">搜索</button>
        <button class="centerBtn resetbtn">重置</button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="10"
      @getAjax="getAjax"
      :loading="loading"
      v-if="showvideo == false"
    />
    <Table
      :data1="tableDataB"
      :columns1="columnsB"
      :total="10"
      @getAjax="getAjax"
      :loading="loading"
      v-else
    />
    <el-dialog title="删除" :visible.sync="delDiaA" width="30%">
      <span>确认删除视频记录</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="delDiaB" width="30%">
      <span>确认删除音频记录</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="delDiaB = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      radio: "1",
      delDiaA: false,
      delDiaB: false,
      showvideo: false,
      formLabelWidth: "100px",
      isAdd: false,
      searchObj: {
        vehicleNumber: "",
        siteName: "",
        operationStatus: "",
      },

      tableData: [
        {
          videoStream: "SV10004",
          vehicleNumber: "SMR-Partol001",
          saveTime: "2021-06-30 10:24:44",
          depositary: "张佳",
        },
        {
          videoStream: "SV10004",
          vehicleNumber: "SMR-Partol001",
          saveTime: "2021-06-30 10:24:44",
          depositary: "张佳",
        },
        {
          videoStream: "SV10004",
          vehicleNumber: "SMR-Partol001",
          saveTime: "2021-06-30 10:24:44",
          depositary: "张佳",
        },
        {
          videoStream: "SV10004",
          vehicleNumber: "SMR-Partol001",
          saveTime: "2021-06-30 10:24:44",
          depositary: "张佳",
        },
      ],

      columns: [
        {
          label: "视频流",
          prop: "videoStream",
        },
        {
          label: "车辆编号",
          prop: "vehicleNumber",
        },
        {
          label: "视频保存时间",
          prop: "saveTime",
        },
        {
          label: "保存人",
          prop: "depositary",
        },
        {
          label: "操作",
          width: 200,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.resetcarstatus = true;
                    },
                  },
                },
                "查看视频"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDiaA = true;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      columnsB: [
        {
          label: "音频流",
          prop: "audioStream",
        },
        {
          label: "车辆编号",
          prop: "vehicleNumber",
        },
        {
          label: "音频保存时间",
          prop: "saveTime",
        },
        {
          label: "保存人",
          prop: "depositary",
        },
        {
          label: "操作",
          width: 200,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.resetcarstatus = true;
                    },
                  },
                },
                "查看视频"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDiaB = true;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      tableDataB: [
        {
          audioStream: "SV10004",
          vehicleNumber: "SMR-Partol001",
          saveTime: "2021-06-30 10:24:44",
          depositary: "张佳",
        },
        {
          audioStream: "SV10004",
          vehicleNumber: "SMR-Partol001",
          saveTime: "2021-06-30 10:24:44",
          depositary: "张佳",
        },
        {
          audioStream: "SV10004",
          vehicleNumber: "SMR-Partol001",
          saveTime: "2021-06-30 10:24:44",
          depositary: "张佳",
        },
        {
          audioStream: "SV10004",
          vehicleNumber: "SMR-Partol001",
          saveTime: "2021-06-30 10:24:44",
          depositary: "张佳",
        },
        {
          audioStream: "SV10004",
          vehicleNumber: "SMR-Partol001",
          saveTime: "2021-06-30 10:24:44",
          depositary: "张佳",
        },
      ],
    };
  },
  methods: {
    getvideo() {
      this.showvideo = false;
    },
    getvideoB() {
      this.showvideo = true;
    },
  },
};
</script>

<style scoped>
.textcolor {
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  margin-right: 61px;
}
.statutext {
  color: rgba(22, 131, 217, 1);
  font-size: 16px;
  margin-right: 61px;
  cursor: pointer;
}
.statustext_bg {
  display: block;
  width: 60px;
  height: 14px;
  background: #1683d9;
  opacity: 0.39;
  filter: blur(5px);
  margin-top: -25px;
  margin-right: 61px;
  margin-left: 20px;
}
.statustext_bgB {
  display: block;
  width: 60px;
  height: 14px;
  background: #1683d9;
  opacity: 0.39;
  filter: blur(5px);
  margin-top: -25px;
  margin-left: 176px;
}
.carnum {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.route_text {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
.text {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-left: 5px;
  margin-right: 136px;
}
.textB {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-left: 10px;
  margin-right: 126px;
}
.textc {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.textd {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
.el-input__inner {
  height: 36px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  border: 0px solid white !important;
  font-size: 14px;
}
</style>