<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="params.carNumber"
          placeholder="请输入车辆编号"
          class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.jobStatus"
          placeholder="作业状态"
          class="centerSelInput ipwidth"
        >
          <el-option
            v-for="item in jobStatus"
            :key="item.value"
            :label="item.status"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="searchDate"
          type="daterange"
          range-separator="—"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd"
          class="pickerTime"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="centerCzbtn ml20" @click="searchList()"
          >搜索</el-button
        >
        <el-button
          type="primary"
          class="centerBtn resetbtn"
          @click="clearSearch()"
          >重置</el-button
        >
        <el-button
          type="primary"
          class="centerCzbtn ml20"
          @click="exportData"
          :loading="excelLoading"
          >导出记录</el-button
        >
      </el-form-item>
    </el-form>

    <el-card :body-style="{ padding: '0px' }">
      <!-- 表格 -->
      <el-table
        max-height="550px"
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{
          'font-weight': 'bold',
          color: 'black',
          'font-size': 'medium'
        }"
      >
        <el-table-column prop="planDate" label="日期"> </el-table-column>
        <el-table-column prop="carNumber" label="车辆编号"></el-table-column>
        <el-table-column
          prop="carSonTypeName"
          label="车辆子类型"
        ></el-table-column>
        <el-table-column prop="jobType" label="清洁类型">
          <template slot-scope="scope">
            <div>
              {{ formatJobType(scope.row.jobType) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="jobStatus" label="作业状态">
          <template slot-scope="scope">
            <div>
              {{ formatJobStatus(scope.row.jobStatus) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间">
          <template slot-scope="scope">
            <div>
              {{ formatTime(scope.row.startTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间">
          <template slot-scope="scope">
            <div>
              {{ formatTime(scope.row.endTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="carRoute" label="行驶线路">
          <template slot-scope="scope">
            <div>
              {{ turnBar(scope.row.carRoute) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="operatorUser" label="操作员">
          <template slot-scope="scope">
            <div>
              {{ turnBar(scope.row.operatorUser) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 获取当前行的信息 然后给到弹出信息框 -->
            <el-link type="primary" @click="openDrawer(scope.row)"
              >查看作业详情</el-link
            >
          </template>
        </el-table-column>
      <template slot="empty" class="emptyTem">
        <img :src="emptyImg" alt="" srcset="">
        <div>暂无数据</div>
      </template>
      </el-table>

      <!-- 弹窗数据 -->
      <el-drawer
        :title="this.jobDetailTitle"
        :visible.sync="isDrawer"
        :direction="direction"
        :destroy-on-close="true"
        size="45%"
      >
        <el-card class="text_font_ card">
          <el-row class="drawer_col_margin">
            <el-col :span="4" class="title_color_grey">车辆编号</el-col>
            <el-col :span="7">{{ this.jobDetail.carNumber }}</el-col>
            <el-col :span="4" :offset="2" class="title_color_grey"
              >作业类型</el-col
            >
            <el-col :span="7">{{
              formatJobType(this.jobDetail.jobType)
            }}</el-col>
          </el-row>
          <el-row class="drawer_col_margin">
            <el-col :span="4" class="title_color_grey">作业时间</el-col>
            <el-col :span="7"
              >{{ this.jobDetail.planDate }}
              {{ formatTime(this.jobDetail.startTime) }}-{{
                formatTime(this.jobDetail.endTime)
              }}</el-col
            >
            <el-col :span="4" :offset="2" class="title_color_grey"
              >作业线路</el-col
            >
            <el-col :span="7">{{ turnBar(this.jobDetail.carRoute) }}</el-col>
          </el-row>
          <el-row class="drawer_col_margin">
            <el-col :span="4" class="title_color_grey">作业状态</el-col>
            <el-col :span="7">{{
              formatJobStatus(this.jobDetail.jobStatus)
            }}</el-col>
            <el-col :span="4" :offset="2" class="title_color_grey"
              >操作员</el-col
            >
            <el-col :span="7">{{
              turnBar(this.jobDetail.operatorUser)
            }}</el-col>
          </el-row>
          <!-- 地图 -->
          <Workmap :jobDetail="jobDetail"></Workmap>
        </el-card>
      </el-drawer>
    </el-card>
    <!-- 分页 -->
    <el-row class="row_top_margin" justify="end">
      <el-col class="pageCol">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.params.page"
          :page-sizes="[10, 20, 30, 50, 80, 100]"
          :page-size="this.params.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Workmap from "@/components/workRecord_map.vue";
import { _GetWorkRecordList } from "@/api/sweep/index.js";
export default {
  components: {
    Workmap
  },
  data() {
    return {
      emptyImg: require('@/assets/images/empty.png'),
      excelLoading: false,
      searchDate: null, //日期
      loading: true,
      // 页面刷新参数
      params: {
        page: 1,
        size: 10,
        carNumber: null, //查询的车辆编号
        jobStatus: null, //1：已完成，2：已取消
        startTime: null, //yyyy-MM-dd
        endTime: null, //yyyy-MM-dd
        parkId: sessionStorage.getItem("parkId") //园区id
      },
      total: null, //总条数
      //作业状态
      jobStatus: [
        { id: "1", status: "已完成" },
        { id: "2", status: "已取消" }
      ],

      // 右侧弹窗
      isDrawer: false,
      direction: "rtl",

      //弹窗的数据
      jobDetail: "",
      //标题
      jobDetailTitle: "",
      //车辆路线信息
      jobDetailRoute: {
        //1工作范围
        //2设置好的工作线路
        //3已完成的工作线路
      },
      //表格信息
      tableData: []
    };
  },
  watch: {
    // 监听日期更改,赋值
    searchDate(newValue) {
      if (newValue) {
        this.params.startTime = newValue[0];
        this.params.endTime = newValue[1];
      } else {
        this.params.startTime = null;
        this.params.endTime = null;
      }
    }
  },
  async created() {
    // 默认时间段
    this.searchDate = this.getTimeArr();
    this.params.startTime = this.searchDate[0];
    this.params.endTime = this.searchDate[1];
    //获取数据
    await this.getWorkRecordList();
  },
  mounted() {},

  methods: {
    // 获取页面数据
    async getWorkRecordList() {
      this.loading = true;
      let { data } = await _GetWorkRecordList(this.params);
      this.tableData = data.list;
      this.total = data.total;
      this.loading = false;
    },
    //协同分页
    async handleSizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      await this.getWorkRecordList();
    },
    async handleCurrentChange(val) {
      this.params.page = val;
      await this.getWorkRecordList();
    },
    //搜索按钮
    async searchList() {
      this.params.page = 1;
      await this.getWorkRecordList();
    },
    //重置
    async clearSearch() {
      this.searchDate = this.getTimeArr();
      this.params = {
        page: 1,
        size: 10,
        carNumber: null, //查询的车辆编号
        jobStatus: null, //1：已完成，2：已取消
        startTime: this.searchDate[0], //yyyy-MM-dd
        endTime: this.searchDate[1], //yyyy-MM-dd
        parkId: sessionStorage.getItem("parkId") //园区id
      };
      await this.getWorkRecordList();
    },
    //详情 弹窗获取数据展示
    //要获取当前编号车辆路线的信息然后才能展示出来

    openDrawer(row) {
      //获取车辆路线信息

      //展示抽屉内容
      this.isDrawer = !this.isDrawer;
      //标题
      this.jobDetailTitle = `工作详情【${row.carNumber}】`;
      //将从table获取到的当前信息 给到详情页的对象
      this.jobDetail = JSON.parse(JSON.stringify(row));
    },
    // 格式换时间
    formatTime(value) {
      if (typeof value == "string") {
        return value.substr(value.lastIndexOf(" ") + 1);
      }
      return value;
    },
    // 格式化jobStatus
    formatJobStatus(value) {
      return value == 1 ? "已完成" : "已取消";
    },
    // 格式化jobType
    formatJobType(value) {
      switch (value) {
        case 1:
          return "清扫";
        case 2:
          return "洒水";
        case 3:
          return "清扫、洒水";
        default:
          return "未知";
      }
    },
    // 导出excel数据
    exportData() {
      //  做操作
      this.excelLoading = true;
      let countAll = Math.ceil(this.total / 200) || 1;
      let count = 0; //计数判断请求是否全部返回
      let twoDimension = []; // 二维路径数组
      for (let i = 1; i <= countAll; i++) {
        let params2 = JSON.parse(JSON.stringify(this.params));
        params2.size = 200;
        params2.page = i;
        // 同时发出全部请求
        _GetWorkRecordList(params2).then(res => {
          ++count; // 请求回来计数加一
          twoDimension[i - 1] = res.data.list; // 二维数组顺序添加
          // 请求全部回来之后
          if (count == countAll) {
            let tableList = twoDimension.flat();
            // console.log(tableList);
            this.excelLoading = false;
            // 表头对应关系
            const headers = {
              日期: "planDate",
              车辆编号: "carNumber",
              车辆子类型: "carSonTypeName",
              清洁类型: "jobType",
              作业状态: "jobStatus",
              开始时间: "startTime",
              结束时间: "endTime",
              行驶线路: "carRoute",
              操作员: "operatorUser"
            };
            // 懒加载
            import("@/until/Export2Excel").then(async excel => {
              let rows = tableList;
              const data = this.formatJson(headers, rows);
              excel.export_json_to_excel({
                header: Object.keys(headers),
                data,
                filename: "清扫车工作记录",
                autoWidth: true,
                bookType: "xlsx"
              });
            });
          }
        });
      }
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      // [{ username: '张三'},{},{}]  => [['张三'],[],[]]
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          if (headers[key] === "startTime" || headers[key] === "endTime") {
            return this.formatTime(item[headers[key]]); // 返回格式化之前的时间
          } else if (headers[key] === "jobStatus") {
            return this.formatJobStatus(item[headers[key]]);
          } else if (headers[key] === "jobType") {
            return this.formatJobType(item[headers[key]]);
          } else if (
            headers[key] === "operatorUser" ||
            headers[key] === "carRoute"
          ) {
            return this.turnBar(item[headers[key]]);
          }
          return item[headers[key]];
        }); // => ["张三", "13811"，"2018","1", "2018", "10002"]
      });
    },
    // 获取 [今天,一星期之后]
    getTimeArr() {
      let nowDate = new Date();
      let nowY = nowDate.getFullYear();
      let nowM = nowDate.getMonth() + 1;
      let nowD = nowDate.getDate();
      let now =
        nowY +
        "-" +
        (nowM < 10 ? "0" + nowM : nowM) +
        "-" +
        (nowD < 10 ? "0" + nowD : nowD); //当前日期
      let lw = new Date(nowDate - 1000 * 60 * 60 * 24 * 7); //最后一个数字7可改，7天的意思
      let lastY = lw.getFullYear();
      let lastM = lw.getMonth() + 1;
      let lastD = lw.getDate();
      let enddate =
        lastY +
        "-" +
        (lastM < 10 ? "0" + lastM : lastM) +
        "-" +
        (lastD < 10 ? "0" + lastD : lastD); //7天之前日期
      return [enddate, now];
    },
    // 表格为空时显示--
    turnBar(value) {
      if (!value) {
        return "--";
      } else {
        return value;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.row_margin_bottom {
  margin: 0 0 1% 0;
}
.text_font_ {
  font-size: medium;
}
.drawer_col_margin {
  margin: 2% 0;
}
.title_color_grey {
  color: grey;
}
.col_left_margin {
  margin-left: 1%;
}
.row_top_margin {
  margin-top: 15px;
}
.pickerTime {
  border: 0px solid black;
  margin: 0 1% 0 1%;
}
.card {
  height: 100%;
  /deep/ .el-card__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  #container {
    flex: 1;
    overflow: hidden;
  }
}
.pageCol {
  text-align: right;
  padding-right: 36px;
}
/deep/.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  margin-right: 1px;
  background: hsla(0, 0%, 70.6%, 0.4);
  background-clip: padding-box;
  min-height: 30px;
  border-radius: 20px;
}
</style>
