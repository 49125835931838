<template>
  <div>
    <!-- 搜索重置 -->
    <el-form :inline="true">
      <el-form-item
        ><el-input
          v-model="list.taskNumber"
          placeholder="任务编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item
        ><el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.operationResult"
          class="centerSelInput centerInput"
          placeholder="执行结果"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in operationResultData"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <!-- <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="list.taskCreateTime"
          type="date"
          placeholder="任务创建时间"
          class="centerInput"
        ></el-date-picker> -->
        <el-date-picker v-model="searchTime" 
          type="daterange" 
          range-separator="-"  
          start-placeholder="开始日期"  
          end-placeholder="结束日期" 
          class="pickerTime"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="search">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="reset">
          重置
        </button>
        <button class="centerCzbtn" type="button" @click="drawer = true">
          新增任务
        </button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      :page="list.page"
      :loading="loading"
      @getAjax="getTableData"
    >
    </Table>
    <!-- 新增侧边栏弹窗 -->
    <el-drawer
      title="新增任务"
      :visible.sync="drawer"
      class="drawer"
      @close="drawerClose"
    >
      <el-form
        label-width="100px"
        label-position="right"
        ref="addForm"
        :rules="rules"
        :model="form"
      >
        <el-form-item label="任务编号" prop="taskNumber">
          <el-input
            placeholder="请输入任务编号"
            v-model="form.taskNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务关联" prop="taskAssociation">
          <el-input
            placeholder="请输入任务关联"
            v-model="form.taskAssociation"
          ></el-input>
        </el-form-item>
        <el-form-item label="姿态角度值" prop="truckAngle">
          <el-input
            placeholder="请输入姿态角度值"
            v-model="form.truckAngle"
            maxlength="9"
            oninput="value=value.replace(/[^\d.]/g,'')"
            @blur="changeTruckAngle"
          ></el-input>
        </el-form-item>
        <el-form-item label="位置差值" prop="positionDeviation">
          <el-input
            placeholder="请输入位置差值"
            v-model="form.positionDeviation"
            maxlength="9"
            oninput="value=value.replace(/[^\d.]/g,'')"
            @blur="changePositionDeviation"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择车辆" prop="carNumber">
          <!-- <el-input placeholder="请输入车辆编号" v-model="form.carNumber"></el-input> -->
          <el-autocomplete
            v-model="form.carNumber"
            :fetch-suggestions="querySearchAsync"
            value-key="carNumber"
            placeholder="请选择车辆"
          >
          </el-autocomplete>
        </el-form-item>
        <el-form-item>
          <el-button :loading="submitBtn" class="centerCzbtn ml20 submitBtn" type="button" @click="submitForm" plain>
            确认
          </el-button>
          <el-button
            class="centerBtn resetbtn"
            type="button"
            @click="drawer = false"
          >
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import {
  _GetTaskLocationList,
  _AddTaskLocation,
  // _ExecuteTaskLocation,
  _GetTaskExecuteResult
} from "@/api/transportCar/locationAgain";
import {_ExecuteTask} from '@/api/transportCar'
import { _CarList } from "@/api/systemMng/index.js";
export default {
  name: "",
  components: {
    Table
  },
  data() {
    return {
      searchTime: [],//时间数组 
      submitBtn: false,// 提交按钮loading
      carList: [], // 商用车列表
      loading: false,
      drawer: false, // 新增侧边栏弹窗
      rules: {
        taskNumber: [
          { required: true, message: "请输入任务编号", trigger: "blur" }
        ],
        taskAssociation: [
          { required: true, message: "请输入任务关联", trigger: "blur" }
        ],
        truckAngle: [
          { required: true, message: "请输入姿态角度值", trigger: "blur" }
        ],
        positionDeviation: [
          { required: true, message: "请输入位置差值", trigger: "blur" }
        ],
        carNumber: [
          { required: true, message: "请输入车辆编号", trigger: "change" }
        ]
      },
      list: {
        taskNumber: null, // 任务编号
        carNumber: null, // 车辆编号
        operationResult: null, // 执行结果
        // taskCreateTime: null, // 任务创建时间
        startTime: null,
        endTime: null,
        parkId: sessionStorage.getItem("parkId"), // 园区id
        page: 1,
        size: 10
      },
      total: 0, // 表格总数
      operationResultData: [
        { value: 0, label: "执行失败" },
        { value: 1, label: "执行成功" }
      ],
      columns: [
        {
          label: "任务编号",
          prop: "taskNumber"
        },
        {
          label: "任务关联",
          prop: "taskAssociation"
        },
        {
          label: "车辆编号",
          prop: "carNumber"
        },
        {
          label: "姿态角度值",
          prop: "truckAngle"
        },
        {
          label: "位置差值",
          prop: "positionDeviation",
          render: (h, params) => {
            return h("span", params.row.positionDeviation+"cm")
          }
        },
        {
          label: "任务创建时间",
          prop: "taskCreateTime"
        },
        {
          label: "执行结果",
          prop: "operationResult",
          render: (h, params) => {
            let label = "-";
            let length = this.operationResultData.length;
            for (let i = 0; i < length; i++) {
              if (
                this.operationResultData[i].value == params.row.operationResult
              ) {
                label = this.operationResultData[i].label;
                break;
              }
            }
            return h("span", label);
          }
        },
        {
          label: "操作",
          render: (h, params) => {
            // 执行成功不需要再次执行
            if (params.row.operationResult == 1) {
              return h("span", "--");
            }
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.taskIssue(params.row);
                    }
                  }
                },
                "下发任务"
              )
            ]);
          }
        }
      ],
      tableData: [],
      form: {
        taskNumber: null,
        taskAssociation: null,
        truckAngle: null,
        positionDeviation: null,
        carNumber: null,
        parkId: sessionStorage.getItem("parkId")
      }
    };
  },
  mounted() {
    this.getNowFormatDate()
    this.getList();
    this.getCarList();
  },
  methods: {
    //默认时间
    getNowFormatDate() {
      //获取当前日期
      var myDate = new Date();
      var nowY = myDate.getFullYear();
      var nowM = myDate.getMonth()+1;
      var nowD = myDate.getDate();
      //获取三个月前日期
      var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 90);//最后一个数字90可改，90天的意思
      var lastY = lw.getFullYear();
      var lastM = lw.getMonth()+1;
      var lastD = lw.getDate();
      this.searchTime=[]
      this.searchTime.push(lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD));
      this.searchTime.push(nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD));   
    },
    // 获取表格数据
    async getList() {
      this.list.startTime = this.searchTime?.[0]
      this.list.endTime = this.searchTime?.[1]
      this.loading = true
      try {
        let { success, data } = await _GetTaskLocationList(this.list);
        if (success) {
          this.tableData = data.list;
          this.total = data.total;
          this.loading = false;
          return;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.tableData = []
        this.total = 0
      }
    },
    // 分页函数
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    // 表单提交
    submitForm() {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          this.submitBtn = true
          try {
            let { success } = await _AddTaskLocation(this.form);
            if (success) {
              this.$message.success("新增成功");
              this.drawer = false
            }
            this.submitBtn = false
            this.getList();
          } catch (error) {
            this.submitBtn = false
          }
        }
      });
    },
    // 关闭抽屉清空数据
    drawerClose() {
      Object.assign(this.$data.form, this.$options.data().form);
      this.$refs.addForm.resetFields();
    },
    // 清空表单
    search() {
      this.list.page = 1;
      this.getList();
    },
    // 任务下发
    async taskIssue(data){
      await this.$confirm(
        `确认将任务下发至车辆【<b>${data.carNumber}</b>】？`,
        "二次定位",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          dangerouslyUseHTMLString: true
        }
      )
      let loading = this.$loading({
        lock: true,
        text: "指令下发中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let order = {
        cmdType: 2,
        timeout: 10,
        carNumber: data.carNumber,
        params:{
          icpsTaskId: data.id,
        }
      }
      try {
        let {success, message} = await _ExecuteTask(order)
        if(success){
          this.$message.success(message);
          // await this.getResult(data.id, loading)
          this.getList();
        }
      } catch (error) {
        loading.close();
        this.getList();
      }
    },
    // 获取执行结果 弃用
    getResult(id, loading) {
      return new Promise((resolve, reject) => {
        let timer = setInterval(async() => {
          try {
            let res = await _GetTaskExecuteResult({ id, timeout: 15 })
            let nowTime = new Date().getTime();
            let { success, data } = res;
            if (success) {
              let startTime = new Date(data.startTime).getTime() + 15000;
              if (nowTime < startTime && data.operationResult == 0) {
                // 操作失败
                clearInterval(timer);
                loading.close();
                this.$message.warning("任务执行失败");
                resolve();
                return
              } else if (nowTime < startTime && data.operationResult == 2) {
                clearInterval(timer);
                loading.close();
                this.$message.success("任务执行成功");
                resolve();
                return
              } else if (nowTime >= startTime) {
                clearInterval(timer);
                loading.close();
                this.$message.warning("车辆无响应，执行超时");
                resolve();
                return
              }
            }
          } catch (error) {
            reject();
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    // 重置
    reset() {
      // 搜索项重置
      Object.assign(this.$data.list, this.$options.data().list);
      //时间默认
      this.getNowFormatDate()
      this.getList();
    },
    // 获取车辆
    async getCarList() {
      let { success, data } = await _CarList({
        page: 1,
        size: 10000,
        parkId: sessionStorage.getItem("parkId"),
        carTypeId: 9,
        companyId: sessionStorage.getItem("companyId")
      });
      if (success) {
        this.carList = data.list;
      }
    },
    // 建议筛选
    querySearchAsync(queryString, cb) {
      let carList = this.carList;
      let results = queryString
        ? carList.filter(this.createStateFilter(queryString))
        : carList;
      console.log(results, 9999);
      cb(results);
    },
    createStateFilter(queryString) {
      return state => {
        return (
          state.carNumber.toLowerCase().indexOf(queryString.toLowerCase()) != -1
        );
      };
    },
    // 解决input输入框限定数字后双向数据绑定失效问题
    changePositionDeviation(e) {
      this.form.positionDeviation = e.target.value;
    },
    changeTruckAngle(e) {
      this.form.truckAngle = e.target.value;
    }
  }
};
</script>
<style lang="less" scoped>
.drawer {
  /deep/.el-select {
    width: 100%;
  }
  /deep/.el-input__inner {
    border: 1px solid #dcdfe6 !important;
  }
  .el-autocomplete {
    width: 100%;
  }
  .submitBtn:hover{
    color: white;
    background-color: #1683d9;
    border-color: #1683d9;
  }
  .submitBtn.is-plain:active{
    color: white;
    background-color: #1683d9;
    border-color: #1683d9;
  }
  .submitBtn.is-plain:focus{
    color: white;
    background-color: #1683d9;
    border-color: #1683d9;
  }
  .resetbtn:hover{
    color: white;
    background-color: #999;
    border-color: #999;
  }
}
.pickerTime{
  border: none;
  box-shadow: 0 0.020833rem 0.083333rem 0 rgb(0 0 0 / 8%)
}
</style>
