<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.operationStatus"
          placeholder="运营状态"
          class="centerSelInput"
        >
          <el-option
            v-for="item in StatusList"
            :key="item.id"
            :label="item.status"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      :loading="loading"
      @getAjax="getTableData"
    />
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _MinBusList,
  _MinBusStatus,
  _MinBusModel,
  _MinBusDel,
  _MinBusOrderStatus,
} from "@/api/minBus";
import { _AiBusList } from "@/api/aiBus";

export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      resetcarstatus: false,
      operationMode: false,
      formLabelWidth: "100px",
      isAdd: false,
      carstdate: [],
      carOpmodel: [],
      total: 0,
      ModeList: [
        { id: "1", mode: "普通模式" },
        { id: "2", mode: "VIP模式" },
      ],
      operationStatus: [],
      StatusList: [
        { id: "1", status: "空闲中" },
        { id: "2", status: "运营中" },
        { id: "3", status: "维修中" },
        { id: "4", status: "运营下线" },
      ],
      searchObj: {
        vehicleNumber: "",
        operationMode: "",
        operationStatus: "",
      },
      form: {
        carId: "",
        carStatus: null,
      },
      formB: {
        carId: "",
        carType: "",
      },
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },
        {
          label: "车辆状态",
          render: (h, params) => {
            if (params.row.carStatus == 1) {
              return h("span", "在线");
            } else {
              return h("span", "离线");
            }
          },
        },
        {
          label: "行驶路线",
          prop: "carRoute",
        },
      ],
      list: {
        page: 1,
        size: 10,
        carNumber: "",
        carStatus: "",
        operationStatus: "",
        parkId: sessionStorage.getItem("parkId"),
      },
      oderStatue: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getOpRecord() {
      this.$router.push("/vehicleOperationRecord");
    },
    cancel() {
      (this.list = {
        page: 1,
        size: 10,
        carNumber: "",
        carStatus: "",
        operationStatus: "",
      }),
        this.getList();
    },
    getoder() {},
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async getList() {
      let { success, data, message } = await _AiBusList(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    async getOpStatu() {
      let { success, data, message } = await _MinBusStatus(this.form);
      if (success) {
        this.$message.success("切换运营状态成功");
        this.loading = false;
        this.getList();
      }
      this.resetcarstatus = false;
    },
    async getOpMdel() {
      let { success, data, message } = await _MinBusModel(this.formB);
      if (success) {
        this.$message.success("切换运营模式成功");
        this.loading = false;
        this.getList();
      }
      this.operationMode = false;
    },
    async getOderStatus(id) {
      let { success, data, message } = await _MinBusOrderStatus({
        carRegisterId: id,
      });
      if (success) {
        this.loading = false;
        this.oderStatue = data.isOrderStatus;
      }
      // this.operationMode = false;
    },
  },
};
</script>

<style scoped>
</style>