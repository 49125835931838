

import service from '../../until/api'
//车辆运营
export function _SwBusList(data) {
    return service({
        url: 'sweeper-bus/web/vehicleOperation/findPage',
        method: 'GET',
        params: data
    })
}
export function _SwBusStatus(data) {
    return service({
        url: 'sweeper-bus/web/vehicleOperation/switchOperationStatus',
        method: 'GET',
        params: data
    })
}
//任务管理
export function _SwBuscleaningTask(data) {
    return service({
        url: 'sweeper-bus/web/cleaningTask/findPage',
        method: 'GET',
        params: data
    })
}

export function _SwBusfindRouteWithPark(data) {
    return service({
        url: 'sweeper-bus/web/cleaningTask/findRouteWithPark',
        method: 'GET',
        params: data
    })
}
export function _SwBusAdd(data) {
    return service({
        url: 'sweeper-bus/web/cleaningTask/insert',
        method: 'POST',
        data
    })
}
export function _SwBusUp(data) {
    return service({
        url: 'sweeper-bus/web/cleaningTask/update',
        method: 'POST',
        data
    })
}

//下载紧急制动记录
export function _DownloadEmergencyBrakeRecord(data) {
    return service({
        url: 'protocol-nacos/carAlarm/getCarBrakePacket',
        method: 'GET',
        params: data,
        responseType: 'blob'

    })
}

export function _DownloadCarBrakeVideo(data) {
    return service({
        url: 'file-nacos/excelDownload/exportCarBrakeVideo',
        method: 'GET',
        params: data,
        responseType: 'blob'

    })
}
// export function _SwBuscleaningTask(data) {
//     return service({
//         url: 'sweeper-bus/web/cleaningTask/findPage',
//         method: 'GET',
//         params: data
//     })
// }
// export function _SwBuscleaningTask(data) {
//     return service({
//         url: 'sweeper-bus/web/cleaningTask/findPage',
//         method: 'GET',
//         params: data
//     })
// }
// export function _SwBuscleaningTask(data) {
//     return service({
//         url: 'sweeper-bus/web/cleaningTask/findPage',
//         method: 'GET',
//         params: data
//     })
// }
// 获取工作记录列表
export function _GetWorkRecordList(data) {
    return service({
        url: 'sweeper-bus/web/workRecord/findPage',
        method: 'GET',
        params: data
    })
}
// 获取工作记录里车辆轨迹
export function _GetSweepTrack(data) {
    return service({
        url: 'sweeper-bus/web/workRecord/operationTrack',
        method: 'GET',
        params: data
    })
}