<template>
  <div>
    <div style="margin-bottom: 20px">
      <button type="button" class="centerBtn" @click="insertMethods">新增</button>
      <button type="button" class="centerBtn" @click="getImport">导入</button>
    </div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.routeName"
          placeholder="线路名称"
          class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="list.carTypeId"
          placeholder="请选择"
          class="centerSelInput"
        >
          <el-option
            v-for="item in carTypetion"
            :key="item.carTypeId"
            :label="item.carTypeName"
            :value="item.carTypeId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <button type="button" class="centerCzbtn" @click="getList2">
          搜索
        </button>
        <button type="button" class="centerBtn resetbtn" @click="reset">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      class="tableTop"
      @getAjax="getTableData"
      :page="list.page"
    />
    <el-dialog title="查看线路" :visible.sync="detail" width="50%">
      <div class="largMap">
        <amap ref="myMap" :center.sync="center"> </amap>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="button" class="centerBtn">确 定</button>
        <button type="button" class="centerBtn resetbtn" @click="detail = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- 新增 -->
    <el-drawer
      :title="isAdd ? '新增路线' : '编辑路线'"
      :visible.sync="added"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="85%"
      :wrapperClosable="isAdd"
      @close="drawClose"
      destroy-on-close
    >
      <div>
        <el-form ref="addform" :model="addForm" :rules="rulesB">
          <el-form-item
            label="线路编号"
            :label-width="formLabelWidth"
            prop="routeNumber"
          >
            <el-input
              v-model="addForm.routeNumber"
              autocomplete="off"
              class="centerCzInput time"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="线路名称"
            :label-width="formLabelWidth"
            prop="routeName"
          >
            <el-input
              v-model="addForm.routeName"
              autocomplete="off"
              class="centerCzInput time"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="车辆类型"
            prop="carTypeId"
            :label-width="formLabelWidth"
            class="select"
          >
            <el-select
              v-model="addForm.carTypeId"
              multiple
              collapse-tags
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in carOptions"
                :key="item.carTypeId"
                :label="item.carTypeName"
                :value="item.carTypeId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="!addFormHaveClearCar"
            label="线路里程"
            :label-width="formLabelWidth"
            prop="routeMileage"
          >
            <el-input
              v-model="addForm.routeMileage"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            >
              <i slot="suffix">KM</i>
            </el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            所属园区：<span>{{ companyName }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="largMap2">
        <div id="containerAdd">
          <img
            src="@/assets/images/draw.png"
            alt=""
            class="drawImg"
            @click="startDraw"
          />
          <img
            src="@/assets/images/return.png"
            alt=""
            class="returnImg"
            @click="againDraw"
          />
        </div>
      </div>
      <div class="drawfoot">
        <el-button
          class="centerCzbtn ml20"
          @click="establish"
          :loading="isLoading"
        >
          {{ isAdd ? "创建" : "修改" }}
        </el-button>
        <button type="button" class="centerBtn resetbtn" @click="added = false">取消</button>
      </div>
    </el-drawer>

    <el-drawer
      title="设置站点"
      :visible.sync="add"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="85%"
    >
      <div>
        <el-form ref="form" :model="form">
          <div
            v-for="(item, index) in form.formList"
            class="formclass"
            :key="item.id"
          >
            <el-form-item
              label="站点编号"
              :prop="'formList.' + index + '.siteNumber'"
              :rules="[
                { required: true, message: '请输入站点编号', trigger: 'blur' },
                { type: 'number', message: '请输入数字', trigger: 'blur' },
              ]"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model.number.trim="item.siteNumber"
                autocomplete="off"
                class="centerCzInput time"
                placeholder="请输入"
                type=""
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="站点名称"
              :prop="'formList.' + index + '.siteName'"
              :label-width="formLabelWidth"
              :rules="[
                { required: true, message: '请输入站点名称', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model.trim="item.siteName"
                autocomplete="off"
                class="centerCzInput time"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>

            <el-form-item
              label="经度(lng)"
              :prop="'formList.' + index + '.longitude'"
              :label-width="formLabelWidth"
              :rules="[
                { required: true, message: '请输入经度', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model.trim="item.longitude"
                autocomplete="off"
                class="centerCzInput time"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="纬度(lat)"
              :prop="'formList.' + index + '.latitude'"
              :label-width="formLabelWidth"
              :rules="[
                { required: true, message: '请输入纬度', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model.trim="item.latitude"
                autocomplete="off"
                class="centerCzInput time"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>

            <el-form-item class="iconOper" label="">
              <img
                src="@/assets/images/delete.png"
                alt=""
                @click="delFormLine(item, index)"
              />
              <img
                src="@/assets/images/add.png"
                alt=""
                @click="addFormItem"
                v-show="index == form.formList.length - 1"
              />
            </el-form-item>
          </div>
        </el-form>
        <!-- <div class="ip_value flex align-center">
          <span class="textcolor">所属园区:</span>
          <span class="textcolor2">{{ companyName }}</span>
        </div> -->
      </div>
      <div class="establish">
        <button type="button" class="centerCzbtn" @click="getselect">保存</button>
        <button type="button" class="centerCzbtn" @click="getCarRoute">更新查看站点</button>
      </div>
      <div class="largMap2">
        <div id="container">
          <!-- <img src="@/assets/images/draw.png" alt="" class="drawImg" @click="startDraw" />
          <img src="@/assets/images/return.png" alt="" class="returnImg" @click="againDraw" /> -->
        </div>
      </div>
    </el-drawer>

    <!-- 导入线路 -->
    <el-drawer
      title="导入线路"
      :visible.sync="Import"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      @close="drawImportClose"
      destroy-on-close
    >
      <el-form :model="fileForm" :rules="rulesC" ref="fileForm">
        <el-form-item
          label="车辆类型"
          :label-width="formLabelWidth"
          class="select"
          prop="carTypeId"
        >
          <el-select
            v-model="fileForm.carTypeId"
            multiple
            collapse-tags
            placeholder="请选择"
            class="centerCzSelInput"
            @change="valuesChange"
          >
            <el-option
              v-for="item in carTypetion"
              :key="item.carTypeId"
              :label="item.carTypeName"
              :value="item.carTypeId"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="线路编号"
          :label-width="formLabelWidth"
          prop="routeNumber"
        >
          <el-input
            v-model="fileForm.routeNumber"
            autocomplete="off"
            class="centerCzInput"
            placeholder="请输入"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="线路名称"
          :label-width="formLabelWidth"
          prop="routeName"
          :rules="[
            { required: true, message: '请输入线路名称', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="fileForm.routeName"
            autocomplete="off"
            class="centerCzInput"
            placeholder="请输入"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="!fileFormHaveClearCar"
          label="线路里程"
          :label-width="formLabelWidth"
          prop="routeName"
          :rules="[
            { required: true, message: '请输入线路里程', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="fileForm.routeMileage"
            autocomplete="off"
            class="centerCzInput"
            placeholder="请输入"
          >
            <i slot="suffix">KM</i>
          </el-input>
        </el-form-item>
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :auto-upload="false"
          :file-list="fileList"
          :on-change="handleUpXls"
          multiple
        >
          <!-- :before-upload="getupload" -->
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            <span> 只能上传.csv文件，且不超过2M</span>
            <span v-show="fileForm.carTypeId.length > 0" @click="downTemplate"
              >下载模板</span
            >
          </div>
        </el-upload>
        <div style="margin-top: 20%" class="flex justify-center">
          <button
            type="button"
            class="centerCzbtn"
            @click="upload"
            v-loading.fullscreen.lock="fullscreenLoading"
          >
            上传
          </button>
        </div>
      </el-form>
    </el-drawer>
    <el-dialog title="删除" :visible.sync="delDiaA" width="30%">
      <span>确认删除线路信息</span>
      <span slot="footer" class="dialog-footer">
        <button type="button" class="centerCzbtn" @click="getDel()">确 定</button>
        <button type="button" class="centerBtn resetbtn" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <el-dialog title="查看路线" :visible.sync="lookline" width="80%">
      <div id="lookContainer"></div>
    </el-dialog>
  </div>
</template>

<script>
// import AMap from "AMap";
import Table from "../../components/Table.vue";
import iconImg from "../../assets/images/new_place.png";
import { calculateCenter } from "@/until/helper";

import {
  _PmLineList,
  _PmLineDel,
  _PmLineAdd,
  _PmLineUp,
  _AddRouteSite,
  _UpdateRouteSite,
  _DeleteRouteSite,
  _AddRouteSiteList,
  _FindOneSite,
  _ExcelUpload,
  _ExcelDownload,
  _FindParkCarType,
  _VerifyCarWorking,
  _ChangeStatus,
} from "@/api/pkMng";
import { _ParkaccountMngRnge } from "@/api/parksysMng";
export default {
  components: {
    Table,
    // AMap,
    // AmapMarker: Marker,
  },
  data() {
    //车大类

    var icon = {
      // 图标类型，现阶段只支持 image 类型
      type: "image",
      // 图片 url
      image:
        "https://a.amap.com/jsapi_demos/static/demo-center/marker/express2.png",
      // 图片尺寸
      size: [64, 30],
      // 图片相对 position 的锚点，默认为 bottom-center
      anchor: "center",
    };
    var Num = (rule, value, callback) => {
      const reg = /^[+-]?(0|([1-9]\d*))(\.\d{1})?$/;
      if (value == "" || value == undefined || value == null) {
        callback();
      } else {
        if (!reg.test(value) && value != "") {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      }
    };
    var Num2 = (rule, value, callback) => {
      const reg = /^[1-9]\d*$/;
      if (value == "" || value == undefined || value == null) {
        callback();
      } else {
        if (!reg.test(value) && value != "") {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      }
    };
    // let valLng = (rule, value, callback) => {
    //     if (!value) {
    //       return callback(new Error('请输入经度'));
    //     } else {
    //       if (isNaN(value)) {
    //         callback()
    //       } else {
    //         return callback(new Error('请输入数字'));
    //       }
    //     }
    // }
    let verifyCarWorking = async (rule, value, callback) => {
      if (!this.isAdd) {
        try {
          let { data } = await _VerifyCarWorking({
            carTypeId: value.toString(),
            carRouteId: this.rowData.id,
          });
          if (data.updateSuccess == 0) {
            callback();
          } else {
            callback(new Error("该线路下有排班计划未执行,车辆类型无法更改"));
          }
        } catch (error) {
          callback(new Error("服务器错误，请稍后重试"));
        }
      } else {
        callback();
      }
    };
    return {
      //车辆类型
      carTypetion: [],
      carOptions: [],
      polyline1: "",
      lookline: false,
      addForm: {
        routeNumber: "",
        routeName: "",
        siteList: "",
        carTypeId: [],
        routeMileage: "",
        parkId: sessionStorage.getItem("parkId"),
      },
      rowData: {},
      companyName: sessionStorage.getItem("companyName"),
      loading: false,
      isLoading: false,
      total: "",
      added: false, //新增
      isStartDraw: false,
      mouseTool: null,
      marker: null,
      map: null,
      isAdd: false,
      addAccount: false,
      Import: false,
      delDiaA: false,
      add: false,
      see: false,
      detail: false,
      formLabelWidth: "80px",
      fullscreenLoading: false, //遮罩
      searchObj: {
        vehicleNumber: "",
        cameraNumber: "",
      },
      center: [114.0811928372, 30.4443817432],
      parkCenterPoint: [],
      form: {
        formList: [
          {
            siteNumber: "",
            siteName: "",
            carRouteId: "",
            parkId: sessionStorage.getItem("parkId"),
            longitude: "",
            latitude: "",
            id: "",
          },
        ],
      },
      fileForm: {
        routeNumber: "",
        routeName: "",
        carTypeId: [],
        routeMileage: "",
      },
      addRuleine: {
        lineNumber: [
          {
            required: true,
            message: "请输入线路编号",
            trigger: "blur",
          },
        ],
        lineName: [
          {
            required: true,
            message: "请输入线路名称",
            trigger: "blur",
          },
        ],
      },
      site: {
        routeNumber: [
          {
            required: true,
            message: "请输入站点编号",
            trigger: "blur",
          },
        ],
        routeName: [
          {
            required: true,
            message: "请输入站点名称",
            trigger: "blur",
          },
        ],
        longitude: [
          {
            required: true,
            message: "请输入经度",
            trigger: "blur",
          },
        ],
        latitude: [
          {
            required: true,
            message: "请输入纬度",
            trigger: "blur",
          },
        ],
      },

      rules: {},
      rulesB: {
        routeNumber: [
          { required: true, message: "请输入线路编号", trigger: "blur" },
          {
            required: true,
            validator: Num2, //在data里定义 ( 重 点 )
            trigger: "blur",
            message: "请输入数字",
          },
        ],
        routeName: [
          { required: true, message: "请输入线路名称", trigger: "blur" },
        ],
        carTypeId: [
          { required: true, message: "请输车辆类别", trigger: "change" },
          {
            validator: verifyCarWorking,
            trigger: "change",
          },
        ],
        routeMileage: [
          { required: true, message: "请输入线路里程", trigger: "blur" },
          {
            required: true,
            validator: Num, //在data里定义 ( 重 点 )
            trigger: "blur",
            message: "请输入数字且最多一位小数",
          },
        ],
      },
      rulesC: {
        routeNumber: [
          { required: true, message: "请输入线路编号", trigger: "blur" },
          {
            required: true,
            validator: Num2, //在data里定义 ( 重 点 )
            trigger: "blur",
            message: "请输入数字",
          },
        ],
        carTypeId: [
          { required: true, message: "请选择车辆类型", trigger: "blur" },
        ],
      },
      tableData: [],
      columns: [
        {
          label: "线路编号",
          width: 100,
          prop: "routeNumber",
        },
        {
          label: "线路名称",
          prop: "routeName",
          // width: 150,
        },
        {
          label: "车辆类型",
          // width: 100,
          prop: "carTypeName",
        },
        {
          label: "线路里程(KM)",
          width: 150,
          prop: "routeMileage",
        },
        {
          label: "所属园区",
          prop: "companyName",
        },
        {
          label: "启停状态",
          prop: "usingStatus",
          render: (h, params) => {
            // return <el-switch v-model={params.row.shiftLineNum} active-text="开" inactive-text="关" active-value={1} inactive-value={0}>
            // </el-switch>
            return h("el-switch", {
              props: {
                value: params.row.usingStatus,
                "active-text": "开",
                "inactive-text": "关",
                "active-value": 1,
                "inactive-value": 0,
              },

              nativeOn: {
                click: async () => {
                  const loading = this.$loading({
                    lock: true,
                    text: "更改中。。。",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                  });
                  try {
                    let status = params.row.usingStatus == 1 ? 0 : 1;
                    let res = await _ChangeStatus({
                      carRouteId: params.row.id,
                      usingStatus: status,
                    });
                    if (res.success) {
                      params.row.usingStatus = status;
                      this.$message.success("切换成功");
                    }
                  } finally {
                    loading.close();
                  }
                },
              },
            });
          },
        },
        {
          label: "添加时间",
          prop: "createTime",
        },
        {
          label: "状态",
          width: 250,
          render: (h, params) => {
            let carType = params.row.carTypeId;
            return h("div", [
              carType !== "5"
                ? h(
                    "span",
                    {
                      class: "operationA",
                      on: {
                        click: () => {
                          this.rowData = params.row;

                          this.isAdd = false;
                          this.addedClick();
                        },
                      },
                    },
                    "编辑"
                  )
                : h(),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDiaA = true;
                      this.rowData = params.row;
                    },
                  },
                },
                "删除"
              ),
              carType !== "5"
                ? h(
                    "span",
                    {
                      class: "operationA",
                      on: {
                        click: () => {
                          this.rowData = params.row;
                          this.addClick(params.row);
                          this.form.formList.forEach((item, index) => {
                            this.form.formList[index].carRouteId =
                              params.row.id;
                          });

                          this.getCarRoute();
                        },
                      },
                    },
                    "设置站点"
                  )
                : h(),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.rowData = params.row;
                      this.lookline = true;
                      let sweeperArea = null;
                      carType !== "5"
                        ? this.$nextTick(() => {
                            this.addedmap = new AMap.Map("lookContainer", {
                              resizeEnable: true,
                              center: this.rowData.siteList
                                ? this.rowData.siteList[
                                    Math.ceil(this.rowData.siteList.length / 2)
                                  ]
                                : this.center,
                              zoom: 16,
                            });
                            this.polyline1 = new AMap.Polyline({
                              path: this.rowData.siteList, // 设置线覆盖物路径
                              showDir: true,
                              strokeColor: "#1683D9", // 线颜色
                              strokeWeight: 10, // 线宽
                            });
                            this.addedmap.add([this.polyline1]);
                          })
                        : this.$nextTick(() => {
                            sweeperArea = JSON.parse(this.rowData.routeArea);
                            this.addedmap = new AMap.Map("lookContainer", {
                              resizeEnable: true,
                              center: sweeperArea
                                ? sweeperArea[0][0][0]
                                : this.center,
                              zoom: 16,
                            });
                            let polyline2 = new AMap.Polygon({
                              path: sweeperArea,
                              fillColor: "rgba(101, 215, 175, 0.40)",
                              strokeOpacity: 1,
                              fillOpacity: 0.5,
                              strokeColor: "#00BBFF",
                              strokeWeight: 1,
                              strokeStyle: "dashed",
                              strokeDasharray: [5, 5],
                            });
                            this.addedmap.add(polyline2);
                          });
                    },
                  },
                },
                "查看路线"
              ),
            ]);
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        total: 0,
        carTypeName: null,
        parkId: sessionStorage.getItem("parkId"),
        routeName: "",
      },
      id: "",
      lineArr: [
        [114.0811928372, 30.4443817432],
        [114.0825095, 30.44603773],
        [114.0813904, 30.44592673],
        [114.0826492, 30.44676163],
        [114.0830097082, 30.4467874862],
        [114.0818726647, 30.4439861127],
        [114.0825906564, 30.4408759242],
      ],
      fileList: [],
      siteMarkerList: [],
    };
  },
  computed: {
    addFormHaveClearCar: function () {
      let clearCarId = this.carTypetion.find(
        (item) => item.carTypeName == "清扫车"
      )?.carTypeId;
      return (
        Array.isArray(this.addForm.carTypeId) &&
        this.addForm.carTypeId.some((ele) => {
          return ele == clearCarId;
        })
      );
    },
    fileFormHaveClearCar: function () {
      let clearCarId = this.carTypetion.find(
        (item) => item.carTypeName == "清扫车"
      )?.carTypeId;
      return (
        Array.isArray(this.fileForm.carTypeId) &&
        this.fileForm.carTypeId.some((ele) => {
          return ele == clearCarId;
        })
      );
    },
  },
  mounted() {
    this.getList();
    this.getParkInfo();
    this.getcartype(); //获取当前园区拥有车类型
  },
  methods: {
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    // 获取站点
    async getCarRoute() {
      let { success, data, message } = await _FindOneSite({
        carRouteId: this.rowData.id,
      });

      this.form.formList = [];

      if (success) {
        if (data.routeSiteList.length == 0) {
          this.form.formList = [
            {
              siteNumber: "",
              siteName: "",
              carRouteId: this.rowData.id,
              parkId: sessionStorage.getItem("parkId"),
              longitude: "",
              latitude: "",
              id: "",
            },
          ];

          this.polyline1 = new AMap.Polyline({
            path: this.rowData.siteList, // 设置线覆盖物路径
            showDir: true,
            strokeColor: "#1683D9", // 线颜色
            strokeWeight: 10, // 线宽
          });
          this.map.add([this.polyline1]);
        } else {
          data.routeSiteList.forEach((item, index) => {
            //     siteNumber: "",
            // siteName: "",
            // carRouteId: "",
            // parkId: sessionStorage.getItem("parkId"),
            // longitude: "",
            // latitude: "",
            // id:""
            this.form.formList.push({
              siteNumber: item.siteNumber,
              siteName: item.siteName,
              carRouteId: this.rowData.id,
              parkId: sessionStorage.getItem("parkId"),
              longitude: item.longitude,
              latitude: item.latitude,
              id: item.id,
            });
          });
          this.drawSite();
        }
      }
    },

    async getParkInfo() {
      let CenterPoint = null;

      await _ParkaccountMngRnge({ userParkId: this.addForm.parkId }).then(
        (res) => {
          if (res.success) {
            CenterPoint = calculateCenter(JSON.parse(res.data.parkScope));

            if (CenterPoint) {
              this.parkCenterPoint = [CenterPoint.lng, CenterPoint.lat];
            }
          }
        }
      );
    },

    drawClose() {
      // this.addForm.routeNumber = "";
      // this.addForm.routeName = "";
      // this.addForm.siteList = "";
      Object.assign(this.$data.addForm, this.$options.data().addForm);
      this.$refs.addform.resetFields();
    },
    drawImportClose() {
      this.carTypetion.forEach((item) => {
        item.disabled = false;
      });
      Object.assign(this.$data.fileForm, this.$options.data().fileForm);
      this.$refs.fileForm.resetFields();
    },
    //重置
    reset() {
      this.list = {
        page: 1,
        size: 10,
        total: this.list.total,
        parkId: sessionStorage.getItem("parkId"),
        routeName: "",
      };
      this.getList();
    },
    // 清扫车与其他车辆类型不能同时选择
    valuesChange(data) {
      if (data && data.length) {
        if (data.includes("5")) {
          this.carTypetion.forEach((item) => {
            item.disabled = item.carTypeId !== "5";
          });
        } else {
          this.carTypetion.forEach((item) => {
            item.disabled = item.carTypeId === "5";
          });
        }
      } else {
        this.carTypetion.forEach((item) => {
          item.disabled = false;
        });
      }
    },

    // 创建
    establish() {
      this.$refs.addform.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.insertLine();
          } else {
            this.getUp();
          }
        }
      });
    },

    //添加线路
    async insertLine() {
      let obj = JSON.parse(JSON.stringify(this.addForm));
      // console.log(AddParams.siteList.length);
      if (obj.siteList.length != 0) {
        if (this.addForm.siteList.$x) {
          obj.siteList = this.addForm.siteList.$x[0];
        } else {
          obj.siteList = this.addForm.siteList;
        }
        obj.carTypeId = this.addForm.carTypeId.toString();
        this.isLoading = true;
        _PmLineAdd(obj).then((res) => {
          if (!res) {
            this.isLoading = false;
          }
          if (res?.success) {
            this.$message.success("创建成功");
            this.getList();
            this.added = false;
          }
          this.isLoading = false;
        });
      } else {
        this.$message({
          message: "线路为必选",
          type: "error",
          center: true,
          customClass: "message-override",
        });
      }
    },

    handleUpXls(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 <= 2;
      if (!isLt2M) {
        this.$message.error({
          message: "线路导入文件上限值为2M",
          type: "error",
          // center: true,
          customClass: "message-override",
        });
        this.fileList = fileList.slice(0, fileList.length - 1);
      } else {
        this.fileList = fileList;
      }
    },

    //获取当前园区的车辆类型  然后存到列表里
    async getcartype() {
      let parkId = sessionStorage.getItem("parkId");
      let arrs = [];
      await _FindParkCarType({ parkId: parkId }).then((res) => {
        if (res.success) {
          for (var i = 0; i < res.data.length; i++) {
            let objs = {
              carTypeId: "",
              carTypeName: "",
            };
            objs.carTypeId = res.data[i].id;
            objs.carTypeName = res.data[i].typeName;
            arrs.push(objs);
          }
        }
        this.carTypetion = arrs;
        this.carOptions = this.carTypetion.filter((ele) => {
          return ele.carTypeId !== "5";
        });
      });
    },
    upload() {
      //  //判断文件是否为空
      if (this.fileList.length == 0) {
        this.$message({
          message: "请添加上传的文件",
          type: "error",
          center: true,
          customClass: "message-override",
        });
        return;
      }
      //判断车辆类型是不是为空
      if (this.fileForm.carTypeId == "") {
        this.$message({
          message: "请选择车辆类型",
          type: "error",
          center: true,
          customClass: "message-override",
        });
        return;
      }
      //判断线路编号是否为数字   名称是否空
      if (this.fileForm.routeNumber == "") {
        this.$message({
          message: "线路编号不能为空",
          type: "error",
          center: true,
          customClass: "message-override",
        });
        return;
      }
      let re = /^[0-9]+.?[0-9]*/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
      if (!re.test(this.fileForm.routeNumber)) {
        this.$message({
          message: "线路编号需为数字",
          type: "error",
          center: true,
          customClass: "message-override",
        });
        return;
      }
      if (this.fileForm.routeName == "") {
        this.$message({
          message: "线路名称不能为空",
          type: "error",
          center: true,
          customClass: "message-override",
        });
        return;
      }
      //判断里程是不是为数字 并且输入了
      if (this.fileForm.routeMileage == "" && !this.fileFormHaveClearCar) {
        this.$message({
          message: "线路里程不能为空",
          type: "error",
          center: true,
          customClass: "message-override",
        });
        return;
      }
      if (!re.test(this.fileForm.routeMileage) && !this.fileFormHaveClearCar) {
        this.$message({
          message: "线路里程需为数字",
          type: "error",
          center: true,
          customClass: "message-override",
        });
        return;
      }
      this.$refs.fileForm.validate((valid) => {
        if (valid) {
          //遮罩
          let loading = this.$loading({
            lock: true,
            text: "导入路线中,请稍后",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let uploadType = "";
          if (this.fileForm.carTypeId == "5") {
            uploadType = 1;
          } else {
            uploadType = 2;
          }
          let file = new FormData();
          file.append("file", this.fileList[0].raw);
          file.append("routeName", this.fileForm.routeName);
          file.append("routeNumber", this.fileForm.routeNumber);
          file.append("carTypeId", this.fileForm.carTypeId.toString());
          if (!this.fileFormHaveClearCar) {
            file.append("routeMileage", this.fileForm.routeMileage);
          }
          file.append("parkId ", this.list.parkId);
          file.append("templateType", uploadType);
          _ExcelUpload(file)
            .then((res) => {
              if (res == undefined) {
                this.carTypetion.forEach((item) => {
                  item.disabled = false;
                });
                loading.close();
                this.Import = false;
              } else {
                if (res.success == true) {
                  this.carTypetion.forEach((item) => {
                    item.disabled = false;
                  });
                  this.$message.success(res.message);
                  this.getList();
                  loading.close();
                  this.Import = false;
                }
              }
            })
            .catch(() => {
              loading.close();
            });
        }
      });
    },

    insertMethods() {
      this.isAdd = true;
      this.addedClick();
    },

    getImport() {
      this.Import = true;
      this.fileList = [];
      this.fileForm = {
        routeNumber: "",
        routeName: "",
        carTypeId: [],
      };
    },

    // 下载模板
    downTemplate() {
      this.exportCar();
    },

    exportCar() {
      let downloadType = "";
      if (this.fileForm.carTypeId == "5") {
        downloadType = 1;
      } else {
        downloadType = 2;
      }
      _ExcelDownload({ templateType: downloadType }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        this.exportLoad = false;

        link.download = "车辆线路导入模板.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.timer = setTimeout(() => {
          this.$message.success("下载成功");
        }, 3000);
      });
    },

    //删除一行
    delFormLine(item, index) {
      if (item.id) {
        _DeleteRouteSite({ routeSiteId: item.id }).then((res) => {
          if (res.success) {
            this.$message.success("删除成功");
            this.add = false;
            // this.getCarRoute(this.rowData.id)
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.form.formList.splice(index, 1);
      }
    },

    // 开始绘制
    addFormItem() {
      this.form.formList.push({
        siteNumber: "",
        siteName: "",
        id: "",
        parkId: sessionStorage.getItem("parkId"),
        longitude: "",
        latitude: "",
        carRouteId: this.rowData.id,
      });
    },

    againDraw() {
      // console.log("撤销线路绘制");
      // this.mouseTool.close(true)
      // console.log(this.addForm.siteList);
      this.addedmap.remove(this.addForm.siteList);
      this.addForm.siteList = [];
    },

    startDraw() {
      this.mouseTool = new AMap.MouseTool(this.addedmap);
      this.isStartDraw = true;
      this.mouseTool.polyline({
        strokeColor: "#1683D9",
        strokeWeight: 6,
        showDir: true,
        strokeStyle: "solid",
      });

      this.mouseTool.on("draw", (type, obj) => {
        this.addForm.siteList = type.obj.$x[0];
      });
    },

    setPolyline(lineArr) {
      var polyline1 = new AMap.Polyline({
        path: lineArr, // 设置线覆盖物路径
        showDir: true,
        strokeColor: "#1683D9", // 线颜色
        strokeWeight: 10, // 线宽
      });
      this.map.add([polyline1]);

      var markers = [];
      var allowCollision = false;
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        // collision: false,
        // 设置 allowCollision：true，可以让标注避让用户的标注
        allowCollision,
      });
      layer.add(markers);
      this.map.add(layer);
    },

    async addedClick() {
      // containerAdd
      this.added = true;
      if (this.isAdd) {
        this.$nextTick(() => {
          this.addedmap = new AMap.Map("containerAdd", {
            resizeEnable: true,
            center: this.parkCenterPoint,
            zoom: 16,
          });
          // this.setPolyline(this.lineArr)
          this.$refs.addform.clearValidate();
        });
      } else {
        this.$nextTick(() => {
          this.addedmap = new AMap.Map("containerAdd", {
            resizeEnable: true,
            center: this.rowData.siteList
              ? this.rowData.siteList[
                  Math.ceil(this.rowData.siteList.length / 2)
                ]
              : this.center,
            zoom: 16,
          });
          this.addForm.siteList = this.rowData.siteList;
          this.addForm.routeNumber = this.rowData.routeNumber;
          this.addForm.routeName = this.rowData.routeName;
          this.addForm.routeMileage = this.rowData.routeMileage;
          this.addForm.carTypeId = this.rowData.carTypeId.split(",");
          this.polyline1 = new AMap.Polyline({
            path: this.rowData.siteList, // 设置线覆盖物路径
            showDir: true,
            strokeColor: "#1683D9", // 线颜色
            strokeWeight: 10, // 线宽
          });
          this.addedmap.add([this.polyline1]);
        });
      }
    },

    addClick(rowdata) {
      // console.log(rowdata);
      this.add = true;

      let LineCenterPoint = calculateCenter(JSON.parse(rowdata.routeSite));

      this.$nextTick(() => {
        this.marker,
          (this.map = new AMap.Map("container", {
            resizeEnable: true,
            center: [LineCenterPoint.lng, LineCenterPoint.lat],
            zoom: 16,
          }));
        // this.setPolyline(this.lineArr)
      });
    },

    ceshiCLick() {
      // console.log("点击一下");
    },

    /**
     * @desc 绘制站点
     * @param rowdata
     */
    drawSite() {
      if (this.siteMarkerList.length > 0) {
        for (let i = 0; i < this.siteMarkerList.length; i++) {
          this.siteMarkerList[i].remove();
        }
      }

      this.$nextTick(() => {
        let routeList = [];
        for (let index = 0; index < this.form.formList.length; index++) {
          // console.log(routeList);
          routeList.push([
            this.form.formList[index].longitude,
            this.form.formList[index].latitude,
          ]);
          // console.log(this.form.formList);

          this.marker = new AMap.Marker({
            icon: iconImg,
            position: [
              this.form.formList[index].longitude,
              this.form.formList[index].latitude,
            ],
          });
          // console.log(this.marker.setMap);
          this.marker.setMap(this.map);
          this.marker.setLabel({
            content: this.form.formList[index].siteName,
          });

          this.siteMarkerList.push(this.marker);
        }

        // console.log("routeList:" + routeList);

        this.polyline1 = new AMap.Polyline({
          path: this.rowData.siteList, // 设置线覆盖物路径
          showDir: true,
          strokeColor: "#1683D9", // 线颜色
          strokeWeight: 10, // 线宽
        });
        this.map.add([this.polyline1]);
        // this.setPolyline(routeList)
      });
    },

    getselect() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          _AddRouteSiteList(this.form.formList).then((res) => {
            if (!res) {
              this.$message.error(res.message);
            }
            if (res.success) {
              this.$message.success("设置成功");
              // this.getList()
              // this.add = false
              this.getCarRoute();
            } else {
              this.$message.error(res.message);
            }
          });
          // console.log(routeList);
        }
      });
      //       var marker=new AMap.Marker({
      //  resizeEnable: true,
      //       center: [116.397428, 39.90923],
      //       zoom: 13
      //       })
      //          var markerContent = document.createElement("div");
      // var markerImg = document.createElement("img");
      //       markerImg.className = "markerlnglat";
      //       markerImg.src = iconImg;
      //       markerImg.setAttribute('width', '25px');
      //       markerImg.setAttribute('height', '34px');
      //       markerContent.appendChild(markerImg);

      //       // 点标记中的文本
      //       var markerSpan = document.createElement("span");
      //       markerSpan.className = 'marker';
      //       markerSpan.innerHTML = "Hi，我被更新啦！";
      //       markerContent.appendChild(markerSpan);

      //       marker.setContent(markerContent); //更新点标记内容
      //  marker.setPosition([116.397428, 39.90923]); //更

      // this.marker = new AMap.Marker({
      //   icon: iconImg,
      //   position: [114.0811928372, 30.4443817432],
      // });

      // this.marker.setMap(this.map);
      // this.marker.setLabel({
      //   content: "定位名称"
      // });

      // if (this.isAdd == true) {
      //   this.form.id = "";
      //   this.getAdd();
      // } else {
      //   this.getUp();
      // }
    },

    async getList() {
      this.loading = true;
      let { success, data, message } = await _PmLineList(this.list);
      // console.log(data)
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2() {
      this.list.page = 1;
      this.getList();
    },

    async getDel() {
      _PmLineDel({
        carRouteId: this.rowData.id,
      }).then((res) => {
        if (res.success == true) {
          this.$message.success("删除线路成功");
          this.loading = false;
          this.getList();
        }
        this.delDiaA = false;
      });
    },

    async getAdd() {
      consoe.log(this.form);
      _PmLineAdd(this.form).then((res) => {
        if (res.success == true) {
          this.$message.success("添加线路成功");
          this.loading = false;
          this.getList();
        }
        this.add = false;
      });
    },

    async getUp() {
      let newform = JSON.parse(JSON.stringify(this.addForm));
      newform.id = this.rowData.id;
      newform.carTypeId = this.addForm.carTypeId.toString();
      _PmLineUp(newform).then((res) => {
        if (res.success == true) {
          this.$message.success("编辑线路成功");
          this.loading = false;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
        this.added = false;
      });
    },
  },
};
</script>
<style>
.el-form-item__label {
  text-align: left;
}

.el-form-item {
  margin-right: 20px !important;
}
</style>
<style scoped>
.el-form-item__label {
  text-align: left;
}

.el-input__inner {
  border: none !important;
  height: 36px;
}

.largMap {
  width: 95%;
  height: 400px;
}

.largMap2 {
  width: 95%;
  /* height: 75%; */
  height: 600px;
}

/deep/.centerCzInput > input,
.centerCzSelInput > div > input {
  width: 170px;
}

.ip_value {
  height: 36px;
  margin-left: 20px;
}

/deep/.el-form-item {
  display: inline-block;
}

.red {
  margin-left: 20px;
}

.largMap2 .img {
  position: fixed;
  margin-top: -28%;
  margin-left: 20px;
}

.demo-drawer__footer {
  margin-top: 70px;
  margin-left: 45%;
}

/* .el-form-item {
  margin: 0%;
} */

.textTop {
  margin: 20px 0px;
}

.tableTop {
  margin-top: 20px;
}

.textType {
  margin-bottom: 20px;
}

.textLeft {
  margin-left: 20px;
}

#containerAdd,
#container {
  height: 550px;
  width: 100%;
  position: relative;
  top: 30px;
}

#lookContainer {
  height: 550px;
}

#container img,
#containerAdd img {
  position: absolute;
  top: 13px;
  z-index: 100;
  cursor: pointer;
}

.drawImg {
  left: 22px;
}

.returnImg {
  left: 71px;
}

.establish {
  margin: 10px 0px;
}

.iconOper img {
  vertical-align: middle;
  margin-right: 20px;
  cursor: pointer;
}

.formclass {
  margin-bottom: 20px;
}

.drawfoot {
  display: flex;
  justify-content: flex-end;
}
.el-upload__tip {
  display: flex;
  justify-content: space-between;
  padding-right: 43px;
  margin-bottom: 22px;
}
.el-upload__tip > span:last-child {
  cursor: pointer;
  color: #1683d9;
}
</style>
