<template>
  <div>
    <div>
      <button
        class="centerBtn"
        @click="addAccount = true"
        style="margin-bottom: 20px"
      >
        新增
      </button>
    </div>
    <el-form :inline="true">
      <el-form-item>
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20">搜索</button>
        <button class="centerBtn resetbtn">重置</button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
       @getAjax="getTableData"
    />
    <el-drawer
      title="新增消息"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules">
          <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
            <el-input
              v-model="form.title"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="内容" :label-width="formLabelWidth" prop="value">
            <el-input
              type="textarea"
              class="centerCzInput"
              :rows="4"
              placeholder="请输入内容"
              v-model="value"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="发送范围"
            :label-width="formLabelWidth"
            prop="sendingRange"
            class="select"
          >
            <el-select
              v-model="form.sendingRange"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in rangeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button class="centerCzbtn" :loading="isLoading">保存</el-button>
        <button class="centerBtn resetbtn" @click="addAccount = false">
          取消
        </button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      addAccount: false,
      isLoading:false,
      value1: "",
      value: "",
      formLabelWidth: "100px",
      rangeList:[],
      searchObj: {
        vehicleNumber: "",
        operationMode: "",
        operationStatus: "",
      },
      form: {
        title: "",
        value: "",
        sendingRange: "",
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur",
          },
        ],
        value: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        sendingRange: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
      },
      tableData: [
        {
          title: "operation",
          value: "测试app消息通知（技术中)",
          publishObject: "普通",
          timeA: "2021-01-26 11:01:48",
          timeB: "2021-01-26 11:01:48",
          Publisher: "饶时彦",
          releaseStatus: "已发送",
          operation: "-",
        },
        {
          title: "operation",
          value: "测试app消息通知（技术中)",
          publishObject: "普通",
          timeA: "2021-01-26 11:01:48",
          timeB: "2021-01-26 11:01:48",
          Publisher: "饶时彦",
          releaseStatus: "已发送",
          operation: "-",
        },
        {
          title: "operation",
          value: "测试app消息通知（技术中)",
          publishObject: "普通",
          timeA: "2021-01-26 11:01:48",
          timeB: "2021-01-26 11:01:48",
          Publisher: "饶时彦",
          releaseStatus: "已发送",
          operation: "-",
        },
        {
          title: "operation",
          value: "测试app消息通知（技术中)",
          publishObject: "普通",
          timeA: "2021-01-26 11:01:48",
          timeB: "2021-01-26 11:01:48",
          Publisher: "饶时彦",
          releaseStatus: "已发送",
          operation: "-",
        },
      ],
      columns: [
        {
          label: "标题",
          prop: "title",
        },
        {
          label: "内容预览",
          prop: "value",
        },
        {
          label: "发布对象",
          prop: "publishObject",
        },
        {
          label: "创建时间",
          prop: "timeA",
        },

        {
          label: "发布时间",
          prop: "timeB",
        },
        {
          label: "发布人",
          prop: "Publisher",
        },
        {
          label: "发布状态",
          prop: "releaseStatus",
        },
        {
          label: "操作",
          prop: "operation",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.textcolor {
  font-size: 14px;
  color: #666666;
  margin-right: 20px;
}
.textcolor2 {
  font-size: 14px;
  color: #333333;
}
.el-input__inner {
  border: none !important;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}

</style>
