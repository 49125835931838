<template>
  <div>
    <p class="title">{{ companyName }}</p>
    <div class="flex">
      <div class="flex align-center">
        <img src="../../assets/images/price.png" alt="" />
        <span class="text">{{ companyAddress }}</span>
      </div>
      <div class="flex align-center">
        <img src="../../assets/images/phone.png" alt="" />
        <span class="text">{{ phone }}</span>
      </div>
    </div>
    <div class="value flex flex-wrap">
      <div
        class="park flex justify-between"
        v-for="(item, index) in list"
        :key="index"
        @click="getpush(item.id, item.companyName)"
        style="height: 170px"
      >
        <div style="width: 100%">
          <p class="flex align-center">
            <span class="leftShape"></span>
            <span class="shapeText">{{ item.companyName }}</span>
          </p>

          <div class="car-type-list">
            <template v-for="(type, idx) in item.carTypeIds">
              <span class="car-type-item car-type-icon-1" v-if="type == 1"
                >微公交</span
              >
              <span class="car-type-item car-type-icon-2" v-if="type == 2"
                >SV-BUS</span
              >
              <span class="car-type-item car-type-icon-3" v-if="type == 3"
                >观光车</span
              >
              <span class="car-type-item car-type-icon-4" v-if="type == 4"
                >巡逻车</span
              >
              <span class="car-type-item car-type-icon-5" v-if="type == 5"
                >清扫车</span
              >
            </template>
          </div>
        </div>
        <p class="flex align-center">
          <img
            src="../../assets/images/right.png"
            alt=""
            width="20px"
            height="20px"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { _ParkList, _DelparkList, _Addpark } from "@/api/systemMng";
export default {
  components: {},
  data() {
    return {
      companyName: "",
      companyAddress: "",
      phone: "",
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //园区列表
    async getList() {
      let { success, data, message } = await _ParkList({
        companyId: sessionStorage.getItem("companyId"),
      });
      if (success) {
        this.parkList = data[0];
        this.companyName = data[0].companyName;
        this.companyAddress = data[0].companyAddress;
        this.phone = data[0].phone;
        this.list = data[0].companyList;

        let CarTypeList;
        let nListCount = this.list.length;
        let nCarTypeCount = 0;

        for (let i = 0; i < nListCount; i++) {
          CarTypeList = [];

          if (this.list[i].carTypeIds) {
            nCarTypeCount = this.list[i].carTypeIds.length;

            for (let j = 0; j < nCarTypeCount; j++) {
              if (
                CarTypeList.indexOf(this.list[i].carTypeIds[j]) === -1 &&
                this.list[i].carTypeIds[j] !== null
              ) {
                CarTypeList.push(this.list[i].carTypeIds[j]);
              }
            }

            this.list[i].carTypeIds = CarTypeList;
          }
        }
      }
    },
    //点击跳转到园区
    getpush(item, companyName) {
      window.sessionStorage.setItem("parkId", item);
      window.sessionStorage.setItem("companyName", companyName);
      window.sessionStorage.setItem("type", 3);
      this.$store.commit("initRoutes", []);
      this.$router.replace({
        path: "/parkOverview",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.car-type-list {
  width: 100%;
  margin-top: 20px;
  padding-left: 20px;

  .car-type-item {
    display: block;
    width: 140px;
    float: left;
    padding: 5px 5px 5px 57px;
    color: #333;
    font-weight: 400;
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 52px auto;
  }
  .car-type-icon-1 {
    background-image: url("../../assets/images/car_type_1.png");
  }
  .car-type-icon-2 {
    background-image: url("../../assets/images/car_type_2.png");
  }
  .car-type-icon-3 {
    background-image: url("../../assets/images/car_type_3.png");
  }
  .car-type-icon-4 {
    background-image: url("../../assets/images/car_type_4.png");
  }
  .car-type-icon-5 {
    background-image: url("../../assets/images/car_type_5.png");
  }
}

p {
  margin: 0;
}
.title {
  font-size: 27px;
  font-weight: 500;
  color: #1683d9;
  margin-bottom: 10px;
}
.text {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-right: 52px;
}
.value {
  word-wrap: break-word;
  word-break: normal;
}
.park {
  width: 45%;
  height: 114px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  margin-top: 22px;
  padding-right: 20px;
  margin-right: 3%;
  cursor: pointer;
}
.leftShape {
  display: inline-block;
  width: 6px;
  height: 24.87px;
  background-color: #1683d9;
  margin-top: 25px;
}
.shapeText {
  font-size: 20px;
  font-weight: 400;
  color: #1683d9;
  margin-top: 25px;
  margin-left: 14px;
}
.carText {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-right: 21px;
}
</style>
