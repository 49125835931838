import service from "../../until/api";
//车辆运营
export function _PmLineList(data) {
  return service({
    url: "vehicle-auth/carRoute/findAllPage",
    method: "GET",
    params: data,
  });
}
export function _PmLineDel(data) {
  return service({
    url: "vehicle-auth/carRoute/deleteCarRoute",
    method: "GET",
    params: data,
  });
}

export function _PmLineAdd(data) {
  return service({
    url: "vehicle-auth/carRoute/addCarRoute",
    method: "POST",
    data,
  });
}
export function _PmLineUp(data) {
  return service({
    url: "vehicle-auth/carRoute/updateCarRoute",
    method: "POST",
    data,
  });
}

//添加线路站点
export function _AddRouteSite(data) {
  return service({
    url: "vehicle-auth/routeSite/addRouteSite",
    method: "POST",
    data,
  });
}

//根据id查询站点

export function _FindByCarRouteId(data) {
  return service({
    url: "vehicle-auth/routeSite/findByCarRouteId",
    method: "GET",
    params: data,
  });
}

// 线路管理详情

export function _FindOneSite(data) {
  return service({
    url: "vehicle-auth/carRoute/findOne",
    method: "GET",
    params: data,
  });
}

//添加多个站点
export function _AddRouteSiteList(data) {
  return service({
    url: "vehicle-auth/routeSite/addRouteSiteList",
    method: "POST",
    data,
  });
}

// 删除线路站点
export function _DeleteRouteSite(data) {
  return service({
    url: "vehicle-auth/routeSite/deleteRouteSite",
    method: "GET",
    params: data,
  });
}
//修改线路站点
export function _UpdateRouteSite(data) {
  return service({
    url: "vehicle-auth/routeSite/updateRouteSite",
    method: "POST",
    data,
  });
}

//摄像头管理
export function _CameraMng(data) {
  return service({
    url: "vehicle-auth/carCamera/findAllPage",
    method: "GET",
    params: data,
  });
}

export function _CameraAdd(data) {
  return service({
    url: "vehicle-auth/carCamera/add",
    method: "POST",
    data,
  });
}
//
export function _Cameraedit(data) {
  return service({
    url: "vehicle-auth/carCamera/update",
    method: "POST",
    data,
  });
}
//删除

export function _Cameradel(data) {
  return service({
    url: "vehicle-auth/carCamera/delete",
    method: "GET",
    params: data,
  });
}
export function _CameraType(data) {
  return service({
    url: "vehicle-auth/carType/findAllType",
    method: "GET",
    params: data,
  });
}
//线路管理导入
export function _ExcelUpload(data) {
  return service({
    url: "file-nacos/excelUpload/uploadCarRouteByCsvV2",
    method: "POST",
    data,
  });
}
export function _ExcelDownload(data) {
  return service({
    url: "file-nacos/excelDownload/exportCarRouteTemplate",
    method: "GET",
    params: data,
    responseType: "blob",
  });
}

//获取当前园区拥有的车辆类型
export function _FindParkCarType(data) {
  return service({
    url: "/user-nacos/company/findParkCarType",
    method: "GET",
    params: data,
  });
}

/**
 * @msg: 校验当前车辆路线的排班计划
 * @param {*} data
 */
export function _VerifyCarWorking(data) {
  return service({
    url: "/vehicle-auth/carRoute/checkCarRoutesShiftPlan",
    method: "GET",
    params: data,
  });
}

/**
 * @msg: 改变线路使用状态
 * @param {*} data
 */
export function _ChangeStatus(data) {
  return service({
    url: "/vehicle-auth/carRoute/updateCarRouteStatus",
    method: "post",
    data,
  });
}
