import service from '../../until/api'
//车辆运营
export function _AppFeedback(data) {
    return service({
        url: 'system-nacos/userFeedBack/findAllPage',
        method: 'GET',
        params: data
    })
}
//消息管理

export function _AppMsgFeedback(data) {
    return service({
        url: 'system-nacos/messageManage/findAllPage',
        method: 'GET',
        params: data
    })
}

//消息管理添加
export function _AppAddMsgFeedback(data) {
    return service({
        url: 'system-nacos/messageManage/addMessage',
        method: 'POST',
        data
    })
}

export function _AppreleaseMessage(data) {
    return service({
        url: 'system-nacos/messageManage/releaseMessage',
        method: 'GET',
        params: data
    })
}
//回复
export function _AppreplyFeedback(data) {
    return service({
        url: 'system-nacos/userFeedBack/replyFeedback',
        method: 'POST',
        data
    })
}

/**
 * @name: _AppMsgEdit
 * @msg: 消息编辑
 * @param {*}
 * @return {*}
 */
 export function _AppMsgEdit(data) {
    return service({
        url: '/system-nacos/messageManage/editMessage',
        method: 'POST',
        data
    })
}

/**
 * @name: _AppMsgDelete
 * @msg: 消息删除
 * @param {*}
 * @return {*}
 */
 export function _AppMsgDelete(data) {
    return service({
        url: '/system-nacos/messageManage/deleteMessage',
        method: 'get',
        params: data
    })
}