<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="searchObj.vehicleNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="searchObj.operationStatus"
          placeholder="车辆状态"
          class="centerSelInput"
        >
          <el-option
            v-for="item in StatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20">搜索</button>
        <button class="centerBtn resetbtn">重置</button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="10"

      :loading="loading"
    />
    <el-dialog title="切换运营状态" :visible.sync="resetcarstatus" width="30%">
      <p>
        <span class="textcolor">当前车辆运营状态</span>
        <span class="textcolor2">空闲</span>
      </p>
      <div>
        <span class="red">*</span><span class="textcolor">切换运营状态至</span>
        <el-radio v-model="radio" label="1">维修中</el-radio>
        <el-radio v-model="radio" label="2">运营下线</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="resetcarstatus = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      radio: "1",
      resetcarstatus: false,
      operationMode: false,
      formLabelWidth: "100px",
      isAdd: false,
      StatusList:[],
      searchObj: {
        vehicleNumber: "",
        operationStatus: "",
      },

      tableData: [
        {
          carnum: "SV10004",
          cartype: "清扫车",

          carstatus: "在线",
          drivingRoute: "技术中心路线",
        },
        {
          carnum: "SV10004",
          cartype: "清扫车",
          carstatus: "在线",

          drivingRoute: "技术中心路线",
        },
        {
          carnum: "SV10004",
          cartype: "清扫车",
          carstatus: "在线",

          drivingRoute: "技术中心路线",
        },
      ],
      columns: [
        {
          label: "车辆编号",
          prop: "carnum",
        },
        {
          label: "车辆类型",
          prop: "cartype",
        },

        {
          label: "车辆状态",
          prop: "carstatus",
        },
        {
          label: "行驶路线",
          prop: "drivingRoute",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
</style>