import { render, staticRenderFns } from "./swpvideoViewing.vue?vue&type=template&id=0632bc79&scoped=true&"
import script from "./swpvideoViewing.vue?vue&type=script&lang=js&"
export * from "./swpvideoViewing.vue?vue&type=script&lang=js&"
import style0 from "./swpvideoViewing.vue?vue&type=style&index=0&id=0632bc79&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0632bc79",
  null
  
)

export default component.exports