<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.phone"
          placeholder="请输入手机号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="parkaccountMngList"
      :columns1="columns"
      :total="total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <!-- 添加车辆弹框 -->
    <el-drawer
      title="回复"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      :before-close="handleClose"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules">
          <el-form-item
            label="回复"
            :label-width="formLabelWidth"
            prop="replyContent"
          >
            <el-input
              type="textarea"
              class="centerCzInput"
              :rows="10"
              placeholder="请输入"
              v-model="form.replyContent"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn" @click="getmsg()">保存</button>
        <button class="centerBtn resetbtn" @click="cancelB">取消</button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import { _AppFeedback, _AppreplyFeedback } from "@/api/appMng";
// import { _RoleList } from "@/api/parksysMng";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      addAccount: false,
      accountEdit: false,
      delDia: false,
      resetPwd: false,
      formLabelWidth: "100px",
      isAdd: false,
      userParkId: "",
      total: 0,
      form: {
        replyContent: "",
        appUserId: "",
        userFeedbackId: "",
      },
      rules: {
        replyContent: [
          {
            required: true,
            message: "请输入回复内容",
            trigger: "blur",
          },
        ],
      },

      columns: [
        {
          label: "用户手机号",
          prop: "phone",
        },
        {
          label: "反馈内容",
          prop: "feedbackContent",
        },
        {
          label: "回复内容",
          prop: "replyContent",
        },
        {
          label: "时间",
          prop: "createTime",
          width: 200,
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.addAccount = true;
                      this.form.appUserId = params.row.appUserId;
                      this.form.userFeedbackId = params.row.id;
                    },
                  },
                },
                "回复"
              ),
            ]);
          },
        },
      ],
      list: {
        page: "1",
        size: "10",
        feedbackType: 2,
        phone: "",
      },
      parkaccountMngList: [],
      rolelist: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancelB() {
      this.addAccount = false;
      this.form.replyContent = "";
    },
    handleClose(done) {
      window.location.reload();
      this.addAccount = false;
      this.form = {
        roleParkIds: [],
        name: "",
        phone: "",
        username: "",
      };
    },
    cancel() {
      this.list.page=1
      this.list.phone = "";
      this.list.username = "";
      this.getList();
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async getList() {
      let { success, data, message } = await _AppFeedback(this.list);
      if (success) {
        this.parkaccountMngList = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2(){
      this.list.page = 1;
      this.getList();
    },
    async getmsg() {
      let { success, data, message } = await _AppreplyFeedback(this.form);
      if (success) {
        this.$message.success("回复成功");
        this.addAccount = false;
        this.getList();
      }
    },
  },
};
</script>

<style scoped>
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 90px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
</style>