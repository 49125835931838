<template>
  <div>
    <el-form :inline="true" class="txetTopB">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-input
          v-model="list.siteName"
          placeholder="站点名称"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.orderStatus"
          placeholder="订单状态"
          class="centerSelInput centerInput"
        >
          <el-option
            v-for="item in StatusList"
            :key="item.id"
            :label="item.staus"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          :clearable="false"
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          class="timePicker"
          @change="getTime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancelB">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      :loading="loading"
      @getAjax="getTableData"
    />

    <el-dialog title="切换运营状态" :visible.sync="operationStatus" width="30%">
      <p>
        <span class="textcolor">当前状态</span>
        <span class="textcolor2">空闲</span>
      </p>
      <div>
        <span class="red">*</span><span class="textcolor">切换运营状态至</span>
        <el-radio v-model="radio" label="1">维修中</el-radio>
        <el-radio v-model="radio" label="2">运营下线</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="operationStatus = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-dialog title="切换运营模式" :visible.sync="operationMode" width="30%">
      <p>
        <span class="textcolor">当前车辆运营模式</span>
        <span class="textcolor2">普通模式</span>
      </p>
      <p>
        <span class="textcolor">是否切换运营模式至</span>
        <span class="textcolor2">vip模式?</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="operationMode = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-drawer
      :title="
        detailList.orderStatus == 1
          ? '订单详情【已预约】'
          : '' || detailList.orderStatus == 2
          ? '订单详情【已取消】'
          : '' || detailList.orderStatus == 3
          ? '订单详情【已接单】'
          : '' || detailList.orderStatus == 4
          ? '订单详情【已上车】'
          : '' || detailList.orderStatus == 5
          ? '订单详情【已完成】'
          : ''
      "
      :visible.sync="detail"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div>
        <p>
          <span class="textc">订单编号:</span>
          <span class="textd">{{ detailList.orderNo }}</span>
        </p>
        <p>
          <span class="textc">车辆编号:</span>
          <span class="textd">{{ detailList.carNumber }}</span>
        </p>
        <p>
          <span class="textc">车辆类型:</span>
          <span class="textd">{{ detailList.carType }}</span>
        </p>
        <p>
          <span class="textc">车辆子类型：</span>
          <span class="textd">{{ detailList.sonCarType }}</span>
        </p>
        <p>
          <span class="textc">安全员:</span>
          <span class="textd">{{ detailList.securityName }}</span>
        </p>
        <p>
          <span class="textc">手机号:</span>
          <span class="textd">{{ detailList.phone }}</span>
        </p>
        <p class="flex align-center textB">
          <img
            src="../../assets/images/bus_Route.png"
            alt=""
            width="21px"
            height="21px"
          />
          <span class="route_text">{{ detailList.routeName }}</span>
        </p>
        <p class="textA">
          <img src="../../assets/images/bus_red.png" alt="" />
          <span class="text">起点</span>
          <img src="../../assets/images/bus_green.png" alt="" />
          <span class="text">终点</span>
        </p>
        <p class="textA">
          <span class="textB textR">{{ detailList.startPointName }}</span>
          <span class="textB">{{ detailList.endPointName }}</span>
        </p>
        <p class="textB">
          <span class="textc">上车时间:</span>
          <span class="textd">{{ detailList.boardingTime }}</span>
        </p>
        <p>
          <span class="textc" v-if="detailList.orderStatus == 5"
            >完成时间:</span
          >
          <span class="textc" v-else-if="detailList.orderStatus == 2"
            >取消时间:</span
          >
          <span class="textd">{{ detailList.completionTime }}</span>
        </p>
      </div>
      <!-- <div class="demo-drawer__footer">
        <button class="centerCzbtn" @click="detail = false">确认</button>
        <button class="centerBtn resetbtn" @click="detail = false">取消</button>
      </div> -->
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";

import { _TuBusorderManage, _TuBusOrderList } from "@/api/tuBus";
export default {
  components: {
    Table,
  },
  data() {
    return {
      valueTime:[],
      loading: false,
      detail: false,
      formLabelWidth: "100px",
      isAdd: false,
      operationStatus: false,
      operationMode: false,
      radio: "",
      StatusList: [
        { id: "1", staus: "已预约" },
        { id: "2", staus: "已取消" },
        { id: "4", staus: "已上车" },
        { id: "5", staus: "已完成" },
      ],
      searchObj: {
        vehicleNumber: "",
        siteName: "",
        operationStatus: "",
      },
      tableData: [],
      detailList: [],
      columns: [
        {
          label: "订单号",
          prop: "orderNo",
        },
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },

        {
          label: "用户手机号",
          prop: "phone",
        },

        {
          label: "完成时间",
          prop: "completionTime",
        },
        {
          label: "状态",
          render: (h, params) => {
            if (params.row.orderStatus == 1) {
              return h("span", "已预约");
            } else if (params.row.orderStatus == 2) {
              return h("span", "已取消");
            } else if (params.row.orderStatus == 4) {
              return h("span", "已上车");
            } else if (params.row.orderStatus == 5) {
              return h("span", "已完成");
            } else if (params.row.orderStatus == 6) {
              return h("span", "已出发");
            }
          },
        },
        {
          label: "操作",
          width: 140,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationB",
                  on: {
                    click: () => {
                      this.detail = true;
                      this.getDetail(params.row.id);
                    },
                  },
                },
                "详情"
              ),
            ]);
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        carNumber: "",
        siteName: "",
        orderStatus: "",
        parkId: sessionStorage.getItem("parkId"),
        startTime: null,
        endTime: null
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancelB() {
      this.list = {
        page: 1,
        size: 10,
        carNumber: "",
        siteName: "",
        orderStatus: "",
        parkId: sessionStorage.getItem("parkId"),
        startTime: null,
        endTime: null
      };
      this.valueTime = []
      this.getList();
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async getList() {
      let { success, data, message } = await _TuBusorderManage(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },

    async getDetail(id) {
      let { success, data, message } = await _TuBusOrderList({
        orderId: id,
      });
      if (success) {
        this.detailList = data;
        this.loading = false;
      }
    },
    getTime(){
      if (this.valueTime != null) {
				this.list.startTime = this.valueTime[0]
				this.list.endTime  = this.valueTime[1]
			}
    }
  },
};
</script>

<style scoped>
.carnum {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.route_text {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
.text {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-left: 5px;
  margin-right: 136px;
}
.textB {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  /* margin-left: 10px; */
  /* margin-right: 126px; */
}
.textR {
  margin-right: 96px;
}
.textc {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.textd {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
.textA {
  margin-left: 24px;
  margin-top: 12px;
}
.textB {
  margin-top: 50px;
}
</style>
