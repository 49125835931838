<template>
  <div>
    <button
      class="centerBtn"
      @click="addAccount = true"
      style="margin-bottom: 20px"
    >
      新增
    </button>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="searchObj.vehicleNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20">搜索</button>
        <button class="centerBtn resetbtn">重置</button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="10"
      :loading="loading"
    />
    <el-drawer
      title="新增消息"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules">
          <el-form-item
            label="任务类型"
            :label-width="formLabelWidth"
            prop="taskType"
            class="select"
          >
            <el-select
              v-model="form.taskType"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in TypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="计划开始时间"
            :label-width="formLabelWidth"
            prop="timeA"
            class="select time"
          >
            <el-time-select v-model="form.timeA" placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <el-form-item
            label="计划结束时间"
            :label-width="formLabelWidth"
            prop="timeB"
            class="select time"
          >
            <el-time-select v-model="form.timeB" placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <el-form-item
            label="行驶路线"
            :label-width="formLabelWidth"
            prop="drivingRoute"
            class="select"
          >
            <el-select
              v-model="form.drivingRoute"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in drivingRouteList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="选择登机口"
            :label-width="formLabelWidth"
            prop="boardingGate"
            class="select"
          >
            <el-select
              v-model="form.boardingGate"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in boardingGateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="选择车辆"
            :label-width="formLabelWidth"
            prop="vehicle"
            class="select"
          >
            <el-select
              v-model="form.vehicle"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in vehiclerList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn">保存</button>
        <button class="centerBtn resetbtn" @click="addAccount = false">
          取消
        </button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      addAccount: false,
      value1: "",
      value: "",
      formLabelWidth: "100px",
      TypeList:[],
      drivingRouteList:[],
      boardingGateList:[],
      vehiclerList:[],
      searchObj: {
        vehicleNumber: "",
        operationMode: "",
        operationStatus: "",
      },
      form: {
        taskType: "",
        timeA: "",
        timeB: "",
        drivingRoute: "",
        boardingGate: "",
        vehicle: "",
      },
      rules: {
        taskType: [
          {
            required: true,
            message: "请选择任务类型",
            trigger: "blur",
          },
        ],
        timeA: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "blur",
          },
        ],
        timeB: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "blur",
          },
        ],
        drivingRoute: [
          {
            required: true,
            message: "请输入行驶线路",
            trigger: "blur",
          },
        ],
        boardingGate: [
          {
            required: true,
            message: "请选择登机口",
            trigger: "blur",
          },
        ],
        vehicle: [
          {
            required: true,
            message: "请选择车辆",
            trigger: "blur",
          },
        ],
      },
      tableData: [
        {
          carnum: "SV10004",
          taskType: "清扫",
          drivingRoute: "技术中心线路",
          timeA: "2021-06-30 10:24:44",
          timeB: "2021-06-30 10:24:44",
          boardingGate: "1号",
          addPeople: "王佳",
          operation: "-",
        },
      ],
      columns: [
        {
          label: "车辆编号",
          prop: "carnum",
        },

        {
          label: "任务类型",
          prop: "taskType",
        },

        {
          label: "计划开始时间",
          prop: "timeA",
        },

        {
          label: "任务开始时间",
          prop: "timeB",
        },
        {
          label: "行驶路线",
          prop: "drivingRoute",
        },
        {
          label: "登机口",
          prop: "boardingGate",
        },
        {
          label: "添加人",
          prop: "addPeople",
        },
        {
          label: "操作",
          prop: "operation",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.textcolor {
  font-size: 14px;
  color: #666666;
  margin-right: 20px;
}
.textcolor2 {
  font-size: 14px;
  color: #333333;
}
.el-input__inner {
  border: none !important;
  height: 36px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
</style>