<template>
  <div>
    <button class="centerBtn" style="margin-bottom: 20px" @click="getDowload">
      导出
    </button>
    <el-form :inline="true">
      <el-form-item
        ><el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item
        ><el-input
          v-model="list.username"
          placeholder="账号名"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin-right: 20px"
          value-format="yyyy-MM-dd"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import { _SQcarControlList, _SQcarexcelDownloa } from "@/api/statisticalQuery";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      addAccount: false,
      formLabelWidth: "100px",
      isAdd: false,
      lineList: [],
      tableData: [],
      columns: [
        {
          label: "账号名",
          prop: "userName",
        },
        {
          label: "日期",
          prop: "controlTime",
        },
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆VIN",
          prop: "carVin",
        },
        {
          label: "车辆类型",
          prop: "typeName",
        },
        {
          label: "车辆子类型",
          prop: "sonTypeName",
        },
        {
          label: "运营类别",
          prop: "operationTypeName",
        },
        {
          label: "操作",
          prop: "controlCommand",
        },
        {
          label: "执行结果",

          render: (h, params) => {
            if (params.row.controlResult == 1) {
              return h("div", "成功");
            } else {
              return h("div", "失败");
            }
          },
        },
        {
          label: "操作来源",
          prop: "controlSource",
        },
      ],
      valueTime: "",
      list: {
        page: 1,
        size: 10,
        total: 0,
        loading: false,
        carNumber: "",
        username: "",
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.valueTime = "";
      this.list = {
        page: 1,
        size: 10,
        total: this.list.total,
        carNumber: "",
        username: "",
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList();
    },
    async getList() {
      this.list.loading = true;
      let { success, data, message } = await _SQcarControlList(this.list);
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.list.loading = false;
      }
    },
    // 搜索
    getList2(){
      this.list.page = 1;
      this.getList();
    },
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.valueTime[0];
        this.list.endTime = this.valueTime[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    async getTableData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }

      await this.getList();
    },

    async getDowload() {
      _SQcarexcelDownloa({
				carNumber: this.list.carNumber,
				username: this.list.username,
				startTime: this.valueTime[0],
				endTime: this.valueTime[1],
				parkId: this.list.parkId,
      }).then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        this.exportLoad = false;

        link.download = "车辆控制记录导出数据.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.timer = setTimeout(() => {
          this.$message.success("导出成功");
        }, 3000);
      });
    },
  },
};
</script>

<style scoped>
.el-input__inner {
  border: none !important;
  height: 36px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
</style>
