<template>
  <div>
    <button
      class="centerBtn"
      @click="(addDia = true), (isAdd = true), (form.role = '')"
      style="margin-bottom: 20px"
    >
      新增
    </button>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.roleName"
          placeholder="请输入角色名"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item
        ><button class="centerCzbtn" type="button" @click="getList2">搜索</button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="roleList"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <!-- 添加角色弹框 -->
    <el-drawer
      :title="isAdd ? '添加角色' : '编辑角色'"
      :visible.sync="addDia"
      width="500px"
      @closed="handleClose"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" inline :rules="addrules" ref="form">
          <el-form-item label="角色" label-width="100px" prop="role">
            <el-input
              class="centerCzInput"
              style="width: 380px"
              v-model="form.role"
              autocomplete="off"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item label-width="100px" label="设置功能权限" prop="menuIds">
            <TreeSelect
              style="width: 380px"
              :options="treeData"
              multiple
              v-model="form.menuIds"
              @getValue="getValue"
            ></TreeSelect>
          </el-form-item>
          <el-form-item
            v-if="isShow"
            label-width="100px"
            label="集卡控车权限"
            prop="truckControlAuthorized"
          >
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="form.truckControlAuthorized"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span class="demo-drawer__footer">
        <el-button class="centerCzbtn" @click="getSelect" :loading="isLoading"
          >确 定</el-button
        >
        <button class="centerBtn resetbtn" @click="handleCloseB">取 消</button>
      </span>
    </el-drawer>

    <!-- 删除弹框 -->
    <el-dialog title="删除" :visible.sync="delDia" width="30%">
      <span>确认删除角色</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getEdit">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">取 消</button>
      </span>
    </el-dialog>

    <!-- 设置权限 -->
    <el-drawer
      title="设置功能权限"
      :destroy-on-close="true"
      :visible.sync="jurisdiction"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          show-checkbox
          node-key="id"
          default-expand-all
          :default-checked-keys="selectedTreeData"
          @check="checkChange"
          ref="resourcesMenuRef"
        >
        </el-tree>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn" @click="jurisdictionCfm">确认</button>
        <button class="centerBtn resetbtn" @click="jurisdiction = false">取消</button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import TreeSelect from "@/components/treeSelect.vue";
import Table from "../../components/Table.vue";
import {
  _RoleMngList,
  _AddroleMngpname,
  _EditroleMngpname,
  _AddroleMng,
  _EditroleMng,
  _Editrole,
  _GetRoute,
  _GetRojur,
} from "@/api/parksysMng";
import qs from "qs";
export default {
  components: {
    Table,
    TreeSelect,
  },
  data() {
    var validateCpName = (rule, value, callback) => {
      if (value == "" || value == undefined || value == null) {
        callback(new Error(""));
      } else {
        if (value != "") {
          if (this.isAdd == false) {
            this.getEditrole();
          } else {
            // this.getAddrole();
          }
          callback();
        }
      }
    };
    return {
      loading: false,
      isLoading: false,
      delDia: false,
      jurisdiction: false,
      isAdd: false,

      addDia: false,
      rowData: {},
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      searchObj: {
        name: "",
      },
      sCompanyId: sessionStorage.getItem("parkId"),
      form: {
        role: "",
        companyId: sessionStorage.getItem("parkId"),
        roleId: "",
        id: "",
        menuIds: [],
        truckControlAuthorized: null,
      },
      roleList: [],
      treeData: [],
      selectedTreeData: [],
      addrules: {
        role: [
          {
            required: true,
            validator: validateCpName,
            message: "请输入角色",
            trigger: ["blur", "change"],
          },
          {
            min: 0,
            max: 20,
            message: "角色名不超过20个字符",
            trigger: ["blur", "change"],
          },
        ],
      },
      columns: [
        {
          label: "角色名",
          prop: "roleName",
        },
        {
          label: "所属组织",
          prop: "companyName",
        },
        {
          label: "创建时间",
          prop: "createTime",
        },
        {
          label: "操作",
          // fixed: "right",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.isAdd = false;
                      this.addDia = true;
                      this.form.role = params.row.roleName;
                      this.form.roleId = params.row.id;
                      this.form.menuIds = params.row.menuIds;
                      this.form.truckControlAuthorized =
                        params.row.truckControlAuthorized;
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDia = true;
                      this.form.roleId = params.row.id;
                    },
                  },
                },
                "删除"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.rowData = params.row;
                      this.selectedTreeData = params.row.menuIds;
                      this.jurisdiction = true;

                      /*this.$nextTick(() => {
                        this.$refs.resourcesMenuRef.setCheckedKeys(
                          params.row.menuIds,
                          true
                        );
                      });*/
                    },
                  },
                },
                "设置功能权限"
              ),
            ]);
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        total: 0,
        roleName: "",
        companyId: sessionStorage.getItem("parkId"),
      },
      total: 0,
      menuIds: "",
      isShow: false,
    };
  },
  mounted() {
    this.getList();
    this.getjur();
  },
  methods: {
    getValue(value, title) {
      this.isShow =
        title.includes("运输载货车") ||
        title.includes("任务管理") ||
        title.includes("二次定位") ||
        title.includes("地图管理");
    },
    checkChange(item, nodes) {
      this.menuIds = nodes.checkedKeys;
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async jurisdictionCfm() {
      if (this.menuIds == "") {
        this.$message.error("功能权限不能为空");
      } else {
        let { success, message, data } = await _GetRojur(
          qs.stringify({
            roleId: this.rowData.id,
            menuIds: this.menuIds.join(","),
          })
        );

        if (success) {
          this.$message.success("设置成功");
          this.jurisdiction = false;
          this.getTableData();
        } else {
          this.$message.error(message);
        }
      }
    },
    async handleClose() {
      this.form = {
        role: "",
        menuIds: [],
        truckControlAuthorized: 0,
      };
      this.$refs.form.resetFields();
      this.addDia = false;
      this.isShow = false;
    },
    handleCloseB() {
      (this.addDia = false),
        (this.form = {
          role: "",
        });
    },
    getSelect() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd == false) {
            this.getEditrolepname();
          } else {
            this.getAddrolepname();
          }
        }
      });
    },
    cancel() {
      this.list.page = 1;
      this.list.roleName = "";
      this.getList();
    },
    async getList() {
      let { success, data, message } = await _RoleMngList(this.list);
      if (success) {
        this.roleList = data.list;
        this.list.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2() {
      this.list.page = 1;
      this.getList();
    },
    async getAddrole() {
      let { success, data, message } = await _AddroleMngpname({
        roleName: this.form.role,
        companyId: sessionStorage.getItem("parkId"),
      });
      if (success) {
      }
    },
    async getEditrole() {
      let { success, data, message } = await _EditroleMngpname({
        roleName: this.form.role,
        companyId: this.form.companyId,
        roleId: this.form.roleId,
      });
      if (success) {
      }
    },
    async getAddrolepname() {
      this.isLoading = true;
      _AddroleMng({
        roleName: this.form.role,
        companyId: sessionStorage.getItem("parkId"),
        menuIds: this.form.menuIds,
        truckControlAuthorized: this.isShow ? this.form.truckControlAuthorized : 0,
      }).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success) {
          this.$message.success("新增成功");
          this.getList();
          this.isLoading = false;
        }
        this.addDia = false;
      });
    },
    async getEditrolepname() {
      // alert(this.form.companyId);
      // this.$message.success("修改成功");
      let { success, data, message } = await _EditroleMng({
        roleName: this.form.role,
        companyId: this.form.companyId || sessionStorage.getItem("parkId"),
        roleId: this.form.roleId,
        menuIds: this.form.menuIds,
        truckControlAuthorized: this.isShow ? this.form.truckControlAuthorized : 0,
      });
      if (success) {
        this.getList();
        this.$message.success("修改成功");
        this.getList();
      } else {
        this.$message.error(message);
      }
      this.addDia = false;
    },
    async getEdit() {
      let { success, data, message } = await _Editrole({
        roleId: this.form.roleId,
      });
      if (success) {
        this.getList();
      }
      this.delDia = false;
    },
    async getjur() {
      let { success, data, message } = await _GetRoute({
        companyId: this.list.companyId,
      });

      if (success) {
        this.treeData = data.list;
      }
    },
  },
};
</script>

<style scoped></style>
