import service from '../../until/api'
//车辆运营
export function _TuBusList(data) {
    return service({
        url: 'tour-bus/web/tourVehicleOperation/findPageForSaas',
        method: 'GET',
        params: data
    })
}

export function _TuBusStatus(data) {
    return service({
        url: 'tour-bus/web/tourVehicleOperation/switchOperationStatus',
        method: 'GET',
        params: data
    })
}
//排班计划
export function _TuBuschedulingPlan(data) {
    return service({
        url: 'tour-bus/web/tourSchedulingPlan/findPage',
        method: 'GET',
        params: data
    })
}
export function _TuBuschedulingDel(data) {
    return service({
        url: 'tour-bus/web/tourSchedulingPlan/delete',
        method: 'GET',
        params: data
    })
}


export function _TuBuschedulingAdd(data) {
    return service({
        url: 'tour-bus/web/tourSchedulingPlan/insert',
        method: 'POST',
        data
    })
}
export function _TuBuschedulingUp(data) {
    return service({
        url: 'tour-bus/web/tourSchedulingPlan/update',
        method: 'POST',
        data
    })
}
//订单管理
export function _TuBusorderManage(data) {
    return service({
        url: 'tour-bus/web/tourOrderManagement/findPage',
        method: 'GET',
        params: data
    })
}


export function _TuBusOrderList(data) {
    return service({
        url: 'tour-bus/web/tourOrderManagement/findDetail',
        method: 'GET',
        params: data
    })
}
//服务评价
export function _TuBusserviceEvaluation(data) {
    return service({
        url: 'tour-bus/web/tourServiceEvaluation/findPage',
        method: 'GET',
        params: data
    })
}
//排班历史记录
export function _TuBusschedulingHistory(data) {
    return service({
        url: 'tour-bus/web/tourHistoricalScheduling/findPage',
        method: 'GET',
        params: data
    })
}
//车辆运营记录
export function _TuBusListRecord(data) {
    return service({
        url: 'tour-bus/web/tourVehicleOperationRecords/findPage',
        method: 'GET',
        params: data
    })
}
//视频查看
export function _TuBusVideoList(data) {
    return service({
        url: 'tour-bus/web/tourVideoViewing/findPage',
        method: 'GET',
        params: data
    })
}

export function _TuBusvideoViewingHis(data) {
    return service({
        url: 'tour-bus/web/tourVideoViewing/findVideoByCarNumber',
        method: 'GET',
        params: data
    })
}

export function _TuBusvideoViewingAll(data) {
    return service({
        url: 'tour-bus/web/tourVideoViewing/getHistoricalVideo',
        method: 'GET',
        params: data
    })
}

/**
 * @msg: 获取车辆线路
 * @param {*} data
 */
 export function _GetAllRoute(data) {
    return service({
        url: 'tour-bus/web/tourSchedulingPlan/getTourBusCarRouteInfo',
        method: 'GET',
        params: data
    })
}