<template>
  <div>
    <button class="centerBtn" @click="getadd" style="margin-bottom: 20px">
      新增
    </button>

    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.status"
          placeholder="任务状态"
          class="centerSelInput ipwidth"
        >
          <el-option
            v-for="item in StatusList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="searchObj.operationStatus"
          placeholder="行驶路线"
          class="centerSelInput"
        >
          <el-option
            v-for="item in RouteList"
            :key="item.id"
            :label="item.routeName"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>

      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getsearchList">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      :loading="loading"
      :page="list.page"
    />
    <el-drawer
      :title="isAdd ? '新增消息' : '编辑消息'"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      destroy-on-close
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item
            label="任务类型"
            :label-width="formLabelWidth"
            prop="taskTypeId"
            class="select"
          >
            <el-select
              v-model="form.taskTypeId"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in sweepList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="车辆编号"
            :label-width="formLabelWidth"
            prop="vehicleId"
          >
            <el-select v-model="form.vehicleId" filterable placeholder="请选择">
              <el-option
                v-for="item in carRegisterlist"
                :key="item.id"
                :label="item.carNumber"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="行驶路线"
            :label-width="formLabelWidth"
            prop="routeId"
            class="select"
          >
            <el-select
              v-model="form.routeId"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in RouteList"
                :key="item.id"
                :label="item.routeName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="计划开始时间"
            :label-width="formLabelWidth"
            prop="planStartTime"
            class="select time"
          >
            <el-date-picker
              v-model="form.planStartTime"
              :picker-options="pickerOptions"
              type="datetime"
              placeholder="选择日期"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="计划结束时间"
            :label-width="formLabelWidth"
            prop="planEndTime"
            class="select time"
          >
            <el-date-picker
              v-model="form.planEndTime"
              :picker-options="pickerOptions"
              type="datetime"
              placeholder="选择日期"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn" @click="getselect">保存</button>
        <button class="centerBtn resetbtn" @click="addAccount = false">
          取消
        </button>
      </div>
    </el-drawer>
    <el-dialog title="暂停任务" :visible.sync="suspend" width="30%">
      <span
        >您当前正在执行"暂停【】车当前进行中的任务"操作,车辆将停止运行,是否确定执行该操作?</span
      >
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn">继 续</button>
        <button class="centerBtn resetbtn ml20" @click="suspend = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-dialog title="结束任务" :visible.sync="end" width="30%">
      <span
        >您当前正在执行"暂停【】车当前进行中的任务"操作,车辆将停止运行,是否确定执行该操作?</span
      >
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn">继 续</button>
        <button class="centerBtn resetbtn ml20" @click="end = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import { _PtBuscleaningTask, _PtBusAdd, _PtBusUp } from "@/api/patrolBus";
import { _SwBusfindRouteWithPark } from "@/api/sweep";
import { _MinBuscarRegister } from "@/api/minBus";
export default {
  components: {
    Table,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      carRegisterlist:[],
      loading: false,
      addAccount: false,
      isAdd: false,
      suspend: false,
      end: false,
      value1: "",
      value: "",
      formLabelWidth: "100px",
      StatusList: [
        { id: "1", value: "进行中" },
        { id: "3", value: "未开始" },
        { id: "4", value: "已结束" },
      ],
      sweepList: [
        { id: "1", value: "安防" },
        // { id: "3", value: "洒水" },
        // { id: "4", value: "清扫、洒水" },
      ],
      TypeList: [],
      drivingRouteList: [],
      setnumberList: [],
      searchObj: {
        vehicleNumber: "",
        operationMode: "",
        operationStatus: "",
      },
      form: {
        taskTypeId: "",
        vehicleId: "",
        routeId: "",
        planStartTime: "",
        planEndTime: "",
        parkId: sessionStorage.getItem("parkId"),
        shiftPlanId: "",
      },
      rules: {
        taskTypeId: [
          {
            required: true,
            message: "请选择任务类型",
            trigger: "change",
          },
        ],
        vehicleId: [
          {
            required: true,
            message: "请输入车辆编号",
            trigger: "change",
          },
        ],
        routeId: [
          {
            required: true,
            message: "请输入行驶线路",
            trigger: "change",
          },
        ],
        planStartTime: [
          {
            required: true,
            message: "请选择计划开始时间",
            trigger: "blur",
          },
        ],
        planEndTime: [
          {
            required: true,
            message: "请选择计结束时间",
            trigger: "blur",
          },
        ],
      },
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          prop: "vehicleNumber",
        },
        {
          label: "任务类型",
          prop: "taskType",
        },
        {
          label: "任务状态",
          render: (h, params) => {
            if (params.row.planStatus == 1) {
              return h("span", "进行中");
            } else if (params.row.planStatus == 3) {
              return h("span", "未开始");
            } else if (params.row.planStatus == 4) {
              return h("span", "已结束");
            }
          },
        },
        {
          label: "车辆子类型",
          prop: "sonCarType",
        },
        {
          label: "行驶路线",
          prop: "routeName",
        },
        {
          label: "计划开始时间",
          prop: "startTime",
        },
        {
          label: "计划结束时间",
          prop: "endTime",
        },
        {
          label: "实际开始时间",
          prop: "",
        },
        {
          label: "实际结束时间",
          prop: "endTime",
        },
        // {
        //   label: "设定趟数",
        //   prop: "setnumber",
        // },
        // {
        //   label: "已跑趟数",
        //   prop: "runNumber",
        // },
        {
          label: "添加人",
          prop: "username",
        },
        {
          label: "操作",
          width: 200,
          render: (h, params) => {
            let srclist = 3;
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.isAdd = false;
                      this.addAccount = true;
                      // (this.form = {
                      //   taskTypeId: params.row.taskType,
                      //   vehicleId: params.row.vehicleNumber,
                      //   routeId: params.row.routeName,
                      //   planStartTime: params.row.startTime,
                      //   planEndTime: params.row.endTime,
                      // }),
                      //   (this.form.shiftPlanId = params.row.id);
                      this.form = {
                        taskTypeId: 1,
                        vehicleId: params.row.carRegisterId,
                        routeId: params.row.carRouteId,
                        planStartTime: params.row.startTime,
                        planEndTime: params.row.endTime,
                        parkId: sessionStorage.getItem("parkId"),
                      };
                      this.form.shiftPlanId = params.row.id;
                    },
                  },
                },
                "编辑"
              ),
              // h(
              //   "span",
              //   {
              //     class: "operationA",
              //     on: {
              //       click: () => {
              //         this.suspend = true;
              //       },
              //     },
              //   },
              //   "暂停任务"
              // ),
              // h(
              //   "span",
              //   {
              //     class: "operationA",
              //     on: {
              //       click: () => {
              //         this.end = true;
              //       },
              //     },
              //   },
              //   "结束任务"
              // ),
            ]);
          },
        },
      ],
      RouteList: [],
      timeValue: "",
      list: {
        page: 1,
        size: 10,
        carNumber: "",
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
        userId: "",
      },
      listB: {
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
    this.getRouteList();
    this.getcarRegister();
  },
  methods: {
    cancel() {
      this.timeValue = "";
      this.list = {
        page: 1,
        size: 10,
        carNumber: "",
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
        userId: "",
      };
      this.getList()
    },
    getadd() {
      this.addAccount = true;
      this.isAdd = true;
      this.form = {
        taskTypeId: "",
        vehicleId: "",
        routeId: "",
        planStartTime: "",
        planEndTime: "",
        parkId: sessionStorage.getItem("parkId"),
        shiftPlanId: "",
      };
    },
    async getList() {
      let { success, data, message } = await _PtBuscleaningTask(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    getsearchList(){
      this.list.page = 1;
      this.getList();
    },
    async getRouteList() {
      let { success, data, message } = await _SwBusfindRouteWithPark(
        this.listB
      );
      if (success) {
        this.RouteList = data;
        this.loading = false;
      }
    },
    async getcarRegister() {
      let { success, data, message } = await _MinBuscarRegister({
        parkId: this.list.parkId,
        carType: 4,
      });
      if (success) {
        this.carRegisterlist = data;
        this.loading = false;
      }
    },
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.timeValue[0];
        this.list.endTime = this.timeValue[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    getselect() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd == true) {
            this.getAdd();
          } else {
            this.getUp();
          }
        }
      });
    },
    async getAdd() {
      if (
        new Date(this.form.planStartTime).getTime() >=
        new Date(this.form.planEndTime).getTime()
      ) {
        this.$message.error("结束时间不能大于开始时间");
      } else {
        _PtBusAdd(this.form).then((res) => {
          if (res.success == true) {
            this.$message.success("清扫任务添加成功");
            this.loading = false;
            this.getList();
          }
          this.addAccount = false;
        });
      }

      // let { success, data, message } = await _SwBusAdd(this.form);
      // if (success) {
      //   this.$message.success("清扫任务成功");
      //   this.loading = false;
      //   this.getList();
      // }
      // this.addAccount = false;
    },
    async getUp() {
      _PtBusUp(this.form).then((res) => {
        if (res.success == true) {
          this.$message.success("清扫任务编辑成功");
          this.loading = false;
          this.getList();
        }
        this.addAccount = false;
      });
      // let { success, data, message } = await _SwBusUp(this.form);
      // if (success) {
      //   this.$message.success("清扫任务成功");
      //   this.loading = false;
      //   this.getList();
      // }
      // this.addAccount = false;
    },
  },
};
</script>

<style scoped>
.textcolor {
  font-size: 14px;
  color: #666666;
  margin-right: 20px;
}
.textcolor2 {
  font-size: 14px;
  color: #333333;
}
.el-input__inner {
  border: none !important;
  height: 36px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
.ipwidth /deep/.el-input__inner {
  width: 155px;
}
</style>