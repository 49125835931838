<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="searchObj.vehicleNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20">搜索</button>
        <button class="centerBtn resetbtn">重置</button>
      </el-form-item>
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="10"
      :loading="loading"
    />
    <el-drawer
      title="查看视频监控点"
      :visible.sync="detail"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="41%">
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="实时视频" name="first">
            <p class="textcolor">车辆编号</p>
            <p class="flex justify-between align-end">
              <span class="textbig">SV10PLUS001</span>
              <span class="textcolor">2021-06-15 16:43:21</span>
            </p>
          </el-tab-pane>
          <el-tab-pane label="历史视频" name="second">
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="选择日期"
              class="date"
            >
            </el-date-picker>
            <el-time-picker
              is-range
              v-model="value2"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              class="time"
            >
            </el-time-picker>
            <button class="centerCzbtn ml20">搜索</button>
            <button class="centerBtn resetbtn">是</button>
            <p class="textcolor textB">车辆编号</p>
            <p class="flex justify-between align-end">
              <span class="textbig">SV10PLUS001</span>
              <span class="textcolor">2021-06-15 16:43:21</span>
            </p>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn">车正面</button>
        <button class="centerBtn resetbtn">车内</button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Table from "../../components/Table.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      radio: "1",
      detail: false,
      activeName: "second",
      formLabelWidth: "100px",
      value1:"",
      value2:"",
      searchObj: {
        vehicleNumber: "",
      },
      tableData: [
        {
          carnum: "SV10004",
          cartype: "出租车",
          carstatus: "在线",
          number: "3",
        },
        {
          carnum: "SV10004",
          cartype: "出租车",
          carstatus: "在线",
          number: "3",
        },
        {
          carnum: "SV10004",
          cartype: "出租车",
          carstatus: "在线",
          number: "3",
        },
      ],
      columns: [
        {
          label: "车辆编号",
          prop: "carnum",
        },
        {
          label: "车辆类型",
          prop: "cartype",
        },
        {
          label: "车辆状态",
          prop: "carstatus",
        },
        {
          label: "摄像头数量",
          prop: "number",
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationB",
                  on: {
                    click: () => {
                      this.detail = true;
                    },
                  },
                },
                "查看视频监控点"
              ),
            ]);
          },
        },
      ],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
/deep/.el-tabs__active-bar {
  width: 280px !important;
}
/deep/.el-tabs__item {
  width: 280px;
  text-align: center;
}
/deep/.el-tabs__nav-wrap::after {
  width: 560px;
}
.date /deep/.el-input__inner {
  width: 136px;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
/deep/ .el-range-editor.el-input__inner {
  width: 190px;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
  margin-left: -30px;
}
.textbig {
  font-size: 27px;
  font-weight: 400;
  color: #666666;
}
/deep/.el-range-input {
  background: #f5f8fa;
}
.textB{
  margin-top:20px;
}
</style>