<template>
  <div class="map-container">
    <div id="container"></div>

    <!-- <div class="line-list-container" v-if="routeList.length > 0">
      <div class="line-list-title">线路运营实况</div>
      <div v-for="(item, idx) in routeList" :key="idx">
        <div
          class="line-color"
          :style="{ backgroundColor: item.color }"
          v-if="item.color !== null"
        ></div>
        <div class="line-color" v-else></div>
        <span>{{ item.routeName }}</span>
      </div>
    </div> -->

    <!-- <el-descriptions
      ref="routeinfo"
      v-if="routeId"
      :routeinfo="routeinfo"
      :column="1"
      :colon="false"
    >
      <el-descriptions-item label="在线车辆" width="100%"
        ><div>{{ routeinfo[0] }}辆</div></el-descriptions-item
      >
      <el-descriptions-item label="线路里程" width="100%"
        ><div>{{ routeinfo[1] }}km</div></el-descriptions-item
      >
      <el-descriptions-item label="站点数" width="100%"
        ><div>{{ routeinfo[2] }}站</div></el-descriptions-item
      >
    </el-descriptions> -->
    <div
      ref="lineinfodesc"
      class="line-info-container"
      v-if="lineinfo"
      :routeinfo="routeinfo"
      :style="{ top: (rowIndex * 28 + 55.6).toString() + 'px' }"
    >
      <table
        class="line-info-table"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <tr>
          <td style="width: 25px">
            <img
              src="@/assets/images/park_overview/line-bus.png"
              alt=""
              height="16px"
              width="16px"
            />
          </td>
          <td style="width: 80px">在线车辆</td>
          <td style="width: 50px">{{ routeinfo[0] }}辆</td>
        </tr>
        <tr>
          <td style="width: 25px">
            <img
              src="@/assets/images/park_overview/line-miles.png"
              alt=""
              height="16px"
              width="16px"
            />
          </td>
          <td style="width: 80px">线路里程</td>
          <td style="width: 50px">{{ routeinfo[1] }}KM</td>
        </tr>
        <tr>
          <td style="width: 25px">
            <img
              src="@/assets/images/park_overview/line-sites.png"
              alt=""
              height="16px"
              width="16px"
            />
          </td>
          <td style="width: 80px">站点数</td>
          <td style="width: 50px">{{ routeinfo[2] }}站</td>
        </tr>
      </table>
    </div>
    <div class="line-list-table">
      <el-table
        class="line-list-container"
        max-height="450"
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        :row-class-name="rowClassName"
        @current-change="selectRoute"
        @row-click="getrouteTable"
      >
        <el-table-column
          header-align="center"
          label="线路运营实况"
          class="line-list-title"
        >
          <template slot-scope="scope">
            <td>
              <!-- <div
                class="line-color"
                :style="{ backgroundColor: scope.row.color }"
                v-if="scope.row.color !== null"
              ></div> -->
              <b
                class="iconfont icon-weibiaoti-1 icon"
                :style="{ color: scope.row.color }"
                v-if="scope.row.color !== null"
              ></b>
              <!-- <div v-if="scope.row.color !== null">
                <img
                  src="@/assets/images/park_overview/line-miles.png"
                  alt=""
                  height="16px"
                  width="16px"
                  :color="scope.row.color"
                  class="line-img"
                />
              </div> -->
            </td>
            <td style="width: 120px">
              <div
                v-if="scope.row.routeName == '全部'"
                style="text-align: center"
              >
                {{ scope.row.routeName }}
              </div>
              <div v-else>{{ scope.row.routeName }}</div>
            </td>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 定位和缩放等级 -->
    <div class="positioning-zoom">
      <img
        src="@/assets/images/location2.png"
        alt=""
        @click="goBack"
        class="positioning"
      />
      <div class="zoom">
        缩放级别：<span>{{ nZoom }}</span>
      </div>
    </div>
    <!--region 车辆实时信息-->
    <CarRealTimeData
      :visible.sync="carRealTimeConfig.visible"
      :carData.sync="carRealTimeConfig.carData"
      v-if="carRealTimeConfig.visible"
    ></CarRealTimeData>
    <!--endregion 车辆实时信息-->

    <!--region 告警通知-->
    <div class="warning-notice" v-if="wnotice.show">
      <div class="warning-notice-header">
        <div class="warning-notice-header-boder"></div>
        <div class="warning-notice-header-title">
          <span>告警通知</span>
        </div>
      </div>

      <div class="warning-notice-body">
        <div class="warning-notice-body-content">
          <p class="content-title">GPS位置异常提醒</p>

          <div class="content-body">
            <p
              class="content-item"
              v-for="(item, idx) in wnotice.message"
              :key="item.id"
            >
              {{ item.content }}
              <span class="content-body-msg-date">{{ item.datetime }}</span>
              <span class="content-item-btn">
                <span @click="hideItem(item, idx)">我知道了</span>
                <span @click="goItem(item, idx)">前往查看</span>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="warning-notice-footer" v-show="noticeFooter">
        <span class="btn-close" @click="wNoticeKnow">我全都知道了</span>
      </div>
    </div>
    <!--endregion 告警通知-->
  </div>
</template>

<script>
import CarRealTimeData from "../../components/CarRealTimeDrawer/CarRealTimeData.vue";
import * as echarts from "echarts";
import "echarts-liquidfill";
import util from "@/until/WSCoordinate.js"; //转坐标
import {
  _FindCarDataById,
  _FindParkByCarOperation,
  _FindCarRouteMap,
  _FindCarRouteList,
  _FindParkInfoByParkId,
} from "@/api/equipmentMonitoring";
import { _FindOneSite, _PmLineList } from "@/api/pkMng";

import {
  GetDateTime,
  mapCarMarker,
  calculateCenter,
  computeSignedArea,
  TimestampToDatetime,
  OffOnValueToLabel,
  SeatsStatusValueToLabel,
  BeltsStatusValueToLabel,
  ChargeValueToLabel,
  GpsValueToLabel,
} from "@/until/helper";

import { transformFromWGSToGCJ } from "@/until/WSCoordinate.js";

export default {
  components: { CarRealTimeData },
  data() {
    return {
      currentRow: { routeName: "全部", color: null }, // 当前线路详情
      routeNameNow: null, // 当前线路名字
      loading: true,
      sParkId: sessionStorage.getItem("parkId"),
      routeList: [],
      routeId: null,
      routeNo: null,
      routeTable: [],
      routeinfo: [0, 0, 0],
      rowIndex: 0,
      backgroundColor: "white",
      wnotice: {
        show: false,
        message: [],
        carIdList: [],
      },
      parkData: null,
      carMarkerList: [],
      carNumberList: [],
      carRealTimeConfig: {
        visible: false,
        carData: null,
      },
      nZoom: null,
      initialZoom: null,
      arrCenter: null, // 例如[108.112583, 34.233588]
      noticeFooter: true,

      tableData: [{ routeName: "全部", color: null }],
      lineinfo: false,
      linechangemark: false,
      mapTimes: null,
    };
  },
  async mounted() {
    await this.InitData();
    window.addEventListener("click", this.clickOther);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.clickOther);
    clearInterval(this.mapTimes);
    this.mapTimes = -1;
    clearInterval(this.timer);
  },

  methods: {
    /**
     * @desc 载入数据
     */
    async InitData() {
      //获取园区车辆实时数据
      await this.findParkByCarOperation();

      //显示超范提示
      this.CarOutRangeNotice();

      //初始化地图
      await this.initMap();

      //初始化地图上的线路标记
      await this.initLine();

      //初始化地图站点
      await this.initStation();

      //初始化地图上的车辆标记
      await this.initCar();

      //选择线路，隐藏markers
      this.selectRoute();

      //定时刷新车辆数据
      // await this.RefreshCarPosition();
      this.mapTimes == -1 ||
        (this.mapTimes = setInterval(async () => {
          await this.RefreshCarPosition();
        }, 1000));
    },

    /**
     * @desc 获取园区车辆实时数据
     */
    async findParkByCarOperation() {
      //this.parkData = null;
      // // 重复请求就取消
      // if (typeof this.cancelAjax === "function") {
      //   this.cancelAjax();
      // }
      let res = await _FindParkInfoByParkId(
        {
          parkId: this.sParkId,
        },
        this
      );
      if (res) {
        if (res.success) {
          this.parkData = res.data;
        }
      }
    },

    /**
     * @desc 初始化地图
     */
    async initMap() {
      let routeSite = null;
      let CenterPoint = null;
      // let arrCenter = [];// 例如[108.112583, 34.233588]
      let nLngLatArea = 0;
      // let nZoom = 5;

      if (this.parkData) {
        routeSite = JSON.parse(this.parkData.parkScope);

        if (routeSite.length > 0) {
          routeSite.push(routeSite[0]); //绘制线路需要将第一个元素复制一个放到数组最后，完成高德线段的闭合绘制
        }

        //计算园区范围的中心点
        CenterPoint = calculateCenter(routeSite);

        //计算园区范围的面积值，用于地图缩放比例
        nLngLatArea = Math.round(computeSignedArea(routeSite));
        this.arrCenter = [CenterPoint.lng, CenterPoint.lat];
        // 取缩放大小
        this.nZoom = this.parkData?.defaultZoom;
        this.initialZoom = this.parkData?.defaultZoom;
        if (!this.nZoom) {
          //根据面积，配置地图缩放级别
          if (nLngLatArea <= 1) {
            this.nZoom = 16;
            this.initialZoom = 16;
          } else if (nLngLatArea <= 2) {
            this.nZoom = 15;
            this.initialZoom = 15;
          } else if (nLngLatArea <= 3) {
            this.nZoom = 14;
            this.initialZoom = 14;
          } else if (nLngLatArea <= 4) {
            this.nZoom = 13;
            this.initialZoom = 13;
          } else {
            this.nZoom = 12;
            this.initialZoom = 12;
          }
        }
      }

      if (!this.map) {
        this.map = new AMap.Map("container", {
          resizeEnable: true,
          center: this.arrCenter,
          zoom: this.initialZoom,
        });

        if (routeSite) {
          this.CreateFence(routeSite);
        }
      }
      // 监听缩放
      this.map.on("zoomchange", () => {
        this.nZoom = this.map.getZoom(); //获取当前地图级别
        this.selectRoute();
      });
    },

    /**
     * @desc 创建园区围栏
     */
    CreateFence(fence_list) {
      let nCount = fence_list.length;
      let polylinePath = [];

      for (let i = 0; i < nCount; i++) {
        polylinePath.push(new AMap.LngLat(fence_list[i][0], fence_list[i][1]));
      }

      let polyline = new AMap.Polyline({
        path: polylinePath, // 设置线覆盖物路径
        showDir: false,
        strokeColor: "#ff6600", // 线颜色
        strokeWeight: 5, // 线宽
        strokeOpacity: 0.7,
        strokeStyle: "dashed",
      });

      this.map.add([polyline]);
    },

    /**
     * @desc 初始化地图上的线路标记
     */
    async initLine() {
      let carRouteList = [];
      let res = await _FindCarRouteList({
        parkId: this.sParkId,
      });
      if (res) {
        if (res.success) {
          carRouteList = res.data;
        }
      }
      // 清扫车区域线路
      let sweeperArea = carRouteList.filter((ele) => {
        return !ele.isBus;
      });
      let sCount = 0;
      if (sweeperArea) {
        sCount = sweeperArea.length;
      }
      if (sCount > 0) {
        sweeperArea.forEach((el) => {
          this.tableData.push(el);
        });
        for (let j = 0; j < sCount; j++) {
          if (sweeperArea[j].routeArea) {
            let Polygon1 = new AMap.Polygon({
              path: JSON.parse(sweeperArea[j].routeArea),
              fillColor: "rgba(101, 215, 175, 0.40)",
              strokeOpacity: 1,
              fillOpacity: 0.5,
              strokeColor: "#00BBFF",
              strokeWeight: 1,
              strokeStyle: "dashed",
              strokeDasharray: [5, 5],
              extData: sweeperArea[j],
            });
            this.map.add(Polygon1);
          }
        }
      }

      // 载人车线路
      let busList = carRouteList.filter((ele) => {
        return ele.isBus;
      });

      let nCount = 0;
      if (busList) {
        nCount = busList.length;
      }

      if (nCount > 0) {
        this.routeList = busList;

        //获取路线
        this.routeList.forEach((el) => {
          this.tableData.push(el);
        });
        for (let i = 0; i < nCount; i++) {
          if (this.routeList[i].routeSite) {
            var polyline2 = new AMap.Polyline({
              path: JSON.parse(this.routeList[i].routeSite), // 设置线覆盖物路径
              showDir: true,
              strokeColor:
                this.routeList[i].color === null
                  ? "#1683d9"
                  : this.routeList[i].color, // 线颜色
              strokeWeight: 10, // 线宽
              extData: this.routeList[i],
            });
            this.map.add([polyline2]);
          }
        }
      }
    },

    async initStation() {
      if (!this.parkData) {
        return false;
      }
      let { success, data } = await _FindCarRouteMap({ parkId: this.sParkId });
      if (success) {
        data.forEach((ele) => {
          if (!ele.pointNameMap) return;
          ele.pointNameMap.forEach((item) => {
            let market = new AMap.Marker({
              position: new AMap.LngLat(item.position[0], item.position[1]),
              icon: new AMap.Icon({
                image: require("@/assets/images/icon_site.png"),
                imageSize: new AMap.Size(24, 24),
                size: new AMap.Size(24, 24),
              }),
              extData: { routeName: ele.carRouteName, type: "stationImg" },
            });
            this.map.add(market);
            let textMarket = new AMap.Text({
              text: item.pointName,
              position: [item.position[0], item.position[1]],
              anchor: "center",
              style: {
                padding: "1px 5px",
                "margin-bottom": "20px",
                "margin-left": "20px",
                // 'border-radius': '.25rem',
                "background-color": "#318ff5",
                "border-width": 0,
                "text-align": "center",
                "font-size": "12px",
                color: "#ffffff",
              },
              extData: { routeName: ele.carRouteName, type: "stationText" },
            });
            this.map.add(textMarket);
          });
        });
      }
    },

    /**
     * @desc 初始化地图上的车辆标记和车辆编号
     *  */
    async initCar() {
      if (!this.parkData) {
        return false;
      }

      let nCount = 0;

      //统计园区在线车辆
      if (this.parkData.operationList) {
        nCount = this.parkData.operationList.length;
      }

      //添加车辆标记对象
      for (let i = 0; i < nCount; i++) {
        this.AddCarMarker(this.parkData.operationList[i]);
      }
    },

    /**
     * @desc 添加车辆地图标记
     * @param {obejct} car_data 车辆数据
     */
    AddCarMarker(car_data) {
      if (!car_data) {
        return false;
      }

      let sCarTypeImage = mapCarMarker(car_data.parentCarTypeId);
      let that = this;
      //GPS坐标转换成高德坐标
      let AmapPosition = transformFromWGSToGCJ(
        parseFloat(car_data.latitude),
        parseFloat(car_data.longitude)
      );

      let marker = new AMap.Marker({
        map: that.map,
        clickable: true,
        extData: car_data,

        //标记在地图上的位置
        position: [AmapPosition.longitude, AmapPosition.latitude],
        icon: new AMap.Icon({
          image: sCarTypeImage,
          imageSize: new AMap.Size(36, 36),
          size: new AMap.Size(36, 36),
        }),
        offset: new AMap.Pixel(-20, -17),

        //航向角
        angle: car_data.head,
      });
      //车辆编号
      let markerText = new AMap.Text({
        map: that.map,
        text: car_data.carNumber,
        position: [AmapPosition.longitude, AmapPosition.latitude],
        extData: car_data,
        zIndex: 50,
        offset: new AMap.Pixel(-15, -45),
        style: {
          "background-color": "#000000",
          "border-width": 5,
          "text-align": "center",
          padding: "2px",
          color: "#ffffff",
          opacity: 0.5,
        },
      });
      //查看车辆状态
      marker.on("click", async function () {
        that.carRealTimeConfig.carData = {
          carNumber: car_data.carNumber,
          carTypeName: car_data.carTypeName,
          carStatus: car_data.carStatus,
        };
        that.carRealTimeConfig.visible = true;
      });

      that.carMarkerList.push({
        car_id: car_data.id,
        marker: marker, //AMap.Marker 车辆标记
        markerText: markerText, //AMap.Text 车辆编号
        number: car_data.carNumber,
        carStatus: car_data.carStatus,
      });
    },

    /**
     * @desc 刷新车辆定位坐标
     */
    async RefreshCarPosition() {
      await this.findParkByCarOperation();
      let blFoundError = false;

      if (!this.parkData) {
        blFoundError = true;
      } else {
        if (!this.parkData.operationList) {
          return false;
        }
      }

      if (blFoundError) {
        clearInterval(this.mapTimes);
        return false;
      }

      let UpdateCar = [];
      let AddCar = [];

      //提取需要删除的车辆
      for (let i = 0; i < this.carMarkerList.length; i++) {
        let FoundDelCar, carMarker;
        carMarker = this.carMarkerList[i];
        FoundDelCar = carMarker;

        for (const item of this.parkData.operationList) {
          if (carMarker.car_id === item.id) {
            FoundDelCar = null;
          }
        }

        if (FoundDelCar !== null) {
          this.carMarkerList.splice(i, 1);
          FoundDelCar.marker.remove(); //移除车辆
          FoundDelCar.markerText.remove(); //移除车辆编号
          FoundDelCar = null;
        }
      }

      //提取需要更新的车辆
      for (const item of this.parkData.operationList) {
        for (const carMarker of this.carMarkerList) {
          if (item.id === carMarker.car_id) {
            UpdateCar.push(item);

            break;
          }
        }
      }

      //提取需要添加的车辆标记。如果需要更新的车辆数量与总数据数量不等，表示有新车辆加入
      for (const item of this.parkData.operationList) {
        if (this.carMarkerList.length <= 0) {
          AddCar.push(item);
        } else {
          let blIsAdd = true;

          for (const carMarker of this.carMarkerList) {
            if (item.id === carMarker.car_id) {
              blIsAdd = false;
              break;
            }
          }

          if (blIsAdd) {
            AddCar.push(item);
          }
        }
      }

      //region 更新现有车辆
      let CarMarkerList = this.map.getAllOverlays("marker");
      let CarTextList = this.map.getAllOverlays("text");

      for (const item of UpdateCar) {
        for (const carMarker of CarMarkerList) {
          var carData = carMarker.getExtData();

          if (item.carNumber === carData.carNumber) {
            var GpsPosition = [item.longitude, item.latitude];

            //更新标记的航向角
            carMarker.setAngle(item.head);

            AMap.convertFrom(GpsPosition, "gps", function (status, result) {
              let AmapPosition = [];

              if (result.info === "ok") {
                AmapPosition = [
                  result["locations"][0].lng,
                  result["locations"][0].lat,
                ];
              } else {
                AmapPosition = GpsPosition;
              }

              //更新标记的定位
              carMarker.setPosition(AmapPosition);
            });

            break;
          }
        }

        //更新车标编号定位
        for (const carText of CarTextList) {
          var carData = carText.getExtData();

          if (item.carNumber === carData.carNumber) {
            var GpsPosition = [item.longitude, item.latitude];

            AMap.convertFrom(GpsPosition, "gps", function (status, result) {
              let AmapPosition = [];

              if (result.info === "ok") {
                AmapPosition = [
                  result["locations"][0].lng,
                  result["locations"][0].lat,
                ];
              } else {
                AmapPosition = GpsPosition;
              }

              //更新标记的定位
              carText.setPosition(AmapPosition);
            });

            break;
          }
        }
      }
      //endregion 更新现有车辆

      //region 添加车辆
      if (AddCar.length > 0) {
        for (const item of AddCar) {
          this.AddCarMarker(item);
        }
      }
      //endregion 添加车辆
    },

    /**
     * @desc 超出范围提醒
     */
    CarOutRangeNotice() {
      if (!this.parkData) {
        return false;
      } else {
        if (!this.parkData.parkScope) {
          return false;
        }

        if (!this.parkData.operationList) {
          return false;
        }
      }

      let CheckRangeResult;
      let WarningNoticeMessage;
      let WarningNoticeMessageDate;
      let nCount = 0;
      var that = this;

      if (this.parkData.operationList) {
        if (this.parkData.operationList.length > 0) {
          nCount = this.parkData.operationList.length;
        }
      }

      let routeSite = JSON.parse(this.parkData.parkScope);

      if (routeSite.length > 0) {
        routeSite.push(routeSite[0]); //绘制线路需要将第一个元素复制一个放到数组最后，完成高德线段的闭合绘制
      }

      for (let i = 0; i < nCount; i++) {
        let GpsPosition = [
          that.parkData.operationList[i].longitude,
          that.parkData.operationList[i].latitude,
        ];

        AMap.convertFrom(GpsPosition, "gps", function (status, result) {
          let AmapPosition = [];

          if (result.info === "ok") {
            AmapPosition = [
              result["locations"][0].lng,
              result["locations"][0].lat,
            ];
          } else {
            AmapPosition = GpsPosition;
          }

          let polygon = new AMap.Polygon({
            map: that.map,
            path: routeSite,
            strokeColor: "#ff33ff",
            strokeWeight: 6,
            strokeOpacity: 0,
            fillOpacity: 0,
            fillColor: "#1791fc",
            zIndex: 0,
          });

          CheckRangeResult = polygon.contains(AmapPosition);

          if (CheckRangeResult === false) {
            if (that.parkData.operationList) {
              if (
                that.wnotice.carIdList.indexOf(
                  that.parkData.operationList[i].id
                ) === -1
              ) {
                WarningNoticeMessage =
                  that.parkData.operationList[i].carTypeName +
                  "-" +
                  that.parkData.operationList[i].carNumber +
                  "，已超出园区围栏";
                WarningNoticeMessageDate = GetDateTime("yyyy-MM-dd hh:mm:ss");

                that.wnotice.message.push({
                  datetime: WarningNoticeMessageDate,
                  content: WarningNoticeMessage,
                  // latitude: that.parkData.operationList[i].latitude,
                  // longitude: that.parkData.operationList[i].longitude,
                  id: that.parkData.operationList[i].id,
                });

                that.wnotice.carIdList.push(that.parkData.operationList[i].id);
              }
            }
          }

          if (that.wnotice.message.length > 0) {
            that.wnotice.show = true;
          }
          if (i == nCount - 1 && that.wnotice.message.length == 1) {
            that.noticeFooter = false;
          }
        });
      }
    },

    //#region 未使用
    async getCarInfo(number) {
      let { success, data, message } = await _FindCarDataById({
        vid: number,
      });

      if (success && data) {
        //region 赋值
        this.CarData.type = data.vehicle.base.type; //车辆类型
        this.CarData.datetime = TimestampToDatetime(data.timestamp);
        this.CarData.vehicle.base.vid = data.vehicle.base.vid; //车编号
        this.CarData.vehicle.base.speed = data.vehicle.base.speed; //车速
        this.CarData.vehicle.base.power = data.vehicle.base.power; //电量
        this.CarData.vehicle.base.accumulate = data.vehicle.base.accumulate; //累计里程
        this.CarData.vehicle.base.recharge = data.vehicle.base.recharge; //续航里程
        this.CarData.vehicle.base.dmode = data.vehicle.base.dmode; //驾驶模式
        this.CarData.vehicle.base.dmodeStr = data.vehicle.base.dmodeStr; //驾驶模式
        this.CarData.vehicle.base.epb = data.vehicle.base.epb; //驾驶模式
        this.CarData.vehicle.base.epbDesc = data.vehicle.base.epbStr; //驾驶模式
        this.CarData.vehicle.base.defrost = data.vehicle.base.defrost; //除雾
        this.CarData.vehicle.base.type = data.vehicle.base.type; //车辆状态
        this.CarData.vehicle.base.dmode = data.vehicle.base.dmode;
        this.CarData.vehicle.ict.uspeed = data.vehicle.ict.uspeed; //上行速率
        this.CarData.vehicle.ict.dspeed = data.vehicle.ict.dspeed; //下行速率
        this.CarData.vehicle.ict.delay = data.vehicle.ict.delay; //网络延时（ms）
        this.CarData.vehicle.ict.loss = data.vehicle.ict.loss; //丢包率
        if (this.CarData.warning.length > 0) {
          this.CarData.warning.code = data.warning.code; //预警编号
          this.CarData.warning.data = data.warning.data; //描述
          this.CarData.warning.desc = data.warning.desc; //预警数据
        }

        this.CarData.business.cstaid = data.business.cstaid; //当前站点id
        this.CarData.business.cstaName =
          data.business.cstaName !== null ? data.business.cstaName : "-"; //当前站点名称
        this.CarData.business.nstaid = data.business.nstaid; //下一站点 ID
        this.CarData.business.nstaName =
          data.business.nstaName !== null ? data.business.nstaName : "-"; //下一站点名称
        this.CarData.business.nstadis = data.business.nstadis; //下一站点距离
        this.CarData.business.lineName =
          data.business.lineName !== null ? data.business.lineName : "-";
        this.CarData.vehicle.doors = data.vehicle.doors; //车门数据
        if (this.CarData.vehicle.doors.length > 0) {
          this.statusdoors = data.vehicle.doors[0].status;
        }
        if (data.business.task.length > 0) {
          let bas = JSON.parse(data.business.task[0].data);
          if (data.business.task[0].type === 1) {
            this.sweeplist.witch = bas.switch;
          } else if (data.business.task[0].type === 4) {
            this.sweeplist.witch_two = bas.switch;
          }
        }
        if (data.vehicle.conditioner.length > 0) {
          this.CarData.vehicle.conditioner.idx =
            data.vehicle.conditioner[0].idx; //空调数据
          this.CarData.vehicle.conditioner.status =
            data.vehicle.conditioner[0].status; //空调数据
          this.CarData.vehicle.conditioner.model =
            data.vehicle.conditioner[0].model; //模式
          this.CarData.vehicle.conditioner.cvolume =
            data.vehicle.conditioner[0].cvolume; //风量
          this.CarData.vehicle.conditioner.stemp =
            data.vehicle.conditioner[0].stemp; //设定温度
          this.CarData.vehicle.conditioner.ctemp =
            data.vehicle.conditioner[0].ctemp; //车内温度
        }

        this.CarData.vehicle.light.lowbeam = data.vehicle.light.lowbeam; //近光灯
        this.CarData.vehicle.light.interior = data.vehicle.light.interior; //车内灯
        this.CarData.vehicle.light.highbeam = data.vehicle.light.highbeam; //远光灯
        this.CarData.vehicle.light.rearfog = data.vehicle.light.rearfog; //后雾灯
        this.CarData.vehicle.light.leftturn = data.vehicle.light.leftturn; //左转灯
        this.CarData.vehicle.light.rightturn = data.vehicle.light.rightturn; //右转灯
        this.CarData.vehicle.base.seats = data.vehicle.base.seats; //座椅
        this.CarData.vehicle.base.seats = []; //座椅
        //endregion 赋值

        //region 报文
        let TableCol = [
          {
            cn_name: "产生时间",
            en_name: "timestamp",
            value: TimestampToDatetime(data.timestamp),
          },
          {
            cn_name: "充电状态",
            en_name: "charge",
            value: ChargeValueToLabel(data.vehicle.base.charge),
          },
          {
            cn_name: "车辆ID",
            en_name: "vid",
            value: data.vehicle.base.vid,
          },
          {
            cn_name: "车速",
            en_name: "speed",
            value: data.vehicle.base.speed + " km/h",
          },
          {
            cn_name: "epb 状态",
            en_name: "epb",
            value: data.vehicle.base.epbStr,
          },
          {
            cn_name: "gps状态",
            en_name: "status",
            value: GpsValueToLabel(data.vehicle.location.status),
          },
          {
            cn_name: "经度",
            en_name: "longitude",
            value: data.vehicle.location.longitude,
          },
          {
            cn_name: "纬度",
            en_name: "latitude",
            value: data.vehicle.location.latitude,
          },
          {
            cn_name: "航向角",
            en_name: "head",
            value: data.vehicle.location.head,
          },
          {
            cn_name: "下一站点ID",
            en_name: "nstaid",
            value:
              data.business.nstaid +
              "（" +
              this.CarData.business.nstaName +
              "）",
          },
          {
            cn_name: "下一站点距离",
            en_name: "nstadis",
            value: data.business.nstadis,
          },
          {
            cn_name: "当前站点ID",
            en_name: "cstaid",
            value:
              data.business.cstaid +
              "（" +
              this.CarData.business.cstaName +
              "）",
          },
          {
            cn_name: "近光灯状态",
            en_name: "lowbeam",
            value: OffOnValueToLabel(data.vehicle.light.lowbeam),
          },
          {
            cn_name: "远光灯状态",
            en_name: "highbeam",
            value: OffOnValueToLabel(data.vehicle.light.highbeam),
          },
          {
            cn_name: "后雾灯状态",
            en_name: "rearfog",
            value: OffOnValueToLabel(data.vehicle.light.rearfog),
          },
          {
            cn_name: "后雾灯状态",
            en_name: "rearfog",
            value: OffOnValueToLabel(data.vehicle.light.rearfog),
          },
          {
            cn_name: "车内灯状态",
            en_name: "interior",
            value: OffOnValueToLabel(data.vehicle.light.interior),
          },
          {
            cn_name: "左转向灯",
            en_name: "leftturn",
            value: OffOnValueToLabel(data.vehicle.light.leftturn),
          },
          {
            cn_name: "右转向灯",
            en_name: "rightturn",
            value: OffOnValueToLabel(data.vehicle.light.rightturn),
          },
          {
            cn_name: "空调状态",
            en_name: "status",
            value: OffOnValueToLabel(data.vehicle.conditioner.status),
          },
          {
            cn_name: "空调模式",
            en_name: "model",
            value: data.vehicle.conditioner.model,
          },
          {
            cn_name: "风量档位",
            en_name: "cvolume",
            value: data.vehicle.conditioner.cvolume,
          },
          {
            cn_name: "电池信息",
            en_name: "power",
            value: data.vehicle.base.power + "%",
          },
          {
            cn_name: "续航里程",
            en_name: "recharge",
            value: data.vehicle.base.recharge + " km",
          },
          {
            cn_name: "驾驶模式",
            en_name: "dmode",
            // value: DmodeValueToLabel(data.vehicle.base.dmode),
            value: data.vehicle.base.dmodeStr,
          },
          {
            cn_name: "座椅状态",
            en_name: "seats",
            value: SeatsStatusValueToLabel(data.vehicle.base.seats),
          },
          {
            cn_name: "安全带状态",
            en_name: "belts",
            value: BeltsStatusValueToLabel(data.vehicle.base.belts),
          },
          {
            cn_name: "上行通信速率",
            en_name: "uspeed",
            value: data.vehicle.ict.uspeed,
          },
          {
            cn_name: "下行通信速率",
            en_name: "dspeed",
            value: data.vehicle.ict.dspeed,
          },
          {
            cn_name: "网络延时",
            en_name: "delay",
            value: data.vehicle.ict.delay,
          },
          {
            cn_name: "丢包率",
            en_name: "loss",
            value: data.vehicle.ict.loss,
          },
          {
            cn_name: "线路ID",
            en_name: "line",
            value:
              data.business.line + "（" + this.CarData.business.lineName + "）",
          },
          {
            cn_name: "车辆类型",
            en_name: "type",
            value: data.vehicle.base.typeStr,
          },
          {
            cn_name: "电机转速",
            en_name: "rspeed",
            value:
              data.vehicle.electrics && data.vehicle.electrics.length > 0
                ? data.vehicle.electrics[0].rspeed + "（r/min）"
                : "",
          },
          {
            cn_name: "信号接收功率",
            en_name: "rsrp",
            value: data.vehicle.ict.rsrp,
          },
          {
            cn_name: "信噪比",
            en_name: "sinr",
            value: data.vehicle.ict.sinr,
          },
          {
            cn_name: "档位",
            en_name: "gear",
            value: data.vehicle.base.gear,
          },
        ];
        //endregion 报文

        this.tableData = TableCol;
        switch (this.CarData.vehicle.base.type) {
          case 1:
            if (this.isMicroBus) {
              this.$nextTick(() => {
                this.drawSpeedChart(this.$refs.chartMicroBusSpeed);
                this.drawPowerChart(this.$refs.chartMicroBusPower);
              });
            }

            break;

          case 4:
            if (this.ispatrolCar) {
              this.$nextTick(() => {
                this.drawSpeedChart(this.$refs.chartPatrolCarSpeed);
                this.drawPowerChart(this.$refs.chartPatrolCarPower);
              });
            }

            break;

          case 5:
            if (this.isSweeper) {
              this.$nextTick(() => {
                this.drawChartDustbinCapacity();
                this.drawChartSolutionTankCapacity();
              });
            }

            break;
        }
      }
    },
    //#endregion

    /**
     * @desc 检查车辆是否超出园区范围
     */
    CheckVehicleOutParkRange(park_range, car_position) {
      if (park_range.length <= 0) {
        return false;
      }

      if (car_position.length <= 0) {
        return false;
      }

      let polygon = new AMap.Polygon({
        map: this.map,
        path: park_range,
        strokeColor: "#ff33ff",
        strokeWeight: 6,
        strokeOpacity: 0,
        fillOpacity: 0,
        fillColor: "#1791fc",
        zIndex: 0,
      });

      return polygon.contains(car_position);
    },

    /**
     * @desc 关闭通知
     */
    wNoticeKnow() {
      this.wnotice.show = false;
      this.wnotice.message = [];
    },

    /**
     * @desc 回到中心缩放恢复默认值
     */
    goBack() {
      this.map.panTo(this.arrCenter);
      this.map.setZoom(this.initialZoom);
    },

    /**
     * @desc 隐藏该项
     */
    hideItem(item, index) {
      this.wnotice.message.splice(index, 1);
      if (this.wnotice.message.length == 0) {
        this.wNoticeKnow();
      } else if (this.wnotice.message.length == 1) {
        this.noticeFooter = false;
      }
    },

    /**
     * @desc 查看该项
     */
    async goItem(item) {
      let amapCarGps = null;
      let res = await _FindParkInfoByParkId({ parkId: this.sParkId }, this);
      // 有数据
      if (res?.data) {
        res.data.operationList.forEach((ele) => {
          if (ele.id == item.id) {
            amapCarGps = util.transformFromWGSToGCJ(
              parseFloat(ele.latitude),
              parseFloat(ele.longitude)
            );
          }
        });
        this.map.panTo([amapCarGps.longitude, amapCarGps.latitude]);
      }
    },

    /**
     * @desc 运营信息点击消失
     */
    clickOther() {
      if (this.linechangemark == false) this.lineinfo = false;
      else {
        this.linechangemark = false;
        // console.log("row1", this.$refs.singleTable);
        // console.log("row2", this.$refs.lineinfodesc);
      }
    },

    //获取当前的行数
    rowClassName({ row, rowIndex }) {
      undefined;
      //把每一行的索引放进row
      row.index = rowIndex;
      // console.log(row)
    },

    /**
     * @desc 选择路线展示运营信息
     * @param row [Object] 选中的行
     */
    async getrouteTable(row, column, event) {
      this.loading = true;
      let CarMarkerList = this.map.getAllOverlays("marker"); //获取全部车辆标记
      //let ParkCar = this.parkData.routeList;
      let nCarCount = 0;
      let nSiteCount = 0;
      let nMileage = 0;
      this.rowIndex = row.index;
      if (this.routeId) {
        this.lineinfo = true;
        this.linechangemark = true;

        CarMarkerList.forEach((el) => {
          if (this.routeId == el.getExtData().carRouteId) {
            nCarCount++; //选中路线车辆数量
          }
        });

        let size = 100;
        let resList = await Promise.all([
          _PmLineList({
            page: 1,
            size: size,
            parkId: this.sParkId,
          }),
          _FindOneSite({
            carRouteId: this.routeId,
          }),
        ]);
        resList[0].data.list.forEach((el) => {
          if (el.id == this.routeId) nMileage = el.routeMileage; //选中路线里程
        });
        if (resList[1].data.routeSiteList) {
          nSiteCount = resList[1].data.routeSiteList.length; //选中路线站点数量
        }
        // await _PmLineList({
        //   page: 1,
        //   size: size,
        //   parkId: this.sParkId,
        // }).then((res) => {
        //   if (res.success) {
        //     res.data.list.forEach((el) => {
        //       if (el.id == this.routeId) nMileage = el.routeMileage; //选中路线里程
        //     });
        //   }
        // });

        // let { success, data, message } = await _FindOneSite({
        //   carRouteId: this.routeId,
        // });
        // if (success) {
        //   if (data.routeSiteList) {
        //     nSiteCount = data.routeSiteList.length; //选中路线站点数量
        //   }
        // }
        this.loading = false;
        this.routeinfo = [nCarCount, nMileage, nSiteCount];
      }
    },

    /**
     * @desc 选择路线展示
     * @param currentRow [Object] 选中的行
     */
    selectRoute(currentRow = this.currentRow) {
      this.currentRow = currentRow;
      let routeList = this.map.getAllOverlays("polyline"); //获取园区围栏和路线
      let areaList = this.map.getAllOverlays("polygon"); //获取清扫车清扫区域
      // let CarMarkerList = this.map.getAllOverlays("marker"); //获取车辆标记
      // let CatTextList = this.map.getAllOverlays("text"); //获取车辆编号文本
      // console.log(currentRow);
      if (currentRow.id) {
        this.routeId = currentRow.id;
        this.routeNo = currentRow.routeNumber;
        this.routeNameNow = currentRow.routeName;
      } else {
        this.routeId = null;
        this.routeNo = null;
        this.routeNameNow = null;
      }

      if (this.parkData) {
        //设置显示线路
        if (routeList) {
          routeList.forEach((el) => {
            if (this.routeId) {
              //设置显示中排除园区围栏，园区围栏没有routeId
              if (el.getExtData().id && el.getExtData().id != this.routeId)
                el.hide();
              else el.show();
            } else el.show(); //选择全部，显示所有路线
          });
        }
        //设置显示清扫车区域
        if (areaList) {
          areaList.forEach((el) => {
            if (this.routeId) {
              if (el.getExtData().id && el.getExtData().id != this.routeId) {
                el.hide();
              } else {
                el.show();
              }
            } else {
              el.show();
            }
          });
        }
        // 设置显示隐藏站点和站点名
        let allMarkers = this.map.getAllOverlays();
        allMarkers.forEach((ele) => {
          let obj = ele.getExtData();
          // 有type属性的是站点相关
          if (obj.type) {
            // 全部隐藏
            ele.hide();
            if (obj.type == "stationImg") {
              if (!this.routeNameNow) {
                ele.show();
              } else if (this.routeNameNow == obj.routeName) {
                ele.show();
              }
            } else if (obj.type == "stationText") {
              let zoom = this.map.getZoom();
              if (zoom > this.initialZoom) {
                if (!this.routeNameNow) {
                  ele.show();
                } else if (this.routeNameNow == obj.routeName) {
                  ele.show();
                }
              }
            }
          }
        });

        if (this.carMarkerList) {
          let zoom = this.map.getZoom();
          this.carMarkerList.forEach((el) => {
            let carMarker = el.marker;
            let carText = el.markerText;
            if (this.routeId) {
              //设置显示车辆
              if (carMarker.getExtData().carRouteId == this.routeId) {
                carMarker.show();
              } else carMarker.hide();
              //设置显示车辆编号
              carText.hide();
              if (zoom > this.initialZoom) {
                if (carText.getExtData().carRouteId == this.routeId) {
                  carText.show();
                }
              }
              // if (carText.getExtData().carRouteId == this.routeId) {
              //   carText.show();
              // } else carText.hide();
            } else {
              carText.hide();
              if (zoom > this.initialZoom) {
                carText.show();
              }
              carMarker.show();
            } //选择全部，显示所有车辆
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.map-container {
  width: 100%;
  height: 100%;
  position: relative;

  #container {
    width: 100%;
    height: 100%;
  }

  .line-list-container {
    width: 200px;
    padding: 0px;
    background: #ffffff;
    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 14px;
    align-items: center;
    color: #000000;
    display: flex;
    flex-direction: column;
  }

  .line-list-container > div {
    display: flex;
    align-items: center;
    margin-top: 0px;
  }

  .line-list-table/deep/.el-table td,
  .el-table th {
    padding: 3px 5px;
    border: #000000;
    border: 0px;
  }

  .line-list-table/deep/.el-table th > .cell {
    align-self: center;
    width: 100%;
    color: black;
    font-weight: bold;
  }

  .line-list-table/deep/.el-table__body-wrapper::-webkit-scrollbar {
    width: 6px;
  }

  // .line-list-table/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  //   margin-right: 1px;
  //   background: hsla(0, 0%, 70.6%, 0.4);
  //   background-clip: padding-box;
  //   min-height: 30px;
  //   border-radius: 10px;
  // }

  .line-img {
    filter: opacity(1);
    filter: drop-shadow(0 0 0 0 #000000);
  }

  .line-list-title {
    align-items: center;
    font-weight: bold;
  }

  .line-info-container {
    width: 220px;
    height: 120px;
    padding: 5px 10px 5px 10px;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 20px;
    right: 220px;
    font-size: 16px;
    align-items: center;
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
  }

  .line-info-container > div {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .line-info-table {
    padding: 10px 5px 10px 5px;
  }

  .positioning-zoom {
    position: absolute;
    bottom: 40px;
    right: 40px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .positioning {
      padding: 10px;
      width: 60px;
      height: 60px;
      background: #ffffff;
      cursor: pointer;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
    .zoom {
      width: 170px;
      text-align: center;
      padding: 10px;
      margin-top: 10px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      span {
        font-weight: 700;
      }
    }
  }

  .line-color {
    width: 30px;
    height: 5px;
    background-color: #1683d9;
    border-radius: 3px;
    margin-right: 20px;
  }

  .warning-notice {
    width: 347px;
    min-height: 145px;
    background-color: #fff3f3;
    position: fixed;
    left: 200px;
    right: 0;
    margin: 170px auto 0 auto;
    top: 0;
    z-index: 1000;
    border-radius: 4px;
    -webkit-border-radius: 4px;

    .warning-notice-header {
      width: 100%;
      height: 18px;

      .warning-notice-header-boder {
        width: 100%;
        height: 4px;
        border-radius: 100px 100px 0px 0px;
        -webkit-border-radius: 100px 100px 0px 0px;
        background-color: #ff7760;
      }

      .warning-notice-header-title {
        width: 110px;
        height: 18px;
        line-height: 17px;
        background: #ff7760;
        text-align: center;
        margin: -4px auto 0 auto;
        padding: 0;
        border-radius: 0px 0px 11px 11px;
        -webkit-border-radius: 0px 0px 11px 11px;

        span {
          font-size: 12px;
          font-weight: 400;
          color: #fff;
        }
      }
    }

    .warning-notice-body {
      width: 100%;
      min-height: 82px;
      padding: 10px 20px;

      .warning-notice-body-content {
        width: 100%;
        min-height: 62px;

        .content-title {
          width: 100%;
          height: 22px;
          line-height: 22px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 27px;
          margin: 0;
          background: url("../../assets/images/warning_notice.png") no-repeat
            left center;
          background-size: 22px auto;
        }

        .content-body {
          width: 100%;
          min-height: 40px;
          max-height: 260px;
          overflow-y: auto;

          .content-item {
            width: 100%;
            line-height: 22px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            margin-top: 10px;
            padding-left: 7px;
            border-bottom: 1px solid hsla(0, 0%, 50.6%, 0.4);
            .content-body-msg-date {
              font-size: 12px;
              font-weight: 400;
              color: #333333;
              display: block;
            }
            .content-item-btn {
              display: block;
              text-align: right;
              > span:first-child {
                color: #7f7f7f;
                margin-right: 15px;
              }
              span {
                color: #169bd5;
                cursor: pointer;
              }
            }
          }
          .content-item:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .warning-notice-footer {
      width: 100%;
      height: 40px;
      text-align: right;
      padding-left: 14px;
      padding-right: 14px;
      line-height: 40px;
      .btn-close {
        font-size: 14px;
        font-weight: 400;
        color: #7f7f7f;
        cursor: pointer;
      }
    }
  }
}
.icon {
  margin-right: 20px;
}
// /deep/.content-body::-webkit-scrollbar {
//   width: 6px;
//   height: 6px;
// }

// /deep/.content-body::-webkit-scrollbar-thumb {
//   margin-right: 1px;
//   background: hsla(0, 0%, 70.6%, 0.4);
//   background-clip: padding-box;
//   min-height: 30px;
//   border-radius: 20px;
// }
</style>
