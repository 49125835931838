<template>
  <div>
    <button class="centerBtn" style="margin-bottom: 20px" @click="getDowload">
      导出
    </button>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          style="margin-right: 20px"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.roadLine"
          placeholder="路线"
          class="centerSelInput centerInput"
        >
          <el-option
            v-for="item in routeList"
            :key="item.id"
            :label="item.routeName"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import { _MinBusOrderLine } from "@/api/minBus";
import { _SvBusschedulingHistory } from "@/api/svBus";
import { _Download } from "@/api/file";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      addAccount: false,
      formLabelWidth: "100px",
      valueTime: "",
      isAdd: false,
      lineList: [],
      searchObj: {
        vehicleNumber: "",
        value1: "",
        line: "",
      },
      list: {
        page: 1,
        size: 10,
        carNumber: "",
        startTime: "",
        endTime: "",
        roadLine: "",
        parkId: sessionStorage.getItem("parkId"),
      },
      tableData: [],
      columns: [
        {
          label: "日期",
          prop: "jobStartTime",
        },
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },
        {
          label: "车辆子类型",
          prop: "sonCarType",
        },
        {
          label: "行驶路线",
          prop: "routeName",
        },
        {
          label: "安全员",
          prop: "securityName",
        },
        {
          label: "时间段",
          width: 300,
          render: (h, params) => {
            return h("span", params.row.startTime + "-" + params.row.endTime);
          },
        },
      ],
    };
  },
  mounted() {
    this.getList();
    this.getLine();
  },
  methods: {
    cancel() {
      this.valueTime = "";
      this.list = {
        page: 1,
        size: 10,
        carNumber: "",
        startTime: "",
        endTime: "",
        roadLine: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList();
    },
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.valueTime[0];
        this.list.endTime = this.valueTime[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    async getList() {
      let { success, data, message } = await _SvBusschedulingHistory(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2(){
      this.list.page=1
      this.getList()
    },
    async getLine() {
      let { success, data, message } = await _MinBusOrderLine({
        parkId: sessionStorage.getItem("parkId"),
      });
      if (success) {
        this.routeList = data;
        this.loading = false;
      }
    },
    async getDowload() {
      _Download({
        page: 1,
        size: 10,
        carType: 2,
      }).then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        this.exportLoad = false;

        link.download = "排班历史记录导出数据.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.timer = setTimeout(() => {
          this.$message.success("导出成功");
        }, 3000);
      });
    },
    //分页
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
  },
};
</script>

<style scoped>
.el-input__inner {
  border: none !important;
  height: 36px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
</style>