<template>
  <div>
    <el-form :inline="true">
      <el-form-item
        ><el-input
          v-model="list.phone"
          placeholder="用户手机号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <!-- <el-form-item>
        <el-select
          v-model="list.orderType"
          placeholder="订单类型"
          class="centerSelInput centerInput"
        >
          <el-option
            v-for="item in customerList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item> -->
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <el-dialog title="切换运营状态" :visible.sync="operationStatus" width="30%">
      <p>
        <span class="textcolor">当前状态</span>
        <span class="textcolor2">空闲</span>
      </p>
      <div>
        <span class="red">*</span><span class="textcolor">切换运营状态至</span>
        <el-radio v-model="radio" label="1">维修中</el-radio>
        <el-radio v-model="radio" label="2">运营下线</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="operationStatus = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-dialog title="切换运营模式" :visible.sync="operationMode" width="30%">
      <p>
        <span class="textcolor">当前车辆运营模式</span>
        <span class="textcolor2">普通模式</span>
      </p>
      <p>
        <span class="textcolor">是否切换运营模式至</span>
        <span class="textcolor2">vip模式?</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="operationMode = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import img from "../../assets/images/feedback.png";
import { _SvBusserviceEvaluation } from "@/api/svBus";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      radio: "1",
      resetcarstatus: false,
      operationMode: false,
      operationStatus: false,
      formLabelWidth: "100px",
      isAdd: false,
      searchObj: {},

      tableData: [],
      columns: [
        {
          label: "订单号",
          prop: "orderNo",
        },
        // {
        //   label: "订单类型",
        //   render(h, params) {
        //     if (params.row.orderType == "1") {
        //       return h("span", "班线订单");
        //     } else if (params.row.orderType == "2") {
        //       return h("span", "VIP实时订单");
        //     } else if (params.row.orderType == "3") {
        //       return h("span", "VIP预约订单");
        //     }
        //   },
        // },
        {
          label: "用户手机号",
          prop: "phone",
        },
        {
          label: "满意度",
          render: (h, params) => {
            let list = [];
            for (var i = 0; i < params.row.satisfaction; i++) {
              list.push("1");
            }
            return h(
              "div",
              list.map(function (item, index) {
                return h("img", {
                  style: {
                    color: "#1683D9",
                  },
                  attrs: {
                    src: img, // 如果是1那种写法,不能实时获取,获取的还是undefined
                  },
                });
              })
            );
          },
        },
        {
          label: "反馈内容",
          prop: "feedbackContent",
        },
        {
          label: "时间",
          prop: "createTime",
        },
      ],
      customerList: [
        { id: 1, value: "班线订单" },
        { id: 2, value: "VIP实时订单" },
        { id: 3, value: "VIP预约订单" },
      ],
      list: {
        page: 1,
        size: 10,
        phone: "",
        orderType: "",
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.list = {
        page: 1,
        size: 10,
        phone: "",
        orderType: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList()
    },
    async getList() {
      let { success, data, message } = await _SvBusserviceEvaluation(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2(){
      this.list.page=1
      this.getList()
    },
    //分页
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
  },
};
</script>

<style scoped>
.textcolor {
  font-size: 14px;
  color: #666666;
  margin-right: 20px;
}
.textcolor2 {
  font-size: 14px;
  color: #333333;
}
</style>