<template>
  <div class="videoBox">
    <video
      ref="videoRef"
      id="videoElement"
      controls
      muted
      @play="videoIsPlay = true"
      @pause="videoIsPlay = false"
      @ended="videoIsPlay = false"
    ></video>
    <i
      class="el-icon-video-play icon"
      @click="changeVideoStatus"
      v-show="!videoIsPlay"
    ></i>
  </div>
</template>

<script>
import flvjs from "flv.js";

export default {
  data() {
    return {
      flvPlayer: null,
      videoIsPlay: false
    };
  },
  props: {
    url: {
      type: String
    }
  },
  methods: {
    /**
     * @description 新建flv实例
     */
    createFlvPlayer() {
      if (flvjs.isSupported()) {
        const videoElement = document.getElementById("videoElement");
        this.flvPlayer = flvjs.createPlayer({
          type: "flv",
          isLive: true,
          url: this.url,
          hasAudio: false
        });

        this.flvPlayer.attachMediaElement(videoElement);
        this.flvPlayer.load();
        this.flvPlayer.play();
      }
    },

    /**
     * @description 停止混流播放并移除直播流抓取
     * (注：离开并重新进入当前路由，观察Network，可知该操作的必要性)
     */
    pausemix() {
      this.flvPlayer.pause();
      this.flvPlayer.unload();
      this.flvPlayer.detachMediaElement();
      this.flvPlayer.destroy();
      this.flvPlayer = null;
      //   console.log('调用删除成功')
    },
    // 视频播放
    changeVideoStatus() {
      const video = this.$refs.videoRef;
      if (video.paused) {
        if (this.flvPlayer) {
          this.flvPlayer.play();
        } else {
          this.$emit("openVideoUrl")
          this.createFlvPlayer();
        }
      } else {
        this.flvPlayer.pause();
      }
    }
  },
  watch: {
    url(newVal, oldVal) {
      if (!oldVal) {
        this.$nextTick(() => {
          // this.createFlvPlayer();
        });
        console.log(newVal, oldVal);
      }
    }
  },
  mounted() {},
  beforeDestroy() {
    if(this.flvPlayer){
      this.pausemix();
    }
    this.$emit("update:url", "");
    this.$emit("closeVideoUrl")
  }
};
</script>
<style lang="less" scoped>
.videoBox {
  position: relative;
  width: 100%;
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-size: 80px;
    font-weight: 100;
    color: white;
    z-index: 99;
    cursor: pointer;
  }
}
</style>
