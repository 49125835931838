<template>
  <div>
    <button
      class="centerBtn"
      @click="
        (addAccount = true),
          (isAdd = true),
          ((form.username = ''),
          (form.phone = ''),
          (form.name = ''),
          (form.roleCenterIds = []))
      "
      style="margin-bottom: 20px"
    >
      新增
    </button>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.phone"
          placeholder="请输入手机号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-input
          v-model="list.username"
          placeholder="请输入账号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">搜索</button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="parkaccountMngList"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <!-- 添加车辆弹框 -->
    <el-drawer
      :title="isAdd ? '添加账号' : '编辑信息'"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      @closed="handleClose"
      destroy-on-close
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item label="账号" :label-width="formLabelWidth" prop="username">
            <el-input
              v-model="form.username"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
            <el-input
              v-model="form.phone"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入 (接受初始密码短信)"
              :disabled="!isAdd"
            >
            </el-input>
          </el-form-item>
          <!-- <el-form-item
            label="所属组织"
            :label-width="formLabelWidth"
            prop="organization"
          >
            <el-input
              v-model="form.organization"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="角色" :label-width="formLabelWidth" prop="roleCenterIds">
            <el-select
              v-model="form.roleCenterIds"
              multiple
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in rolelist"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="中文名" :label-width="formLabelWidth" prop="name">
            <el-input
              v-model="form.name"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button class="centerCzbtn" @click="getselect" :loading="isLoading"
          >保存</el-button
        >
        <button class="centerBtn resetbtn" @click="addAccount = false">取消</button>
      </div>
    </el-drawer>

    <!-- 删除弹框 -->
    <el-dialog title="删除" :visible.sync="delDia" width="30%">
      <span>确认删除</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getdel">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">取 消</button>
      </span>
    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="resetPwd" width="30%">
      <span>执行操作后，系统会给用户手机号发送新密码</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" @click="getparkPwd">确 定</button>
        <button class="centerBtn resetbtn" @click="resetPwd = false">取 消</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _AccountMngList,
  _AddAccountCpname,
  _AddAccountPhone,
  _AddAccount,
  _UpAccountCpname,
  _UpAccountPhone,
  _UpAccount,
  _UpStatu,
  _UpAccountpwd,
  _DelList,
} from "@/api/systemMng";
import { _RoleList } from "@/api/parksysMng";
export default {
  components: {
    Table,
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

      if (value === "" || value === undefined || value === null) {
        callback();
      } else {
        if (!reg.test(value) && value !== "") {
          callback(new Error("请输入正确的电话号码"));
        } else {
          // if (this.isAdd == false) {
          //   this.getUpphone();
          // } else {
          //   this.getAddphone();
          // }

          callback();
        }
      }
    };
    var validateCpName = (rule, value, callback) => {
      if (value === "" || value === undefined || value === null) {
        callback(new Error("账号名不能为空"));
      } else {
        if (value.length >= 15) {
          callback(new Error("账号名长度不能超过15个字母"));
          return false;
        }

        const reg = /^[a-zA-Z]*$/g;

        if (!reg.test(value)) {
          callback(new Error("账号名只能输入字母"));
          return false;
        } else {
          if (value !== "") {
            callback();
          }
        }
      }
    };
    return {
      loading: false,
      isLoading: false,
      addAccount: false,
      accountEdit: false,
      delDia: false,
      resetPwd: false,
      formLabelWidth: "100px",
      isAdd: false,
      userParkId: "",
      total: 0,
      searchObj: {
        phone: "",
      },
      form: {
        id: "",
        name: "",
        companyId: sessionStorage.getItem("parkId"),
        phone: "",
        username: "",
        roleCenterIds: [],
      },
      rules: {
        username: [
          {
            required: true,
            validator: validateCpName,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入中文名",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            required: true,
            validator: validatePhone, //在data里定义 ( 重 点 )
            trigger: "blur",
            message: "请输入正确的手机号格式",
          },
        ],
        roleCenterIds: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change",
          },
        ],
      },
      columns: [
        {
          label: "账号名",
          prop: "username",
          /*
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    color: "#1683D9",
                  },
                },
                params.row.username
              ),
            ]);
          },
					*/
        },
        {
          label: "角色",
          render: (h, params) => {
            return h(
              "div",
              params.row.roleCenterList.map(function (item, index) {
                return h("div", item.roleName);
              })
            );
          },
        },
        {
          label: "中文名",
          prop: "name",
        },
        {
          label: "所属组织",
          prop: "companyName",
        },
        {
          label: "手机号",
          prop: "phone",
        },
        {
          label: "激活",
          render: (h, params) => {
            return h("div", {}, [
              h("span", {style: `color: ${params.row.activate? '#2FCA3B': '#A2B2A4'}`}, '●'),
              h("span", {}, params.row.activate ? '已激活': '未激活')
            ])
          }
        },
        {
          label: "账号状态",
          prop: "status",
          render: (h, params) => {
            return h("div", [
              h(
                "el-switch",
                {
                  on: {
                    change: (val) => {
                      if (val == true) {
                        params.row.authStatus = 1;
                      } else {
                        params.row.authStatus = 0;
                      }
                      this.getparkstatus(params.row, params.row.authStatus);
                    },
                  },
                  props: {
                    value: params.row.authStatus == 0 ? false : true,
                    disabled: (params.row.activate ? false : true),
                  },
                },
                "编辑"
              ),
            ]);
          },
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: 200,
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.isAdd = false;
                      this.addAccount = true;
                      let list = [];
                      this.userCompanyId = params.row.id;
                      this.form = {
                        username: params.row.username,
                        name: params.row.name,
                        phone: params.row.phone,
                        roleCenterIds: list,
                        parkId: params.row.parkId,
                        id: params.row.id,
                      };
                      for (var a = 0; a < params.row.roleCenterList.length; a++) {
                        list.push(params.row.roleCenterList[a].id);
                      }
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      if(params.row.activate) {
                        this.resetPwd = true;
                        this.userParkId = params.row.id;
                      }
                    },
                  },
                  style: `cursor: ${params.row.activate ? 'pointer': 'not-allowed'};
                          color: ${params.row.activate ? '#1683d9': '#A2B2A4'}`
                },
                "重置密码"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.delDia = true;
                      this.userParkId = params.row.id;
                    },
                  },
                },
                "删除账号"
              ),
            ]);
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        total: 0,
        phone: "",
        username: "",
        companyId: sessionStorage.getItem("parkId"),
      },
      parkaccountMngList: [],
      rolelist: [],
    };
  },
  mounted() {
    this.getList();
    this.getroleList();
  },
  methods: {
    cancelB() {
      // window.location.reload();
      this.addAccount = false;
      this.form = {
        id: "",
        name: "",
        companyId: sessionStorage.getItem("parkId"),
        phone: "",
        username: "",
        roleCenterIds: [],
      };
    },
    handleClose(done) {
      // window.location.reload();
      this.addAccount = false;
      this.form = {
        id: "",
        name: "",
        companyId: sessionStorage.getItem("parkId"),
        phone: "",
        username: "",
        roleCenterIds: [],
      };
    },
    getselect() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd == false) {
            this.getparkUp();
          } else {
            this.getAdd();
          }
        }
      });
    },
    cancel() {
      this.list.page1;
      this.list.phone = "";
      this.list.username = "";
      this.getList();
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async getList() {
      let { success, data, message } = await _AccountMngList(this.list);
      if (success) {
        this.parkaccountMngList = data.list;
        this.list.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2() {
      this.list.page = 1;
      this.getList();
    },
    async getAddCpname() {
      console.log(this.form.username);
      let { success, data, message } = await _AddAccountCpname({
        username: this.form.username,
      });
      if (success) {
      }
    },
    async getAddphone() {
      let { success, data, message } = await _AddAccountPhone({
        phone: this.form.phone,
      });
      if (success) {
      }
    },
    async getUpCpname() {
      let { success, data, message } = await _UpAccountCpname({
        userId: this.userCompanyId,
        username: this.form.username,
      });
      if (success) {
      }
    },
    async getUpphone() {
      let { success, data, message } = await _UpAccountPhone({
        userId: this.userCompanyId,
        phone: this.form.phone,
      });
      if (success) {
      }
    },
    async getparkUp() {
      _UpAccount(this.form).then((res) => {
        if (res.success == true) {
          this.getList();
          this.$message.success("账号修改成功");
          this.addAccount = false;
        }
      });
    },
    async getparkPwd() {
      let { success, data, message } = await _UpAccountpwd({
        userId: this.userParkId,
        platformType: 2
      });
      if (success) {
        this.getList();
        this.$message.success("重置密码成功");
      }
      this.resetPwd = false;
    },
    async getparkstatus(row, statu) {
      let { success, data, message } = await _UpStatu({
        userId: row.id,
        authStatus: statu,
      });
      if (success) {
        this.getList();
      }
      this.resetPwd = false;
    },
    async getroleList() {
      let { success, data, message } = await _RoleList({
        companyId: this.form.companyId,
      });
      if (success) {
        this.rolelist = data;
      }
    },
    async getAdd() {
      this.isLoading = true;
      _AddAccount(this.form).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success == true) {
          this.getList();
          this.$message.success("账号添加成功");
          this.addAccount = false;
          this.isLoading = false;
        }
      });
    },
    async getdel() {
      _DelList({ userId: this.userParkId }).then((res) => {
        if (res.success == true) {
          this.getList();
          this.$message.success("账号删除成功");
        }
        this.delDia = false;
      });
    },
  },
};
</script>

<style scoped></style>
