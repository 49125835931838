<template>
  <div>
    <button class="centerBtn" @click="getAdd" style="margin-bottom: 20px">
      新增
    </button>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-input
          v-model="list.carVin"
          placeholder="VIN"
          class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          style="margin-right: 20px"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="list.loading"
      @getAjax="getTableData"
    />
    <el-drawer
      title="新增维修记录"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      destroy-on-close
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item
            label="车辆编号"
            :label-width="formLabelWidth"
            prop="carRegisterId"
          >
            <el-select
              v-model="form.carRegisterId"
              filterable
              placeholder="请选择"
              class="select"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.carNumber"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="故障描述"
            :label-width="formLabelWidth"
            prop="faultDescription"
          >
            <el-input
              type="textarea"
              class="centerCzInput"
              :rows="4"
              placeholder="请输入故障描述"
              v-model="form.faultDescription"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="维修处理方案"
            :label-width="formLabelWidth"
            prop="treatmentScheme"
            class="time"
          >
            <el-input
              type="textarea"
              class="centerCzInput"
              :rows="4"
              placeholder="请输入维修处理方案"
              v-model="form.treatmentScheme"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button class="centerCzbtn" @click="getadd">保存</el-button>
        <button class="centerBtn resetbtn" @click="addAccount = false">
          取消
        </button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _SQcarMain,
  _SQcarMainType,
  _SQcarMainAdd,
} from "@/api/statisticalQuery";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      addAccount: false,
      formLabelWidth: "100px",
      valueTime: "",
      isAdd: false,
      searchObj: {
        vehicleNumber: "",
        faultMaintenance: "",
        VIN: "",
      },
      form: {
        carRegisterId: "",
        faultDescription: "",
        treatmentScheme: "",
        companyId: sessionStorage.getItem("parkId"),
      },
      rules: {
        faultDescription: [
          {
            required: true,
            message: "请输入故障描述",
            trigger: "blur",
          },
        ],
        treatmentScheme: [
          {
            required: true,
            message: "请输入处理方案",
            trigger: "blur",
          },
        ],
        carRegisterId: [
          {
            required: true,
            message: "请选择车辆编号",
            trigger: "change",
          },
        ],
      },
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆VIN",
          prop: "carVin",
        },
        {
          label: "车辆类型",
          prop: "typeName",
        },
        {
          label: "车辆子类型",
          prop: "sonTypeName",
        },
        {
          label: "运营类别",
          prop: "operationTypeName",
        },
        {
          label: "故障描述",
          prop: "faultDescription",
        },
        {
          label: "维修处理方案",
          prop: "treatmentScheme",
        },
        {
          label: "登记时间",
          prop: "createTime",
        },
        {
          label: "记录人",
          prop: "uName",
        },
      ],
      list: {
        page: 1,
        size: 10,
        total: 0,
        loading: false,
        carNumber: "",
        carVin: "",
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
      },
      typeList:[]
    };
  },
  mounted() {
    this.getList();
    this.getCarType();
  },
  methods: {
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.valueTime[0];
        this.list.endTime = this.valueTime[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    cancel() {
      this.valueTime = "";
      this.list = {
        page: this.list.page,
        size: this.list.size,
        total: this.list.total,
        loading: false,
        carNumber: "",
        carVin: "",
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList();
    },
    getAdd() {
      this.addAccount = true;
      this.form = {
        carRegisterId: "",
        faultDescription: "",
        treatmentScheme: "",
        companyId: sessionStorage.getItem("parkId"),
      };
    },
    async getadd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          _SQcarMainAdd(this.form).then((res) => {
            if (!res) {
              this.isLoading = false;
            }
            if (res.success) {
              this.$message.success("新增成功");
              this.isLoading = false;
            }
            this.addAccount = false;
            this.getList();
          });
        }
      });
    },
    async getList() {
      this.list.loading = true;

      let { success, data, message } = await _SQcarMain(this.list);
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.list.loading = false;
      }
    },
    async getTableData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }

      await this.getList();
    },
    async getCarType() {
      let { success, data, message } = await _SQcarMainType({
        parkId: this.list.parkId,
      });
      if (success) {
        this.typeList = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.el-input__inner {
  border: none !important;
  height: 36px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
</style>
