<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="searchObj.vehicleNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="searchObj.value1"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        ><button class="centerCzbtn ml20">搜索</button>
        <button class="centerBtn resetbtn">重置</button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="10"
      :loading="loading"
    />
    <el-dialog title="删除" :visible.sync="delDiaA" width="30%">
      <span>确认删除吗</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn" >确 定</button>
        <button class="centerBtn resetbtn ml20" @click="delDiaA = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      vipshow: false,
      delDiaA:false,
      searchObj: {
        vehicleNumber: "",
        value1: "",
      },
      tableData: [
        {
          phone: "134****5467",
          time: "2021-06-12 16:34:56",
          operation: "-",
        },
        {
          phone: "134****5467",
          time: "2021-06-12 16:34:56",
          operation: "-",
        },
        {
          phone: "134****5467",
          time: "2021-06-12 16:34:56",
          operation: "-",
        },
        {
          phone: "134****5467",
          time: "2021-06-12 16:34:56",
          operation: "-",
        },
      ],
      columns: [
        {
          label: "手机号",
          prop: "phone",
        },
        {
          label: "访问时间",
          prop: "time",
        },
        {
          label: "操作",
          prop: "operation",
          width: 160,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationB",
                  on: {
                    click: () => {
                      this.delDiaA = true;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.textcolor {
  font-size: 14px;
  color: #666666;
  margin-right: 20px;
}
.textcolor2 {
  font-size: 14px;
  color: #333333;
}
.el-input__inner {
  border: none !important;
}
/deep/.el-textarea__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
</style>