import service from '../../until/api'

// 地图管理_新增坐标
export function _addLocation(data) {
    return service({
        url: 'transport-truck/web/mapManage/insert',
        method: 'POST',
        data
    })
}


//地图管理——删除
export function _DeleteData(data) {
    return service({
        url: 'transport-truck/web/mapManage/delete',
        method: 'GET',
        params: data
    })
}



//地图管理——查询
export function _GetList(data) {
    return service({
        url: 'transport-truck/web/mapManage/findPage',
        method: 'GET',
        params: data
    })
}


//线路管理导入
export function _ExcelUpload(data) {
    return service({
        url: 'file-nacos/excelUpload/uploadMapPointByCsv',
        method: 'POST',
        data
    })
}

export function _ExcelDownload(data) {
    return service({
        url: 'file-nacos/excelDownload/exportMapPointTemplate',
        method: 'GET',
        params: data,
        responseType: 'blob'
    })
}

