import service from '../../until/api'
import axios from "axios";
//车辆运营
export function _MinBusList(data) {
    return service({
        url: 'mini-bus/web/carOperation/findPageForSaas',
        method: 'GET',
        params: data
    })
}

export function _MinBusStatus(data) {
    return service({
        url: 'mini-bus/web/carOperation/switchOperationStatus',
        method: 'GET',
        params: data
    })
}
export function _MinBusModel(data) {
    return service({
        url: 'mini-bus/web/carOperation/switchOperationType',
        method: 'GET',
        params: data
    })
}
export function _MinBusDel(data) {
    return service({
        url: 'mini-bus/web/carOperation/deleteCarOperation',
        method: 'GET',
        params: data
    })
}
//车辆运营记录
export function _MinBusListRecord(data) {
    return service({
        url: 'mini-bus/web/carOperationRecord/findPage',
        method: 'GET',
        params: data
    })
}
//订单管理
export function _MinBusOrderStatus(data) {
    return service({
        url: 'mini-bus/web/orderManage/findOrderStatus',
        method: 'GET',
        params: data
    })
}
export function _MinBusOrderList(data) {
    return service({
        url: 'mini-bus/web/orderManage/findPage',
        method: 'GET',
        params: data
    })
}
//查询全部路线
export function _MinBusOrderLine(data) {
    return service({
        url: 'mini-bus/web/carOperationRecord/findRouteAll',
        method: 'GET',
        params: data
    })
}
export function _MinBusOrderDetail(data) {
    return service({
        url: 'mini-bus/web/orderManage/findDetail',
        method: 'GET',
        params: data
    })
}
//取消订单
export function _MinBusOrdercancel(data) {
    return service({
        url: 'protocol-nacos/taxiManage/isCancelOrder',
        method: 'GET',
        params: data
    })
}
//排班计划
export function _MinBuschedulingPlan(data) {
    return service({
        url: 'mini-bus/web/schedulingPlan/findPage',
        method: 'GET',
        params: data
    })
}
export function _MinBuschedulingDel(data) {
    return service({
        url: 'mini-bus/web/schedulingPlan/delete',
        method: 'GET',
        params: data
    })
}


export function _MinBuschedulingAdd(data) {
    return service({
        url: 'mini-bus/web/schedulingPlan/insert',
        method: 'POST',
        data
    })
}
export function _MinBuschedulingUp(data) {
    return service({
        url: 'mini-bus/web/schedulingPlan/update',
        method: 'POST',
        data
    })
}
//排班历史

export function _MinBusschedulingHistory(data) {
    return service({
        url: 'mini-bus/web/schedulingHistory/findPage',
        method: 'GET',
        params: data
    })
}
//查询安全员

export function _MinBusGetpep(data) {
    return service({
        url: 'user-nacos/user/findAllByParkId',
        method: 'GET',
        params: data
    })
}

//服务评价
export function _MinBusserviceEvaluation(data) {
    return service({
        url: 'mini-bus/web/serviceEvaluation/findPage',
        method: 'GET',
        params: data
    })
}
//获取车辆
export function _MinBuscarRegister(data) {
    return service({
        url: 'vehicle-auth/carRegister/findByParkId',
        method: 'GET',
        params: data
    })
}
//视频查看
export function _MinBusvideoViewing(data) {
    return service({
        url: 'mini-bus/web/videoViewing/findPage',
        method: 'GET',
        params: data
    })
}
//查看历史视频
export function _MinBusvideoViewingHis(data) {
    return service({
        url: 'mini-bus/web/videoViewing/findVideoByCarNumber',
        method: 'GET',
        params: data
    })
}
//

export function _MinBusvideoViewingAll(data) {
    return service({
        url: 'mini-bus/web/videoViewing/getHistoricalVideo',
        method: 'GET',
        params: data
    })
}
//查询线路名
export function _MinBusgetLineName(data) {
    return service({
        url: 'vehicle-auth/carRoute/findByCarId',
        method: 'GET',
        params: data
    })
}
//查看视频下发操作
export function _MinBusgetVideoveh(data,_this) {
    let CancelToken = axios.CancelToken;
    return service({
        url: 'protocol-nacos/vehicleController/vehicleVideoControl',
        method: 'POST',
        data,
        cancelToken: new CancelToken(function executor(c) {
            console.log(_this.cancelAjax);
            _this.cancelAjax.fn = c;
            _this.cancelAjax.cmd = data.cmd
        }),
    })
}





//数据看板

//订单概览
export function _OrderDataOverview(data) {
    return service({
        url: '/mini-bus/web/operationDataPlatform/orderDataOverview',
        method: 'GET',
        params: data
    })
}
//  车辆实时运营
export function _RealTimeCarOperationData(data) {
    return service({
        url: '/mini-bus/web/operationDataPlatform/realTimeCarOperationData',
        method: 'GET',
        params: data
    })
}
   
//昨日运营线路概览
export function _OperationRouteOverview(data) {
    return service({
        url: '/mini-bus/web/operationDataPlatform/operationRouteOverview',
        method: 'GET',
        params: data
    })
}
// 运营服务详情  
export function _OperationServiceOverview(data) {
    return service({
        url: '/mini-bus/web/operationDataPlatform/operationServiceOverview',
        method: 'GET',
        params: data
    })
}


/**
 * @msg: 获取车辆线路
 * @param {*} data
 */
export function _GetAllRoute(data) {
    return service({
        url: '/mini-bus/web/schedulingPlan/getMinibusCarRouteInfo',
        method: 'GET',
        params: data
    })
}
