<template>
	<div class="container">
		<div id="map_bg"></div>
		
		<div class="data-header-container">
			<div class="r-date">
				<div class="r-time">{{ time }}</div>
				<div class="r-timeB">
					<span class="r-timeA">{{ date }}</span>
					<span class="r-timeA">{{ week }}</span>
				</div>
			</div>
		</div>
		
		<div class="left-container">
			<div class="left-data">
				<div class="model-title">业务概览</div>
				
				<el-row class="left-row-1">
					<el-col :span="8" class="lr1-item">
						<p class="title">总运营里程</p>
						<p class="value"><span>{{ businessOverview.totalCarMile }}</span>km</p>
					</el-col>
					<el-col :span="8" class="lr1-item">
						<p class="title">总订单数</p>
						<p class="value"><span>{{ businessOverview.totalOrderNum }}</span>单</p>
					</el-col>
					<el-col :span="8" class="lr1-item">
						<p class="title">总完成任务数</p>
						<p class="value"><span>{{ businessOverview.totalCompletedOrderNum }}</span>项</p>
					</el-col>
				</el-row>
				
				<div class="left-row-2">
					<div class="sub-title">今日运营实况</div>
					
					<div class="left">
						<i-circle
								:size="circleWidth"
								:trail-width="6"
								:stroke-width="6"
								:percent="OperatingCarRate"
								stroke-linecap="square"
								stroke-color="rgb(42,225,225)"
						>
							<div class="demo-Circle-custom">
								<p class="lc-textB">{{ todayOperation.todayOperatingCarNum }}/{{ todayOperation.totalCarNum }}</p>
								<p class="lc-textC">今日运营车辆</p>
							</div>
						</i-circle>
					</div>
					<div class="right">
						<p>今日园区乘车: <span>{{ todayOperation.todayPeopleNum }}</span>人次</p>
						<p>今日园区招车: <span>{{ todayOperation.todayCallCarNum }}</span>单</p>
						<p>今日运营里程: <span>{{ todayOperation.todayTotalOperateMiles }}</span>km</p>
					</div>
				</div>
				
				<div class="left-row-3">
					<div class="sub-title">实时订单动态</div>
					
					<ul class="order-list" v-if="orderList.length > 0">
						<li v-for="(item, idx) in orderList" :key="idx">
							<span class="user">{{ item.appUserName }}</span>
							<span class="site"><i class="el-icon-location-outline"></i> {{ item.startName }}</span>
							<span class="status">{{ item.orderStatuName }}</span>
						</li>
					</ul>
					<div class="order-list-empty" v-else>
						<el-empty description="暂无订单"></el-empty>
					</div>
				</div>
			</div>
			<div class="left-mask"></div>
		</div>
		
		<div class="right-container">
			<div class="right-data">
				<div class="model-title">车辆实况</div>
				
				<el-row class="car-list">
					<el-col :span="6" class="car-item" v-for="(item, idx) in carActuality" :key="idx">
						<img :class="item.carNumber === currentCarActuality.sonCarType ? 'car-active': ''" src="../../assets/images/bigcar.png" alt="">
					</el-col>
				</el-row>
				
				<el-row class="car-name">
					<el-col :span="14" class="name">
						{{ currentCarActuality.sonCarType ? currentCarActuality.sonCarType : '--' }}
						<p class="status">{{ currentCarActuality.carStatus ? currentCarActuality.carStatus : '--' }}</p>
					</el-col>
					<el-col :span="10" class="power">
						<span class="power-icon power-icon-full" v-if="currentCarActuality.electricQuantity >= 71"></span>
						<span class="power-icon power-icon-half" v-else-if="currentCarActuality.electricQuantity >= 31 && currentCarActuality.electricQuantity <= 70"></span>
						<span class="power-icon power-icon-empty" v-else></span>
						{{ currentCarActuality.electricQuantity }}%
					</el-col>
				</el-row>
				
				<div class="car-image">
					<img src="../../assets/images/big_car_2.png" alt="">
				</div>
				
				<div class="car-info">
					<div class="left">
						<div class="title">驾驶模式</div>
						
						<div class="autopilot-model">
							<p class="model-name">{{ currentCarActuality.driveMode ? currentCarActuality.driveMode : '--' }}</p>
							<p class="line-name">{{ currentCarActuality.carRoute ? currentCarActuality.carRoute : '--' }}</p>
						</div>
						
						<div class="speed-charts">
							<div id="speed_charts"></div>
							<div class="speed-charts-name">实时车速Km/h</div>
						</div>
					</div>
					<div class="right">
						<div class="title">电量</div>
						
						<div class="power-model">
							<p class="model-name">{{ currentCarActuality.electricQuantity }}%</p>
							<p class="line-name">预计 16:47 充电完毕</p>
						</div>
						
						<div class="power-charts">
							<div id="power_charts"></div>
							<div class="power-charts-name">续航里程KM</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right-mask"></div>
		</div>
		
		<div class="footer-container">
			<div class="footer-data">
				<div class="left">
					<div class="model-title">近三个月园区站点订单分布概览</div>
					
					<div class="order-chart">
						<div id="order_one"></div>
						<div id="order_two"></div>
					</div>
				</div>
				<div class="center">
					<div class="model-title">近30天运营质量概览</div>
					
					<div class="overview">
						<div class="avg">
							<i-circle
									:size="overviewCircleSize"
									:trail-width="6"
									:stroke-width="6"
									:percent="100"
									stroke-linecap="square"
									stroke-color="rgb(42,225,225)"
									style="margin-top: 4.6vh; margin-left: 1.8vw;"
							>
								<div class="demo-Circle-custom">
									<p class="lc-textB">{{ operationQuality.onceSitePeopleNum }}</p>
									<p class="lc-textC">人</p>
								</div>
							</i-circle>
							<div class="overview-title">站点单次上车平均人数</div>
						</div>
						<div class="complete">
							<div id="order_completion_rate_chart"></div>
							<div class="complete-title">订单完成率</div>
						</div>
						<div class="riding">
							<i-circle
									:size="overviewCircleSize"
									:trail-width="6"
									:stroke-width="6"
									:percent="100"
									stroke-linecap="square"
									stroke-color="rgb(42,225,225)"
									style="margin-top: 4.6vh; margin-left: 1.8vw;"
							>
								<div class="demo-Circle-custom">
									<p class="lc-textB">{{ operationQuality.perDayPeopleNum }}</p>
									<p class="lc-textC">人</p>
								</div>
							</i-circle>
							<div class="overview-title">日均乘车人次</div>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="model-title">近15日运营情况趋势</div>
					
					<div id="operation_chart"></div>
				</div>
			</div>
			<div class="footer-mask"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	import "echarts-liquidfill";
	import {
		_FindBusinessOverview,
		_FindCarActuality, _FindCarRouteMap,
		_FindOperationQuality,
		_FindOrderDistribution,
		_FindOrderList,
		_FindParkByCarOperation,
		_FindTodayOperation,
		_FindTodayTrends
	} from "@/api/equipmentMonitoring";
	
	import { ellipsis, calculateCenter, computeSignedArea } from "@/until/helper";
	import { Amap, Marker, Polyline } from "@amap/amap-vue";
	
	export default {
		data() {
			return {
				circleWidth: window.innerHeight/100*15,
				overviewCircleSize: window.innerHeight/100*11,
				OperatingCarRate: 0,
				list: 5,
				imglist: 4,
				time: null,
				date: null,
				week: null,
				scalseNum: 1,
				ParkID: '',
				todayOperation: {
					todayAvgOperateTime: 0,
					todayOperatingCarNum: 0,
					todayTotalOperateMiles: 0,
					todayTotalOrders: 0,
					totalCarNum: 0,
					todayPeopleNum: 0,
					todayCallCarNum: 0,
				},
				todayTrends: {
					carMilesTrends: [],
					peopleNumTrends: [],
				},
				businessOverview: {
					totalCarMile: 0,
					totalCompletedOrderNum: 0,
					totalOrderNum: 0,
				},
				operationQuality: {
					onceSitePeopleNum: 0,
					orderCompletePercent: 0,
					perDayPeopleNum: 0,
				},
				carActuality: [],
				currentCarActuality: {
					sonCarType: '',
					carSpeed: 0,
					recharge: 0,
					driveMode: '--',
					carRoute: '',
					electricQuantity: 0,
				},
				currentCarActualityIdx: 0,
				orderList: [],
				orderDistribution: {
					one: {
						title: '',
						list: [],
					},
					two: {
						title: '',
						list: [],
					}
				},
				parkCarAndLine: [],
				carMarkerList: [],
			};
		},
		computed: {
			Amap,
		},
		mounted() {
			window.onresize = () => {
				return (() => {
					this.getCircleWidth();
					this.getOverviewCircleSize();
				})()
			}
			
			setInterval((res) => {
				this.getTime();
			}, 1000);

			this.initPage();
			
			setInterval((res) => {
				this.UpdateCarLngLat();
			}, 1000);
		},
		methods: {
			//获取时间
			getTime() {
				var obj = new Date();
				if (obj.getSeconds() < 10) {
					this.time =
							obj.getHours() + ":" + obj.getMinutes() + ":0" + obj.getSeconds();
				} else {
					this.time =
							obj.getHours() + ":" + obj.getMinutes() + ":" + obj.getSeconds();
				}
				if (obj.getMonth() + 1 < 10) {
					this.date =
							obj.getFullYear() + "-0" + (obj.getMonth() + 1) + "-" + obj.getDate();
				} else if (obj.getDate() < 10) {
					this.date =
							obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-0" + obj.getDate();
				} else if (obj.getDate() < 10 && obj.getMonth() + 1 < 10) {
					this.date =
							obj.getFullYear() +
							"-0" +
							(obj.getMonth() + 1) +
							"-0" +
							obj.getDate();
				} else {
					this.date =
							obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate();
				}
				var weeks = new Array(
						"星期日",
						"星期一",
						"星期二",
						"星期三",
						"星期四",
						"星期五",
						"星期六"
				);
				this.week = weeks[obj.getDay()];
			},
			
			getCircleWidth(){
				this.circleWidth = window.innerHeight/100*15;
			},
			
			getOverviewCircleSize(){
				this.overviewCircleSize = window.innerHeight/100*10;
			},
			
			async initPage(){
				this.ParkID = sessionStorage.getItem("parkId");
				this.getCircleWidth();
				await this.initMap();
				await this.initLine();

				await this.getTodayOperation();
				await this.getTodayTrends();
				await this.getBusinessOverview();
				await this.getOperationQuality();
				await this.getCarActuality();
				await this.getOrderList();
				await this.getOrderDistribution();
				
				this.initCharts();
			},
			
			//region 地图操作
			async initMap(){
				let { success, data, message } = await _FindParkByCarOperation({
					parkId: this.ParkID,
				});
				
				if (!success) {
					return false;
				}
				
				let routeSite = null;
				let CenterPoint = null;
				let arrCenter = [108.112583, 34.233588];
				let nLngLatArea = 0;
				let nZoom = 5;
				var nParkArea = 0;
				
				if (data.parkScope){
					routeSite = JSON.parse(data.parkScope);
					if (routeSite.length > 0){
						routeSite.push(routeSite[0]); //绘制线路需要将第一个元素复制一个放到数组最后，完成高德线段的闭合绘制
					}
					
					CenterPoint = calculateCenter(routeSite);
					nLngLatArea = Math.round(computeSignedArea(routeSite));
					nParkArea = Math.round(AMap.GeometryUtil.ringArea(routeSite)); //计算范围面积，单位平方米
					arrCenter = [CenterPoint.lng, CenterPoint.lat];
				}
				
				//根据面积，配置地图缩放级别
				if (nLngLatArea <= 1) {
					nZoom = 16;
				} else if (nLngLatArea <= 2) {
					nZoom = 15;
				} else if (nLngLatArea <= 3) {
					nZoom = 14;
				} else if (nLngLatArea <= 4) {
					nZoom = 13;
				} else {
					nZoom = 12;
				}
				
				this.addedmap = new AMap.Map("map_bg", {
					resizeEnable: true,
					center: arrCenter,
					zoom: nZoom,
				});
				
				this.addedmap.setMapStyle('amap://styles/darkblue');
				
				if (routeSite){
					//this.CreateFence(routeSite);
				}
				
				if (data.operationList){
					for (const car of data.operationList) {
						await this.initCar(car);
					}
				}
			},
			
			async initLine(){
				let res = await _FindCarRouteMap({
					parkId: this.ParkID,
				});
				
				if (res){
					if (res.success && res.data){
						for (const item of res.data) {
							//region 线路
							if (!item.routeSite || item.routeSite === ''){
								continue;
							}
							
							let lineList = JSON.parse(item.routeSite);
							
							this.CreateLine(lineList);
							//endregion 线路
							
							//region 站点
							if (item.pointNameMap){
								this.CreateSite(item.pointNameMap);
							}
							//endregion 站点
						}
					}
				}
			},
			
			//初始化车辆
			async initCar(car_data){
				if (!car_data) {
					return false;
				}
				
				var sCarTypeImageDefault = '';
				var sCarTypeImageActive = '';
				
				switch (car_data.parentCarTypeId) {
					case "1":
						sCarTypeImageDefault = require('@/assets/images/icon_wgj_default.png');
						sCarTypeImageActive = require('@/assets/images/icon_wgj_active.png');
						break;
					
					case "2":
						sCarTypeImageDefault = require('@/assets/images/icon_bus_default.png');
						sCarTypeImageActive = require('@/assets/images/icon_bus_active.png');
						break;
					
					case "3":
						sCarTypeImageDefault = require('@/assets/images/icon_bus_default.png');
						sCarTypeImageActive = require('@/assets/images/icon_bus_active.png');
						break;
					
					case "4":
						sCarTypeImageDefault = require('@/assets/images/icon_bus_default.png');
						sCarTypeImageActive = require('@/assets/images/icon_bus_active.png');
						break;
					
					case "5":
						sCarTypeImageDefault = require('@/assets/images/icon_bus_default.png');
						sCarTypeImageActive = require('@/assets/images/icon_bus_active.png');
						break;
					
					default:
						sCarTypeImageDefault = require('@/assets/images/minbus.png');
						sCarTypeImageActive = require('@/assets/images/minbus.png');
						break;
				}
				
				var that = this;
				var GPS_Position = [car_data.longitude, car_data.latitude];
				var AmapPosition;
				
				//region GPS坐标转换成高德坐标
				AMap.convertFrom(GPS_Position, 'gps', function (status, result) {
					if (result.info === 'ok') {
						AmapPosition = [result['locations'][0].lng, result['locations'][0].lat];
						
						// console.log('GPS_Position: ' + GPS_Position[0] + ', ' + GPS_Position[1]);
						// console.log('AmapPosition: ' + AmapPosition[0] + ', ' + AmapPosition[1]);
						
						let marker = new AMap.Marker({
							map: that.addedmap,
							clickable: true,
							zIndex: 100,
							//标记在地图上的位置
							position: AmapPosition,
							icon: new AMap.Icon({
								image: sCarTypeImageDefault,
								imageSize: new AMap.Size(36, 36),
								size: new AMap.Size(36, 36),
							}),
							offset: new AMap.Pixel(-20, -17), //偏移调整
							
							//航向角
							angle: car_data.head,
						});
						
						that.carMarkerList.push({
							car: car_data,
							marker: marker
						});
					}
				});
				//endregion GPS坐标转换成高德坐标
			},
			
			//更新车辆坐标
			async UpdateCarLngLat(){
				let res = await _FindParkByCarOperation({
					parkId: this.ParkID,
				});
				
				if (!res){
					return false;
				}else{
					if (!res.success || !res.data){
						return false;
					}else {
						if (!res.data.operationList){
							return false;
						}
					}
				}
				
				let UpdateCar = [];
				let AddCar = [];
				
				//提取需要删除的车辆
				let FoundDelCar, carMarker;
				for (let i = 0; i < this.carMarkerList.length; i++) {
					carMarker = this.carMarkerList[i]
					FoundDelCar = carMarker;
					
					for (const item of res.data.operationList) {
						if (carMarker.car.id === item.id){
							FoundDelCar = null;
						}
					}
					
					if (FoundDelCar !== null){
						this.carMarkerList.splice(i, 1);
						FoundDelCar.marker.remove();
						FoundDelCar = null;
					}
				}
				
				//提取需要更新的车辆
				for (const item of res.data.operationList) {
					for (const carMarker of this.carMarkerList) {
						if (item.id === carMarker.car.id) {
							UpdateCar.push(item);
							
							break;
						}
					}
				}
				
				//提取需要添加的车辆标记。如果需要更新的车辆数量与总数据数量不等，表示有新车辆加入
				for (const item of res.data.operationList) {
					if (this.carMarkerList.length <= 0){
						AddCar.push(item);
					}else{
						let blIsAdd = true;
						
						for (const carMarker of this.carMarkerList) {
							if (item.id === carMarker.car.id) {
								blIsAdd = false;
								break;
							}
						}
						
						if (blIsAdd){
							AddCar.push(item);
						}
						
					}
				}
				
				var sCarTypeImageDefault = '';
				var sCarTypeImageActive = '';
				var that = this;
				
				//region 更新现有车辆
				for (const item of UpdateCar) {
					switch (item.parentCarTypeId) {
						case "1":
							sCarTypeImageDefault = require('@/assets/images/icon_wgj_default.png');
							sCarTypeImageActive = require('@/assets/images/icon_wgj_active.png');
							break;
						
						case "2":
							sCarTypeImageDefault = require('@/assets/images/icon_bus_default.png');
							sCarTypeImageActive = require('@/assets/images/icon_bus_active.png');
							break;
						
						case "3":
							sCarTypeImageDefault = require('@/assets/images/icon_bus_default.png');
							sCarTypeImageActive = require('@/assets/images/icon_bus_active.png');
							break;
						
						case "4":
							sCarTypeImageDefault = require('@/assets/images/icon_bus_default.png');
							sCarTypeImageActive = require('@/assets/images/icon_bus_active.png');
							break;
						
						case "5":
							sCarTypeImageDefault = require('@/assets/images/icon_bus_default.png');
							sCarTypeImageActive = require('@/assets/images/icon_bus_active.png');
							break;
						
						default:
							sCarTypeImageDefault = require('@/assets/images/minbus.png');
							sCarTypeImageActive = require('@/assets/images/minbus.png');
							break;
					}
					
					for (const carMarker of this.carMarkerList) {
						/*console.log('--- UpdateCar Item begin ---');
						console.log(item);
						console.log('--- UpdateCar Item end ---');
	
						console.log('--- this.carMarkerList carMarker.car begin ---');
						console.log(carMarker.car);
						console.log('--- this.carMarkerList carMarker.car end ---');
	
						console.log('--- this.currentCarActuality begin ---');
						console.log(this.currentCarActuality);
						console.log('--- this.currentCarActuality end ---');*/
						
						if (item.id === carMarker.car.id) {
							var GpsPosition = [item.longitude, item.latitude];
							var CarHead = item.head;
							
							AMap.convertFrom(GpsPosition, 'gps', function (status, result) {
								let AmapPosition = [];
								
								if (result.info === 'ok') {
									AmapPosition = [result['locations'][0].lng, result['locations'][0].lat];
								}else{
									AmapPosition = GpsPosition;
								}
								
								//更新标记的定位
								carMarker.marker.setPosition(AmapPosition);
								
								//更新标记的航向角
								carMarker.marker.setAngle(CarHead);
								
								if (that.currentCarActuality.sonCarType === carMarker.car.carNumber){
									carMarker.marker.setIcon(new AMap.Icon({
										image: sCarTypeImageActive,
										imageSize: new AMap.Size(36, 36),
										size: new AMap.Size(36, 36),
									}));
								}else{
									carMarker.marker.setIcon(new AMap.Icon({
										image: sCarTypeImageDefault,
										imageSize: new AMap.Size(36, 36),
										size: new AMap.Size(36, 36),
									}));
								}
							});
							
							break;
						}
					}
				}
				//endregion 更新现有车辆
				
				//region 添加车辆
				if (AddCar.length > 0) {
					var that = this;
					
					for (const item of AddCar) {
						await this.initCar(item);
						
					}
				}
				//endregion 添加车辆
			},
			
			//创建园区围栏
			CreateFence(fence_list){
				let nCount = fence_list.length;
				let polylinePath = [];
				
				for (let i = 0; i < nCount; i++) {
					polylinePath.push(new AMap.LngLat(fence_list[i][0], fence_list[i][1]));
				}
				
				let polyline = new AMap.Polyline({
					path: polylinePath, // 设置线覆盖物路径
					showDir: false,
					strokeColor: "#FF6600", // 线颜色
					strokeWeight: 5, // 线宽
					strokeOpacity: 0.7,
				});
				
				this.addedmap.add([polyline]);
			},
			
			//创建线路
			CreateLine(line_list){
				let nCount = line_list.length;
				let polylinePath = [];
				
				for (let i = 0; i < nCount; i++) {
					polylinePath.push(new AMap.LngLat(line_list[i][0], line_list[i][1]));
				}
				
				let polyline = new AMap.Polyline({
					path: polylinePath, // 设置线覆盖物路径
					showDir: true,
					strokeColor: "#2affff", // 线颜色
					strokeWeight: 10, // 线宽
				});
				
				this.addedmap.add([polyline]);
			},
			
			//创建站点
			CreateSite(site_list){
				for (const item of site_list) {
					let market = new AMap.Marker({
						position: new AMap.LngLat(item.position[0], item.position[1]),
						icon: new AMap.Icon({
							image: require('@/assets/images/icon_site.png'),
							imageSize: new AMap.Size(24, 24),
							size: new AMap.Size(24, 24),
							// offset: new AMap.Pixel(0, 0),
						}),
					});
					
					let textMarket = new AMap.Text({
						text: item.pointName,
						position: [item.position[0], item.position[1]],
						anchor:'center',
						style:{
							'padding': '1px 5px',
							'margin-bottom': '20px',
							'margin-left': '20px',
							// 'border-radius': '.25rem',
							'background-color': '#318ff5',
							'border-width': 0,
							'text-align': 'center',
							'font-size': '12px',
							'color': '#ffffff'
						},
					})
					
					this.addedmap.add(market);
					this.addedmap.add(textMarket);
				}
			},
			//endregion 地图操作
			
			//region 获取页面数据
			//今日运营实况
			async getTodayOperation(){
				let res = await _FindTodayOperation({
					parkId: this.ParkID,
				});
				
				if (res){
					if (res.success){
						this.todayOperation.todayAvgOperateTime = (res.data.todayAvgOperateTime) ? res.data.todayAvgOperateTime : 0;
						this.todayOperation.todayOperatingCarNum = (res.data.todayOperatingCarNum) ? res.data.todayOperatingCarNum : 0;
						this.todayOperation.todayTotalOperateMiles = (res.data.todayTotalOperateMiles) ? res.data.todayTotalOperateMiles : 0;
						this.todayOperation.todayTotalOrders = (res.data.todayTotalOrders) ? res.data.todayTotalOrders : 0;
						this.todayOperation.totalCarNum = (res.data.totalCarNum) ? res.data.totalCarNum : 0;
						this.todayOperation.todayCallCarNum = (res.data.todayCallCarNum) ? res.data.todayCallCarNum : 0;
						this.todayOperation.todayPeopleNum = (res.data.todayPeopleNum) ? res.data.todayPeopleNum : 0;
						
						if (this.todayOperation.todayOperatingCarNum === 0 || this.todayOperation.totalCarNum === 0){
							this.OperatingCarRate = 0;
						}else{
							this.OperatingCarRate = parseInt(this.todayOperation.todayOperatingCarNum / this.todayOperation.totalCarNum * 100);
						}
					}
				}
			},
			
			//近15日运营情况趋势
			async getTodayTrends(){
				let res = await _FindTodayTrends({
					parkId: this.ParkID,
				});
				
				// console.log(res);
				
				if (res){
					if (res.success){
						this.todayTrends.peopleNumTrends = (res.data.peopleNumTrends) ? res.data.peopleNumTrends : 0;
						this.todayTrends.carMilesTrends = (res.data.carMilesTrends) ? res.data.carMilesTrends : 0;
					}
				}
			},
			
			//业务概览
			async getBusinessOverview(){
				let res = await _FindBusinessOverview({
					parkId: this.ParkID,
				});
				
				// console.log(data);
				
				if (res){
					if (res.success){
						this.businessOverview = res.data;
					}
				}
			},
			
			//近30天运营质量概览
			async getOperationQuality(){
				let res = await _FindOperationQuality({
					parkId: this.ParkID,
				});
				
				// console.log(data);
				
				if (res){
					if (res.success){
						this.operationQuality.onceSitePeopleNum = (res.data.onceSitePeopleNum) ? res.data.onceSitePeopleNum : 0;
						this.operationQuality.orderCompletePercent = (res.data.orderCompletePercent) ? res.data.orderCompletePercent : 0;
						this.operationQuality.perDayPeopleNum = (res.data.perDayPeopleNum) ? res.data.perDayPeopleNum : 0;
					}
				}
			},
			
			//车辆实况
			async getCarActuality(){
				let res = await _FindCarActuality({
					parkId: this.ParkID,
				});
				
				if (res){
					if (res.success){
						if (res.data.vehicleOperationList){
							let Temp = [];
							let nLoop = 0;
							
							for (const item of res.data.vehicleOperationList) {
								if (nLoop === 0){
									item['css'] = 'active';
								}else{
									item['css'] = '';
								}
								
								Temp.push(item);
								
								nLoop++;
							}
							
							this.carActuality = Temp;
							this.SetCurrentCarActuality();
							
							setInterval(() => {
								this.SetCurrentCarActuality();
								this.currentCarActualityIdx++;
								
								this.UpdateCarSpeed();
								this.UpdateCarPower();
							}, 5000);
						}
					}
				}
			},
			
			//订单列表
			async getOrderList(){
				let res = await _FindOrderList({
					parkId: this.ParkID,
				});
				
				if (res){
					if (res.success){
						let TempOrder = [];
						let nLoop = 1;
						
						for (const o of res.data) {
							if (nLoop >= 5){
								break;
							}
							
							o.appUserName = ellipsis(o.appUserName, 9);
							o.startName = ellipsis(o.startName, 9);
							TempOrder.push(o);
							
							nLoop++;
						}
						
						this.orderList = TempOrder;
					}
				}
			},
			
			//近三个月园区站点订单分布概览
			async getOrderDistribution(){
				let res = await _FindOrderDistribution({
					parkId: this.ParkID,
				});
				
				if (res){
					if (res.success){
						this.orderDistribution2 = res.data;
						
						for (let i=0; i < res.data.length; i++){
							if (i===0){
								this.orderDistribution.one.title = res.data[0].routeName;
								this.orderDistribution.one.list = res.data[0].list;
							}else{
								this.orderDistribution.two.title = res.data[1].routeName;
								this.orderDistribution.two.list = res.data[1].list;
								break;
							}
						}
					}
				}
			},
			//endregion 获取页面数据
			
			//生成图表
			async initCharts() {
				this.UpdateCarSpeed();
				this.UpdateCarPower();
				
				/*
				var objModelCharts = document.getElementById("speed_charts");
				var modelCharts = echarts.init(objModelCharts);
				var modelChartsOption = {
					series: [
						{
							type: 'gauge',
							progress: {
								show: true,
								width: 6,
								itemStyle: {
									color: '#2affff',
								}
							},
							axisLine: {
								lineStyle: {
									width: 6,
								}
							},
							axisTick: {
								show: false
							},
							anchor: {
								show: true,
								showAbove: true,
								size: 6,
								itemStyle: {
									borderWidth: 3,
									borderColor: '#2affff',
								}
							},
							title: {
								show: false,
								text: '测试',
								textStyle: {
									fontFamily: "ArialMT",
									fontSize: "13",
									color: "#8f9ea9",
								},
							},
							detail: {
								valueAnimation: true,
								fontSize: 24,
								color: '#fff',
								offsetCenter: [0, '70%'],
							},
							splitLine:{
								show:false,//是否显示分隔线。
							},
							axisLabel:false,
							pointer: {
								width:3,//指针的宽度
								length:"65%", //指针长度，按照半圆半径的百分比
								shadowColor : '#ccc', //默认透明
								shadowBlur: 5,
								itemStyle: {
									color: '#2affff',
								}
							},
							data: [
								{
									value: this.currentCarActuality.carSpeed ? this.currentCarActuality.carSpeed : 0,
								}
							]
						}
					]
				};
				modelChartsOption && modelCharts.setOption(modelChartsOption);
				
				var objPowerCharts = document.getElementById("power_charts");
				var powerCharts = echarts.init(objPowerCharts);
				var powerChartsOption = {
					series: [
						{
							type: 'gauge',
							progress: {
								show: true,
								width: 6,
								itemStyle: {
									color: '#2affff',
								}
							},
							axisLine: {
								lineStyle: {
									width: 6,
								}
							},
							axisTick: {
								show: false
							},
							anchor: {
								show: true,
								showAbove: true,
								size: 6,
								itemStyle: {
									borderWidth: 3,
									borderColor: '#2affff',
								}
							},
							title: {
								show: false
							},
							detail: {
								valueAnimation: true,
								fontSize: 24,
								color: '#fff',
								offsetCenter: [0, '70%'],
							},
							splitLine:{
								show:false,//是否显示分隔线。
							},
							axisLabel:false,
							pointer: {
								width:3,//指针的宽度
								length:"65%", //指针长度，按照半圆半径的百分比
								shadowColor : '#ccc', //默认透明
								shadowBlur: 5,
								itemStyle: {
									color: '#2affff',
								}
							},
							data: [
								{
									value: this.currentCarActuality.recharge ? this.currentCarActuality.recharge : 0,
								}
							]
						}
					]
				};
				powerChartsOption && powerCharts.setOption(powerChartsOption);
				*/
				
				var objOrderData = null;
				
				//region 三个月园区站点订单
				objOrderData = this.FmtOrderDistribution(this.orderDistribution.one.list);
				if (this.orderDistribution.one.list.length > 0){
					var chartDom = document.getElementById("order_one");
					var myChart = echarts.init(chartDom);
					var option= {
						title: [
							{
								left: "center",
								bottom: "7%",
								text: this.orderDistribution.one.title,
								textStyle: {
									fontFamily: "ArialMT",
									fontSize: "13",
									color: "#8f9ea9",
								},
							},
						],
						xAxis: {
							type: "category",
							data: objOrderData.title,
							axisTick: {
								//刻度
								show: false, //不显示刻度线
							},
							axisLine: {
								lineStyle: {
									color: "#b8cdd9", //轴线的颜色
								},
							},
						},
						yAxis: {
							type: "value",
							axisTick: {
								//刻度
								show: false, //不显示刻度线
							},
							axisLine: {
								lineStyle: {
									color: "#b8cdd9", //轴线的颜色
								},
							},
						},
						series: [
							{
								data: objOrderData.value,
								type: "line",
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: "#2a94ee",
									},
									{
										offset: 1,
										color: "#29ced2",
									},
								]),
							},
						],
					};
					option && myChart.setOption(option);
				}
				
				
				objOrderData = this.FmtOrderDistribution(this.orderDistribution.two.list);
				if (this.orderDistribution.two.list.length > 0){
					var chartDom2 = document.getElementById("order_two");
					var myChart2 = echarts.init(chartDom2);
					var option2= {
						title: [
							{
								left: "center",
								bottom: "7%",
								text: this.orderDistribution.two.title,
								textStyle: {
									fontFamily: "ArialMT",
									fontSize: "13",
									color: "#8f9ea9",
								},
							},
						],
						xAxis: {
							type: "category",
							data: objOrderData.title,
							axisLine: {
								lineStyle: {
									color: "#b8cdd9", //轴线的颜色
								},
							},
							
							axisTick: {
								//刻度
								show: false, //不显示刻度线
							},
						},
						
						yAxis: {
							// type: "value",
							axisLine: {
								lineStyle: {
									color: "#b8cdd9", //轴线的颜色
								},
							},
							axisTick: {
								//刻度
								show: false, //不显示刻度线
							},
							axisLabel: {
								//轴线字体样式设置
								textStyle: {
									fontFamily: "ArialMT",
									fontSize: "0",
									color: "#86A5C3",
								},
							},
						},
						series: [
							{
								data: objOrderData.value,
								type: "line",
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: "#2a94ee",
									},
									{
										offset: 1,
										color: "#29ced2",
									},
								]),
							},
						],
					};
					option2 && myChart2.setOption(option2);
				}
				//endregion 三个月园区站点订单
				
				//region 近15日运营情况趋势
				var Dom4DataSource = this.FmtTodayTrendsDataSource();
				
				if (Dom4DataSource.length > 0){
					var chartDom4 = document.getElementById("operation_chart");
					var myChart4 = echarts.init(chartDom4);
					var option4 = {
						legend: {
							left: "right",
							data: ['2015', '2016', '2017'],
							bottom: '87%',
							padding: [0, 40, 0, 0],
							inactiveColor: '#777',
							textStyle: {
								color: '#fff',
							},
						},
						tooltip: {},
						dataset: {
							source: Dom4DataSource,
						},
						textStyle: {
							color: '#fff',
						},
						xAxis: { type: "category" },
						yAxis: {},
						series: [
							{ type: "bar", color: "#2a78f4" },
							{ type: "bar", color: "#2bfaba" },
						],
					};
					option4 && myChart4.setOption(option4);
				}
				//endregion 近15日运营情况趋势
				
				//region 订单完成率
				var objOrderCompletionRateCharts = document.getElementById("order_completion_rate_chart");
				var orderCompletionRateCharts = echarts.init(objOrderCompletionRateCharts);
				var orderCompletionRateChartsOption = {
					series: [{
						type: 'liquidFill',
						backgroundStyle: {
							color: "#2affff",
						},
						data: [this.operationQuality.orderCompletePercent],
						label: {
							show: true,
							color: '#294D99',
							insideColor: '#fff',
							fontSize: 24,
							fontWeight: 'bold',
							align: 'center',
							baseline: 'middle',
							position: 'inside'
						},
						outline: {
							show: false,
							borderDistance: 8,
							itemStyle: {
								color: 'none',
								borderColor: '#294D99',
								borderWidth: 1,
								shadowBlur: 20,
								shadowColor: 'rgba(0, 0, 0, 0.25)'
							}
						},
					}]
				};
				orderCompletionRateChartsOption && orderCompletionRateCharts.setOption(orderCompletionRateChartsOption);
				//endregion 订单完成率
			},
			
			async UpdateCarSpeed(){
				var objModelCharts = document.getElementById("speed_charts");
				var modelCharts = echarts.init(objModelCharts);
				var modelChartsOption = {
					series: [
						{
							type: 'gauge',
							progress: {
								show: true,
								width: 6,
								itemStyle: {
									color: '#2affff',
								}
							},
							axisLine: {
								lineStyle: {
									width: 6,
								}
							},
							axisTick: {
								show: false
							},
							anchor: {
								show: true,
								showAbove: true,
								size: 6,
								itemStyle: {
									borderWidth: 3,
									borderColor: '#2affff',
								}
							},
							title: {
								show: false,
								text: '测试',
								textStyle: {
									fontFamily: "ArialMT",
									fontSize: "13",
									color: "#8f9ea9",
								},
							},
							detail: {
								valueAnimation: true,
								fontSize: 24,
								color: '#fff',
								offsetCenter: [0, '70%'],
							},
							splitLine:{
								show:false,//是否显示分隔线。
							},
							axisLabel:false,
							pointer: {
								width:3,//指针的宽度
								length:"65%", //指针长度，按照半圆半径的百分比
								shadowColor : '#ccc', //默认透明
								shadowBlur: 5,
								itemStyle: {
									color: '#2affff',
								}
							},
							data: [
								{
									value: this.currentCarActuality.carSpeed ? this.currentCarActuality.carSpeed : 0,
								}
							]
						}
					]
				};
				modelChartsOption && modelCharts.setOption(modelChartsOption);
			},
			
			async UpdateCarPower(){
				var objPowerCharts = document.getElementById("power_charts");
				var powerCharts = echarts.init(objPowerCharts);
				var powerChartsOption = {
					series: [
						{
							type: 'gauge',
							progress: {
								show: true,
								width: 6,
								itemStyle: {
									color: '#2affff',
								}
							},
							axisLine: {
								lineStyle: {
									width: 6,
								}
							},
							axisTick: {
								show: false
							},
							anchor: {
								show: true,
								showAbove: true,
								size: 6,
								itemStyle: {
									borderWidth: 3,
									borderColor: '#2affff',
								}
							},
							title: {
								show: false
							},
							detail: {
								valueAnimation: true,
								fontSize: 24,
								color: '#fff',
								offsetCenter: [0, '70%'],
							},
							splitLine:{
								show:false,//是否显示分隔线。
							},
							axisLabel:false,
							pointer: {
								width:3,//指针的宽度
								length:"65%", //指针长度，按照半圆半径的百分比
								shadowColor : '#ccc', //默认透明
								shadowBlur: 5,
								itemStyle: {
									color: '#2affff',
								}
							},
							data: [
								{
									value: this.currentCarActuality.recharge ? this.currentCarActuality.recharge : 0,
								}
							]
						}
					]
				};
				powerChartsOption && powerCharts.setOption(powerChartsOption);
			},
			
			//设置当前车辆实况的数据
			SetCurrentCarActuality(){
				if (this.carActuality.length <= 0){
					return false;
				}
				
				if ((this.currentCarActualityIdx+1) > this.carActuality.length){
					this.currentCarActualityIdx = 0;
				}
				
				if (this.currentCarActualityIdx === 0){
					this.carActuality[(this.carActuality.length-1)].css = '';
				}else{
					if (this.carActuality.length > 3){
						if (this.currentCarActualityIdx <= 3){
							this.carActuality[(this.currentCarActualityIdx-1)].css = '';
						}
					}else{
						this.carActuality[(this.currentCarActualityIdx-1)].css = '';
					}
				}
				
				this.currentCarActuality.sonCarType = this.carActuality[this.currentCarActualityIdx].carNumber;
				this.currentCarActuality.carSpeed = (this.carActuality[this.currentCarActualityIdx].carSpeed) ? parseInt(this.carActuality[this.currentCarActualityIdx].carSpeed) : 0;
				this.currentCarActuality.recharge = (this.carActuality[this.currentCarActualityIdx].recharge) ? this.carActuality[this.currentCarActualityIdx].recharge : 0;
				
				if (!this.carActuality[this.currentCarActualityIdx].driveMode){
					switch (this.carActuality[this.currentCarActualityIdx].driveMode){
						case 0:
							this.currentCarActuality.driveMode = '自动驾驶（正常）';
							break;
						
						case 1:
							this.currentCarActuality.driveMode = '自动驾驶（故障）';
							break;
						
						case 2:
							this.currentCarActuality.driveMode = '远程驾驶（正常）';
							break;
						
						case 3:
							this.currentCarActuality.driveMode = '远程驾驶（故障）';
							break;
						
						default:
							this.currentCarActuality.driveMode = '其它';
							break;
					}
				}
				this.currentCarActuality.carRoute = (this.carActuality[this.currentCarActualityIdx].carRoute) ? this.carActuality[this.currentCarActualityIdx].carRoute : '--';
				this.currentCarActuality.electricQuantity = (this.carActuality[this.currentCarActualityIdx].electricQuantity) ? this.carActuality[this.currentCarActualityIdx].electricQuantity : 0;
				this.currentCarActuality.carStatus = (this.carActuality[this.currentCarActualityIdx].carStatus === 0) ? '离线' : '运营中';
				
				if (this.currentCarActualityIdx <= 3){
					this.carActuality[this.currentCarActualityIdx].css = 'active';
				}
			},
			
			//格式化近15日运营情况
			FmtTodayTrendsDataSource(){
				let DataList = [];
				let DataSource = [
					["product", "运营里程", "乘车人次"]
				];
				let TempData = {
					car: 0,
					people: 0,
				};
				
				//region 提取日期
				if (this.todayTrends.peopleNumTrends.length > 0){
					for (const item of this.todayTrends.peopleNumTrends) {
						if (!item.completionTime){
							continue;
						}
						
						if (DataList.indexOf(item.completionTime) === -1){
							DataList.push(item.completionTime);
						}
					}
				}
				
				if (this.todayTrends.carMilesTrends.length > 0){
					for (const item of this.todayTrends.carMilesTrends) {
						if (!item.countDate){
							continue;
						}
						if (DataList.indexOf(item.countDate) === -1){
							DataList.push(item.countDate);
						}
					}
				}
				//endregion 提取日期
				
				//region 提取对应日期的数据
				if (DataList.length > 0){
					DataList.sort();
					
					for (const item of DataList) {
						for (const car of this.todayTrends.carMilesTrends) {
							if (car.countDate === item){
								TempData.car = car.carMile;
								break;
							}
						}
						
						for (const people of this.todayTrends.peopleNumTrends) {
							if (people.completionTime === item){
								TempData.people = people.peopleNum;
								break;
							}
						}
						
						DataSource.push([item, TempData.car, TempData.people]);
						
						TempData.car = 0;
						TempData.people = 0;
					}
				}else{
					DataSource = [];
				}
				//endregion 提取对应日期的数据
				
				return DataSource;
			},
			
			FmtOrderDistribution(list){
				let TitleList = [];
				let ValueList = [];
				
				// console.log(list);
				
				for (const item of list) {
					TitleList.push(item.pointName);
					ValueList.push(item.pointOrderNum);
				}
				
				return {title: TitleList, value: ValueList}
			}
		}
	};
</script>

<style lang="less" scoped>
	.container{width: 100%; height: 100%;}
	
	#map_bg{width: 100%; height: 100%; z-index: 0; position: absolute;}
	
	.model-title{
		font-size: 20px; font-weight: bold; color: #fff; padding: 0 0 0 20px;
		background: url("../../assets/images/title_icon.png") no-repeat left center; background-size: auto 16px;
	}
	.sub-title{
		font-size: 18px; color: #fff; padding: 20px 0 20px 15px;
		background: url("../../assets/images/dot.png") no-repeat left center; background-size: auto 8px;
	}
	
	.demo-Circle-custom{
		p{padding: 0 0 3px 0 !important;}
		
		.lc-textB{font-size: 24px; color: #fff; text-align: center;}
		.lc-textC{font-size: 12px; color: #829199; text-align: center;}
	}
	
	.car-active{border: 3px solid #2affff; -moz-border-radius: 50%; -webkit-border-radius: 50%;}
	
	.data-header-container{
		width: 100%; height: 160px; margin: 0 auto; background: url("../../assets/images/big_head_bg.png") no-repeat top center;
		background-size: 100% auto; position: absolute; z-index: 999; left: 0; top: 0; display: flex;
		
		.r-date {
			width: 255px; position: absolute; right: 0; margin-top: 45px; overflow: hidden;
			
			.r-time {
				width: 120px; overflow: hidden; font-size: 30px; color: #fff; float: left; font-weight: bold;
			}
			.r-timeB {width: 95px; float: left; padding: 2px 0;}
			.r-timeA {font-size: 14px; padding-left: 10px; color: rgb(255, 255, 255);}
		}
	}
	
	.left-container{
		width: 494px; height: 686px; position: absolute; z-index: 999; margin-top: 100px; margin-left: 30px;
		
		.left-data{
			width: 494px; height: 686px; z-index: 999; position: absolute; padding: 20px;
			background: url("../../assets/images/top_left.png") no-repeat top left,
			url("../../assets/images/top_right.png") no-repeat top right,
			url("../../assets/images/bottom_left.png") no-repeat bottom left,
			url("../../assets/images/bottom_right.png") no-repeat bottom right;
			
			.left-row-1{
				width: 100%; overflow: hidden; border-bottom: 1px solid #5e5e5e; padding-bottom: 10px;
				
				.lr1-item{
					padding: 10px; text-align: center; font-size: 16px; color: #fff;
					span{color: #2affff; padding-right: 5px;}
				}
			}
			
			.left-row-2{
				width: 100%; overflow: hidden; margin-top: 5px; border-bottom: 1px solid #5e5e5e; padding-bottom: 30px;
				
				.left{
					width: 156px; height: 166px; float: left;
				}
				.right{
					width: 250px; overflow: hidden; float: right; padding-top: 20px;
					
					p{
						font-size: 16px; color: #b8cdd9; padding: 10px 0;
						span{color: #2affff; padding: 0 10px;}
					}
				}
			}
			
			.left-row-3{
				width: 100%; overflow: hidden; margin-top: 5px;
				
				.order-list{
					background-color: #000; padding: 15px 15px;
					
					li{
						width: 100%; overflow: hidden; font-size: 14px; color: #b8cdd9; padding: 5px 0;
						
						.user{
							display: block; width: 50%; float: left; text-align: left;
							white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
						}
						.site{
							display: block; width: 30%; float: left; text-align: left;
							white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
						}
						.status{
							display: block; width: 20%; float: left; text-align: right;
							white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
						}
					}
				}
				
				.order-list-empty{
					width: 400px; height: 260px; font-size: 16px; color: #fff; margin-top: -20px;
				}
			}
		}
		.left-mask{width: 494px; height: 686px; background-color: #333; opacity: 0.7; z-index: 998;}
	}
	
	.right-container{
		width: 494px; height: 686px; position: absolute; margin-top: 100px; right:0; margin-right: 30px; z-index: 999;
		
		.right-data{
			width: 494px; height: 686px; z-index: 999; position: absolute; padding: 20px;
			background: url("../../assets/images/top_left.png") no-repeat top left,
			url("../../assets/images/top_right.png") no-repeat top right,
			url("../../assets/images/bottom_left.png") no-repeat bottom left,
			url("../../assets/images/bottom_right.png") no-repeat bottom right;
			
			.car-list{
				width: 400px; height: 75px; margin: 20px auto 0 auto; overflow: hidden;
				
				.car-item{
					text-align: center;
					
					img{width: 74px; height: 74px;}
				}
			}
			
			.car-name{
				margin-top: 10px;
				.name{font-size: 24px; color: #f0f0f0; font-weight: bold;}
				.status{
					font-size: 14px; color: #2affff; padding: 0 0 0 15px;
					background: url("../../assets/images/dot.png") no-repeat left center; background-size: auto 8px;
				}
				.power{font-size: 24px; color: #2affff; font-weight: bold; text-align: right;}
				.power-icon{padding: 10px 18px; background-size: 28px auto !important;}
				.power-icon-full{background: url("../../assets/images/power_full.png") no-repeat left center;}
				.power-icon-half{background: url("../../assets/images/power_half.png") no-repeat left center;}
				.power-icon-empty{background: url("../../assets/images/power_empty.png") no-repeat left center;}
			}
			
			.car-image{
				width: 100%; overflow: hidden; text-align: center; margin-top: 10px;
				
				img{width: 280px; height: 175px;}
			}
			
			.car-info{
				width: 454px; overflow: hidden;
				
				.left{width: 220px; overflow: hidden; float: left; padding-left: 40px;}
				.right{width: 220px; overflow: hidden; float: right;}
				
				.title{
					width: 100%; font-size: 18px; color: #b8cdd9; padding: 3px 0 3px 25px;
					background: url("../../assets/images/xing.png") no-repeat left center; background-size: 20px auto;
				}
				
				.autopilot-model{
					width: 100%; overflow: hidden; padding-left: 45px; margin-top: 10px;
					background: url("../../assets/images/fxp.png") no-repeat left center; background-size: 40px auto;
					
					.model-name{font-size: 16px; color: #2affff;}
					.line-name{font-size: 14px; color: #b8cdd9;}
				}
				
				.speed-charts{
					width:168px; overflow: hidden;
					
					#speed_charts{
						width: 168px; height: 168px;
					}
					
					.speed-charts-name{
						width: 168px; overflow: hidden; text-align: center; color: #b8cdd9; font-size: 14px; position: absolute;
						margin-top: -20px;
					}
				}
				
				.power-model{
					width: 100%; overflow: hidden; padding-left: 45px; margin-top: 10px;
					background: url("../../assets/images/jy.png") no-repeat left center; background-size: 40px auto;
					
					.model-name{font-size: 16px; color: #2affff;}
					.line-name{font-size: 14px; color: #b8cdd9;}
				}
				
				.power-charts{
					width:168px; overflow: hidden;
					
					#power_charts{width: 168px; height: 168px;}
					
					.power-charts-name{
						width: 168px; overflow: hidden; text-align: center; color: #b8cdd9; font-size: 14px; position: absolute;
						margin-top: -20px;
					}
				}
			}
		}
		.right-mask{width: 494px; height: 686px; background-color: #333; opacity: 0.7; z-index: 998;}
	}
	
	.footer-container{
		width: 1860px; height: 250px; position: absolute; bottom: 0; left: 0; right: 0; margin: 0 auto 22px auto;
		
		.footer-data{
			width: 1860px; height: 250px; z-index: 999; position: absolute; padding: 20px;
			background: url("../../assets/images/top_left.png") no-repeat top left,
			url("../../assets/images/top_right.png") no-repeat top right,
			url("../../assets/images/bottom_left.png") no-repeat bottom left,
			url("../../assets/images/bottom_right.png") no-repeat bottom right;
			
			.left{
				width: 700px; height: 210px; float: left;
				
				.order-chart{
					width: 725px; height: 180px; position: absolute; margin-left: 8px; padding-top: 10px;
					#order_one {
						width: 400px;
						height: 260px;
						margin-top: -60px;
						float: left;
					}
					
					#order_two {
						width: 400px;
						height: 260px;
						margin-top: -60px;
						margin-left: -79px;
						float: left;
					}
				}
			}
			.center{
				width: 480px; height: 210px; float: left; margin-left: 40px;
				
				.overview{
					width: 480px; height: 110px;
					
					.avg{
						width: 150px; height: 150px; position: absolute; margin-left: -40px; margin-top: -25px;
					}
					.complete{
						width: 150px; height: 150px; position: absolute; margin-top: 15px; margin-left: 170px;
						
						#order_completion_rate_chart{
							width: 380px; height: 300px; margin-top: -76px; margin-left: -115px;
						}
					}
					.riding{
						width: 150px; height: 150px; position: absolute; margin-left: 330px; margin-top: -25px;
					}
					
					.overview-title{
						width: 170px; overflow: hidden; font-size: 14px; color: #b8cdd9; text-align: center; margin-left: 12px;
						margin-top: 10px;
					}
					.complete-title{
						width: 170px; overflow: hidden; font-size: 14px; color: #b8cdd9; text-align: center; margin-left: -10px;
						margin-top: -68px;
					}
				}
			}
			.right{
				width: 560px; height: 210px; float: right;
				
				#operation_chart{
					width: 600px; height: 270px; margin-top: -40px;
				}
			}
		}
		
		.footer-mask{width: 1860px; height: 250px; background-color: #333; opacity: 0.7; z-index: 998;}
	}
	
	/deep/.el-empty__image{width: 80px; height: 80px;}
	/deep/.el-empty__description p{font-size: 16px; color: #ccc;}
</style>
