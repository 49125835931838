<template>
  <div>
    <div style="margin-bottom: 20px">
      <span class="textcolor2 textA">陌生人识别提醒</span>
      <el-switch
        v-model="reminderStatus"
        active-color="#1683D9"
        inactive-color="#ff4949"
        @change="getstatus"
      >
      </el-switch>
    </div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.warningStatus"
          placeholder="警告状态"
          class="width"
        >
          <el-option
            v-for="item in StatusList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="searchObj.eventType"
          placeholder="事件类型"
          class="centerSelInput"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          style="margin-right: 20px"
          @change="gettime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      :loading="loading"
      :page="list.page"
    />
    <el-drawer
      title="新增消息"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules">
          <el-form-item
            label="任务类型"
            :label-width="formLabelWidth"
            prop="taskType"
            class="select"
          >
            <el-select
              v-model="form.taskType"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in TypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="绑定车辆"
            :label-width="formLabelWidth"
            prop="car"
          >
            <el-input
              v-model="form.car"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="行驶路线"
            :label-width="formLabelWidth"
            prop="drivingRoute"
            class="select"
          >
            <el-select
              v-model="form.drivingRoute"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in drivingRouteList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="计划开始时间"
            :label-width="formLabelWidth"
            prop="timeA"
            class="select time"
          >
            <el-time-select v-model="form.timeA" placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <el-form-item
            label="趟数设定"
            :label-width="formLabelWidth"
            prop="setnumber"
            class="select"
          >
            <el-select
              v-model="form.setnumber"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in setnumberList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn">保存</button>
        <button class="centerBtn resetbtn" @click="addAccount = false">
          取消
        </button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";

import { _PtBusPwList, _PtBusPwStatus } from "@/api/patrolBus";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      addAccount: false,
      value1: "",
      value: "",
      reminderStatus: "",
      formLabelWidth: "100px",
      StatusList: [],
      typeList: [],
      TypeList: [],
      drivingRouteList: [],
      setnumberList: [],
      statusList: [
        {
          id: "1",
          value: "进行中",
        },
        { id: "2", value: "已结束" },
      ],
      searchObj: {
        vehicleNumber: "",
        operationMode: "",
        operationStatus: "",
      },
      form: {
        taskType: "",
        car: "",
        drivingRoute: "",
        timeA: "",
        setnumber: "",
      },
      rules: {
        taskType: [
          {
            required: true,
            message: "请选择任务类型",
            trigger: "blur",
          },
        ],
        car: [
          {
            required: true,
            message: "请输入车辆编号",
            trigger: "blur",
          },
        ],
        drivingRoute: [
          {
            required: true,
            message: "请输入行驶线路",
            trigger: "blur",
          },
        ],
        timeA: [
          {
            required: true,
            message: "请选择计划开始时间",
            trigger: "blur",
          },
        ],
        setnumber: [
          {
            required: true,
            message: "请选择趟数设定",
            trigger: "blur",
          },
        ],
      },
      tableData: [],
      columns: [
        {
          label: "警告事件",
          render: (h, params) => {
            if (params.row.eventType == 1) {
              return h("span", "人脸");
            } else {
              return h("span", "车辆");
            }
          },
        },

        {
          label: "警告状态",
          render: (h, params) => {
            if (params.row.alarmStatus == 1) {
              return h("span", "进行中");
            } else {
              return h("span", "已结束");
            }
          },
        },
        {
          label: "巡逻车辆",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "typeName",
        },
        {
          label: "当前状态",

          render: (h, params) => {
            if (params.row.carStatus == 1) {
              return h("span", "在线");
            } else {
              return h("span", "离线");
            }
          },
        },
        {
          label: "行驶路线",
          prop: "carRoute",
        },
        {
          label: "警报开始时间",
          prop: "startTime",
        },
        {
          label: "警报结束时间",
          prop: "endTime",
        },
        {
          label: "操作",
          prop: "operation",
        },
      ],
      valueTime: "",
      list: {
        page: 1,
        size: 10,
        carNumber: "",
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.valueTime[0];
        this.list.endTime = this.valueTime[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    getstatus() {
      let status = null;
      if (this.reminderStatus == false) {
        status = 0;
      } else {
        status = 1;
      }
      this.getStatus(status);
    },
    cancel() {
      this.valueTime = "";
      this.list = {
        page: 1,
        size: 10,
        carNumber: "",
        startTime: "",
        endTime: "",
      };
      this.getList()
    },
    async getList() {
      let { success, data, message } = await _PtBusPwList(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2(){
      this.list.page=1
      this.getList()
    },
    async getStatus(status) {
      let { success, data, message } = await _PtBusPwStatus({
        reminderStatus: status,
      });
      if (success) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.textcolor {
  font-size: 14px;
  color: #666666;
  margin-right: 20px;
}
.textcolor2 {
  font-size: 14px;
  color: #333333;
}
.el-input__inner {
  border: none !important;
  height: 36px;
}
.select /deep/.el-input__inner {
  width: 100%;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
.time /deep/ .el-form-item__label {
  width: 120px !important;
  margin-left: -20px;
}
.width /deep/ .el-input {
  width: 140px !important;
  margin-right: 20px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

  font-size: 14px;
}
.width /deep/.el-input__inner {
  border: 0px solid white !important;
}
</style>