var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-drawer',{attrs:{"title":"视角配置","visible":_vm.viewConfigShow,"custom-class":"footerDrawer","size":"45%"},on:{"update:visible":function($event){_vm.viewConfigShow=$event},"closed":_vm.closeDrawer,"open":_vm.openDrawer}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.drawerLoading),expression:"drawerLoading"}],staticClass:"body"},[_c('div',{staticStyle:{"height":"100%","overflow-y":"auto"}},[_c('el-tabs',{attrs:{"type":"card"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.cameraArr),function(item){return _c('el-tab-pane',{key:item.carType,attrs:{"label":item.carTypeName,"name":item.carTypeName}})}),1),_vm._l((_vm.cameraArr),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.carTypeName == _vm.activeName),expression:"item.carTypeName == activeName"}],key:item.carType},_vm._l((item.carCameraViewTypeDataList),function(ele,i){return _c('el-form',{key:i,ref:"form",refInFor:true,attrs:{"inline":"","model":ele}},[_c('div',{staticClass:"row"},[_c('el-form-item',{attrs:{"label":"OBU_Camera_IP:","prop":"camCode","rules":[
                {
                  validator: function (rule, value, callback) {
                    _vm.validValue(
                      rule,
                      value,
                      callback,
                      ele,
                      item.carCameraViewTypeDataList
                    );
                  },
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"placeholder":"例：OBU_Camera0","disabled":ele.id ? true : false},model:{value:(ele.camCode),callback:function ($$v) {_vm.$set(ele, "camCode", $$v)},expression:"ele.camCode"}})],1),_c('el-form-item',{attrs:{"label":"视角位:","prop":"view","rules":[
                {
                  validator: function (rule, value, callback) {
                    _vm.validLable(
                      rule,
                      value,
                      callback,
                      ele,
                      item.carCameraViewTypeDataList
                    );
                  },
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"placeholder":"例：车正前平视","disabled":ele.id ? true : false},model:{value:(ele.view),callback:function ($$v) {_vm.$set(ele, "view", $$v)},expression:"ele.view"}})],1),_c('div',{staticClass:"icon"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(item.carCameraViewTypeDataList.length != 1),expression:"item.carCameraViewTypeDataList.length != 1"}],staticClass:"el-icon-remove-outline",on:{"click":function($event){return _vm.removeCamera(item.carCameraViewTypeDataList, i, ele)}}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                  i == item.carCameraViewTypeDataList.length - 1 && i < 9
                ),expression:"\n                  i == item.carCameraViewTypeDataList.length - 1 && i < 9\n                "}],staticClass:"el-icon-circle-plus-outline",on:{"click":function($event){return _vm.addCamera(item.carCameraViewTypeDataList)}}})])],1)])}),1)})],2)]),_c('div',{staticClass:"foot"},[_c('button',{staticClass:"centerCzbtn ml20",attrs:{"type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" 保存 ")]),_c('button',{staticClass:"centerBtn resetbtn",attrs:{"type":"button"},on:{"click":function($event){_vm.viewConfigShow = false}}},[_vm._v(" 取消 ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }