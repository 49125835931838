<template>
  <div>
    <div class="bottom">
      <span
        :class="showvehicle == false ? 'statutext' : 'textcolor'"
        @click="getvehicle"
        >人脸信息采集</span
      >
      <span
        :class="showvehicle == false ? 'textcolor' : 'statutext'"
        @click="getvehicleB"
        style="margin-right: 60%"
        >车辆信息采集</span
      >
      <el-checkbox
        v-model="checked"
        :class="checked == true ? 'radioA ' : ''"
        @change="getcheke"
        >白名单</el-checkbox
      >
      <el-checkbox
        v-model="checkedB"
        :class="checked == false ? 'radioA' : ''"
        @change="getchekeB"
        >黑名单</el-checkbox
      >
      <p class="flex">
        <span :class="showvehicle == false ? 'statustext_bg' : ''"></span>
        <span :class="showvehicle == false ? '' : 'statustext_bgB'"></span>
      </p>
    </div>
    <el-form v-if="showvehicle == false" :inline="true">
      <el-form-item>
        <el-input
          v-model="list.name"
          placeholder="姓名"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="gettime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList">
          搜索
        </button>
        <button class="centerBtn resetbtn" @click="cancel">重置</button>
        <button class="centerBtn" @click="addAccount = true">新增白名单</button>
        <button class="centerBtn" @click="Import = true">导入</button>
      </el-form-item>
    </el-form>
    <el-form v-else inline="true">
      <el-form-item>
        <el-input
          v-model="searchObj.name"
          placeholder="姓名"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="searchObj.value1"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20">搜索</button>
        <button class="centerBtn resetbtn">重置</button>
        <button class="centerBtn" @click="addLicensep = true">
          新增白名单
        </button>
        <button class="centerBtn" @click="importLicensep = true">
          导入
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="10"
      :loading="loading"
      :page="list.page"
      v-if="showvehicle == false"
    />
    <Table
      :data1="tableDataB"
      :columns1="columnsB"
      :total="10"
      :loading="loading"
      :page="list.page"
      v-else
    />
    <el-drawer
      title="人脸信息采集"
      :visible.sync="addAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <p class="textcolor"><span class="red">*</span>上传采集头像</p>
      <el-upload
        class="avatar-uploader"
        action="https://jsonplaceholder.typicode.com/posts/"
        :show-file-list="false"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <div class="demo-drawer__footer" style="margin-top: 80%">
        <button class="centerCzbtn">确认</button>
        <button class="centerBtn resetbtn" @click="addAccount = false">
          取消
        </button>
      </div>
    </el-drawer>
    <el-drawer
      title="批量导入人脸信息"
      :visible.sync="Import"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <el-upload
        class="upload-demo"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传jpg/png文件，且不超过500kb
        </div>
      </el-upload>
      <div class="demo-drawer__footer" style="margin-top: 80%">
        <button class="centerCzbtn">确认</button>
        <button class="centerBtn resetbtn" @click="Import = false">取消</button>
      </div>
    </el-drawer>
    <el-dialog title="车牌信息采集" :visible.sync="addLicensep" width="30%">
      <el-form :model="form" :rules="rules">
        <el-form-item
          label="账号"
          :label-width="formLabelWidth"
          prop="licensepNumber"
        >
          <el-input
            v-model="form.licensepNumber"
            autocomplete="off"
            class="centerCzInput"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn" @click="addLicensep = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-drawer
      title="批量导入车牌"
      :visible.sync="importLicensep"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <el-upload
        class="upload-demo"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传jpg/png文件，且不超过500kb
        </div>
      </el-upload>
      <div style="margin-top: 20%" class="flex justify-center">
        <button class="centerCzbtn">上传</button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import img from "../../assets/images/tx.png";
import { _PtBusinfList } from "@/api/patrolBus";

export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      radio: "1",
      checked: true,
      checkedB: false,
      showvehicle: false,
      addAccount: false,
      addLicensep: false,
      Import: false,
      importLicensep: false,
      formLabelWidth: "100px",
      isAdd: false,
      imageUrl: "",
      searchObj: {
        name: "",
        value: "",
      },
      form: {
        licensepNumber: "",
      },
      rules: {
        licensepNumber: [
          {
            required: true,
            message: "请输入车牌号",
            trigger: "blur",
          },
        ],
      },
      tableData: [],

      columns: [
        {
          label: "编号",
          prop: "carNumber",
        },
        {
          label: "头像",
          prop: "img",
          render: (h, params) => {
            return h("div", [
              h("img", {
                style: {
                  color: "#1683D9",
                  width: "40px",
                  height: "40px",
                },
                attrs: {
                  src: img, // 如果是1那种写法,不能实时获取,获取的还是undefined
                },
              }),
            ]);
          },
        },
        {
          label: "姓名",
          prop: "name",
        },
        {
          label: "采集时间",
          prop: "crawlerTime",
        },
        {
          label: "添加人",
          prop: "createName",
        },
        {
          label: "操作",
          prop: "operation",
        },
      ],
      columnsB: [
        {
          label: "车牌号",
          prop: "carLicense",
        },
        {
          label: "采集时间",
          prop: "crawlerTime",
        },
        {
          label: "添加人",
          prop: "createName",
        },
        {
          label: "操作",
          prop: "operation",
        },
      ],
      tableDataB: [],
      valueTime: "",
      list: {
        page: 1,
        size: 10,
        crawlerType: 1,
        name: "",
        startTime: "",
        endTime: "",
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    gettime(val) {
      if (val != null) {
        this.list.startTime = this.valueTime[0];
        this.list.endTime = this.valueTime[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
    },
    cancel() {
      this.valueTime = "";
      this.list = {
        page: 1,
        size: 10,
        name: "",
        startTime: "",
        endTime: "",
      };
    },
    getvehicle() {
      this.showvehicle = false;
      this.list.crawlerType = 1;
      this.getList();
    },
    getvehicleB() {
      this.showvehicle = true;
      this.list.crawlerType = 2;
      this.getList();
    },
    getcheke() {
      if (this.checked == false) {
        this.checkedB = true;
      } else {
        this.checkedB = false;
      }
    },
    getchekeB() {
      if (this.checkedB == false) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    async getList() {
      let { success, data, message } = await _PtBusinfList(this.list);
      if (success) {
        if (this.list.crawlerType == 1) {
          this.tableData = data.list;
        } else {
          this.tableDataB = data.list;
        }

        this.total = data.total;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.textcolor {
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  margin-right: 61px;
}
.statutext {
  color: rgba(22, 131, 217, 1);
  font-size: 16px;

  margin-right: 61px;
  cursor: pointer;
}
.statustext_bg {
  display: block;
  width: 60px;
  height: 14px;
  background: #1683d9;
  opacity: 0.39;
  filter: blur(5px);
  margin-top: -35px;
  margin-right: 61px;
  margin-left: 20px;
}
.statustext_bgB {
  display: block;
  width: 60px;
  height: 14px;
  background: #1683d9;
  opacity: 0.39;
  filter: blur(5px);
  margin-top: -35px;
  margin-left: 176px;
}
.carnum {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.route_text {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
.text {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-left: 5px;
  margin-right: 136px;
}
.textB {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-left: 10px;
  margin-right: 126px;
}
.textc {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.textd {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
/* .margin {
  margin-left: 24px;
  margin-top: 12px;
}
.marginB {
  margin-top: 50px;
} */
.el-input__inner {
  height: 36px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  border: 0px solid white !important;
  font-size: 14px;
}
.radioA {
  width: 100px;
  height: 36px;
  border: 1px solid #409eff;
  text-align: center;
  line-height: 36px;
}
/deep/.el-checkbox__inner {
  border-radius: 100% !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  margin-left: 161px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.bottom {
  margin-bottom: 20px;
}
</style>