<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList2">搜索</button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />

    <el-drawer
      title="查看视频监控点"
      :visible.sync="detail"
      direction="rtl"
      custom-class="demo-drawer"
      :destroy-on-close="true"
      ref="drawer"
      size="41%"
      @close="closeVideoDrawer"
    >
      <div>
        <p class="textcolor">车辆编号</p>
        <p class="flex justify-between align-end">
          <span class="textbig">{{ cNumber }}</span>
          <span class="textcolor">{{ this.currentTime }}</span>
        </p>
        <div class="mainContainer" v-if="carStatus == 1">
          <video
            v-show="videoShow"
            ref="videoElement"
            autoplay
            controls
            width="824"
            height="476"
            id="videoElement"
          ></video>
          <div class="demo-drawer__footer">
            <!-- {{vido}} -->
            <button
              class="centerCzbtnB"
              @click="ShowHistoryVideo(item)"
              v-for="item in vido"
              :key="item.id"
            >
              {{ item.view }}
            </button>
            <!-- <button class="centerBtn resetbtn">车内</button> -->
          </div>
        </div>
        <div class="carcolor flex justify-center" v-else>车辆不在线无法播放实时数据</div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="changeTabs">
          <el-tab-pane label="实时视频" name="first">
            <p class="textcolor">车辆编号</p>
            <p class="flex justify-between align-end">
              <span class="textbig">{{ cNumber }}</span>
              <span class="textcolor">{{ this.currentTime }}</span>
            </p>
            <div class="mainContainer" v-if="carStatus == 1">
              <video
                v-show="videoShow"
                ref="videoElement"
                autoplay
                controls
                width="824"
                height="476"
                id="videoElement"
              ></video>
              <div class="demo-drawer__footer">
                {{vido}}
                <button
                  class="centerCzbtnB"
                  @click="ShowHistoryVideo(item)"
                  v-for="item in vido"
                  :key="item.id"
                >
                  {{ item.view }}
                </button>
              </div>
            </div>
            <div class="carcolor flex justify-center" v-else>
              车辆不在线无法播放实时数据
            </div>
          </el-tab-pane>
          <el-tab-pane label="历史视频" name="second">
            <el-date-picker  v-model="time"  type="date"  placeholder="选择日期"  value-format="yyyy-MM-dd"  class="date">
            </el-date-picker>
            <el-time-picker  is-range  v-model="value2"  range-separator="-"  start-placeholder="开始时间"  end-placeholder="结束时间"  placeholder="选择时间范围"  value-format="HH:mm:ss"  class="time"  @change="gettime"></el-time-picker>
            <button class="centerCzbtn ml20" @click="getvidioAll">搜索</button>
            <button class="centerBtn resetbtn" @click="cancel2">重置</button>
            <p class="textcolor textB">车辆编号</p>
            <p class="flex justify-between align-end">
              <span class="textbig">{{ cNumber }}</span>
              <span class="textcolor">{{ createTime }}</span>
            </p>
            <div v-show="canShow">
              <div class="mainContainer">
                <video  ref="videoHistoryElement"  autoplay  controls  width="824"  height="476"  id="videoHistoryElement"  v-show="allShow"></video>
              </div>
              <div class="demo-drawer__footer">
                {{vido}}
                <button  class="centerCzbtnB"  @click="VideoAllShow(item)"  v-for="item in AllList"  :key="item.id">
                  {{ item.param }}
                </button>
                <button class="centerBtn resetbtn">车内</button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs> -->
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  // _MinBusvideoViewing,
  // _MinBusvideoViewingHis,
  // _MinBusvideoViewingAll,
  _MinBusgetVideoveh,
} from "@/api/minBus";
import {
  _SvBusVideoList,
  _SvBusvideoViewingHis,
  _SvBusvideoViewingAll,
} from "@/api/svBus";
import flvjs from "flv.js";
export default {
  components: {
    Table,
  },
  data() {
    return {
      currentTime: "",
      loading: false,
      radio: "1",
      detail: false,
      videoShow: false,
      allShow: false,
      canShow: false,
      value1: "",
      value2: "",
      cNumber: "",
      carStatus: null,
      activeName: "first",
      formLabelWidth: "100px",
      time: "",
      startTime: "",
      endTime: "",
      createTime: "",
      searchObj: {
        vehicleNumber: "",
      },
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          prop: "vehicleNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },
        {
          label: "车辆状态",
          prop: "carStatus",
          render: (h, params) => {
            if (params.row.carStatus == 1) {
              return h("span", "在线");
            } else {
              return h("span", "离线");
            }
          },
        },
        {
          label: "摄像头数量",
          prop: "count",
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            if (params.row.carStatus == 0) {
              return h("div", [
                h(
                  "span",
                  {
                    class: "operationA",
                  },
                  "--"
                ),
              ]);
            } else {
              return h("div", [
                h(
                  "span",
                  {
                    class: params.row.count == 0 ? "colorB" : "operationB",
                    on: {
                      click: () => {
                        if (params.row.count != 0) {
                          this.detail = true;
                          this.activeName = "first";
                          this.carStatus = params.row.carStatus;
                          this.cNumber = params.row.vehicleNumber;
                          this.getvidio(params.row.vehicleNumber);
                        }
                      },
                    },
                  },
                  "查看视频监控点"
                ),
              ]);
            }
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        total: 0,
        parkId: sessionStorage.getItem("parkId"),
        carNumber: "",
      },
      vehList: {
        cmd: 1,
        cmdType: 14,
        mediaNum: "",
        portNum: "18086",
        source: "WEB",
        vehicleId: "",
      },
      histroyvido: "",
      vido: null,
      url: "",
      AllList: [],
      flvPlayer: null,
      blLoadRealTimeVideo: false,
      cancelAjax:{
        fn: null,
        cmd: 0
      }
    };
  },
  mounted() {
    this.getList();
    // this.getvidio();
    // this.getVeh();
  },
  methods: {
    closeVideoDrawer() {
      this.destroyFlvPlayer();
      this.videoShow = false;
      if (this.vehList.mediaNum !== "") {
        this.vehList.cmd = 0;
        // 取消未执行完成的开流操作
        if (typeof this.cancelAjax.fn === "function" && this.cancelAjax.cmd == 1) {
          this.cancelAjax.fn();
        }
        _MinBusgetVideoveh(this.vehList,this);
      }
      this.vehList = {
        cmd: 1,
        cmdType: 14,
        mediaNum: "",
        portNum: "18086",
        source: "WEB",
        vehicleId: "",
      };
    },
    cancel2() {
      this.vehicleNumber = "";
      this.time = "";
      this.value2 = "";
      this.startTime = "";
      this.endTime = "";
      this.histroyvido = "";
      this.canShow = false;
      this.getHs();
      this.getvidioAll();
    },
    gettime() {
      this.startTime = this.value2[0];
      this.endTime = this.value2[1];
    },
    cancel() {
      this.list = {
        page: 1,
        size: 10,
        total: this.list.total,
        parkId: sessionStorage.getItem("parkId"),
        carNumber: "",
      };
      this.getList();
    },
    getHs() {
      let videoHistoryElement = document.getElementById("videoHistoryElement");

      this.flvPlayer = flvjs.createPlayer({
        type: "flv",
        isLive: true,
        hasAudio: false,
        url: this.histroyvido,
      });
      this.flvPlayer.attachMediaElement(videoHistoryElement);

      this.flvPlayer.load();
      this.flvPlayer.play();
    },
    changeTabs() {
      this.stopVideoSourceOutput();
      this.destroyFlvPlayer();

      this.videoShow = false;
    },

    //销毁播放器
    destroyFlvPlayer() {
      if (this.flvPlayer !== null && this.flvPlayer !== undefined) {
        this.flvPlayer.pause();
        this.flvPlayer.unload();
        this.flvPlayer.destroy();
        this.flvPlayer = null;
      }
    },

    //通知视频源停止发送视频流
    stopVideoSourceOutput() {
      if (this.blLoadRealTimeVideo) {
        this.vehList.cmd = 0;
        this.getVeh();
      }
    },

    getSs() {
      let videoElement = document.getElementById("videoElement");
      this.flvPlayer = flvjs.createPlayer({
        type: "flv",
        isLive: true,
        hasAudio: false,
        url: this.url,
      });
      this.flvPlayer.attachMediaElement(videoElement);
      this.flvPlayer.load();
      this.flvPlayer.play();
    },
    ShowHistoryVideo(item) {
      if (this.vehList?.mediaNum == item.camCode) return;
      // 关闭上一个摄像头
      if (this.vehList?.vehicleId && this.vehList?.cmd != 0) {
        let obj = { ...JSON.parse(JSON.stringify(this.vehList)), cmd: 0 };
        // 取消未执行完成的开流操作
        if (typeof this.cancelAjax.fn === "function" && this.cancelAjax.cmd == 1) {
          this.cancelAjax.fn();
        }
        _MinBusgetVideoveh(obj,this);
      }
      this.url = item.yl2;
      // this.url = item.yl2;
      // this.url =
      //   "http://36.133.220.62:18086/jdp/12345678912345678_2/2021.09.10/02.27.50.440.flv";
      this.vehList.mediaNum = item.camCode;
      this.vehList.vehicleId = item.yl1;
      this.vehList.cmd = 1;
      this.blLoadRealTimeVideo = true;
      this.getVeh();
    },
    VideoAllShow(item) {
      this.allShow = true;
      this.histroyvido = item.fileRoute;
      this.createTime = item.createTime;
      this.getHs();
    },
    async getList() {
      let { success, data, message } = await _SvBusVideoList(this.list);
      console.log(data);
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.loading = false;
      }
    },
    // 搜索
    getList2() {
      this.list.page = 1;
      this.getList();
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      await this.getList();
    },
    async getvidio() {
      this.histroyvido = [];
      this.vido = [];
      let { success, data, message } = await _SvBusvideoViewingHis({
        vehicleNumber: this.cNumber,
      });
      if (success) {
        // this.histroyvido = data.historicalList[0].fileRoute;
        this.vido = data.list;
        // this.tableData = data.list;
        // this.total = data.total;
        // this.loading = false;
      }
    },
    async getvidioAll() {
      if (
        this.cNumber != "" &&
        this.time != "" &&
        this.startTime != "" &&
        this.endTime != ""
      ) {
        let { success, data, message } = await _SvBusvideoViewingAll({
          vehicleNumber: this.cNumber,
          time: this.time,
          startTime: this.startTime,
          endTime: this.endTime,
        });
        if (success) {
          // this.histroyvido = data.historicalList[0].fileRoute;
          // this.vido = data.list;
          this.canShow = true;
          this.AllList = data;
          console.log(this.AllList);
        }
      }
    },

    async getVeh() {
      this.destroyFlvPlayer();
        // 取消未执行完成的开流操作
      if (typeof this.cancelAjax.fn === "function" && this.cancelAjax.cmd == 1) {
        this.cancelAjax.fn();
      }
      _MinBusgetVideoveh(this.vehList,this).then((res) => {
        if (!res) {
          this.$message.error(message);
        }
        if (res.success) {
          if (this.vehList.cmd === 0) {
            this.videoShow = false;
          } else {
            this.videoShow = true;
            this.getSs();
          }
        }
      });
    },
    getCurrentTime() {
      //获取当前时间并打印
      let _this = this;
      let objDate = new Date();

      let yy = objDate.getFullYear();
      let mm = objDate.getMonth() + 1;
      let dd = objDate.getDate();
      let hh = objDate.getHours();
      let mf =
        objDate.getMinutes() < 10 ? "0" + objDate.getMinutes() : objDate.getMinutes();
      let ss =
        objDate.getSeconds() < 10 ? "0" + objDate.getSeconds() : objDate.getSeconds();
      mm = mm < 10 ? "0" + mm : mm;
      _this.currentTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      console.log(_this.currentTime);
    },
  },
};
</script>

<style scoped>
/deep/.el-tabs__active-bar {
  width: 280px !important;
}
/deep/.el-tabs__item {
  width: 280px;
  text-align: center;
}
/deep/.el-tabs__nav-wrap::after {
  width: 560px;
}
.date /deep/.el-input__inner {
  width: 136px;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
}
/deep/ .el-range-editor.el-input__inner {
  width: 190px;
  background: #f5f8fa;
  border: 0px solid white !important;
  font-size: 14px;
  margin-left: -30px;
}

.textbig {
  font-size: 27px;
  font-weight: 400;
  color: #666666;
}
/deep/.el-range-input {
  background: #f5f8fa;
}
.carcolor {
  font-size: 30px;
  margin-top: 30px;
}
.textB {
  margin-top: 20px;
}
.demo-drawer__footer {
  margin-top: 20px;
}
#myPlayer {
  width: 100%;
  height: 100%;
}
.mainContainer {
  /* display: block; */
  /* height:450px; */
}
.colorB {
  /* color: red; */
  /* -webkit-touch-callout: none; */
  cursor: pointer;
}
/* .colorB:hover{cursor:not-allowed;} */
</style>
