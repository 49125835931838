<template>
  <div>
    <el-card class="box-card" >
        <!-- 数据 -->
        <el-row style="margin:0  0 1% 0" >
          <el-col :span="17"  >
              <div class="blue_left"></div>
              <div class="title_black" style="float:left;margin:0  0  0 1%">订单概览</div>
          </el-col>
          <el-col :span="4" style="margin:0 0 0 -0.5%">
               <div class="blue_left"></div>
              <div class="title_black" style="float:left;margin:0  0  0 1%" >车辆实时运营状态</div>
          </el-col>
        </el-row>
        <el-row class="title_databox">
             <el-col :span="4" style="margin:0% 2% 0 0"  >
                 <el-card style="margin:0 0 5% 0" class="border_r_10 orderrow" >
                    <el-row>    
                        <span class="title_black">总计订单数</span>
                        <div class="blue_down"></div>
                      </el-row>
                      <el-row>
                        <el-col :span="6">
                            <img src="../../assets/images/databord1.png" class="databord_icon">
                        </el-col>
                        <el-col :span="18" class="title_allorders">
                          {{totalOrderNum}}
                        </el-col>
                      </el-row>
                  </el-card>
                 <el-card class="border_r_10 orderrow">
                      <el-row>    
                        <span class="title_black">已完成订单数</span>
                        <div class="blue_down"></div>
                      </el-row>
                      <el-row>
                        <el-col :span="6">
                            <img src="../../assets/images/databord1.png" class="databord_icon">
                        </el-col>
                        <el-col :span="18" class="title_allorders">
                            {{completeOrderNum}}
                        </el-col>
                      </el-row>
                 </el-card>
             </el-col>
             <el-col :span="12" style="margin:0 2% 0 0">
                <el-card class="border_r_10 databox_item">
                    <el-row>
                       <el-col :span="12">
                            <div id="charts1" style=" width:100% ;height:200px;"></div>
                       </el-col>
                       <el-col :span="12">
                            <div id="charts2" style=" width:100% ;height:200px;"></div>
                       </el-col>
                    </el-row>
                </el-card>
             </el-col>
             <el-col :span="7">
                <el-row>
                    <el-row>
                        <el-col :span="11">
                          <el-card >
                            <el-row>
                                <img src="../../assets/images/databord3.png" class="databord_icon2">
                                <div  class="carRealtext">
                                  <span class="carRealtext_bold">{{totalShiftLineNum}}</span>台<br>班线车辆
                                </div>
                            </el-row>
                          </el-card>
                        </el-col>
                         <el-col :span="11" :offset="1">
                          <el-card>
                            <el-row>
                                <img src="../../assets/images/databord2.png" class="databord_icon2 databord_icon2_w">
                                <div  class="carRealtext">
                                  <span class="carRealtext_bold">{{totalVipNum}}</span>台<br>VIP车辆
                                </div>
                            </el-row>
                          </el-card>
                        </el-col>
                    </el-row>
                    <el-row class="showOpeB">
                      <el-link type="primary" class="showOpBtext" @click="showOperationD">查看运营服务详情</el-link>
                    </el-row>
                    <el-row class="carRealrow ">
                       <el-scrollbar  class="scrollbarShow"> 
                         <el-table  :data="carRouteList"  class="border_r_10"   :header-cell-style="{background:'#CBE6FF',color:'#000'}"    >
                            <el-table-column prop="routeName"  fixed  label="车辆线路分布" align="center"></el-table-column>
                            <el-table-column prop="shiftLineNum" label="班线车辆" align="center"></el-table-column>
                            <el-table-column prop="memberNum" label="VIP车辆" align="center"></el-table-column>
                          </el-table>
                          </el-scrollbar>
                    </el-row>
                </el-row>
             </el-col>
        </el-row>
        <!-- 线路 -->
        <el-row>
            <el-row style="margin:1% 0 0 0">
                <div class="blue_left"></div>
                <div class="title_black" style="float:left;margin-left:0.5%">昨日运营线路概览</div>
            </el-row>
            <el-row>
                <el-card v-for="(routelist , i ) in siteLists" :key="i" class="routebox">
                  <el-row class="routeTitle">
                      <el-col :span="4">{{routelist.routeName}}</el-col>
                      <el-col :span="4" :offset="12" class="text_left">昨日累计服务班次:{{routelist.carTime}}次</el-col>
                      <el-col :span="4" class="text_left">平均完成时间:{{routelist.averageTime}}min</el-col>
                      <!-- <el-col :span="4">{{routelist.siteList}}min</el-col> -->
                  </el-row>
                  <el-row class="route_box">
                        <!-- 每一项 -->
                        <ul class="" id="routeSite">
                          <li class="route_site" v-for="(site,i) in routelist.sites" :key="i"   >
                            <div class="route_site_name">{{site.siteName}}</div>
                            <img src="../../assets/images/databord4.png">
                            <div class="route_site_people">{{site.sitePeople}}人</div>
                          </li>
                        </ul>
                         <!-- 背景绿线 -->
                        <div class="green_line"></div>
                  </el-row>
                </el-card>
            </el-row>
        </el-row>
    </el-card>
    <!-- 抽屉 -->
    <el-drawer title="车辆服务班次查询" :visible.sync="drawer" direction="rtl"  size="50%">
        <el-form :inline="true" class="txetTopB">
          <el-form-item>
            <el-select v-model="searchObj.routeId" placeholder="请选择线路" >
              <el-option  v-for="item in routesOptions"  :key="item.routeId"  :label="item.routeName"  :value="item.routeId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item  >
            <el-date-picker  v-model="searchObj.searchTime"  :clearable="false"  type="daterange"    value-format="yyyy-MM-dd" range-separator="-"  start-placeholder="开始日期"  end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
         <el-form-item>
           <button class="centerCzbtn ml20" type="button" @click="getsearchobj">
            搜索
           </button>
           <button class="centerBtn resetbtn" type="button" @click="cancelA">
            重置
           </button>
           </el-form-item>
        </el-form>
        <el-table  :data="searchTable"  border  style="width: 100%" :header-cell-style="{background:'#F2F2F2',color:'#000'}">
          <el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
          <el-table-column  prop="vid"  label="车辆编号"  align="center"></el-table-column>
          <el-table-column prop="scheduleCount" label="服务班次" align="center"> </el-table-column>
        </el-table>
    </el-drawer>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import{ 
      _OrderDataOverview,
      _RealTimeCarOperationData,
      _OperationRouteOverview,
      _OperationServiceOverview
} from '../../api/minBus/index';
export default {
  
  data() {
    return {   
      //当前园区ID
      parkId:null,
  //订单数据
      //总订单数 默认0
      totalOrderNum:0,
      //完成订单数
      completeOrderNum:0,
      //今日订单数
      todayTotalOrderNum:0,
      //今日进行中
      todayProcessingOrderNum:0,
      //今日已取消
      todayCancelOrderNum:0,
      //今日已完成
      todayCompleteOrderNum:0,
      //班线订单
      shiftLineOrderNum:0,
      //vip订单
      memberOrderNum:0,
   //可视化数据
      charts1Data:[
        { "name": "已完成", "value": 100 },
        { "name": "进行中", "value": 100 },
        { "name": "已取消", "value": 100},
      ],
   //车辆实时运营状态
     totalShiftLineNum:0,
     totalVipNum:0,
     carRouteList:[
      ],

  //昨日运营站点概览
      // 站点
      siteLists:[
      ],
  //抽屉搜索框
      drawer: false,
      searchObj:{
        routeId:null,
        searchTime:[]
      },
      routesOptions:[
       ],
      searchTable:[
      ] 
        
    };
    
  },
  created(){
    
  },
  mounted() {
    //parkID
     this.getparkId()
//实时
    //获取订单
    this.getorderData()
    this.getrealRoutes()

    //画图
  
    //定
  this.timer1 = setInterval(()=>{
      this.getorderData()},180000)
  this.timer2 = setInterval(()=>{
      this.getrealRoutes()},10000)
//非实时
    //获得昨日运营线路
    this.getOperationRouteOverview()
  },

 beforeRouteLeave (to, from, next) {
       clearInterval(this.timer1)
       clearInterval(this.timer2)
        // console.log("清除")
       next()
  },



  methods: {
//获取parkID
    getparkId(){
        this.parkId = window.sessionStorage.getItem("parkId")
    },
   //默认搜索的东西
    //默认时间  默认前一天
  getNowTime() {
    //昨天时间
        var yesterday=new Date(new Date - 1000 * 60 * 60 * 24);
        //时间转化
        var formatDate = function (date) {  
            var y = date.getFullYear();  
            var m = date.getMonth() + 1;  
            m = m < 10 ? '0' + m : m;  
            var d = date.getDate();  
            d = d < 10 ? ('0' + d) : d;  
            return y + '-' + m + '-' + d;  
        }; 
        //昨天日期
       this.searchObj.searchTime.push(formatDate(yesterday));//startTime
       this.searchObj.searchTime.push(formatDate(yesterday));//endTime
    },
    //默认线路
    getNowSelect(){
      //存到下拉框数组
        this.searchObj.routeId =  this.routesOptions[0].routeId
    },  
  //展开抽屉
    showOperationD(){
      this.drawer = true;
      //默认时间 默认下拉框
      this.getNowTime();
      this.getNowSelect();
      //发送请求 获得表格
      this.getsearchobj();
    },
//获取订单   实时
    getorderData(){
       let parkId= this.parkId
       _OrderDataOverview({parkId:parkId}).then((res)=>{
        // console.log(res.data)
        // let that = this
          // console.log("订单实时")
          this.totalOrderNum = res.data.totalOrderNum ;
          this.completeOrderNum = res.data.completeOrderNum ;
          this.todayTotalOrderNum = res.data.todayTotalOrderNum;
          this.todayProcessingOrderNum = res.data.todayProcessingOrderNum ;
          this.todayCancelOrderNum = res.data.todayCancelOrderNum ;
          this.todayCompleteOrderNum = res.data.todayCompleteOrderNum ;
          this.shiftLineOrderNum = res.data.shiftLineOrderNum ;
          this.memberOrderNum = res.data.memberOrderNum ;
          //画图
          this.drawcharts1()
          this.drawcharts2()
      })
    },
//车辆运营实时线路表 实时
    getrealRoutes(){
      let parkId= this.parkId
     _RealTimeCarOperationData({parkId:parkId}).then((res)=>{
      //  console.log(res)
      //  console.log("实时线路")
       if(res.success == true){
          this.totalShiftLineNum = res.data.totalShiftLineNum;
          this.totalVipNum = res.data.totalVipNum;
          let arrobj = res.data.carRouteList;
            for(var i = 0;i<arrobj.length;i++){
              arrobj[i].shiftLineNum =  arrobj[i].shiftLineNum +'台'
              arrobj[i].memberNum = arrobj[i].memberNum +'台';
            }
            this.carRouteList = arrobj;
          //获得当前园区 所有线路
          let selectList = [];
          arrobj.forEach((item) =>{
              selectList.push({
                routeName : item.routeName,
                routeId : item.id               
              })
          })
          this.routesOptions = selectList

       }else{
          this.$message.error("操作失败，请稍后再试");
       }
     })
    },
//获取昨日线路运营 非实时
    getOperationRouteOverview(){
      let parkId= this.parkId
      // console.log("昨日线路")
     _OperationRouteOverview({parkId:parkId}).then(res=>{
        //  console.log(res)
        //  console.log("昨日线路")
         if(res.success){
            this.siteLists =res.data.carRouteList;
         }
      })
    },
//搜索
    getsearchobj(){
     console.log(this.todayCompleteOrderNum)
     //必传parkID  线路id  和 时间
     let routeId = this.searchObj.routeId; //默认线路id
     let startTime = this.searchObj.searchTime[0];//默认时间
     let endTime = this.searchObj.searchTime[1];//默认时间
     console.log(startTime)
     console.log(endTime)
     console.log(routeId)
     _OperationServiceOverview({routeId:routeId,startTime:startTime,endTime:endTime}).then(res=>{
          console.log(res)
          if(res.success){
              for(let i = 0;i<res.data.scheduledFinishedList.length;i++){
              res.data.scheduledFinishedList[i].scheduleCount = res.data.scheduledFinishedList[i].scheduleCount+'次'
            }
            this.searchTable = res.data.scheduledFinishedList
          }else{
            this.$message.error("操作失败，请稍后再试");
          }  
     })
    },
    //清除
    cancelA(){
      //恢复默认
        this.searchObj.searchTime=[]
        this.getNowSelect();
      //发情请求
        this.getsearchobj();
    },
    //图标
    drawcharts1(){
      let charts1Data = this.charts1Data;
      charts1Data[0].value= this.todayCompleteOrderNum;
      charts1Data[1].value= this.todayProcessingOrderNum;
      charts1Data[2].value= this.todayCancelOrderNum;

        let objChar = echarts.init(document.getElementById("charts1"));
        objChar.setOption({
          color:[ '#1DD39A', '#FF5478', '#BFC1C6'],
          grid: {
              top:0,
              left: 0,
              right: 0,
              bottom: 5,
              containLabel: true,
          },
          legend: {
              icon: "circle",
              orient: 'vertical',
              top:90,
              right:10,
              textStyle: {
                  align: 'left',
                  verticalAlign: 'middle',
                  rich: {
                      name: {
                          color: 'rgba(255,255,255,)',
                          fontSize: 12,
                      },
                      value: {
                          color: 'rgba(255,255,255,)',
                          fontSize: 12,
                      },
                      rate: {
                          color: 'rgba(255,255,255,0)',
                          fontSize: 12,
                      },
                  },
              },
              data: [  '已完成', '进行中', '已取消',],
              formatter: (name) => {
                  if (this.charts1Data.length) {
                      const item = charts1Data.filter((item) => item.name === name)[0];
                      return `{name12|${name}：}{value| ${item.value}} {rate| 单}`;
                  }
              },
          },
          series: [{
              name: '需求类型占比',
              type: 'pie',
              center: ['30%', '60%'],
              radius: ['55%', '75%'],
              label: {
                  normal: {
                      show: true,
                      position: 'center',
                      formatter:  () => {
                            return `今日订单\n${this.todayTotalOrderNum}`
                                },
                       rich: {
                          value: {
                              padding: 5,
                              align: 'center',
                              verticalAlign: 'middle',
                              fontSize: 32,
                          },
                          label: {
                              align: 'center',
                              verticalAlign: 'middle',
                              fontSize: 16,
                          },
                      }, 
                      
                  },
                  emphasis: {
                      show: true,
                      textStyle: {
                          fontSize: '12',
                      },
                  },
              },
              labelLine: {
                  show: false,
                  length: 0,
                  length2: 0,
              },
              data:charts1Data,
          }],
        })
    },
    drawcharts2(){
      let memberOrderNum =  [];
      let shiftLineOrderNum = [];
      memberOrderNum.push(this.memberOrderNum)
      shiftLineOrderNum.push(this.shiftLineOrderNum)
      let objChar = echarts.init(document.getElementById("charts2"));
      objChar.setOption({
            //标题
            title: {
                text: '今日订单类型分布',
                x: 10,
                y: 15,
                textStyle:{
                    color:'#000',
                    fontSize:15,
                    fontWeight:'normal',
                },  
            },
            legend: {
                data: ['班线订单数量', 'VIP订单数量'],
                top: '70%',
                left: '10%',
                textStyle: {
                    color: '#000',
                    fontSize: 10,
                    // fontWeight: 600
                },
                barBorderRadius: 2,
            },
            barWidth: 17,
            xAxis: {
                type: 'value',
                splitLine: {
                    lineStyle: {
                        color: 'rgba(255,255,255,0)',
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    //  改变x轴颜色
                    show: false,
                    lineStyle: {
                        color: '#26D9FF',
                    },
                },
                axisLabel: {
                    //  改变x轴字体颜色和大小
                    textStyle: {
                        color: 'rgba(250,250,250,0)',
                        fontSize: 16,
                    },
                },
            },
            yAxis: {
                type: 'category',
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    //  改变y轴颜色
                    show: false,
                    lineStyle: {
                        color: '#26D9FF',
                    },
                },
                axisLabel: {
                    //  改变y轴字体颜色和大小
                    textStyle: {
                        color: 'rgba(250,250,250,0)',
                        fontSize: 16,
                    },
                },
            },
            series: [
                {
                    type: 'bar',
                    name: '班线订单数量',
                    itemStyle: {
                        normal: {
                            label: {
                                show: true, //开启显示
                                position: 'right', //在上方显示
                                textStyle: {
                                    //数值样式
                                    color: '#000',
                                    fontSize: 15,
                                },
                            },
                            color: '#108EFF',
                            borderWidth: 4,
                            barBorderRadius: 4,
                        },
                    },
                    data:shiftLineOrderNum,
                },
                {
                    type: 'bar',
                    name: 'VIP订单数量',
                    itemStyle: {
                        normal: {
                            label: {
                                show: true, //开启显示
                                position: 'right', //在上方显示
                                textStyle: {
                                    //数值样式
                                    color: '#000',
                                    fontSize: 14,
                                    
                                },
                            },
                            color: '#CBE6FF',
                            borderWidth: 4,
                            barBorderRadius: 4,
                        },
                    },
                    data: memberOrderNum,
                },
            ],
      })
    }


 
  },
};
</script>

<style scoped>
.el-scrollbar  /deep/  .el-scrollbar__wrap {overflow-x: hidden;}
.box-card{
 background-color: #F5F8FA;
}
.border_r_10{border-radius: 10px; }
.databox_item{
  height:1.7rem
}
.orderrow{ height:0.8rem}
.title_black{font-weight: bold;font-size: 0.1rem;}
.blue_down{width:13%;border:2px #108EFF solid;border-radius: 4px;}
.blue_left{height:25px;border:2px #108EFF solid;float:left;margin-left:5px;border-radius: 4px;}
.title_allorders{
  font-weight: bold;
  font-size:0.17rem;
  box-sizing: border-box;
  padding:9% 0 2% 0 ;
  text-align: center;
}
.databord_icon{
  width:70%;
   box-sizing: border-box;
  padding:40% 0 2% 0 ;
}
.databord_icon2{
   float:left;
   width:40%;
   box-sizing: border-box;
   padding:3% 0 0  5%;
   margin:0 15% 0  0;
} 
.databord_icon2_w{
   padding:4% 0 0  5%;
  width:35%;
}
.carRealtext{
  float:left;
  font-size:17px;
  text-align: center;
}
.carRealtext_bold{
  font-weight: bold;
  font-size:25px;
}
.carRealrow{
  position:relative;
}
.scrollbarShow{
  height:180px;
  overflow-x:hidden;
}
.scrollbarShow 
.el-table th.el-table__cell{
    background:'#CBE6FF';color:'#000'
}
.showOpeB{
  margin:2% 0;
}
.showOpBtext{
  font-size:17px;
  font-weight: bold;
}
/* 昨日运营路线 */
.routeTitle{
  font-size:0.1rem;
  font-weight: bold;
}
.green_line{
  width:100%;
  height:0.05rem;
  background:#1DD39A;
  border-radius: 6px;
  position: absolute;
  top:72%;
}
.route_box{
  position: relative;
  margin:2% 0 1%;
}

#routeSite{
  display:-ms-flex;/* IE10 */
  display:-moz-flex;/* Firefox2 ~ Firefox21 */
  display:-webkit-flex;
  justify-content:space-around;
}
.route_site{
   text-align: center;
  flex-direction:column;
  flex-wrap: nowrap;
 
}
ul li{
  list-style-type: none;
}
.route_site_name{font-weight: bold;}
.route_site_people{font-weight: bold;}
.routebox{
  margin:1% 0;
}

.text_left{text-align: center;}
.pickerTime{
  border: 0px solid black;
}
</style>
