<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.carStatus"
          placeholder="运营模式"
          class="centerSelInput centerInput"
        >
          <el-option
            v-for="item in ModeList"
            :key="item.id"
            :label="item.mode"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <el-select
          v-model="list.operationStatus"
          placeholder="运营状态"
          class="centerSelInput"
        >
          <el-option
            v-for="item in StatusList"
            :key="item.id"
            :label="item.status"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getsearchList">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button>
        <button class="centerBtn" type="button" @click="getOpRecord">
          车辆运营记录
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :loading="loading"
      @getAjax="getTableData"
      :page="list.page"
    />
    <el-dialog title="切换运营状态" :visible.sync="resetcarstatus" width="30%">
      <p>
        <span class="textcolor">当前状态</span>
        <span class="textcolor2" v-if="carstdate.operationStatus == 1"
          >空闲</span
        >
        <span class="textcolor2" v-else-if="carstdate.operationStatus == 2"
          >运营中</span
        >
        <span class="textcolor2" v-else-if="carstdate.operationStatus == 3"
          >维修中</span
        >
        <span class="textcolor2" v-else-if="carstdate.operationStatus == 4"
          >运营下线</span
        >
      </p>
      <div>
        <span class="red">*</span><span class="textcolor">切换运营状态至</span>
        <el-radio
          v-model="form.carStatus"
          label="1"
          v-if="
            carstdate.operationStatus == 3 || carstdate.operationStatus == 4
          "
          >空闲</el-radio
        >
        <el-radio
          v-model="form.carStatus"
          label="3"
          v-if="
            carstdate.operationStatus == 1 || carstdate.operationStatus == 4
          "
          >维修中</el-radio
        >
        <el-radio
          v-model="form.carStatus"
          label="4"
          v-if="
            carstdate.operationStatus == 1 || carstdate.operationStatus == 3
          "
          >运营下线</el-radio
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn ml20" @click="getOpStatu">确 定</button>
        <button class="centerBtn resetbtn" @click="resetcarstatus = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-dialog title="切换运营模式" :visible.sync="operationMode" width="30%">
      <p>
        <span class="textcolor" style="margin-left: 14px"
          >当前车辆运营模式</span
        >
        <span class="textcolor2" v-if="carOpmodel.practices == 1"
          >普通模式</span
        >
        <span class="textcolor2" v-if="carOpmodel.practices == 2">VIP模式</span>
      </p>
      <p>
        <span class="textcolor">是否切换运营模式至</span>
        <span class="textcolor2" v-if="carOpmodel.practices == 2"
          >普通模式?</span
        >
        <span class="textcolor2" v-if="carOpmodel.practices == 1"
          >VIP模式?</span
        >
      </p>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn ml20" @click="getOpMdel">确 定</button>
        <button class="centerBtn resetbtn" @click="operationMode = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _MinBusList,
  _MinBusStatus,
  _MinBusModel,
  _MinBusDel,
  _MinBusOrderStatus,
} from "@/api/minBus";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      resetcarstatus: false,
      operationMode: false,
      isAdd: false,
      oderStatue: null,
      total: 0,
      formLabelWidth: "100px",
      carstdate: [],
      carOpmodel: [],
      operationStatus: [],
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },
        {
          label: "车辆子类型",
          prop: "sonCarType",
        },
        {
          label: "车辆状态",
          render: (h, params) => {
            if (params.row.carStatus == 1) {
              return h("span", "在线");
            } else {
              return h("span", "离线");
            }
          },
        },

        {
          label: "运营模式",
          render: (h, params) => {
            if (params.row.practices == 1) {
              return h("span", "普通模式");
            } else {
              return h("span", "VIP模式");
            }
          },
        },
        {
          label: "运营状态",
          render: (h, params) => {
            if (params.row.operationStatus == 1) {
              return h("span", "空闲中");
            } else if (params.row.operationStatus == 2) {
              return h("span", "运营中");
            } else if (params.row.operationStatus == 3) {
              return h("span", "维修中");
            } else if (params.row.operationStatus == 4) {
              return h("span", "运营下线");
            }
          },
        },
        {
          label: "行驶路线",
          prop: "carRoute",
        },
        {
          label: "安全员",
          prop: "securityName",
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class:
                    params.row.operationStatus == "2" ? "colorB" : "operationA",
                  on: {
                    click: () => {
                      if (params.row.operationStatus == "2") {
                        this.resetcarstatus = false;
                      } else {
                        this.resetcarstatus = true;
                      }
                      this.carstdate = params.row;
                      this.form.carId = params.row.id;
                      if (params.row.operationStatus == "1") {
                        this.form.carStatus = "3";
                      } else if (
                        params.row.operationStatus == "3" ||
                        params.row.operationStatus == "4"
                      ) {
                        this.form.carStatus = "1";
                      }
                    },
                  },
                },
                "切换运营状态"
              ),
              h(
                "span",
                {
                  class:
                     "operationA",
                  on: {
                    click: () => {
                      //点击之后先通过id去查 车有没有未完成订单 没有才可以切换 有不能切换         
                      if(params.row.operationStatus == '2'){  
                          this.getOderStatus(params.row.id);  
                        //此位置有一段判断的if只能放到 定义函数时进行 
                          this.carOpmodel = params.row;
                          this.formB.carId = params.row.id;
                          if (params.row.practices == 1) {
                                this.formB.carType = 2;
                          } else {
                                this.formB.carType = 1;
                          }
                      }else{
                            this.$alert(`只有运营中的车辆才可以切换 普通/VIP模式`, `注意`, {
                            confirmButtonText: `我知道了`,
                           });
                      }   
                    },
                  },
                },
                "..."
              ),
            ]);
          },
        },
      ],
      ModeList: [
        { id: "1", mode: "普通模式" },
        { id: "2", mode: "VIP模式" },
      ],
      StatusList: [
        { id: "1", status: "空闲中" },
        { id: "2", status: "运营中" },
        { id: "3", status: "维修中" },
        { id: "4", status: "运营下线" },
      ],
      searchObj: {
        vehicleNumber: "",
        operationMode: "",
        operationStatus: "",
      },
      form: {
        carId: "",
        carStatus: null,
      },
      formB: {
        carId: "",
        carType: "",
      },
      list: {
        page: 1,
        size: 10,
        total: 0,
        carNumber: "",
        carStatus: "",
        operationStatus: "",
        parkId: sessionStorage.getItem("parkId"),
      },
    };
  },
  mounted() {
    this.getList();
  },
   beforeDestroy() {
    
},
  methods: {
    //跳转至车辆运营记录
    getOpRecord() {
      this.$router.push("/vehicleOperationRecord");
    },
    //取消
    cancel() {
      this.list = {
        page: 1,
        size: 10,
        total: this.list.total,
        carNumber: "",
        carStatus: "",
        operationStatus: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      this.getList();
    },
    //切换分页
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    //获取页面数据
    async getList() {
      let { success, data, message } = await _MinBusList(this.list);
      if (success) {
        this.tableData = data.list;
        this.list.total = data.total;
        this.loading = false;
      } 
        // console.log(this.tableData)
    },
    //搜索 与获取不能同用一个函数
    getsearchList(){
        this.list.page = 1;
        this.getList()
    },
    //切换运营状态
    async getOpStatu() {
    
      let { success, data, message } = await _MinBusStatus(this.form);
      if (success) {
        this.$message.success("切换运营状态成功");
        this.loading = false;
        this.getList();
      }
      this.resetcarstatus = false;
    },
    //切换运营模式
    async getOpMdel() {
      let { success, data, message } = await _MinBusModel(this.formB);
      if (success) {
        this.$message.success("切换运营模式成功");
        this.loading = false;
        this.getList();
      }
      this.operationMode = false;
    },
    //查询订单状态
    async getOderStatus(id) {
      // isOrderStatus 0是没有订单  1是有订单
      let { success, data, message } = await _MinBusOrderStatus({
        carRegisterId: id,
      });
      if (success) {
        this.loading = false;
        this.oderStatue = data.isOrderStatus;
      //下面一段本是在render函数中 因为render无法执行异步操作 (能力有限) 只能挪到这里执行
        if ( this.oderStatue == 1 ) {
              this.operationMode = false;
              this.$alert(`当前车辆有未完成的订单无法切换运营模式`, `切换运营模式`, {
              confirmButtonText: `我知道了`,
              });
          } else {
              this.operationMode = true;
          }
          
      }
      

    },

  },
};
</script>

<style scoped>
/deep/ .colorB {
  /* color: red; */
  /* -webkit-touch-callout: none; */
  cursor: not-allowed;
  margin-right: 20px;
}
</style>