<template>
  <div class="largMap">
    <div id="container"></div>
    <div class="leftArr">
      <img src="@/assets/images/leftArrow.png" alt="" />
    </div>
    <div class="lineMap" @click="startAnimation" v-if="routeList.length > 0">
      <div v-for="(item, idx) in routeList" :key="idx">
        <div
          class="technology"
          :style="{ backgroundColor: item.color }"
          v-if="item.color !== null"
        ></div>
        <div class="technology" v-else></div>
        <span>{{ item.routeName }}</span>
      </div>
    </div>

    <!-- 微公交-->
    <el-drawer
      title="车辆实时信息"
      :visible.sync="isMicroBus"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="70%"
      @close="getnoShow"
    >
      <span class="time">更新时间：{{ CarData.datetime }}</span>

      <div class="drawContent">
        <div class="drawContentLeft">
          <div class="drawContentHead">
            <div class="licenseWords">
              车辆编号<span v-if="CarData.vehicle.base.type === 1"
                >【微公交】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 2"
                >【SV-BUS】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 3"
                >【观光车】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 4"
                >【巡逻车】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 5"
                >【清扫车】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 6"
                >【智能抗冰车】</span
              >
            </div>
            <div class="licenseNumber">
              <span>{{ CarData.vehicle.base.vid }}</span>
              <div class="onLine" v-if="carStatusin === 1">在线</div>
              <div class="onLinetwo" v-else>离线</div>
            </div>
            <div class="vehIcon">
              <div>
                <img src="@/assets/images/number.png" />
                <span>未编队</span>
              </div>
              <div>
                <img src="@/assets/images/expresstrain.png" />
                <span>快车</span>
              </div>
              <div>
                <img src="@/assets/images/route.png" />
                <span>{{ CarData.business.lineName }}</span>
              </div>
            </div>
            <div class="nextS">
              <div class="nextStation">
                下一站 <span>{{ CarData.business.nstaName }}</span>
              </div>
              <div class="location">
                <img src="@/assets/images/location.png" alt="" />
                <span
                  >距离站点<span class="locationWords">{{
                    CarData.business.nstadis
                  }}</span
                  >m
                </span>
              </div>
            </div>
            <div class="drivingMode">
              <span>驾驶模式</span>
              <img src="@/assets/images/drive.png" alt="" />
              <span>{{ CarData.vehicle.base.dmodeStr }}</span>
            </div>
          </div>
          <div class="ec">
            <div class="ec1">
              <div ref="chartMicroBusSpeed" class="chart-car chart-speed"></div>
              <div class="speed-info">
                <div class="unit">km/h</div>
                <div class="title">车速</div>
              </div>
            </div>
            <div class="ec1">
              <div ref="chartMicroBusPower" class="chart-car chart-power"></div>
              <div class="power-info">
                <div class="unit">%</div>
                <div class="title">电量</div>
              </div>
            </div>
          </div>
          <div class="mileage">
            <div>
              <div>累计里程</div>
              <div>
                <span class="mileageBold">{{
                  CarData.vehicle.base.accumulate
                }}</span>
                <span>km</span>
              </div>
            </div>
            <!-- <div>
              <div>小计里程</div>
              <div>
                <span class="mileageBold">{{
                  this.CarData.mile_subtotal
                }}</span>
                <span>km({{ this.CarData.time_subtotal }}h)</span>
              </div>
            </div> -->
            <div>
              <div>续航里程</div>
              <div>
                <span class="mileageBold">{{
                  CarData.vehicle.base.recharge
                }}</span>
                <span>km</span>
              </div>
            </div>
          </div>

          <div class="warning" v-if="CarData.warning.desc">
            <div class="warningHead"></div>
            <div class="warningNotice">
              <img src="@/assets/images/notice.png" alt="" />
              <span>告警通知</span>
            </div>
            <div class="Tertiary">三级警告</div>
            <div class="fault">
              {{ CarData.warning.desc }}
            </div>
          </div>

          <div class="drawContentHead">
            <div class="licenseWords">CBU编号</div>
            <div class="licenseNumber">
              <span>{{ CarData.vehicle.base.vid }}</span>
              <div class="onLine">运行</div>
            </div>
          </div>
          <div>
            <el-descriptions class="margin-top" :column="1" :size="size" border>
              <el-descriptions-item>
                <template slot="label"> 上行通信速率</template>
                {{ CarData.vehicle.ict.uspeed }}M/s
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 下行通信速率</template>
                {{ CarData.vehicle.ict.dspeed }}M/s
              </el-descriptions-item>

              <!--<el-descriptions-item>
                <template slot="label"> OBU通信效率 </template>
                {{ this.CarData.rsrp }}M/s
              </el-descriptions-item>-->

              <el-descriptions-item>
                <template slot="label"> 网络延时</template>
                {{ CarData.vehicle.ict.delay }}ms
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 丢包</template>
                {{ CarData.vehicle.ict.loss }}%
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <div class="drawContentRight">
          <el-tabs v-model="activeName">
            <el-tab-pane label="车锁车门" name="first">
              <div class="doorImg">
                <img src="@/assets/images/microBusImg.png" alt="" />
                <div class="doorLock">
                  <div v-for="(item, idx) in CarData.vehicle.doors" :key="idx">
                    <div v-if="item.desc !== null">
                      <span>{{ item.desc }}</span>
                      <img src="@/assets/images/lock.png" alt="" />
                      <span v-if="item.status === 1">打开</span>
                      <span v-else>关闭</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lockTheDoor">
                <img
                  src="@/assets/images/lockTheDoor.png"
                  alt=""
                  @click="openFullScreen1"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  v-if="statusdoors === 1"
                />
                <img
                  src="@/assets/images/doors.png"
                  alt=""
                  @click="openFullScreen1"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  v-if="statusdoors !== 1"
                />
                <!-- <img
                  src="@/assets/images/dooorstwo.png"
                  alt=""
                  @click="openFullScreen1"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  v-if="fullscreenLoading !== 1 && statusdoors === 1"
                />
                <img
                  src="@/assets/images/doorsB.png"
                  alt=""
                  @click="openFullScreen1"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  v-if="fullscreenLoading !== 1 && statusdoors !== 1"
                /> -->
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="空调"
              name="second"
              v-if="
                CarData.vehicle.base.type === 1 ||
                CarData.vehicle.base.type === 2
              "
            >
              <div class="airConditioner">
                <div class="airConditionerHead">
                  <div class="temperature">
                    <!-- <p>车内温度</p>
                    <p>
                      <span class="centigrade">{{
                        CarData.vehicle.conditioner.ctemp
                      }}</span>
                      ℃
                    </p> -->
                  </div>
                  <div class="airConditionerImg">
                    <img src="@/assets/images/microBusImg.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="vehState">
                <div class="AirConditioningStatus">
                  <div>
                    空调状态
                    <img src="@/assets/images/lock.png" alt="" />
                    <span v-if="CarData.vehicle.conditioner.status === 1"
                      >开启</span
                    >
                    <span v-else>关闭</span>
                  </div>
                  <div>
                    空调模式
                    <img src="@/assets/images/airconditioner.png" alt="" />

                    <span
                      v-if="
                        CarData.vehicle.conditioner.model === '0x1' &&
                        CarData.vehicle.conditioner.status === 1
                      "
                      >自动制冷</span
                    >
                    <span
                      v-else-if="
                        CarData.vehicle.conditioner.model === '0x2' &&
                        CarData.vehicle.conditioner.status === 1
                      "
                      >自动制热</span
                    >
                    <span
                      v-else-if="
                        CarData.vehicle.conditioner.model === '0x3' &&
                        CarData.vehicle.conditioner.status === 1
                      "
                      >强制制冷模式</span
                    >
                    <span
                      v-else-if="
                        CarData.vehicle.conditioner.model === '0x4' &&
                        CarData.vehicle.conditioner.status === 1
                      "
                      >强制制热模式</span
                    >
                    <span
                      v-else-if="CarData.vehicle.conditioner.model === '0x5'"
                      >通风</span
                    >
                    <span v-else>关闭</span>
                  </div>
                </div>
              </div>
              <div class="state" v-loading.fullscreen.lock="fullscreenLoading2">
                <img
                  src="@/assets/images/refrigeration.png"
                  alt=""
                  @click="getcool(0x1)"
                  v-if="CarData.vehicle.conditioner.model !== '0x1'"
                />
                <img
                  src="@/assets/images/refrigeration_two.png"
                  alt=""
                  @click="getcool(0x1)"
                  v-if="
                    CarData.vehicle.conditioner.model === '0x1' &&
                    CarData.vehicle.conditioner.status === 1
                  "
                />
                <img
                  src="@/assets/images/ForcedCooling.png"
                  alt=""
                  @click="getcool(0x2)"
                  v-if="CarData.vehicle.conditioner.model !== '0x2'"
                />
                <img
                  src="@/assets/images/ForcedCooling_two.png"
                  alt=""
                  @click="getcool(0x2)"
                  v-if="
                    CarData.vehicle.conditioner.model === '0x2' &&
                    CarData.vehicle.conditioner.status === 1
                  "
                />
                <img
                  src="@/assets/images/heating.png"
                  alt=""
                  @click="getcool(0x3)"
                  v-if="
                    CarData.vehicle.conditioner.model !== '0x3' ||
                    CarData.vehicle.conditioner.status === 0
                  "
                />
                <img
                  src="@/assets/images/heating_two.png"
                  alt=""
                  @click="getcool(0x3)"
                  v-if="
                    CarData.vehicle.conditioner.model === '0x3' &&
                    CarData.vehicle.conditioner.status === 1
                  "
                />
                <img
                  src="@/assets/images/forcedHeat.png"
                  alt=""
                  @click="getcool(0x4)"
                  v-if="CarData.vehicle.conditioner.model !== '0x4'"
                />
                <img
                  src="@/assets/images/forcedHeat_two.png"
                  alt=""
                  @click="getcool(0x4)"
                  v-if="
                    CarData.vehicle.conditioner.model === '0x4' &&
                    CarData.vehicle.conditioner.status === 1
                  "
                />
                <img
                  src="@/assets/images/improveAircirculation.png"
                  alt=""
                  @click="getcool(0x5)"
                  v-if="CarData.vehicle.conditioner.model !== '0x5'"
                />
                <img
                  src="@/assets/images/improveAircirculation_two.png"
                  alt=""
                  @click="getcool(0x5)"
                  v-if="
                    CarData.vehicle.conditioner.model === '0x5' &&
                    CarData.vehicle.conditioner.status === 1
                  "
                />
              </div>
              <div class="wind flex justify-center">
                <el-input-number
                  v-model="CarData.vehicle.conditioner.cvolume"
                  @change="handleChangewind"
                  :min="1"
                  :max="7"
                  label="风量"
                ></el-input-number>
              </div>
              <!-- <div
                class="openClean"
                @click="getcool(0x0)"
                v-if="this.sweeplist.witch === 1"
              >
                关闭
              </div> -->
              <div class="openClean sprayWater" @click="getcool(0x0)">关闭</div>
            </el-tab-pane>
            <el-tab-pane label="灯光" name="third">
              <div class="doorImg">
                <img src="@/assets/images/microBusImg.png" alt="" />
                <div class="doorLock">
                  <div>
                    <span>近光灯</span>
                    <img src="@/assets/images/lamp.png" alt="" />

                    <span v-if="CarData.vehicle.light.lowbeam === 1">开</span>
                    <span v-else>关 </span>
                    <el-switch
                      v-model="CarData.vehicle.light.lowbeam"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="0"
                      @change="getsw_one(3, CarData.vehicle.light.lowbeam)"
                    >
                    </el-switch>
                  </div>
                  <div>
                    <span>远光灯</span>
                    <img src="@/assets/images/lamp.png" alt="" />
                    <span v-if="CarData.vehicle.light.highbeam === 1">开</span>
                    <span v-else>关</span>
                    <el-switch
                      v-model="CarData.vehicle.light.highbeam"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="0"
                      @change="getsw_one(4, CarData.vehicle.light.highbeam)"
                    >
                    </el-switch>
                  </div>
                  <div>
                    <span>车内灯</span>
                    <img src="@/assets/images/lamp.png" alt="" />
                    <span v-if="CarData.vehicle.light.interior === 1">开</span>
                    <span v-else>关</span>
                    <el-switch
                      v-model="CarData.vehicle.light.interior"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="0"
                      @change="getsw_one(5, CarData.vehicle.light.interior)"
                    >
                    </el-switch>
                  </div>
                  <div>
                    <span>后雾灯</span>
                    <img src="@/assets/images/lamp.png" alt="" />
                    <span v-if="CarData.vehicle.light.rearfog === 1">开</span>
                    <span v-else>关</span>
                    <el-switch
                      v-model="CarData.vehicle.light.rearfog"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="0"
                      @change="6, CarData.vehicle.light.rearfog"
                    >
                    </el-switch>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!--<el-tab-pane label="座椅" name="fourth">
              <div class="doorImg">
                <img src="@/assets/images/microBusImg.png" alt="" />
                <div class="doorLock">
                  <div>
                    <span>休息1</span>
                    <img src="@/assets/images/rest.png" alt="" />
                    <span>无人</span>
                  </div>
                  <div>
                    <span>休息2</span>
                    <img src="@/assets/images/rest.png" alt="" />
                    <span>无人</span>
                  </div>
                  <div>
                    <span>休息3</span>
                    <img src="@/assets/images/rest.png" alt="" />
                    <span>无人</span>
                  </div>
                  <div>
                    <span>休息4</span>
                    <img src="@/assets/images/rest.png" alt="" />
                    <span>无人</span>
                  </div>
                  <div>
                    <span>休息5</span>
                    <img src="@/assets/images/rest.png" alt="" />
                    <span>无人</span>
                  </div>
                  <div>
                    <span>休息6</span>
                    <img src="@/assets/images/rest.png" alt="" />
                    <span>无人</span>
                  </div>
                </div>
              </div>
            </el-tab-pane>-->
            <el-tab-pane label="除雾" name="five">
              <div class="doorImg">
                <img src="@/assets/images/microBusImg.png" alt="" />
                <div class="demisting">
                  <span>模式除雾</span>
                  <img src="@/assets/images/lamp.png" alt="" />
                  <span v-if="CarData.vehicle.base.defrost === 1">开</span>
                  <span v-else>关</span>
                  <el-switch
                    v-model="CarData.vehicle.base.defrost"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="1"
                    :inactive-value="0"
                    @change="getsw_one(8, CarData.vehicle.base.defrost)"
                  >
                  </el-switch>
                </div>
              </div>
            </el-tab-pane>
            <!--<el-tab-pane label="EPB状态" name="six"
            >EPB状态: {{ CarData.vehicle.base.epbDesc }}</el-tab-pane
            >-->
            <el-tab-pane label="报文" name="seven">
              <el-table
                :data="tableData"
                border
                style="width: 100%; height: 730px; overflow-y: auto"
              >
                <el-table-column prop="en_name" label="中文名称" width="180">
                </el-table-column>
                <el-table-column prop="cn_name" label="英文名称" width="180">
                </el-table-column>
                <el-table-column prop="value" label="值"></el-table-column>
              </el-table>
            </el-tab-pane>
            <!--<el-tab-pane label="监控" name="eighth" class="video">
							<button class="centerCzbtn lookVideo">查看视频</button>
						</el-tab-pane>-->
          </el-tabs>
        </div>
      </div>
    </el-drawer>

    <!-- 清扫车 -->
    <el-drawer
      title="车辆实时信息"
      :visible.sync="isSweeper"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="70%"
      @close="getnoShow"
    >
      <span class="time">更新时间：{{ CarData.datetime }}</span>
      <div class="drawContent">
        <div class="drawContentLeft">
          <div class="drawContentHead">
            <div class="licenseWords">
              <span> 车辆编号【清扫车】</span>
            </div>
            <div class="licenseNumber">
              <span>{{ CarData.vehicle.base.vid }}</span>
              <div class="onLine" v-if="carStatusin === 1">在线</div>
              <div class="onLine" v-else>离线</div>
            </div>
            <div class="vehIcon">
              <div>
                <img src="@/assets/images/number.png" />
                <span>未编队</span>
              </div>
              <div>
                <img src="@/assets/images/expresstrain.png" />
                <span>快车</span>
              </div>
              <div>
                <img src="@/assets/images/route.png" />
                <span>{{ CarData.business.lineName }}</span>
              </div>
            </div>
            <div class="nextS">
              <div class="nextStation">
                下一站 <span>{{ CarData.business.nstaName }}</span>
              </div>
              <div class="location">
                <img src="@/assets/images/location.png" alt="" />
                <span>距离站点<span class="locationWords">50</span>m </span>
              </div>
            </div>
            <div class="drivingMode">
              <span>驾驶模式</span>
              <img src="@/assets/images/drive.png" alt="" />
              <span>{{ CarData.vehicle.base.dmodeStr }}</span>
            </div>
          </div>
          <div class="ec">
            <div class="ec1">
              <div ref="chartCleanCarSpeed" class="chart-car chart-speed"></div>
              <div class="speed-info">
                <div class="unit">km/h</div>
                <div class="title">车速</div>
              </div>
            </div>
            <div class="ec1">
              <div ref="chartCleanCarPower" class="chart-car chart-power"></div>
              <div class="power-info">
                <div class="unit">%</div>
                <div class="title">电量</div>
              </div>
            </div>
          </div>
          <div class="mileage">
            <div>
              <div>累计里程</div>
              <div>
                <span class="mileageBold">{{
                  CarData.vehicle.base.accumulate
                }}</span>
                <span>km</span>
              </div>
            </div>
            <div>
              <div>漫漫长路</div>
              <div>
                <span class="mileageBold">{{
                  CarData.vehicle.base.recharge
                }}</span>
                <span>km</span>
              </div>
            </div>
          </div>

          <div class="epbState">
            <div>
              <span>EPB状态</span>
              <img src="@/assets/images/epb.png" alt="" />
              <span>已释放</span>
            </div>
            <div>
              <span>非充电状态</span>
              <img src="@/assets/images/gear.png" alt="" />
              <span>D挡</span>
            </div>
          </div>
          <div>
            <el-descriptions class="margin-top" :column="1" :size="size" border>
              <el-descriptions-item>
                <template slot="label"> 上行通信速率</template>
                {{ CarData.vehicle.ict.uspeed }}M/s
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 下行通信速率</template>
                {{ CarData.vehicle.ict.dspeed }}M/s
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 网络延时</template>
                {{ CarData.vehicle.ict.delay }}ms
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 丢包</template>
                {{ CarData.vehicle.ict.loss }}%
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <div class="drawContentRight">
          <el-tabs
            v-model="activeName1"
            v-loading.fullscreen.lock="fullscreenLoading"
          >
            <el-tab-pane label="清扫" name="first">
              <div class="doorImg">
                <img src="@/assets/images/sweeperImg.png" alt="" />
                <div class="clean">
                  <span>清扫开关</span>
                  <img src="@/assets/images/cleartwo.png" alt="" />
                  <span v-if="this.sweeplist.witch === 1">开启</span>
                  <span v-else>关闭</span>
                </div>
              </div>
              <div class="liquidfill">
                <div ref="chartDustbinCapacity" class="chart-qs-car"></div>
              </div>
              <div
                class="openClean sprayWater"
                @click="getsweep"
                v-if="sweeplist.witch === 1 && carStatusin === 1"
              >
                关闭清扫
              </div>
              <div
                class="openClean"
                @click="getsweep"
                v-if="sweeplist.witch === 1 && carStatusin !== 1"
              >
                关闭清扫
              </div>
              <div
                class="openClean sprayWater"
                @click="getsweep"
                v-if="sweeplist.witch !== 1 && carStatusin === 1"
              >
                开启清扫
              </div>
              <div
                class="openClean"
                @click="getsweep"
                v-if="sweeplist.witch !== 1 && carStatusin !== 1"
              >
                开启清扫
              </div>
            </el-tab-pane>
            <el-tab-pane label="喷水" name="second">
              <div class="doorImg">
                <img src="@/assets/images/sweeperImg.png" alt="" />
                <div class="clean">
                  <span>喷水开关</span>
                  <img src="@/assets/images/watertwo.png" alt="" />
                  <span v-if="this.sweeplist.witch_two === 1">开启</span>
                  <span v-else>关闭</span>
                </div>
              </div>
              <div class="liquidfill">
                <div ref="chartSolutionTankCapacity" class="chart-qs-car"></div>
              </div>
              <div
                class="openClean sprayWater"
                v-if="sweeplist.witch_two === 1 && carStatusin === 1"
                @click="getwater"
              >
                关闭喷水
              </div>
              <div
                class="openClean"
                v-if="sweeplist.witch_two === 1 && carStatusin !== 1"
                @click="getwater"
              >
                关闭喷水
              </div>
              <div
                class="openClean sprayWater"
                @click="getwater"
                v-if="sweeplist.witch_two !== 1 && carStatusin === 1"
              >
                开启喷水
              </div>
              <div
                class="openClean"
                v-if="sweeplist.witch_two !== 1 && carStatusin !== 1"
                @click="getwater"
              >
                开启喷水
              </div>
            </el-tab-pane>
            <el-tab-pane label="报文" name="seven">
              <el-table
                :data="tableData"
                border
                style="width: 100%; height: 730px; overflow-y: auto"
              >
                <el-table-column prop="en_name" label="中文名称" width="180">
                </el-table-column>
                <el-table-column prop="cn_name" label="英文名称" width="180">
                </el-table-column>
                <el-table-column prop="value" label="值"></el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>

    <!-- 巡逻车 -->
    <el-drawer
      title="车辆实时信息"
      :visible.sync="ispatrolCar"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="70%"
      @close="getnoShow"
    >
      <span class="time">更新时间：{{ CarData.datetime }}</span>
      <div class="drawContent">
        <div class="drawContentLeft">
          <div class="drawContentHead">
            <div class="licenseWords">
              车辆编号<span v-if="CarData.vehicle.base.type === 1"
                >【微公交】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 2"
                >【SV-BUS】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 3"
                >【观光车】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 4"
                >【巡逻车】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 5"
                >【清扫车】</span
              >
              <span v-else-if="CarData.vehicle.base.type === 6"
                >【智能抗冰车】</span
              >
            </div>
            <div class="licenseNumber">
              <span>{{ CarData.vehicle.base.vid }}</span>
              <div class="onLine" v-if="carStatusin === 1">在线</div>
              <div class="onLinetwo" v-else>离线</div>
            </div>
            <div class="vehIcon">
              <div>
                <img src="@/assets/images/number.png" />
                <span>未编队</span>
              </div>
              <div>
                <img src="@/assets/images/expresstrain.png" />
                <span>快车</span>
              </div>
              <div>
                <img src="@/assets/images/route.png" />
                <span>{{ CarData.business.lineName }}</span>
              </div>
            </div>
            <div class="nextS">
              <div class="nextStation">
                下一站 <span>{{ CarData.business.nstaName }}</span>
              </div>
              <div class="location">
                <img src="@/assets/images/location.png" alt="" />
                <span
                  >距离站点<span class="locationWords">{{
                    CarData.business.nstadis
                  }}</span
                  >m
                </span>
              </div>
            </div>
            <div class="drivingMode">
              <span>驾驶模式</span>
              <img src="@/assets/images/drive.png" alt="" />
              <span>{{ CarData.vehicle.base.dmodeStr }}</span>
            </div>
          </div>
          <div class="ec">
            <div class="ec1">
              <div
                ref="chartPatrolCarSpeed"
                class="chart-car chart-speed"
              ></div>
              <div class="speed-info">
                <div class="unit">km/h</div>
                <div class="title">车速</div>
              </div>
            </div>
            <div class="ec1">
              <div
                ref="chartPatrolCarPower"
                class="chart-car chart-power"
              ></div>
              <div class="power-info">
                <div class="unit">%</div>
                <div class="title">电量</div>
              </div>
            </div>
          </div>
          <div class="mileage">
            <div>
              <div>累计里程</div>
              <div>
                <span class="mileageBold">{{
                  CarData.vehicle.base.accumulate
                }}</span>
                <span>km</span>
              </div>
            </div>
            <!-- <div>
              <div>小计里程</div>
              <div>
                <span class="mileageBold">{{
                  this.CarData.mile_subtotal
                }}</span>
                <span>km({{ this.CarData.time_subtotal }}h)</span>
              </div>
            </div> -->
            <div>
              <div>续航里程</div>
              <div>
                <span class="mileageBold">{{
                  CarData.vehicle.base.recharge
                }}</span>
                <span>km</span>
              </div>
            </div>
          </div>

          <div class="drawContentHead">
            <div class="licenseWords">CBU编号</div>
            <div class="licenseNumber">
              <span>{{ CarData.vehicle.base.vid }}</span>
              <div class="onLine">运行</div>
            </div>
          </div>

          <div>
            <el-descriptions class="margin-top" :column="1" :size="size" border>
              <el-descriptions-item>
                <template slot="label"> 信号接收功率RSRP</template>
                {{ CarData.vehicle.ict.uspeed }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 信噪比SNR</template>
                {{ CarData.vehicle.ict.dspeed }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <div class="drawContentRight">
          <el-tabs v-model="activeName">
            <el-tab-pane label="报文" name="first">
              <el-table
                :data="tableData"
                border
                style="width: 100%; height: 730px; overflow-y: auto"
              >
                <el-table-column prop="en_name" label="中文名称" width="180">
                </el-table-column>
                <el-table-column prop="cn_name" label="英文名称" width="180">
                </el-table-column>
                <el-table-column prop="value" label="值"></el-table-column>
              </el-table>
            </el-tab-pane>
            <!--<el-tab-pane label="监控" name="eighth" class="video">
							<button class="centerCzbtn lookVideo">查看视频</button>
						</el-tab-pane>-->
          </el-tabs>
        </div>
      </div>
    </el-drawer>

    <!--region 告警通知-->
    <div class="wnotice" v-if="wnotice.show">
      <div class="wnotice-header">
        <div class="wnotice-header-boder"></div>
        <div class="wnotice-header-title">
          <span>告警通知</span>
        </div>
      </div>

      <div class="wnotice-body">
        <div class="wnotice-body-content">
          <p class="content-title">GPS位置异常提醒</p>

          <div class="content-body">
            <p
              class="content-item"
              v-for="(item, idx) in wnotice.message"
              :key="idx"
            >
              {{ item.content }}
              <span class="content-body-msg-date">{{ item.datetime }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="wnotice-footer">
        <span class="btn-close" @click="wNoticeKnow">我知道了</span>
      </div>
    </div>
    <!--endregion 告警通知-->
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-liquidfill";
import {
  _FindCarDataById,
  _FindParkByCarOperation,
  _Findpatrol,
} from "@/api/equipmentMonitoring";
import { Amap, Marker, Polyline } from "@amap/amap-vue";
import {
  GetDateTime,
  calculateCenter,
  computeSignedArea,
  TimestampToDatetime,
  OffOnValueToLabel,
  SeatsStatusValueToLabel,
  BeltsStatusValueToLabel,
  DmodeValueToLabel,
  ChargeValueToLabel,
  GpsValueToLabel,
} from "@/until/helper";

export default {
  components: { Amap },
  data() {
    return {
      editable: false,
      draggable: true,
      fullscreenLoading: false,
      fullscreenLoading2: false,
      ispatrolCar: false,
      avoshow: false,
      microBusImg: false,
      isSweeper: false,
      isMicroBus: false,
      size: null,
      parkData: null,
      marker: null,
      mapTimes: null,
      carStatusin: 0,
      statusdoors: 0,
      timer: "",
      id: "",
      stroke: "#409eff",
      activeName: "first",
      activeName1: "first",
      doors: [],
      routeList: [],
      carMarkerList: [],
      LightValueList: [false, true],
      lineArr: [
        [116.478935, 39.997761],
        [116.478939, 39.997825],
        [116.478912, 39.998549],
        [116.478912, 39.998549],
        [116.478998, 39.998555],
        [116.478998, 39.998555],
        [116.479282, 39.99856],
        [116.479658, 39.998528],
        [116.480151, 39.998453],
        [116.480784, 39.998302],
        [116.480784, 39.998302],
        [116.481149, 39.998184],
        [116.481573, 39.997997],
        [116.481863, 39.997846],
        [116.482072, 39.997718],
        [116.482362, 39.997718],
        [116.483633, 39.998935],
        [116.48367, 39.998968],
        [116.484648, 39.999861],
        [116.478935, 39.997761],
      ],
      tableData: [],
      wnotice: {
        show: false,
        message: [],
        carIdList: [],
      },
      CarData: {
        type: "",
        datetime: "",
        business: {
          nstaid: "",
          nstaName: "",
          nstadis: "",
          lineName: "",
          cstaid: "",
          cstaName: "",
        },
        warning: {
          code: 1,
          data: "1",
          desc: null,
        },
        vehicle: {
          base: {
            vid: "",
            speed: "",
            power: "",
            accumulate: "",
            recharge: "",
            seats: [],
            dmode: "",
            dmodeStr: "",
            epb: "",
            epbDesc: "",
            defrost: 0,
            type: 1,
          },
          ict: {
            loss: "",
            uspeed: "",
            delay: "",
            rsrp: "",
            dspeed: "",
            sinr: "",
          },

          conditioner: {
            idx: "",
            status: 0,
            model: "",
            cvolume: "",
            stemp: "",
            ctemp: "",
            modelList: [],
            cvolumeList: [],
            statusList: [],
          },

          light: {
            parking: "",
            highbeam: "",
            lowbeam: "",
            backlight: "",
            ambient: "",
            brakelight: "",
            interior: "",
            outline: "",
            rightturn: "",
            rearfog: "",
            frontfog: "",
            warning: "",
            welcome: "",
            leftturn: "",
          },
        },
      },
      lightSwitch: {
        parking: false,
        highbeam: false,
        lowbeam: false,
        backlight: false,
        ambient: false,
        brakelight: false,
        interior: false,
        outline: false,
        rightturn: false,
        rearfog: false,
        frontfog: false,
        warning: false,
        welcome: false,
        leftturn: false,
      },
      distributionIcon: {
        image: require("@/assets/images/distribution.png"),
        imageSize: [40, 41],
      },
      sweeperIcon: {
        image: require("@/assets/images/sweeper.png"),
        imageSize: [60, 61],
      },
      microBusIcon: {
        image: require("@/assets/images/microBus.png"),
        imageSize: [60, 61],
      },
      Cardorm: {
        cmd: 0,
        cmdType: 0,
        vehicleId: "",
        source: "WEB",
      },
      sweeplist: {
        witch: 0,
        witch_two: 0,
      },
    };
  },
  mounted() {
    this.InitData();
  },

  destroyed() {
    clearInterval(this.mapTimes);
    clearInterval(this.timer);
  },

  methods: {
    //关闭抽屉
    getnoShow() {
      this.isMicroBus = false;
      this.isSweeper = false;
      clearInterval(this.timer);
    },

    // region 下行指令
    //开门
    openFullScreen1() {
      this.fullscreenLoading = true;
      if (this.statusdoors === 1) {
        this.Cardorm = {
          cmd: 0,
          cmdType: 1,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        // this.minBusform = {
        //   cmdType: 1,
        //   cmd: 1,
        //   vehicleId: this.id,
        // };
        this.Cardorm = {
          cmd: 1,
          cmdType: 1,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },

    //空调(微公交)
    getcool(val) {
      this.fullscreenLoading2 = true;
      if (val === "0x5") {
        this.Cardorm = {
          cmd: val,
          cmdType: 7,
          vehicleId: this.id,
          source: "WEB",
        };
        console.log(this.Cardorm);
      } else {
        this.Cardorm = {
          cmd: val,
          cmdType: 2,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //风量
    handleChangewind(value) {
      this.fullscreenLoading = true;
      this.Cardorm = {
        cmd: value,
        cmdType: 7,
        vehicleId: this.id,
        source: "WEB",
      };
      this.newfindCar();
    },
    //灯光
    getsw_one(one, val) {
      this.fullscreenLoading = true;
      if (val === 1) {
        this.Cardorm = {
          cmd: 1,
          cmdType: one,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        this.Cardorm = {
          cmd: 0,
          cmdType: one,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //清扫
    getsweep() {
      this.fullscreenLoading = true;
      if (this.sweeplist.switch === 1) {
        // this.clearform = {
        //   cmdType: 1,
        //   cmd: 0,
        //   vehicleId: this.id,
        // };
        this.Cardorm = {
          cmd: 0,
          cmdType: 10,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        // this.clearform = {
        //   cmdType: 1,
        //   cmd: 1,
        //   vehicleId: this.id,
        // };
        this.Cardorm = {
          cmd: 1,
          cmdType: 10,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //喷水
    getwater() {
      this.fullscreenLoading = true;
      if (this.sweeplist.witch_two === 1) {
        this.Cardorm = {
          cmd: 0,
          cmdType: 11,
          vehicleId: this.id,
          source: "WEB",
        };
      } else {
        this.Cardorm = {
          cmd: 1,
          cmdType: 11,
          vehicleId: this.id,
          source: "WEB",
        };
      }
      this.newfindCar();
    },
    //下发操作指令
    async newfindCar() {
      _Findpatrol(this.Cardorm).then((res) => {
        let blFoundError = false;
        if (!res) {
          blFoundError = true;
        } else {
          if (!res.success) {
            blFoundError = true;
          }
        }
        if (!blFoundError) {
          this.$message.success("下发指令成功");
          this.carDiaA = false;
          this.doorsDiaA = false;
          this.DemistingDiaA = false;
          this.sweepDiaA = false;
          this.waterDiaA = false;
          this.timer = setInterval(() => {
            this.fullscreenLoading = false;
            this.fullscreenLoading2 = false;
          }, 2000);
          if (this.Cardorm.cmdType === 7) {
            this.avoshow = true;
          }
        } else {
          this.$message.error("下发指令失败");
          this.carDiaA = false;
          this.doorsDiaA = false;
          this.avoshow = false;
          this.DemistingDiaA = false;
          this.sweepDiaA = false;
          this.waterDiaA = false;
          this.timer = setInterval(() => {
            this.fullscreenLoading = false;
            this.fullscreenLoading2 = false;
          }, 2000);
        }
      });
    },
    // endregion 下行指令

    //region 废弃代码
    getAngle(startPoint, endPoint) {
      if (!(startPoint && endPoint)) {
        return 0;
      }

      let dRotateAngle = Math.atan2(
        Math.abs(startPoint.lng - endPoint.lng),
        Math.abs(startPoint.lat - endPoint.lat)
      );
      if (endPoint.lng >= startPoint.lng) {
        if (endPoint.lat >= startPoint.lat) {
        } else {
          dRotateAngle = Math.PI - dRotateAngle;
        }
      } else {
        if (endPoint.lat >= startPoint.lat) {
          dRotateAngle = 2 * Math.PI - dRotateAngle;
        } else {
          dRotateAngle = Math.PI + dRotateAngle;
        }
      }
      dRotateAngle = (dRotateAngle * 180) / Math.PI;
      console.log(dRotateAngle);
      return dRotateAngle;
    },
    //endregion 废弃代码

    startAnimation() {
      this.marker.moveAlong(this.lineArr, {
        // 每一段的时长
        duration: 500, //可根据实际采集时间间隔设置
        // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
        autoRotation: true,
        circlable: true,
      });
    },

    async InitData() {
      //获取园区车辆实时数据
      await this.findParkByCarOperation();

      //显示超范提示
      this.CarOutRangeNotice();

      //初始化地图
      await this.initMap();

      //初始化地图上的线路标记
      await this.initLine();

      //初始化地图上的车辆标记
      await this.initCar();

      //定时刷新车辆数据
      // await this.RefreshCarPosition();
      this.mapTimes = setInterval(async () => {
        await this.RefreshCarPosition();
      }, 1000);
    },

    //获取园区车辆实时数据
    async findParkByCarOperation() {
      this.parkData = null;

      let res = await _FindParkByCarOperation({
        parkId: sessionStorage.getItem("parkId"),
      });

      if (res) {
        if (res.success) {
          this.parkData = res.data;
        }
      }
    },

    //初始化地图
    async initMap() {
      let routeSite = null;
      let CenterPoint = null;
      let arrCenter = [108.112583, 34.233588];
      let nLngLatArea = 0;
      let nZoom = 5;

      if (this.parkData) {
        routeSite = JSON.parse(this.parkData.parkScope);

        if (routeSite.length > 0) {
          routeSite.push(routeSite[0]); //绘制线路需要将第一个元素复制一个放到数组最后，完成高德线段的闭合绘制
        }

        //计算园区范围的中心点
        CenterPoint = calculateCenter(routeSite);

        //计算园区范围的面积值，用于地图缩放比例
        nLngLatArea = Math.round(computeSignedArea(routeSite));
        arrCenter = [CenterPoint.lng, CenterPoint.lat];

        //根据面积，配置地图缩放级别
        if (nLngLatArea <= 1) {
          nZoom = 16;
        } else if (nLngLatArea <= 2) {
          nZoom = 15;
        } else if (nLngLatArea <= 3) {
          nZoom = 14;
        } else if (nLngLatArea <= 4) {
          nZoom = 13;
        } else {
          nZoom = 12;
        }
      }

      if (!this.map) {
        this.map = new AMap.Map("container", {
          resizeEnable: true,
          center: arrCenter,
          zoom: nZoom,
        });

        if (routeSite) {
          this.CreateFence(routeSite);
        }
      }
    },

    //创建园区围栏
    CreateFence(fence_list) {
      let nCount = fence_list.length;
      let polylinePath = [];

      for (let i = 0; i < nCount; i++) {
        polylinePath.push(new AMap.LngLat(fence_list[i][0], fence_list[i][1]));
      }

      let polyline = new AMap.Polyline({
        path: polylinePath, // 设置线覆盖物路径
        showDir: false,
        strokeColor: "#ff6600", // 线颜色
        strokeWeight: 5, // 线宽
        strokeOpacity: 0.7,
        strokeStyle: "dashed",
      });

      this.map.add([polyline]);
    },

    //初始化地图上的线路标记
    async initLine() {
      if (!this.parkData) {
        return false;
      }

      let nCount = 0;

      if (this.parkData.carRouteList) {
        nCount = this.parkData.carRouteList.length;
      }

      if (nCount > 0) {
        this.routeList = this.parkData.carRouteList;

        for (let i = 0; i < nCount; i++) {
          if (this.routeList[i].routeSite) {
            var polyline2 = new AMap.Polyline({
              path: JSON.parse(this.routeList[i].routeSite), // 设置线覆盖物路径
              showDir: true,
              strokeColor:
                this.routeList[i].color === null
                  ? "#1683d9"
                  : this.routeList[i].color, // 线颜色
              strokeWeight: 10, // 线宽
            });

            this.map.add([polyline2]);
          }
        }
      }
    },

    //初始化地图上的车辆标记
    async initCar() {
      if (!this.parkData) {
        return false;
      }

      let nCount = 0;

      if (this.parkData.operationList) {
        nCount = this.parkData.operationList.length;
      }

      //添加车辆标记对象
      for (let i = 0; i < nCount; i++) {
        this.AddCarMarker(this.parkData.operationList[i]);
      }
    },

    //添加车辆地图标记
    AddCarMarker(car_data) {
      if (!car_data) {
        return false;
      }

      let sCarTypeImage = "";

      switch (car_data.parentCarTypeId) {
        case "1":
          sCarTypeImage = require("@/assets/images/map_car_type_1.png");
          break;

        case "2":
          sCarTypeImage = require("@/assets/images/map_car_type_2.png");
          break;

        case "3":
          sCarTypeImage = require("@/assets/images/map_car_type_3.png");
          break;

        case "4":
          sCarTypeImage = require("@/assets/images/map_car_type_4.png");
          break;

        case "5":
          sCarTypeImage = require("@/assets/images/map_car_type_5.png");
          break;

        default:
          sCarTypeImage = require("@/assets/images/minbus.png");
          break;
      }

      var that = this;
      var GPS_Position = [car_data.longitude, car_data.latitude];
      var AmapPosition;

      //region GPS坐标转换成高德坐标
      AMap.convertFrom(GPS_Position, "gps", function (status, result) {
        if (result.info === "ok") {
          AmapPosition = [
            result["locations"][0].lng,
            result["locations"][0].lat,
          ];

          let marker = new AMap.Marker({
            map: that.map,
            clickable: true,
            extData: car_data,

            //标记在地图上的位置
            position: AmapPosition,
            icon: new AMap.Icon({
              image: sCarTypeImage,
              imageSize: new AMap.Size(36, 36),
              size: new AMap.Size(36, 36),
            }),
            offset: new AMap.Pixel(-20, -17),

            //航向角
            angle: car_data.head,
          });

          marker.on("click", async function () {
            that.carStatusin = car_data.carStatus;
            that.id = car_data.carNumber;
            await that.getCarInfo(car_data.carNumber);
            if (
              that.CarData.vehicle.base.type === 1 ||
              that.CarData.vehicle.base.type === 2 ||
              that.CarData.vehicle.base.type === 3
            ) {
              that.isMicroBus = true;

              that.$nextTick(() => {
                that.drawSpeedChart(that.$refs.chartMicroBusSpeed);
                that.drawPowerChart(that.$refs.chartMicroBusPower);
              });
            } else if (that.CarData.vehicle.base.type === 4) {
              that.ispatrolCar = true;

              that.$nextTick(() => {
                that.drawSpeedChart(that.$refs.chartPatrolCarSpeed);
                that.drawPowerChart(that.$refs.chartPatrolCarPower);

                // that.drawLine3();
                // that.drawLine4();
              });
            } else if (that.CarData.vehicle.base.type === 5) {
              that.isSweeper = true;

              that.$nextTick(() => {
                that.drawSpeedChart(that.$refs.chartCleanCarSpeed);
                that.drawPowerChart(that.$refs.chartCleanCarPower);
                that.drawChartDustbinCapacity();
                that.drawChartSolutionTankCapacity();
              });
            }

            that.timer = setInterval(() => {
              that.getCarInfo(that.id);
            }, 5000);
          });

          that.carMarkerList.push({
            car_id: car_data.id,
            marker: marker,
            number: car_data.carNumber,
            carStatus: car_data.carStatus,
          });
        }
      });
      //endregion GPS坐标转换成高德坐标
    },

    //刷新车辆定位坐标
    async RefreshCarPosition() {
      await this.findParkByCarOperation();
      let blFoundError = false;

      if (!this.parkData) {
        blFoundError = true;
      } else {
        if (!this.parkData.operationList) {
          return false;
        }
      }

      if (blFoundError) {
        clearInterval(this.mapTimes);
        return false;
      }

      // if (!this.parkData) {
      // 	return false;
      // } else {
      // 	if (!this.parkData.operationList) {
      // 		return false;
      // 	}
      // }

      let UpdateCar = [];
      let AddCar = [];

      //提取需要删除的车辆
      let FoundDelCar, carMarker;
      for (let i = 0; i < this.carMarkerList.length; i++) {
        carMarker = this.carMarkerList[i];
        FoundDelCar = carMarker;

        for (const item of this.parkData.operationList) {
          if (carMarker.car_id === item.id) {
            FoundDelCar = null;
          }
        }

        if (FoundDelCar !== null) {
          this.carMarkerList.splice(i, 1);
          FoundDelCar.marker.remove();
          FoundDelCar = null;
        }
      }

      //提取需要更新的车辆
      for (const item of this.parkData.operationList) {
        for (const carMarker of this.carMarkerList) {
          if (item.id === carMarker.car_id) {
            UpdateCar.push(item);

            break;
          }
        }
      }

      //提取需要添加的车辆标记。如果需要更新的车辆数量与总数据数量不等，表示有新车辆加入
      for (const item of this.parkData.operationList) {
        if (this.carMarkerList.length <= 0) {
          AddCar.push(item);
        } else {
          let blIsAdd = true;

          for (const carMarker of this.carMarkerList) {
            if (item.id === carMarker.car_id) {
              blIsAdd = false;
              break;
            }
          }

          if (blIsAdd) {
            AddCar.push(item);
          }
        }
      }

      //region 更新现有车辆
      for (const item of UpdateCar) {
        for (const carMarker of this.carMarkerList) {
          if (item.id === carMarker.car_id) {
            var GpsPosition = [item.longitude, item.latitude];
            var CarHead = item.head;

            AMap.convertFrom(GpsPosition, "gps", function (status, result) {
              let AmapPosition = [];

              if (result.info === "ok") {
                AmapPosition = [
                  result["locations"][0].lng,
                  result["locations"][0].lat,
                ];
              } else {
                AmapPosition = GpsPosition;
              }

              //更新标记的定位
              carMarker.marker.setPosition(AmapPosition);

              //更新标记的航向角
              carMarker.marker.setAngle(CarHead);
            });

            break;
          }
        }
      }
      //endregion 更新现有车辆

      //region 添加车辆
      if (AddCar.length > 0) {
        var that = this;

        for (const item of AddCar) {
          this.AddCarMarker(item);
        }
      }
      //endregion 添加车辆
    },

    CarOutRangeNotice() {
      if (!this.parkData) {
        return false;
      } else {
        if (!this.parkData.parkScope) {
          return false;
        }

        if (!this.parkData.operationList) {
          return false;
        }
      }

      let CheckRangeResult;
      let WarningNoticeMessage;
      let WarningNoticeMessageDate;
      let nCount = 0;
      var that = this;

      if (this.parkData.operationList) {
        if (this.parkData.operationList.length > 0) {
          nCount = this.parkData.operationList.length;
        }
      }

      let routeSite = JSON.parse(this.parkData.parkScope);

      if (routeSite.length > 0) {
        routeSite.push(routeSite[0]); //绘制线路需要将第一个元素复制一个放到数组最后，完成高德线段的闭合绘制
      }

      for (let i = 0; i < nCount; i++) {
        let GpsPosition = [
          that.parkData.operationList[i].longitude,
          that.parkData.operationList[i].latitude,
        ];

        AMap.convertFrom(GpsPosition, "gps", function (status, result) {
          let AmapPosition = [];

          if (result.info === "ok") {
            AmapPosition = [
              result["locations"][0].lng,
              result["locations"][0].lat,
            ];
          } else {
            AmapPosition = GpsPosition;
          }

          let polygon = new AMap.Polygon({
            map: that.map,
            path: routeSite,
            strokeColor: "#ff33ff",
            strokeWeight: 6,
            strokeOpacity: 0,
            fillOpacity: 0,
            fillColor: "#1791fc",
            zIndex: 0,
          });

          CheckRangeResult = polygon.contains(AmapPosition);

          if (CheckRangeResult === false) {
            if (
              that.wnotice.carIdList.indexOf(
                that.parkData.operationList[i].id
              ) === -1
            ) {
              WarningNoticeMessage =
                that.parkData.operationList[i].carTypeName +
                "-" +
                that.parkData.operationList[i].carNumber +
                "，已超出园区围栏";
              WarningNoticeMessageDate = GetDateTime("yyyy-MM-dd hh:mm:ss");

              that.wnotice.message.push({
                datetime: WarningNoticeMessageDate,
                content: WarningNoticeMessage,
              });

              that.wnotice.carIdList.push(that.parkData.operationList[i].id);
            }
          }

          if (that.wnotice.message.length > 0) {
            that.wnotice.show = true;
          }
        });
      }
    },

    async getCarInfo(number) {
      let { success, data, message } = await _FindCarDataById({
        vid: number,
      });

      if (success && data) {
        //region 赋值
        this.CarData.type = data.vehicle.base.type; //车辆类型
        this.CarData.datetime = TimestampToDatetime(data.timestamp);
        this.CarData.vehicle.base.vid = data.vehicle.base.vid; //车编号
        this.CarData.vehicle.base.speed = data.vehicle.base.speed; //车速
        this.CarData.vehicle.base.power = data.vehicle.base.power; //电量
        this.CarData.vehicle.base.accumulate = data.vehicle.base.accumulate; //累计里程
        this.CarData.vehicle.base.recharge = data.vehicle.base.recharge; //续航里程
        this.CarData.vehicle.base.dmode = data.vehicle.base.dmode; //驾驶模式
        this.CarData.vehicle.base.dmodeStr = data.vehicle.base.dmodeStr; //驾驶模式
        this.CarData.vehicle.base.epb = data.vehicle.base.epb; //驾驶模式
        this.CarData.vehicle.base.epbDesc = data.vehicle.base.epbStr; //驾驶模式
        this.CarData.vehicle.base.defrost = data.vehicle.base.defrost; //除雾
        this.CarData.vehicle.base.type = data.vehicle.base.type; //车辆状态
        this.CarData.vehicle.base.dmode = data.vehicle.base.dmode;
        this.CarData.vehicle.ict.uspeed = data.vehicle.ict.uspeed; //上行速率
        this.CarData.vehicle.ict.dspeed = data.vehicle.ict.dspeed; //下行速率
        this.CarData.vehicle.ict.delay = data.vehicle.ict.delay; //网络延时（ms）
        this.CarData.vehicle.ict.loss = data.vehicle.ict.loss; //丢包率
        if (this.CarData.warning.length > 0) {
          this.CarData.warning.code = data.warning.code; //预警编号
          this.CarData.warning.data = data.warning.data; //描述
          this.CarData.warning.desc = data.warning.desc; //预警数据
        }

        this.CarData.business.cstaid = data.business.cstaid; //当前站点id
        this.CarData.business.cstaName =
          data.business.cstaName !== null ? data.business.cstaName : "-"; //当前站点名称
        this.CarData.business.nstaid = data.business.nstaid; //下一站点 ID
        this.CarData.business.nstaName =
          data.business.nstaName !== null ? data.business.nstaName : "-"; //下一站点名称
        this.CarData.business.nstadis = data.business.nstadis; //下一站点距离
        this.CarData.business.lineName =
          data.business.lineName !== null ? data.business.lineName : "-";
        this.CarData.vehicle.doors = data.vehicle.doors; //车门数据
        if (this.CarData.vehicle.doors.length > 0) {
          this.statusdoors = data.vehicle.doors[0].status;
        }
        if (data.business.task.length > 0) {
          let bas = JSON.parse(data.business.task[0].data);
          if (data.business.task[0].type === 1) {
            this.sweeplist.witch = bas.switch;
          } else if (data.business.task[0].type === 4) {
            this.sweeplist.witch_two = bas.switch;
          }
        }
        if (data.vehicle.conditioner.length > 0) {
          this.CarData.vehicle.conditioner.idx =
            data.vehicle.conditioner[0].idx; //空调数据
          this.CarData.vehicle.conditioner.status =
            data.vehicle.conditioner[0].status; //空调数据
          this.CarData.vehicle.conditioner.model =
            data.vehicle.conditioner[0].model; //模式
          this.CarData.vehicle.conditioner.cvolume =
            data.vehicle.conditioner[0].cvolume; //风量
          this.CarData.vehicle.conditioner.stemp =
            data.vehicle.conditioner[0].stemp; //设定温度
          this.CarData.vehicle.conditioner.ctemp =
            data.vehicle.conditioner[0].ctemp; //车内温度

          for (const conditioner of data.vehicle.conditioner) {
            this.CarData.vehicle.conditioner.modelList.push(
              conditioner.modelStr
            );
            this.CarData.vehicle.conditioner.cvolumeList.push(
              conditioner.cvolume
            );
            this.CarData.vehicle.conditioner.statusList.push(
              OffOnValueToLabel(conditioner.status)
            );
          }
        }

        this.CarData.vehicle.light.lowbeam = data.vehicle.light.lowbeam; //近光灯
        this.CarData.vehicle.light.interior = data.vehicle.light.interior; //车内灯
        this.CarData.vehicle.light.highbeam = data.vehicle.light.highbeam; //远光灯
        this.CarData.vehicle.light.rearfog = data.vehicle.light.rearfog; //后雾灯
        this.CarData.vehicle.light.leftturn = data.vehicle.light.leftturn; //左转灯
        this.CarData.vehicle.light.rightturn = data.vehicle.light.rightturn; //右转灯
        this.CarData.vehicle.base.seats = data.vehicle.base.seats; //座椅
        this.CarData.vehicle.base.seats = []; //座椅
        //endregion 赋值

        //region 报文
        let TableCol = [
          {
            cn_name: "产生时间",
            en_name: "timestamp",
            value: TimestampToDatetime(data.timestamp),
          },
          {
            cn_name: "充电状态",
            en_name: "charge",
            value: data.vehicle.base.chargeStr,
          },
          {
            cn_name: "车辆ID",
            en_name: "vid",
            value: data.vehicle.base.vid,
          },
          {
            cn_name: "车速",
            en_name: "speed",
            value: data.vehicle.base.speed + " km/h",
          },
          {
            cn_name: "epb 状态",
            en_name: "epb",
            value: data.vehicle.base.epbStr,
          },
          {
            cn_name: "gps状态",
            en_name: "status",
            value: GpsValueToLabel(data.vehicle.location.status),
          },
          {
            cn_name: "经度",
            en_name: "longitude",
            value: data.vehicle.location.longitude,
          },
          {
            cn_name: "纬度",
            en_name: "latitude",
            value: data.vehicle.location.latitude,
          },
          {
            cn_name: "航向角",
            en_name: "head",
            value: data.vehicle.location.head,
          },
          {
            cn_name: "下一站点ID",
            en_name: "nstaid",
            value:
              data.business.nstaid +
              "（" +
              this.CarData.business.nstaName +
              "）",
          },
          {
            cn_name: "下一站点距离",
            en_name: "nstadis",
            value: data.business.nstadis,
          },
          {
            cn_name: "当前站点ID",
            en_name: "cstaid",
            value:
              data.business.cstaid +
              "（" +
              this.CarData.business.cstaName +
              "）",
          },
          {
            cn_name: "近光灯状态",
            en_name: "lowbeam",
            value: OffOnValueToLabel(data.vehicle.light.lowbeam),
          },
          {
            cn_name: "远光灯状态",
            en_name: "highbeam",
            value: OffOnValueToLabel(data.vehicle.light.highbeam),
          },
          {
            cn_name: "后雾灯状态",
            en_name: "rearfog",
            value: OffOnValueToLabel(data.vehicle.light.rearfog),
          },
          {
            cn_name: "后雾灯状态",
            en_name: "rearfog",
            value: OffOnValueToLabel(data.vehicle.light.rearfog),
          },
          {
            cn_name: "车内灯状态",
            en_name: "interior",
            value: OffOnValueToLabel(data.vehicle.light.interior),
          },
          {
            cn_name: "左转向灯",
            en_name: "leftturn",
            value: OffOnValueToLabel(data.vehicle.light.leftturn),
          },
          {
            cn_name: "右转向灯",
            en_name: "rightturn",
            value: OffOnValueToLabel(data.vehicle.light.rightturn),
          },
          {
            cn_name: "空调状态",
            en_name: "status",
            value: this.CarData.vehicle.conditioner.statusList.join("、"),
          },
          {
            cn_name: "空调模式",
            en_name: "model",
            value: this.CarData.vehicle.conditioner.modelList.join("、"),
          },
          {
            cn_name: "风量档位",
            en_name: "cvolume",
            value: this.CarData.vehicle.conditioner.cvolumeList.join("、"),
          },
          {
            cn_name: "电池信息",
            en_name: "power",
            value: data.vehicle.base.power + "%",
          },
          {
            cn_name: "续航里程",
            en_name: "recharge",
            value: data.vehicle.base.recharge + " km",
          },
          {
            cn_name: "驾驶模式",
            en_name: "dmode",
            // value: DmodeValueToLabel(data.vehicle.base.dmode),
            value: data.vehicle.base.dmodeStr,
          },
          {
            cn_name: "座椅状态",
            en_name: "seats",
            value: SeatsStatusValueToLabel(data.vehicle.base.seats),
          },
          {
            cn_name: "安全带状态",
            en_name: "belts",
            value: BeltsStatusValueToLabel(data.vehicle.base.belts),
          },
          {
            cn_name: "上行通信速率",
            en_name: "uspeed",
            value: data.vehicle.ict.uspeed,
          },
          {
            cn_name: "下行通信速率",
            en_name: "dspeed",
            value: data.vehicle.ict.dspeed,
          },
          {
            cn_name: "网络延时",
            en_name: "delay",
            value: data.vehicle.ict.delay,
          },
          {
            cn_name: "丢包率",
            en_name: "loss",
            value: data.vehicle.ict.loss,
          },
          {
            cn_name: "线路ID",
            en_name: "line",
            value:
              data.business.line + "（" + this.CarData.business.lineName + "）",
          },
          {
            cn_name: "车辆类型",
            en_name: "type",
            value: data.vehicle.base.typeStr,
          },
          {
            cn_name: "电机转速",
            en_name: "rspeed",
            value:
              data.vehicle.electrics && data.vehicle.electrics.length > 0
                ? data.vehicle.electrics[0].rspeed + "（r/min）"
                : "",
          },
          {
            cn_name: "信号接收功率",
            en_name: "rsrp",
            value: data.vehicle.ict.rsrp,
          },
          {
            cn_name: "信噪比",
            en_name: "sinr",
            value: data.vehicle.ict.sinr,
          },
          {
            cn_name: "档位",
            en_name: "gear",
            value: data.vehicle.base.gear,
          },
        ];
        //endregion 报文

        this.tableData = TableCol;
        switch (this.CarData.vehicle.base.type) {
          case 1:
            if (this.isMicroBus) {
              this.$nextTick(() => {
                this.drawSpeedChart(this.$refs.chartMicroBusSpeed);
                this.drawPowerChart(this.$refs.chartMicroBusPower);
              });
            }

            break;

          case 4:
            if (this.ispatrolCar) {
              this.$nextTick(() => {
                this.drawSpeedChart(this.$refs.chartPatrolCarSpeed);
                this.drawPowerChart(this.$refs.chartPatrolCarPower);
              });
            }

            break;

          case 5:
            if (this.isSweeper) {
              this.$nextTick(() => {
                this.drawChartDustbinCapacity();
                this.drawChartSolutionTankCapacity();
              });
            }

            break;
        }
      }
    },

    //检查车辆是否超出园区范围
    CheckVehicleOutParkRange(park_range, car_position) {
      if (park_range.length <= 0) {
        return false;
      }

      if (car_position.length <= 0) {
        return false;
      }

      let polygon = new AMap.Polygon({
        map: this.map,
        path: park_range,
        strokeColor: "#ff33ff",
        strokeWeight: 6,
        strokeOpacity: 0,
        fillOpacity: 0,
        fillColor: "#1791fc",
        zIndex: 0,
      });

      return polygon.contains(car_position);
    },

    wNoticeKnow() {
      this.wnotice.show = false;
      this.wnotice.message = [];
    },

    //速度图表
    drawSpeedChart(objDom) {
      let newPromise = new Promise((resolve) => {
        resolve();
      });

      newPromise.then(() => {
        let myChart = echarts.init(objDom);
        let nSpeed = parseInt(this.CarData.vehicle.base.speed);

        this.drawLine(myChart, "#1490fe", nSpeed, 8, 0, 80);
      });
    },

    //电量图表
    drawPowerChart(objDom) {
      let newPromise = new Promise((resolve) => {
        resolve();
      });

      newPromise.then(() => {
        let objChart = echarts.init(objDom);
        let nPower = this.CarData.vehicle.base.power;

        this.drawLine(objChart, "#78c976", nPower, 4, 0, 100);
      });
    },

    //垃圾箱容量图表
    drawChartDustbinCapacity() {
      let newPromise = new Promise((resolve) => {
        resolve();
      });

      newPromise.then(() => {
        var objChartDom = this.$refs.chartDustbinCapacity;

        let myChart = echarts.init(objChartDom);

        this.drawLineTwo(myChart, 0.65, "#45e4a3");
      });
    },

    //水箱容量图表
    drawChartSolutionTankCapacity() {
      let newPromise = new Promise((resolve) => {
        resolve();
      });

      newPromise.then(() => {
        var objChartDom = this.$refs.chartSolutionTankCapacity;

        let myChart = echarts.init(objChartDom);

        this.drawLineTwo(myChart, 0.8, "#1891fe");
      });
    },

    drawLine(myChart, color, value, splitNumber, min, max) {
      myChart.setOption({
        series: [
          {
            type: "gauge",
            progress: {
              show: true,
              width: 3,
            },
            radius: "100%",
            itemStyle: {
              color: color, //设置圆环颜色
            },
            axisLine: {
              lineStyle: {
                width: 4,
                color: [
                  [0.0, "lime"],
                  [0, "#fa8565"],
                  [1, "#efefef"],
                ],
              },
            },
            pointer: {
              show: false, //是否显示指针,
              width: 10,
            },
            splitNumber, //刻度数量
            axisTick: {
              show: false,
              splitNumber: 10,
            },
            splitLine: {
              length: 8,
              lineStyle: {
                width: 2,
                color: "#999",
              },
              distance: 0,
            },
            axisLabel: {
              distance: 10,
              color: "#999",
              fontSize: 10,
            },
            anchor: {
              show: false,
              showAbove: false,
              size: 10,
              itemStyle: {
                borderWidth: 10,
              },
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: 20,
              offsetCenter: [0, "0%"],
            },
            data: [
              {
                value,
              },
            ],
            min,
            max,
          },
        ],
      });
    },

    drawLineTwo(myChart, value, color) {
      myChart.setOption({
        series: [
          {
            radius: "90%",
            type: "liquidFill",
            data: [value],
            color: [color],
            label: {
              fontSize: 15,
              color: "#308055",
              fontFamily: "DINAlternate-Bold, DINAlternate",
            },
            outline: {
              borderDistance: 4,
              itemStyle: {
                borderWidth: 1,
                borderColor: color,
              },
            },
            backgroundColor: "white",
            backgroundStyle: {
              color: "GhostWhite",
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.el-drawer__header {
  margin-bottom: 0px !important;
}

/deep/ .el-input-number {
  margin: 0px auto;
  margin-top: 50px;
}
</style>

<style lang="less" scoped>
.chart-car {
  width: 200px;
  height: 153px;
}

.chart-qs-car {
  width: 126px;
  height: 126px;
}

.chart-speed {
  margin-left: 15px;
}

.chart-power {
  margin-left: -45px;
}

.speed-info {
  width: 100%;
  position: relative;
  margin-left: 40px;
  margin-top: -65px;

  div {
    text-align: center;
  }
}

.power-info {
  width: 100%;
  position: relative;
  margin-left: -20px;
  margin-top: -65px;

  div {
    text-align: center;
  }
}

.wnotice {
  width: 347px;
  min-height: 145px;
  background-color: #fff3f3;
  position: fixed; /* margin-top: 170px; top: 0; left: 50%;*/
  left: 200px;
  right: 0;
  margin: 170px auto 0 auto;
  top: 0;
  z-index: 1000;
  border-radius: 4px;
  -webkit-border-radius: 4px;

  .wnotice-header {
    width: 100%;
    height: 18px;

    .wnotice-header-boder {
      width: 100%;
      height: 4px;
      border-radius: 100px 100px 0px 0px;
      -webkit-border-radius: 100px 100px 0px 0px;
      background-color: #ff7760;
    }

    .wnotice-header-title {
      width: 110px;
      height: 18px;
      line-height: 17px;
      background: #ff7760;
      text-align: center;
      margin: -4px auto 0 auto;
      padding: 0;
      border-radius: 0px 0px 11px 11px;
      -webkit-border-radius: 0px 0px 11px 11px;

      span {
        font-size: 12px;
        font-weight: 400;
        color: #fff;
      }
    }
  }

  .wnotice-body {
    width: 100%;
    min-height: 82px;
    padding: 10px 20px;

    .wnotice-body-content {
      width: 100%;
      min-height: 62px;

      .content-title {
        width: 100%;
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 27px;
        margin: 0;
        background: url("../../assets/images/warning_notice.png") no-repeat left
          center;
        background-size: 22px auto;
      }

      .content-body {
        width: 100%;
        min-height: 40px;
        max-height: 260px;
        overflow-y: auto;

        .content-item {
          width: 100%;
          line-height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          margin-top: 10px;
          padding-left: 27px;

          .content-body-msg-date {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            display: block;
          }
        }
      }
    }
  }

  .wnotice-footer {
    width: 100%;
    height: 40px;
    text-align: right;
    padding-left: 14px;
    padding-right: 14px;

    .btn-close {
      font-size: 14px;
      font-weight: 400;
      color: #1683d9;
      cursor: pointer;
    }
  }
}

.largMap {
  width: 100%;
  height: 100%;
  position: relative;
}

.leftArr {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  text-align: center;
  height: 100px;
  line-height: 100px;
  width: 40px;
  background: rgba(22, 131, 217, 0.039);
  cursor: pointer;
  outline: none;
}

.time {
  position: absolute;
  top: 27px;
  left: 190px;
  font-size: 16px;
  color: #999;
}

.drawContent {
  display: flex;
}

.drawContentLeft {
  width: 35%;
  border-right: 4px solid rgba(151, 151, 151, 0.15);
  box-sizing: border-box;
  padding: 0px 30px 0px 0px;
}

.drawContentRight {
  width: 65%;
  box-sizing: border-box;
  padding: 0px 30px;
}

.drawContentHead {
  margin-top: 10px;
  margin-bottom: 10px;
}

.licenseWords {
  font-size: 12px;
  color: #333;
  margin-bottom: 12px;
}

.licenseNumber {
  color: #000000;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.onLine {
  height: 22px;
  border-radius: 4px;
  border: 1px solid #42c382;
  color: #3dc27f;
  font-size: 14px;
  padding: 0px 9px;
  line-height: 22px;
  margin-left: 15px;
}

.onLinetwo {
  height: 22px;
  border-radius: 4px;
  border: 1px solid #999999;
  color: #999999;
  font-size: 14px;
  padding: 0px 9px;
  line-height: 22px;
  margin-left: 15px;
}

.vehIcon {
  display: flex;
  background: #f5f7fa;
  border-radius: 4px;
  justify-content: space-around;
  height: 41px;
  align-items: center;
  color: #000;
  font-size: 14px;
  margin: 19px 0px 13px;
}

.vehIcon img {
  vertical-align: text-top;
}

.vehIcon span {
  margin-left: 5px;
}

.nextS {
  display: flex;
  align-items: center;
}

.nextStation {
  color: #999999;
  font-size: 14px;
}

.nextStation > span {
  color: #333;
  font-size: 14px;
}

.location {
  margin-left: 32px;
  color: #333;
  font-size: 14px;
}

.location img {
  vertical-align: text-bottom;
}

.locationWords {
  font-size: 16px;
  color: #4287ff;
  margin: 0px 5px;
}

.drivingMode {
  margin: 15px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 20px;
}

.drivingMode > span:first-child {
  font-size: 14px;
  color: #999;
}

.drivingMode img {
  vertical-align: text-bottom;
  margin-left: 15px;
}

.drivingMode > span:last-child {
  font-size: 14px;
  color: #4287ff;
  margin-left: 4px;
}

.ec {
  display: flex;
  justify-content: space-between;
}

.ec1 {
  position: relative;
  width: 146px;
}

.km {
  position: absolute;
  left: 50%;
  bottom: 40px;
  font-size: 12px;
  color: #999;
  transform: translateX(-50%);
  background-color: #63a35c;
}

.kmTwo {
  position: absolute;
  left: 50%;
  bottom: 40px;
  font-size: 12px;
  color: #999;
  transform: translateX(-50%);
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.speed {
  position: absolute;
  bottom: 13px;
  font-size: 12px;
  color: #333;
  left: 50%;
  transform: translateX(-50%);
}

.mileage {
  display: flex;
  justify-content: space-around;
}

.mileage > div {
  text-align: center;
  font-size: 12px;
  color: #333333;
}

.mileage > div > div {
  margin-top: 7px;
}

.mileageBold {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.warning {
  height: 77px;
  background: #fff3f3;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-top: 30px;
  font-size: 12px;
  color: #333;
}

.warningHead {
  height: 4px;
  background: #ff7760;
}

.warningNotice {
  width: 110px;
  height: 18px;
  background: #ff7760;
  border-radius: 0px 0px 11px 11px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.Tertiary {
  margin-top: 15px;
  margin-left: 10px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.fault {
  font-size: 12px;
  color: #333333;
  margin-top: 5px;
  margin-left: 10px;
}

.doorImg {
  text-align: center;
}

.doorLock {
  text-align: center;
  font-size: 14px;
  color: #999;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.doorLock .el-switch,
.demisting .el-switch {
  margin-left: 14px;
}

.doorLock > div {
  width: 50%;
  margin-top: 21px;
}

.doorLock > div > span:first-child {
  width: 100px;
  text-align: right;
  display: inline-block;
}

.doorLock > div img {
  vertical-align: text-top;
  margin-left: 20px;
  margin-right: 5px;
}

.lockTheDoor {
  margin-top: 57px;
  width: 100%;
  text-align: center;
}

.lockTheDoor img {
  cursor: pointer;
}

.airConditioner {
}

.airConditionerHead {
  display: flex;
}

.temperature {
  width: 20%;
  box-sizing: border-box;
  padding-top: 40px;
}

.temperature > p:first-child {
  color: #999999;
  font-size: 14px;
}

.centigrade {
  color: #000000;
  font-size: 64px;
}

.airConditionerImg {
  width: 80%;
}

.AirConditioningStatus {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  color: #999;
}

.AirConditioningStatus img {
  margin-left: 20px;
  vertical-align: text-bottom;
  margin-right: 4px;
}

.state {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.state img {
  cursor: pointer;
}

// .wind {
//   width: 166px;
//   height: 64px;
//   background: #f5f7fa;
//   border: 1px solid #ededed;
//   margin: 0px auto;
//   margin-top: 50px;
//   display: flex;
//   line-height: 64px;
// }

// .wind > :first-child {
//   width: 51px;
//   text-align: center;
//   cursor: pointer;
// }

// .wind > :nth-child(2) {
//   width: 62px;
//   text-align: center;
//   font-size: 18px;
//   color: #000000;
//   background-color: #fff;
// }

// .wind > :nth-child(3) {
//   width: 51px;
//   text-align: center;
//   cursor: pointer;
// }

.demisting img {
  vertical-align: middle;
  margin: 0px 8px 0px 21px;
}

.video {
  text-align: center;
}

.lookVideo {
  margin-top: 90px;
}

.epbState {
  display: flex;
  justify-content: space-around;
  margin: 30px 0px;
  font-size: 14px;
  color: #999;
}

.epbState img {
  vertical-align: middle;
  margin: 0px 14px 0px 5px;
}

.clean {
  display: flex;
  justify-content: center;
}

.clean span {
  margin-left: 30px;
}

.openClean {
  width: 166px;
  height: 60px;
  font-size: 20px;
  color: #fff;
  line-height: 60px;
  text-align: center;
  background-color: #abb7bb;
  border-radius: 30px;
  margin: 30px auto;
  cursor: pointer;
  outline: none;
}

.sprayWater {
  background-color: #0a94e1;
}

.lineMap {
  width: 185px;
  /* height:100%; */
  /* overflow:auto; */
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  color: #333;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}

.lineMap > div {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  margin-top: 10px;
}

.technology {
  width: 30px;
  height: 5px;
  background-color: #1683d9;
  border-radius: 3px;
  margin-right: 20px;
}

.businessAffairs {
  width: 30px;
  height: 5px;
  background: #c72928;
  border-radius: 3px;
}

.production {
  width: 30px;
  height: 5px;
  background: #3a0f7a;
  border-radius: 3px;
}

.liquidfill {
  width: 100%;
  height: 126px;
  display: flex;
  justify-content: center;
  margin-top: 27px;
}

#container {
  width: 100%;
  height: 100%;
}
</style>
