<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="list.carNumber"
          placeholder="车辆编号"
          class="centerInput"
        ></el-input
      ></el-form-item>
      <!-- <el-form-item>
        <el-select
          v-model="list.carStatus"
          placeholder="运营模式"
          class="centerSelInput centerInput"
        >
          <el-option
            v-for="item in ModeList"
            :key="item.id"
            :label="item.mode"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item> -->
      <el-form-item>
        <el-select
          v-model="list.operationStatus"
          placeholder="运营状态"
          class="centerSelInput"
        >
          <el-option
            v-for="item in StatusList"
            :key="item.id"
            :label="item.status"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item>
        <button class="centerCzbtn ml20" type="button" @click="getList">
          搜索
        </button>
        <button class="centerBtn resetbtn" type="button" @click="cancel">
          重置
        </button>
        <button class="centerBtn" type="button" @click="getOpRecord">
          车辆运营记录
        </button></el-form-item
      >
    </el-form>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      :loading="loading"
      @getAjax="getTableData"
    />
    <el-dialog title="切换运营状态" :visible.sync="resetcarstatus" width="30%">
      <p>
        <span class="textcolor">当前状态</span>
        <span class="textcolor2" v-if="carstdate.operationStatus == 1"
          >空闲</span
        >
        <span class="textcolor2" v-else-if="carstdate.operationStatus == 2"
          >运营中</span
        >
        <span class="textcolor2" v-else-if="carstdate.operationStatus == 3"
          >维修中</span
        >
        <span class="textcolor2" v-else-if="carstdate.operationStatus == 4"
          >运营下线</span
        >
      </p>
      <div>
        <span class="red">*</span><span class="textcolor">切换运营状态至</span>
        <el-radio
          v-model="form.carStatus"
          label="1"
          v-if="
            carstdate.operationStatus == 3 || carstdate.operationStatus == 4
          "
          >空闲</el-radio
        >
        <el-radio
          v-model="form.carStatus"
          label="3"
          v-if="
            carstdate.operationStatus == 1 || carstdate.operationStatus == 4
          "
          >维修中</el-radio
        >
        <el-radio
          v-model="form.carStatus"
          label="4"
          v-if="
            carstdate.operationStatus == 1 || carstdate.operationStatus == 3
          "
          >运营下线</el-radio
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn ml20" @click="getOpStatu">确 定</button>
        <button class="centerBtn resetbtn" @click="resetcarstatus = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <el-dialog title="切换运营模式" :visible.sync="operationMode" width="30%">
      <p>
        <span class="textcolor" style="margin-left: 14px"
          >当前车辆运营模式</span
        >
        <span class="textcolor2" v-if="carOpmodel.practices == 1"
          >普通模式</span
        >
        <span class="textcolor2" v-if="carOpmodel.practices == 2">VIP模式</span>
      </p>
      <p>
        <span class="textcolor">是否切换运营模式至</span>
        <span class="textcolor2" v-if="carOpmodel.practices == 2"
          >普通模式?</span
        >
        <span class="textcolor2" v-if="carOpmodel.practices == 1"
          >VIP模式?</span
        >
      </p>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn ml20" @click="getoder">确 定</button>
        <button class="centerBtn resetbtn" @click="operationMode = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import { _MinBusModel, _MinBusDel, _MinBusOrderStatus } from "@/api/minBus";
import { _TuBusList, _TuBusStatus } from "@/api/tuBus";
export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      resetcarstatus: false,
      operationMode: false,
      formLabelWidth: "100px",
      isAdd: false,
      carstdate: [],
      carOpmodel: [],
      total: 0,
      ModeList: [
        { id: "1", mode: "普通模式" },
        { id: "2", mode: "VIP模式" },
      ],
      operationStatus: [],
      StatusList: [
        { id: "1", status: "空闲中" },
        { id: "2", status: "运营中" },
        { id: "3", status: "维修中" },
        { id: "4", status: "运营下线" },
      ],
      searchObj: {
        vehicleNumber: "",
        operationMode: "",
        operationStatus: "",
      },
      form: {
        carId: "",
        carStatus: null,
      },
      formB: {
        carId: "",
        carType: "",
      },
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carType",
        },
        {
          label: "车辆子类型",
          prop: "sonCarType",
        },
        {
          label: "车辆状态",
          render: (h, params) => {
            if (params.row.carStatus == 1) {
              return h("span", "在线");
            } else {
              return h("span", "离线");
            }
          },
        },
        {
          label: "运营状态",
          render: (h, params) => {
            if (params.row.operationStatus == 1) {
              return h("span", "空闲");
            } else if (params.row.operationStatus == 2) {
              return h("span", "运营中");
            } else if (params.row.operationStatus == 3) {
              return h("span", "维修中");
            } else if (params.row.operationStatus == 4) {
              return h("span", "运营下线");
            }
          },
        },
        {
          label: "行驶路线",
          prop: "carRoute",
        },
        {
          label: "安全员",
          prop: "securityName",
        },
        {
          label: "操作",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class:
                    params.row.operationStatus == "2" ? "colorB" : "operationA",
                  on: {
                    click: () => {
                      if (params.row.operationStatus == "2") {
                        this.resetcarstatus = false;
                      } else {
                        this.resetcarstatus = true;
                      }
                      this.carstdate = params.row;
                      this.form.carId = params.row.id;
                      if (params.row.operationStatus == "1") {
                        this.form.carStatus = "3";
                      } else if (
                        params.row.operationStatus == "3" ||
                        params.row.operationStatus == "4"
                      ) {
                        this.form.carStatus = "1";
                      }
                    },
                  },
                },
                "切换运营状态"
              ),
            ]);
          },
        },
      ],
      list: {
        page: 1,
        size: 10,
        carNumber: "",
        carStatus: "",
        operationStatus: "",
        parkId: sessionStorage.getItem("parkId"),
      },
      oderStatue: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getOpRecord() {
      this.$router.push("/vehicleCRecord");
    },
    cancel() {
      this.list = {
        page: this.list.page,
        size: this.list.size,
        carNumber: "",
        carStatus: "",
        parkId: sessionStorage.getItem("parkId"),
      };
      // this.getList();
    },
    getoder() {},
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      this.getList();
    },
    async getList() {
      let { success, data, message } = await _TuBusList(this.list);
      if (success) {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
    async getOpStatu() {
      let { success, data, message } = await _TuBusStatus(this.form);
      if (success) {
        this.$message.success("切换运营状态成功");
        this.loading = false;
        this.getList();
      }
      this.resetcarstatus = false;
    },
    async getOpMdel() {
      let { success, data, message } = await _MinBusModel(this.formB);
      if (success) {
        this.$message.success("切换运营模式成功");
        this.loading = false;
        this.getList();
      }
      this.operationMode = false;
    },
    async getOderStatus(id) {
      let { success, data, message } = await _MinBusOrderStatus({
        carRegisterId: id,
      });
      if (success) {
        this.loading = false;
        this.oderStatue = res.data.isOrderStatus;
      }
      this.operationMode = false;
    },
  },
};
</script>

<style scoped>
/deep/ .colorB {
  /* color: red; */
  /* -webkit-touch-callout: none; */
  cursor: not-allowed;
  margin-right: 20px;
}
</style>